import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {
  UserProfileFragment,
  useMyProfileQuery,
  useLogoutMutation
} from "../../generated/graphql";

export type ProfileContextValues = {
  profile?: UserProfileFragment | null | undefined;
  loading: boolean;
  impersonating?: boolean;
  logout: () => Promise<any>;
}

export const ProfileContext = createContext<ProfileContextValues>({
  loading: true, logout: async () => {
  }
})

export const ProfileProvider: FC<PropsWithChildren> = ({children}) => {
  const query = useMyProfileQuery();
  const profile = query.data?.myProfile?.id ? query.data.myProfile : null
  const [logout] = useLogoutMutation()

  return <ProfileContext.Provider value={{
    profile,
    loading: query.loading,
    logout
  }}>
    {children}
  </ProfileContext.Provider>
}

export const useProfile = () => useContext(ProfileContext)