import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  JSONString: any;
  Time: any;
  UUID: any;
};

export type ActiveUserFilterSetInput = {
  email_Icontains?: Maybe<Scalars['String']>;
};

export type AlertWarning = {
  __typename?: 'AlertWarning';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  zone: Zone;
  metric: AlertWarningMetric;
  day: EnvironmentalDataDailyType;
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  avgDeviation: Scalars['Float'];
  maxDeviation: Scalars['Float'];
  position: AlertWarningPosition;
  message: Scalars['String'];
  envType: AlertWarningEnvType;
  timeOfDay: AlertWarningTimeOfDay;
  stage: Stage;
};

export enum AlertWarningEnvType {
  Veg = 'VEG',
  Flower = 'FLOWER',
  NoTargets = 'NO_TARGETS'
}

export enum AlertWarningMetric {
  TempF = 'TEMP_F',
  HumidityPercent = 'HUMIDITY_PERCENT',
  Co2Ppm = 'CO2_PPM',
  Vpd = 'VPD'
}

export enum AlertWarningPosition {
  Above = 'ABOVE',
  Below = 'BELOW'
}

export enum AlertWarningTimeOfDay {
  Day = 'DAY',
  Night = 'NIGHT'
}

export type AnalyticsChartData = {
  __typename?: 'AnalyticsChartData';
  data: Array<AnalyticsDataPoint>;
  name: Scalars['String'];
  alertWarnings: Array<AlertWarning>;
};

export type AnalyticsDataPoint = {
  __typename?: 'AnalyticsDataPoint';
  x: Scalars['DateTime'];
  y: Scalars['Float'];
};

export type ApproveHarvestCompletionInput = {
  harvestGroupId: Scalars['ID'];
};

export type ApproveLogEntryInput = {
  id: Scalars['ID'];
};

export type AssignProcessingJobToUserInput = {
  jobId: Scalars['ID'];
  assigneeId: Scalars['ID'];
  newStatus?: Maybe<Scalars['Boolean']>;
};

export type BudFormFactor = {
  __typename?: 'BudFormFactor';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  createdBy: UserProfile;
  orderPosition: Scalars['Int'];
  classification: BudFormFactorClassification;
};

export enum BudFormFactorClassification {
  Flower = 'FLOWER',
  Trim = 'TRIM',
  Unfinished = 'UNFINISHED',
  Waste = 'WASTE'
}

export type BudFormFactorFilterSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  classification?: Maybe<Scalars['String']>;
};

export type BudFormFactorResult = BudFormFactor | Error;

export type BudFormFactorYield = {
  __typename?: 'BudFormFactorYield';
  id: Scalars['UUID'];
  budFormFactor: BudFormFactor;
  harvestStrainYield: HarvestStrainYield;
  numberOfGrams: Scalars['Float'];
  budFormFactorId: Scalars['String'];
};

export type CalendarBranch = {
  __typename?: 'CalendarBranch';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  createdBy?: Maybe<UserProfile>;
  lastUpdatedBy?: Maybe<UserProfile>;
  organization: Organization;
  status: CalendarBranchStatus;
  newStages: Array<Stage>;
  newTasks: Array<Task>;
  calendarBranchReviews: Array<CalendarBranchReview>;
  calendarBranchApprovals: Array<CalendarBranchApproval>;
  stageUpdates: Array<StageUpdate>;
  taskUpdates: Array<TaskUpdate>;
  stages: Array<Stage>;
  tasks: Array<Task>;
  updateCount: Scalars['Int'];
  conflictCount: Scalars['Int'];
  updates: Array<UpdateDisplay>;
  conflicts: Array<ConflictDisplay>;
};


export type CalendarBranchStagesArgs = {
  dateRange?: Maybe<DateRangeInput>;
};


export type CalendarBranchTasksArgs = {
  dateRange?: Maybe<DateRangeInput>;
};

export type CalendarBranchApproval = {
  __typename?: 'CalendarBranchApproval';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  calendarBranch: CalendarBranch;
  createdBy?: Maybe<UserProfile>;
};

export type CalendarBranchFilterSetInput = {
  name?: Maybe<Scalars['String']>;
};

export type CalendarBranchMergeLogEntry = {
  __typename?: 'CalendarBranchMergeLogEntry';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  organization?: Maybe<Organization>;
  calendarBranchName: Scalars['String'];
  mergedBy?: Maybe<UserProfile>;
  mergedAt: Scalars['DateTime'];
  changelog?: Maybe<Scalars['GenericScalar']>;
};

export type CalendarBranchMergeLogEntryFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export type CalendarBranchResult = CalendarBranch | Error;

export type CalendarBranchReview = {
  __typename?: 'CalendarBranchReview';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  calendarBranch: CalendarBranch;
  comments: Scalars['String'];
  createdBy?: Maybe<UserProfile>;
};

export enum CalendarBranchStatus {
  Created = 'CREATED',
  ChangesMade = 'CHANGES_MADE',
  ReviewRequested = 'REVIEW_REQUESTED',
  ChangesRequested = 'CHANGES_REQUESTED',
  Approved = 'APPROVED',
  MainBranch = 'MAIN_BRANCH'
}

export type CompleteHarvestInput = {
  harvestGroupId?: Maybe<Scalars['ID']>;
};

export type CompletedHarvestGroupFilterSetInput = {
  id?: Maybe<Scalars['String']>;
  unarchived?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type CompletedHarvestGroupFilterSetOutput = {
  __typename?: 'CompletedHarvestGroupFilterSetOutput';
  id?: Maybe<Scalars['String']>;
  unarchived?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type ConflictDisplay = {
  __typename?: 'ConflictDisplay';
  id: Scalars['String'];
  description: Scalars['String'];
  harvestGroupName: Scalars['String'];
  name: Scalars['String'];
  conflictType: Scalars['String'];
  calendarBranchId: Scalars['String'];
  justification: Scalars['String'];
  colorHexCode: Scalars['String'];
  schedulingRule: Scalars['String'];
};

export type CreateBudFormFactorInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  classification: Scalars['String'];
  orderPosition?: Maybe<Scalars['Int']>;
};

export type CreateBudFormFactorYieldInput = {
  budFormFactorId: Scalars['ID'];
  numberOfGrams: Scalars['Decimal'];
};

export type CreateCalendarBranchInput = {
  name: Scalars['String'];
};

export type CreateEnvironmentalTargetSetInput = {
  dayStartTime?: Maybe<Scalars['String']>;
  nightStartTime?: Maybe<Scalars['String']>;
  environmentalTargetType: Scalars['String'];
  zoneId?: Maybe<Scalars['ID']>;
  daysInStageStart?: Maybe<Scalars['Int']>;
  daysInStageEnd?: Maybe<Scalars['Int']>;
  dayTempLow?: Maybe<Scalars['Int']>;
  dayTempHigh?: Maybe<Scalars['Int']>;
  nightTempLow?: Maybe<Scalars['Int']>;
  nightTempHigh?: Maybe<Scalars['Int']>;
  dayVpdLow?: Maybe<Scalars['Float']>;
  dayVpdHigh?: Maybe<Scalars['Float']>;
  nightVpdLow?: Maybe<Scalars['Float']>;
  nightVpdHigh?: Maybe<Scalars['Float']>;
  dayHumidityLow?: Maybe<Scalars['Int']>;
  dayHumidityHigh?: Maybe<Scalars['Int']>;
  nightHumidityLow?: Maybe<Scalars['Int']>;
  nightHumidityHigh?: Maybe<Scalars['Int']>;
  dayCo2Low?: Maybe<Scalars['Int']>;
  dayCo2High?: Maybe<Scalars['Int']>;
  nightCo2Low?: Maybe<Scalars['Int']>;
  nightCo2High?: Maybe<Scalars['Int']>;
};

export type CreateExpenseInput = {
  date?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
  categoryId: Scalars['String'];
  costInCents: Scalars['Int'];
  hourlyCostInCents?: Maybe<Scalars['Int']>;
  hoursWorked?: Maybe<Scalars['Float']>;
  allocationRatio?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
};

export type CreateHarvestGroupInput = {
  name: Scalars['String'];
  flowerZoneId: Scalars['String'];
  stages: Array<CreateStageInput>;
  zoneIteration: Scalars['String'];
  colorHexCode?: Maybe<Scalars['String']>;
  templateId: Scalars['ID'];
};

export type CreateHarvestGroupTemplateInput = {
  name: Scalars['String'];
  initialTemplate: CreateHarvestGroupTemplateVersionInput;
};

export type CreateHarvestGroupTemplateVersionInput = {
  stageTemplates: Array<Maybe<CreateStageTemplateInput>>;
};

export type CreateHarvestReportInput = {
  harvestGroupId: Scalars['ID'];
  strainYields?: Maybe<Array<CreateHarvestStrainYieldInput>>;
};

export type CreateHarvestStrainYieldInput = {
  strainId: Scalars['ID'];
  finalPlantCount: Scalars['Int'];
  totalGrams: Scalars['Decimal'];
  budFormFactorYields?: Maybe<Array<CreateBudFormFactorYieldInput>>;
};

export type CreateMaintenanceRequestInput = {
  description: Scalars['String'];
  firstObservedAt: Scalars['Date'];
  dueDate: Scalars['Date'];
  urgent?: Maybe<Scalars['Boolean']>;
  equipmentInoperable?: Maybe<Scalars['Boolean']>;
  lockOutTagOut?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  tags: Array<Maybe<Scalars['String']>>;
};

export type CreateNutrientInput = {
  name: Scalars['String'];
  unitOfMeasure: Scalars['String'];
  priceInCentsPerUnit?: Maybe<Scalars['Decimal']>;
  boughtPrice?: Maybe<Scalars['Decimal']>;
  boughtAmount?: Maybe<Scalars['Decimal']>;
  boughtUnit?: Maybe<Scalars['String']>;
};

export type CreateOrganizationAndFirstUserInput = {
  name: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type CreateProcessingGroupInput = {
  name: Scalars['String'];
  fromHarvestGroupId?: Maybe<Scalars['String']>;
  processingGroupTemplateVersionId: Scalars['ID'];
  stages: Array<CreateProcessingGroupStageInput>;
  strains: Array<CreateProcessingGroupStrainInput>;
  colorHexCode?: Maybe<Scalars['String']>;
};

export type CreateProcessingGroupStageInput = {
  processingStageTemplateId: Scalars['ID'];
  startDate: Scalars['Date'];
  schedulingScope: Scalars['String'];
  strainStages: Array<CreateProcessingGroupStrainStageInput>;
  name?: Maybe<Scalars['String']>;
};

export type CreateProcessingGroupStrainInput = {
  strainId: Scalars['String'];
  inventoryGrams: Array<CreateProcessingGroupStrainInventoryGramsInput>;
  valid: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type CreateProcessingGroupStrainInventoryAdjustmentInput = {
  id: Scalars['ID'];
  formFactorId: Scalars['ID'];
  adjustmentGrams?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateProcessingGroupStrainInventoryGramsInput = {
  formFactorId: Scalars['ID'];
  grams: Scalars['Decimal'];
};

export type CreateProcessingGroupStrainStageInput = {
  strainId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export type CreateProcessingGroupTemplateInput = {
  name: Scalars['String'];
  initialTemplate: CreateProcessingGroupTemplateVersionInput;
};

export type CreateProcessingGroupTemplateVersionInput = {
  stageTemplates?: Maybe<Array<Maybe<CreateProcessingStageTemplateInput>>>;
};

export type CreateProcessingJobInput = {
  strainStageId: Scalars['ID'];
  jobStatus?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['ID']>;
  inputGrams: Scalars['Decimal'];
  inputType: Scalars['String'];
  dontTrackUserGpm?: Maybe<Scalars['Boolean']>;
};

export type CreateProcessingJobLogInput = {
  jobId: Scalars['ID'];
  logType: Scalars['String'];
  performerId?: Maybe<Scalars['ID']>;
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  breakMinutes: Scalars['Decimal'];
  leftoverInputGrams?: Maybe<Scalars['Decimal']>;
  outputGrams: Array<JobLogOutputGramsInput>;
};

export type CreateProcessingStageTemplateInput = {
  createdStageName: Scalars['String'];
  numberOfDays: Scalars['Int'];
  trackPerformance: Scalars['Boolean'];
  orderInProcess: Scalars['Int'];
  progressScope?: Maybe<Scalars['String']>;
  schedulingScope?: Maybe<Scalars['String']>;
  inputTypes: Array<Scalars['String']>;
  outputTypes: Array<Scalars['String']>;
};

export type CreateRecipeComponentInput = {
  nutrientId: Scalars['String'];
  volume: Scalars['Decimal'];
};

export type CreateRecipeInput = {
  name: Scalars['String'];
  applicationType: Scalars['String'];
  components: Array<Maybe<CreateRecipeComponentInput>>;
};

export type CreateStageInput = {
  name: Scalars['String'];
  startDate: Scalars['Date'];
  zoneId: Scalars['String'];
  tasks: Array<CreateTaskInlineInput>;
  strains: Array<CreateStrainInStageInput>;
  templateId: Scalars['ID'];
};

export type CreateStageTemplateInput = {
  createdStageName: Scalars['String'];
  createdStageCalendarDisplayName?: Maybe<Scalars['String']>;
  numberOfDays: Scalars['Int'];
  maxNumberOfDays: Scalars['Int'];
  minNumberOfDays: Scalars['Int'];
  designatedZones: Array<Maybe<Scalars['String']>>;
  nextStageTemplate?: Maybe<Scalars['String']>;
  taskTemplates?: Maybe<Array<Maybe<CreateTaskTemplateInput>>>;
  environmentalTargetType: Scalars['String'];
};

export type CreateStrainInStageInput = {
  strainId: Scalars['ID'];
  numberOfPlants: Scalars['Int'];
};

export type CreateStrainInput = {
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  subspecies: Scalars['String'];
  documentationUrl?: Maybe<Scalars['String']>;
};

export type CreateTaskInlineInput = {
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  templateId: Scalars['ID'];
};

export type CreateTaskInput = {
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  assignedToId?: Maybe<Scalars['ID']>;
  harvestGroupId: Scalars['ID'];
  priority: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  calendarBranchId?: Maybe<Scalars['ID']>;
};

export type CreateTaskTemplateInput = {
  createdTaskName: Scalars['String'];
  stageDayStart?: Maybe<Scalars['Int']>;
  stageDayEnd?: Maybe<Scalars['Int']>;
};

export type CreateToppingStyleInput = {
  name: Scalars['String'];
};

export type CreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  roleId: Scalars['String'];
  userPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateZoneInput = {
  name: Scalars['String'];
  maxConcurrentHarvestGroups?: Maybe<Scalars['Int']>;
  maxConcurrentPlants?: Maybe<Scalars['Int']>;
  cultivationStyle?: Maybe<Scalars['String']>;
  cultivationStyleOther?: Maybe<Scalars['String']>;
  lightingType?: Maybe<Scalars['String']>;
  lightingTypeOther?: Maybe<Scalars['String']>;
  growingMedia?: Maybe<Scalars['String']>;
  growingMediaOther?: Maybe<Scalars['String']>;
  irrigationStyle?: Maybe<Scalars['String']>;
  irrigationStyleOther?: Maybe<Scalars['String']>;
  irrigationFrequency?: Maybe<Scalars['String']>;
  irrigationFrequencyOther?: Maybe<Scalars['String']>;
  lengthFeet?: Maybe<Scalars['Float']>;
  lengthInches?: Maybe<Scalars['Float']>;
  heightFeet?: Maybe<Scalars['Float']>;
  heightInches?: Maybe<Scalars['Float']>;
  widthFeet?: Maybe<Scalars['Float']>;
  widthInches?: Maybe<Scalars['Float']>;
  growMediaSize?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  acSizeByTon?: Maybe<Scalars['Float']>;
  acNumUnits?: Maybe<Scalars['Float']>;
  acBrand?: Maybe<Scalars['String']>;
  dehumidifierSizeByPintsPerDay?: Maybe<Scalars['Float']>;
  dehumidifierNumUnits?: Maybe<Scalars['Float']>;
  dehumidifierBrand?: Maybe<Scalars['String']>;
};


export type DateRangeInput = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};



export type DeleteLogEntryInput = {
  id: Scalars['ID'];
};

export type DeleteLogEntryResult = {
  __typename?: 'DeleteLogEntryResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteRecipeComponentInput = {
  id: Scalars['ID'];
};

export type DeleteStrainInStageInput = {
  strainId: Scalars['ID'];
};

export type DeleteToppingStyleInput = {
  id: Scalars['ID'];
};

export type DeleteToppingStyleResult = {
  __typename?: 'DeleteToppingStyleResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type DuplicateCalendarBranchInput = {
  name: Scalars['String'];
  originalId: Scalars['ID'];
};

export type EnvironmentalAnalyticsFilterInput = {
  zoneId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type EnvironmentalAnalyticsResult = {
  __typename?: 'EnvironmentalAnalyticsResult';
  temperature: AnalyticsChartData;
  humidity: AnalyticsChartData;
  co2: AnalyticsChartData;
  vpd: AnalyticsChartData;
};

export type EnvironmentalDataDailyType = {
  __typename?: 'EnvironmentalDataDailyType';
  id: Scalars['UUID'];
  zone: Zone;
  environmentalFile: EnvironmentalFileType;
  day: Scalars['Date'];
  dataPoints?: Maybe<Scalars['GenericScalar']>;
};

export enum EnvironmentalFileAutodetectStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Processed = 'PROCESSED',
  Error = 'ERROR'
}

export enum EnvironmentalFileFileStatus {
  NotStarted = 'NOT_STARTED',
  Uploaded = 'UPLOADED',
  Processed = 'PROCESSED',
  Error = 'ERROR'
}

export enum EnvironmentalFileFileType {
  Thrive = 'THRIVE',
  Trollmaster = 'TROLLMASTER'
}

export type EnvironmentalFileFilterSetInput = {
  zoneId?: Maybe<Scalars['String']>;
};

export type EnvironmentalFileInput = {
  fileUrl: Scalars['String'];
  zoneId: Scalars['ID'];
};

export type EnvironmentalFileType = {
  __typename?: 'EnvironmentalFileType';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  organization: Organization;
  dataFile: Scalars['String'];
  fileType?: Maybe<EnvironmentalFileFileType>;
  zone: Zone;
  timeOffsetSeconds: Scalars['Int'];
  sevenDayPreview?: Maybe<Array<AnalyticsDataPoint>>;
  fileStatus: EnvironmentalFileFileStatus;
  autodetectStatus: EnvironmentalFileAutodetectStatus;
  currentHgs?: Maybe<Array<HarvestGroup>>;
  currentStages?: Maybe<Array<Stage>>;
  guessEnvType?: Maybe<Scalars['String']>;
  guessTargetSet?: Maybe<EnvironmentalTargetSet>;
};

export type EnvironmentalFileTypeResult = EnvironmentalFileType | Error;

export type EnvironmentalTargetSet = {
  __typename?: 'EnvironmentalTargetSet';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization: Organization;
  environmentalTargetType: EnvironmentalTargetSetEnvironmentalTargetType;
  zone?: Maybe<Zone>;
  daysInStageStart?: Maybe<Scalars['Int']>;
  daysInStageEnd?: Maybe<Scalars['Int']>;
  dayStartTime: Scalars['Time'];
  nightStartTime: Scalars['Time'];
  dayTempLow: Scalars['Int'];
  dayTempHigh: Scalars['Int'];
  nightTempLow: Scalars['Int'];
  nightTempHigh: Scalars['Int'];
  dayVpdLow: Scalars['Float'];
  dayVpdHigh: Scalars['Float'];
  nightVpdLow: Scalars['Float'];
  nightVpdHigh: Scalars['Float'];
  dayHumidityLow: Scalars['Int'];
  dayHumidityHigh: Scalars['Int'];
  nightHumidityLow: Scalars['Int'];
  nightHumidityHigh: Scalars['Int'];
  dayCo2Low: Scalars['Int'];
  dayCo2High: Scalars['Int'];
  nightCo2Low: Scalars['Int'];
  nightCo2High: Scalars['Int'];
  environmentalfileSet: Array<EnvironmentalFileType>;
  displayName?: Maybe<Scalars['String']>;
};

export enum EnvironmentalTargetSetEnvironmentalTargetType {
  Veg = 'VEG',
  Flower = 'FLOWER',
  NoTargets = 'NO_TARGETS'
}

export type EnvironmentalTargetSetResult = EnvironmentalTargetSet | Error;

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
};

export type Expense = {
  __typename?: 'Expense';
  id: Scalars['UUID'];
  date: Scalars['Date'];
  type: ExpenseType;
  category: ExpenseCategory;
  costInCents: Scalars['Int'];
  hourlyCostInCents?: Maybe<Scalars['Int']>;
  hoursWorked?: Maybe<Scalars['Decimal']>;
  allocationRatio: Scalars['Decimal'];
  notes?: Maybe<Scalars['String']>;
  recurringExpense?: Maybe<RecurringExpense>;
  createdBy?: Maybe<UserProfile>;
};

export type ExpenseCategory = {
  __typename?: 'ExpenseCategory';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  organization: Organization;
  expenses: Array<Expense>;
  recurringExpenses: Array<RecurringExpense>;
};

export type ExpenseCategoryFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export type ExpenseFilterSetInput = {
  date_Gte?: Maybe<Scalars['Date']>;
  date_Lte?: Maybe<Scalars['Date']>;
};

export type ExpenseReportAllocationBreakdown = {
  __typename?: 'ExpenseReportAllocationBreakdown';
  entries?: Maybe<Array<ExpenseReportAllocationBreakdownEntry>>;
  monthlySummaries?: Maybe<Array<ExpenseReportAllocationBreakdownMonthlySummary>>;
};

export type ExpenseReportAllocationBreakdownEntry = {
  __typename?: 'ExpenseReportAllocationBreakdownEntry';
  id: Scalars['ID'];
  strain: Strain;
  harvestGroup?: Maybe<HarvestGroup>;
  harvestProjection?: Maybe<HarvestProjection>;
  cultivationComplete?: Maybe<Scalars['Boolean']>;
  cultivationDays?: Maybe<Scalars['Int']>;
  cultivationNumPlants?: Maybe<Scalars['Int']>;
  cultivationStartDate?: Maybe<Scalars['Date']>;
  cultivationEndDate?: Maybe<Scalars['Date']>;
  cultivationCostInCents?: Maybe<Scalars['Int']>;
  processingGroup?: Maybe<ProcessingGroup>;
  processingHours?: Maybe<Scalars['Float']>;
  processingCostInCents?: Maybe<Scalars['Int']>;
  processingStartDate?: Maybe<Scalars['Date']>;
  processingEndDate?: Maybe<Scalars['Date']>;
  strainMonthlySummaries?: Maybe<Array<ExpenseReportAllocationBreakdownStrainMonthlySummary>>;
};

export type ExpenseReportAllocationBreakdownMonthlySummary = {
  __typename?: 'ExpenseReportAllocationBreakdownMonthlySummary';
  id: Scalars['ID'];
  month?: Maybe<Scalars['Date']>;
  cultivationCostInCents?: Maybe<Scalars['Int']>;
  processingCostInCents?: Maybe<Scalars['Int']>;
  totalPlantDays?: Maybe<Scalars['Int']>;
  totalProcessingHours?: Maybe<Scalars['Float']>;
};

export type ExpenseReportAllocationBreakdownStrainMonthlySummary = {
  __typename?: 'ExpenseReportAllocationBreakdownStrainMonthlySummary';
  id: Scalars['ID'];
  month?: Maybe<Scalars['Date']>;
  cultivationCostInCents?: Maybe<Scalars['Int']>;
  processingCostInCents?: Maybe<Scalars['Int']>;
  plantDays?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Float']>;
};

export type ExpenseReportAllocationFiltersInput = {
  harvestGroupIds?: Maybe<Array<Scalars['String']>>;
  processingGroupIds?: Maybe<Array<Scalars['String']>>;
};

export type ExpenseReportByCategory = {
  __typename?: 'ExpenseReportByCategory';
  period: Scalars['String'];
  entries?: Maybe<Array<ExpenseReportByCategoryEntry>>;
};

export type ExpenseReportByCategoryEntry = {
  __typename?: 'ExpenseReportByCategoryEntry';
  category: ExpenseCategory;
  totalCostInCents: Scalars['Int'];
};

export type ExpenseReportMonthlySummary = {
  __typename?: 'ExpenseReportMonthlySummary';
  period: Scalars['String'];
  months?: Maybe<Array<ExpenseReportMonthlySummaryMonth>>;
};

export type ExpenseReportMonthlySummaryMonth = {
  __typename?: 'ExpenseReportMonthlySummaryMonth';
  month: Scalars['String'];
  cultivationCostInCents: Scalars['Int'];
  processingCostInCents: Scalars['Int'];
};

export type ExpenseResult = Expense | Error;

export enum ExpenseType {
  Once = 'ONCE',
  Recurring = 'RECURRING',
  Automatic = 'AUTOMATIC'
}

export type FinishProcessingGroupStrainInput = {
  id: Scalars['ID'];
};


export type GetSignedUploadUrlInput = {
  path: Scalars['String'];
  contentType: Scalars['String'];
};

export type GetSignedUploadUrlResult = {
  __typename?: 'GetSignedUploadURLResult';
  signedUploadUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type HarvestCompletion = {
  __typename?: 'HarvestCompletion';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  harvestGroup: HarvestGroup;
  createdBy: UserProfile;
  cosignedAt?: Maybe<Scalars['DateTime']>;
  cosignedBy?: Maybe<UserProfile>;
};

export type HarvestCompletionResult = HarvestCompletion | Error;

export type HarvestGroup = {
  __typename?: 'HarvestGroup';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  zoneIteration: Scalars['String'];
  createdBy: UserProfile;
  flowerZone: Zone;
  colorHexCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  harvestDate?: Maybe<Scalars['Date']>;
  template: HarvestGroupTemplateVersion;
  yields: Array<HarvestStrainYield>;
  harvestCompletion?: Maybe<HarvestCompletion>;
  stages: Array<Stage>;
  logEntries: Array<LogEntry>;
  becameProcessingGroup?: Maybe<ProcessingGroup>;
  projections?: Maybe<Array<HarvestProjection>>;
  currentStage: Stage;
  strains: Array<Strain>;
  tasks: Array<Task>;
};


export type HarvestGroupLogEntriesArgs = {
  logEntryOptions?: Maybe<LogsFilterInput>;
};

export type HarvestGroupFilterSetInput = {
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  unarchived?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['String']>;
};

export type HarvestGroupQuerysetOptionsInput = {
  paging?: Maybe<PagingInput>;
  filters?: Maybe<CompletedHarvestGroupFilterSetInput>;
};

export type HarvestGroupQuerysetOptionsOutput = {
  __typename?: 'HarvestGroupQuerysetOptionsOutput';
  paging: PagingInfo;
  filters: CompletedHarvestGroupFilterSetOutput;
};

export type HarvestGroupQuerysetResult = {
  __typename?: 'HarvestGroupQuerysetResult';
  options: HarvestGroupQuerysetOptionsOutput;
  items: Array<Maybe<HarvestGroup>>;
};

export type HarvestGroupResult = HarvestGroup | Error;

export type HarvestGroupTemplate = {
  __typename?: 'HarvestGroupTemplate';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  activeTemplateVersion: HarvestGroupTemplateVersion;
  versions: Array<HarvestGroupTemplateVersion>;
};

export type HarvestGroupTemplateFilterSetInput = {
  archivedAt_Isnull?: Maybe<Scalars['Boolean']>;
  unarchived?: Maybe<Scalars['String']>;
};

export type HarvestGroupTemplateResult = HarvestGroupTemplate | Error;

export type HarvestGroupTemplateVersion = {
  __typename?: 'HarvestGroupTemplateVersion';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  template?: Maybe<HarvestGroupTemplate>;
  version: Scalars['Int'];
  harvestGroups: Array<HarvestGroup>;
  harvestGroupTemplates: Array<HarvestGroupTemplate>;
  stageTemplates: Array<StageTemplate>;
};

export type HarvestProjection = {
  __typename?: 'HarvestProjection';
  id: Scalars['UUID'];
  strain: Strain;
  plantCount: Scalars['Int'];
  harvestGroupId: Scalars['String'];
  strainId: Scalars['String'];
};

export type HarvestProjectionResult = HarvestProjection | Error;

export type HarvestStrainPerformance = {
  __typename?: 'HarvestStrainPerformance';
  id: Scalars['String'];
  name: Scalars['String'];
  harvestDate: Scalars['String'];
  totalGrams: Scalars['Float'];
  totalFlowerGrams: Scalars['Float'];
  finalPlantCount: Scalars['Int'];
  gramsPerPlant: Scalars['Float'];
  byFormFactor: Array<BudFormFactorYield>;
};

export type HarvestStrainYield = {
  __typename?: 'HarvestStrainYield';
  id: Scalars['UUID'];
  harvestGroup: HarvestGroup;
  strain: Strain;
  enteredBy: UserProfile;
  totalGrams: Scalars['Float'];
  finalPlantCount: Scalars['Int'];
  harvestGroupId: Scalars['String'];
  strainId: Scalars['String'];
  budFormFactorYields: Array<BudFormFactorYield>;
};


export type JobLogOutputGramsInput = {
  formFactorId: Scalars['ID'];
  grams: Scalars['Decimal'];
};

export type Location = {
  __typename?: 'Location';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  maintenancerequestSet: Array<MaintenanceRequest>;
};

export type LocationFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export type LogEntry = {
  __typename?: 'LogEntry';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  originalId?: Maybe<Scalars['String']>;
  isCurrentRow: Scalars['Boolean'];
  date: Scalars['DateTime'];
  performedBy?: Maybe<UserProfile>;
  createdBy: UserProfile;
  logType: LogEntryLogType;
  logEntryData?: Maybe<Scalars['GenericScalar']>;
  isDraft: Scalars['Boolean'];
  readyForApproval: Scalars['Boolean'];
  approvedBy?: Maybe<UserProfile>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  expense?: Maybe<Expense>;
  paperlyticsImage?: Maybe<PaperlyticsImage>;
  paperlyticsAccuracyScore?: Maybe<Scalars['Int']>;
  paperlyticsIssuesList?: Maybe<Scalars['JSONString']>;
  paperlyticsBoundaries?: Maybe<Scalars['JSONString']>;
  stage?: Maybe<Stage>;
};

export type LogEntryInput = {
  id?: Maybe<Scalars['ID']>;
  date: Scalars['Date'];
  harvestGroupId: Scalars['ID'];
  dataFields?: Maybe<Scalars['GenericScalar']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  performedBy?: Maybe<Scalars['ID']>;
};

export enum LogEntryLogType {
  Observation = 'OBSERVATION',
  Issue = 'ISSUE',
  Irrigation = 'IRRIGATION',
  Foliar = 'FOLIAR',
  Pruning = 'PRUNING',
  Topping = 'TOPPING',
  Greenwaste = 'GREENWASTE'
}

export type LogEntryResult = LogEntry | Error;

export type LogoutResult = LogoutSuccess | Error;

export type LogoutSuccess = {
  __typename?: 'LogoutSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type LogsFilterInput = {
  logEntryDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  includeDraftLogs?: Maybe<Scalars['Boolean']>;
};

export type MaintenanceRequest = {
  __typename?: 'MaintenanceRequest';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  firstObservedAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  urgent: Scalars['Boolean'];
  equipmentInoperable: Scalars['Boolean'];
  lockOutTagOut: Scalars['Boolean'];
  requestedBy: UserProfile;
  description: Scalars['String'];
  location: Location;
  imageUrl?: Maybe<Scalars['String']>;
  resolution?: Maybe<MaintenanceRequestResolution>;
  tags?: Maybe<Array<Maybe<MaintenanceRequestTag>>>;
};

export type MaintenanceRequestFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export type MaintenanceRequestResolution = {
  __typename?: 'MaintenanceRequestResolution';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  maintenanceRequest: MaintenanceRequest;
  resolvedAt: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  resolvedBy: UserProfile;
};

export type MaintenanceRequestResult = MaintenanceRequest | Error;

export type MaintenanceRequestTag = {
  __typename?: 'MaintenanceRequestTag';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  maintenanceRequest: MaintenanceRequest;
  tag: Tag;
  name?: Maybe<Scalars['String']>;
};

export type MoveUpProcessingGroupStrainInput = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createExpense?: Maybe<ExpenseResult>;
  updateExpense?: Maybe<ExpenseResult>;
  approveCalendarBranch?: Maybe<CalendarBranchResult>;
  createCalendarBranch?: Maybe<CalendarBranchResult>;
  deleteCalendarBranch?: Maybe<SuccessOkResult>;
  duplicateCalendarBranch?: Maybe<CalendarBranchResult>;
  undoBranchCalendarStageUpdate?: Maybe<CalendarBranchResult>;
  undoBranchCalendarTaskUpdate?: Maybe<CalendarBranchResult>;
  updateCalendarBranchStage?: Maybe<CalendarBranchResult>;
  updateCalendarBranchTask?: Maybe<CalendarBranchResult>;
  updateCalendarBranchStageAndFollowingEventsInHarvestGroup?: Maybe<CalendarBranchResult>;
  updateStageConflict?: Maybe<CalendarBranchResult>;
  updateTaskConflict?: Maybe<CalendarBranchResult>;
  createProcessingGroup?: Maybe<ProcessingGroupResult>;
  updateProcessingGroupArchived?: Maybe<ProcessingGroupResult>;
  finishProcessingGroupStrain?: Maybe<ProcessingGroupStrainResult>;
  moveUpProcessingGroupStrain?: Maybe<ProcessingGroupStrainResult>;
  createProcessingGroupStrainInventoryAdjustment?: Maybe<ProcessingGroupStrainResult>;
  createProcessingJob?: Maybe<ProcessingJobResult>;
  assignProcessingJobToUser?: Maybe<ProcessingJobResult>;
  updateProcessingJob?: Maybe<ProcessingJobResult>;
  createProcessingJobLog?: Maybe<ProcessingJobLogResult>;
  updateProcessingJobLog?: Maybe<ProcessingJobLogResult>;
  createProcessingGroupTemplate?: Maybe<ProcessingGroupTemplateResult>;
  updateActiveProcessingGroupTemplateVersion?: Maybe<ProcessingGroupTemplateResult>;
  updateProcessingGroupTemplateArchived?: Maybe<ProcessingGroupTemplateResult>;
  createMaintenanceRequest?: Maybe<MaintenanceRequestResult>;
  resolveMaintenanceRequest?: Maybe<MaintenanceRequestResult>;
  createNutrient?: Maybe<NutrientResult>;
  updateNutrient?: Maybe<NutrientResult>;
  createRecipe?: Maybe<RecipeResult>;
  updateRecipe?: Maybe<RecipeResult>;
  createZone?: Maybe<ZoneResult>;
  updateZone?: Maybe<ZoneResult>;
  createBudFormFactor?: Maybe<BudFormFactorResult>;
  updateBudFormFactor?: Maybe<BudFormFactorResult>;
  reorderBudFormFactors?: Maybe<BudFormFactorResult>;
  createTask?: Maybe<TaskResult>;
  updateTask?: Maybe<TaskResult>;
  createStrain?: Maybe<StrainResult>;
  updateStrain?: Maybe<StrainResult>;
  createHarvestGroup?: Maybe<HarvestGroupResult>;
  updateHarvestGroup?: Maybe<HarvestGroupResult>;
  completeHarvest?: Maybe<HarvestCompletionResult>;
  approveHarvestCompletion?: Maybe<HarvestCompletionResult>;
  updateHarvestGroupArchived?: Maybe<HarvestGroupResult>;
  createHarvestReport?: Maybe<HarvestGroupResult>;
  updateHarvestReport?: Maybe<HarvestGroupResult>;
  updateHarvestProjection?: Maybe<HarvestProjectionResult>;
  addObservationLogEntry?: Maybe<LogEntryResult>;
  addIrrigationLogEntry?: Maybe<LogEntryResult>;
  addFoliarLogEntry?: Maybe<LogEntryResult>;
  addGreenWasteLogEntry?: Maybe<LogEntryResult>;
  addPruningLogEntry?: Maybe<LogEntryResult>;
  addToppingLogEntry?: Maybe<LogEntryResult>;
  addIssueLogEntry?: Maybe<LogEntryResult>;
  approveLogEntry?: Maybe<LogEntryResult>;
  deleteLogEntry?: Maybe<DeleteLogEntryResult>;
  createHarvestGroupTemplate?: Maybe<HarvestGroupTemplateResult>;
  updateHarvestGroupTemplate?: Maybe<HarvestGroupTemplateResult>;
  updateActiveHarvestGroupTemplateVersion?: Maybe<HarvestGroupTemplateResult>;
  updateHarvestGroupTemplateArchived?: Maybe<HarvestGroupTemplateResult>;
  uploadPaperlyticsImage?: Maybe<PaperlyticsImageResult>;
  retryPaperlyticsImage?: Maybe<PaperlyticsImageResult>;
  uploadEnvironmentalFile?: Maybe<EnvironmentalFileTypeResult>;
  updateTimeOffset?: Maybe<EnvironmentalFileTypeResult>;
  processEnvironmentalFile?: Maybe<EnvironmentalFileTypeResult>;
  tokenAuth?: Maybe<TokenAuthResult>;
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailResult>;
  sendAccountInvitation?: Maybe<SendAccountInvitation>;
  passwordReset?: Maybe<PasswordResetResult>;
  logout?: Maybe<LogoutResult>;
  updateUserProfile?: Maybe<UpdateUserProfileResult>;
  createUser?: Maybe<UserProfileResult>;
  updateUser?: Maybe<UserProfileResult>;
  updateUserActive?: Maybe<UserProfileResult>;
  getSignedUploadUrl?: Maybe<GetSignedUploadUrlResult>;
  createToppingStyle?: Maybe<ToppingStyleResult>;
  deleteToppingStyle?: Maybe<DeleteToppingStyleResult>;
  updateOrganizationSettings?: Maybe<OrganizationResult>;
  createEnvironmentalTargetSet?: Maybe<EnvironmentalTargetSetResult>;
  updateEnvironmentalTargetSet?: Maybe<EnvironmentalTargetSetResult>;
  updateRole?: Maybe<RoleResult>;
  createOrganizationAndFirstUser?: Maybe<OrganizationResult>;
};


export type MutationCreateExpenseArgs = {
  input?: Maybe<CreateExpenseInput>;
};


export type MutationUpdateExpenseArgs = {
  input?: Maybe<UpdateExpenseInput>;
};


export type MutationApproveCalendarBranchArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCalendarBranchArgs = {
  input?: Maybe<CreateCalendarBranchInput>;
};


export type MutationDeleteCalendarBranchArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateCalendarBranchArgs = {
  input?: Maybe<DuplicateCalendarBranchInput>;
};


export type MutationUndoBranchCalendarStageUpdateArgs = {
  input?: Maybe<UndoBranchCalendarStageUpdateInput>;
};


export type MutationUndoBranchCalendarTaskUpdateArgs = {
  input?: Maybe<UndoBranchCalendarTaskUpdateInput>;
};


export type MutationUpdateCalendarBranchStageArgs = {
  input?: Maybe<UpdateCalendarBranchStageInput>;
};


export type MutationUpdateCalendarBranchTaskArgs = {
  input?: Maybe<UpdateCalendarBranchTaskInput>;
};


export type MutationUpdateCalendarBranchStageAndFollowingEventsInHarvestGroupArgs = {
  input?: Maybe<UpdateCalendarBranchStageInput>;
};


export type MutationUpdateStageConflictArgs = {
  input?: Maybe<UpdateStageConflictInput>;
};


export type MutationUpdateTaskConflictArgs = {
  input?: Maybe<UpdateTaskConflictInput>;
};


export type MutationCreateProcessingGroupArgs = {
  input?: Maybe<CreateProcessingGroupInput>;
};


export type MutationUpdateProcessingGroupArchivedArgs = {
  input?: Maybe<UpdateProcessingGroupArchivedInput>;
};


export type MutationFinishProcessingGroupStrainArgs = {
  input?: Maybe<FinishProcessingGroupStrainInput>;
};


export type MutationMoveUpProcessingGroupStrainArgs = {
  input?: Maybe<MoveUpProcessingGroupStrainInput>;
};


export type MutationCreateProcessingGroupStrainInventoryAdjustmentArgs = {
  input?: Maybe<CreateProcessingGroupStrainInventoryAdjustmentInput>;
};


export type MutationCreateProcessingJobArgs = {
  input?: Maybe<CreateProcessingJobInput>;
};


export type MutationAssignProcessingJobToUserArgs = {
  input?: Maybe<AssignProcessingJobToUserInput>;
};


export type MutationUpdateProcessingJobArgs = {
  input?: Maybe<UpdateProcessingJobInput>;
};


export type MutationCreateProcessingJobLogArgs = {
  input?: Maybe<CreateProcessingJobLogInput>;
};


export type MutationUpdateProcessingJobLogArgs = {
  input?: Maybe<UpdateProcessingJobLogInput>;
};


export type MutationCreateProcessingGroupTemplateArgs = {
  input?: Maybe<CreateProcessingGroupTemplateInput>;
};


export type MutationUpdateActiveProcessingGroupTemplateVersionArgs = {
  input?: Maybe<UpdateActiveProcessingGroupTemplateVersionInput>;
};


export type MutationUpdateProcessingGroupTemplateArchivedArgs = {
  input?: Maybe<UpdateProcessingGroupTemplateArchivedInput>;
};


export type MutationCreateMaintenanceRequestArgs = {
  input?: Maybe<CreateMaintenanceRequestInput>;
};


export type MutationResolveMaintenanceRequestArgs = {
  input?: Maybe<ResolveMaintenanceRequestInput>;
};


export type MutationCreateNutrientArgs = {
  input?: Maybe<CreateNutrientInput>;
};


export type MutationUpdateNutrientArgs = {
  input?: Maybe<UpdateNutrientInput>;
};


export type MutationCreateRecipeArgs = {
  input?: Maybe<CreateRecipeInput>;
};


export type MutationUpdateRecipeArgs = {
  input?: Maybe<UpdateRecipeInput>;
};


export type MutationCreateZoneArgs = {
  input?: Maybe<CreateZoneInput>;
};


export type MutationUpdateZoneArgs = {
  input?: Maybe<UpdateZoneInput>;
};


export type MutationCreateBudFormFactorArgs = {
  input?: Maybe<CreateBudFormFactorInput>;
};


export type MutationUpdateBudFormFactorArgs = {
  input?: Maybe<UpdateBudFormFactorInput>;
};


export type MutationReorderBudFormFactorsArgs = {
  input?: Maybe<ReorderBudFormFactorsInput>;
};


export type MutationCreateTaskArgs = {
  input?: Maybe<CreateTaskInput>;
};


export type MutationUpdateTaskArgs = {
  input?: Maybe<UpdateTaskInput>;
};


export type MutationCreateStrainArgs = {
  input?: Maybe<CreateStrainInput>;
};


export type MutationUpdateStrainArgs = {
  input?: Maybe<UpdateStrainInput>;
};


export type MutationCreateHarvestGroupArgs = {
  input?: Maybe<CreateHarvestGroupInput>;
};


export type MutationUpdateHarvestGroupArgs = {
  input?: Maybe<UpdateHarvestGroupInput>;
};


export type MutationCompleteHarvestArgs = {
  input?: Maybe<CompleteHarvestInput>;
};


export type MutationApproveHarvestCompletionArgs = {
  input?: Maybe<ApproveHarvestCompletionInput>;
};


export type MutationUpdateHarvestGroupArchivedArgs = {
  input?: Maybe<UpdateHarvestGroupArchivedInput>;
};


export type MutationCreateHarvestReportArgs = {
  input?: Maybe<CreateHarvestReportInput>;
};


export type MutationUpdateHarvestReportArgs = {
  input?: Maybe<UpdateHarvestReportInput>;
};


export type MutationUpdateHarvestProjectionArgs = {
  input?: Maybe<UpdateHarvestProjectionInput>;
};


export type MutationAddObservationLogEntryArgs = {
  input?: Maybe<LogEntryInput>;
};


export type MutationAddIrrigationLogEntryArgs = {
  input?: Maybe<LogEntryInput>;
};


export type MutationAddFoliarLogEntryArgs = {
  input?: Maybe<LogEntryInput>;
};


export type MutationAddGreenWasteLogEntryArgs = {
  input?: Maybe<LogEntryInput>;
};


export type MutationAddPruningLogEntryArgs = {
  input?: Maybe<LogEntryInput>;
};


export type MutationAddToppingLogEntryArgs = {
  input?: Maybe<LogEntryInput>;
};


export type MutationAddIssueLogEntryArgs = {
  input?: Maybe<LogEntryInput>;
};


export type MutationApproveLogEntryArgs = {
  input?: Maybe<ApproveLogEntryInput>;
};


export type MutationDeleteLogEntryArgs = {
  input?: Maybe<DeleteLogEntryInput>;
};


export type MutationCreateHarvestGroupTemplateArgs = {
  input?: Maybe<CreateHarvestGroupTemplateInput>;
};


export type MutationUpdateHarvestGroupTemplateArgs = {
  input?: Maybe<UpdateHarvestGroupTemplateInput>;
};


export type MutationUpdateActiveHarvestGroupTemplateVersionArgs = {
  input?: Maybe<UpdateActiveHarvestGroupTemplateVersionInput>;
};


export type MutationUpdateHarvestGroupTemplateArchivedArgs = {
  input?: Maybe<UpdateHarvestGroupTemplateArchivedInput>;
};


export type MutationUploadPaperlyticsImageArgs = {
  harvestGroupId: Scalars['String'];
  imageUrl: Scalars['String'];
};


export type MutationRetryPaperlyticsImageArgs = {
  imageId: Scalars['String'];
};


export type MutationUploadEnvironmentalFileArgs = {
  input?: Maybe<EnvironmentalFileInput>;
};


export type MutationUpdateTimeOffsetArgs = {
  input?: Maybe<UpdateTimeOffsetInput>;
};


export type MutationProcessEnvironmentalFileArgs = {
  input?: Maybe<ProcessEnvironmentalFileInput>;
};


export type MutationTokenAuthArgs = {
  password: Scalars['String'];
  username_Iexact: Scalars['String'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendAccountInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationPasswordResetArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUpdateUserProfileArgs = {
  input?: Maybe<UpdateUserProfileInput>;
};


export type MutationCreateUserArgs = {
  input?: Maybe<CreateUserInput>;
};


export type MutationUpdateUserArgs = {
  input?: Maybe<UpdateUserInput>;
};


export type MutationUpdateUserActiveArgs = {
  input?: Maybe<UpdateUserActiveInput>;
};


export type MutationGetSignedUploadUrlArgs = {
  input: GetSignedUploadUrlInput;
};


export type MutationCreateToppingStyleArgs = {
  input?: Maybe<CreateToppingStyleInput>;
};


export type MutationDeleteToppingStyleArgs = {
  input?: Maybe<DeleteToppingStyleInput>;
};


export type MutationUpdateOrganizationSettingsArgs = {
  input?: Maybe<UpdateOrganizationSettingsInput>;
};


export type MutationCreateEnvironmentalTargetSetArgs = {
  input: CreateEnvironmentalTargetSetInput;
};


export type MutationUpdateEnvironmentalTargetSetArgs = {
  input: UpdateEnvironmentalTargetSetInput;
};


export type MutationUpdateRoleArgs = {
  input?: Maybe<UpdateRoleInput>;
};


export type MutationCreateOrganizationAndFirstUserArgs = {
  input?: Maybe<CreateOrganizationAndFirstUserInput>;
};

export type Nutrient = {
  __typename?: 'Nutrient';
  id: Scalars['UUID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  unitOfMeasure: NutrientUnitOfMeasure;
  priceInCentsPerUnit: Scalars['Decimal'];
  boughtPrice?: Maybe<Scalars['Decimal']>;
  boughtAmount?: Maybe<Scalars['Decimal']>;
  boughtUnit?: Maybe<Scalars['String']>;
};

export type NutrientFilterSetInput = {
  active?: Maybe<Scalars['Boolean']>;
};

export type NutrientResult = Nutrient | Error;

export enum NutrientUnitOfMeasure {
  MillilitersPerGallon = 'MILLILITERS_PER_GALLON',
  GramsPerGallon = 'GRAMS_PER_GALLON'
}

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  name: Scalars['String'];
  hubspotOrganizationId?: Maybe<Scalars['String']>;
  moduleEnabledCultivation: Scalars['Boolean'];
  moduleEnabledProcessing: Scalars['Boolean'];
  moduleEnabledFinance: Scalars['Boolean'];
  optionConvertGramsAbove: Scalars['Int'];
  timezone: Scalars['String'];
  normalizeWindowMinutes: Scalars['Int'];
  alertConsecutiveThreshold: Scalars['Int'];
  environmentalTargetSets: Array<EnvironmentalTargetSet>;
};

export type OrganizationFilterSetInput = {
  id_Icontains?: Maybe<Scalars['String']>;
  deactivatedAt_Isnull?: Maybe<Scalars['Boolean']>;
};

export type OrganizationResult = Organization | Error;

export type PagingInfo = {
  __typename?: 'PagingInfo';
  totalCount: Scalars['Int'];
  unfilteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  ordering: Scalars['String'];
  start: Scalars['Int'];
  size: Scalars['Int'];
  page: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type PagingInput = {
  start?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type PaperlyticsFilterSetInput = {
  harvestGroupId?: Maybe<Scalars['String']>;
};

export type PaperlyticsImage = {
  __typename?: 'PaperlyticsImage';
  id: Scalars['UUID'];
  imageUpload?: Maybe<Scalars['String']>;
  organization: Organization;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  uploadedBy?: Maybe<UserProfile>;
  success: Scalars['Boolean'];
  targetType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['JSONString']>;
  accuracyScore?: Maybe<Scalars['Int']>;
  issuesList?: Maybe<Scalars['JSONString']>;
  harvestGroup?: Maybe<HarvestGroup>;
  harvestGroupId: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type PaperlyticsImageResult = PaperlyticsImage | Error;

export type PasswordResetResult = PasswordResetSuccess | Error;

export type PasswordResetSuccess = {
  __typename?: 'PasswordResetSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type Permission = {
  __typename?: 'Permission';
  name: Scalars['String'];
};

export type ProcessEnvironmentalFileInput = {
  fileId: Scalars['String'];
};

export type ProcessingGroup = {
  __typename?: 'ProcessingGroup';
  id: Scalars['String'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  status: ProcessingGroupStatus;
  startDate?: Maybe<Scalars['Date']>;
  createdBy: UserProfile;
  colorHexCode?: Maybe<Scalars['String']>;
  processingGroupTemplateVersion?: Maybe<ProcessingGroupTemplateVersion>;
  fromHarvestGroup?: Maybe<HarvestGroup>;
  processingGroupStrains: Array<ProcessingGroupStrain>;
  processingGroupStages: Array<ProcessingGroupStage>;
  stages?: Maybe<Array<ProcessingGroupStage>>;
  strains?: Maybe<Array<ProcessingGroupStrain>>;
  latestStage?: Maybe<ProcessingGroupStage>;
  processingGroupStrainStages: Array<ProcessingGroupStrainStage>;
  inUnfinishedJobs?: Maybe<Scalars['GenericScalar']>;
};


export type ProcessingGroupProcessingGroupStrainsArgs = {
  strainId?: Maybe<Scalars['String']>;
};


export type ProcessingGroupProcessingGroupStrainStagesArgs = {
  strainId?: Maybe<Scalars['String']>;
};


export type ProcessingGroupInUnfinishedJobsArgs = {
  strainId?: Maybe<Scalars['String']>;
};

export type ProcessingGroupFilterSetInput = {
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  unarchived?: Maybe<Scalars['String']>;
};

export type ProcessingGroupResult = ProcessingGroup | Error;

export type ProcessingGroupStage = {
  __typename?: 'ProcessingGroupStage';
  id: Scalars['UUID'];
  name: Scalars['String'];
  status: ProcessingGroupStageStatus;
  processingGroup: ProcessingGroup;
  processingStageTemplate: ProcessingStageTemplate;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  progressScope: ProcessingGroupStageProgressScope;
  schedulingScope: ProcessingGroupStageSchedulingScope;
  totalInputGrams?: Maybe<Scalars['GenericScalar']>;
  totalOutputGrams?: Maybe<Scalars['GenericScalar']>;
  totalPendingGrams?: Maybe<Scalars['GenericScalar']>;
  totalPartialGrams?: Maybe<Scalars['GenericScalar']>;
  summary: ProcessingGroupStrainStageSummary;
  jobs?: Maybe<Array<ProcessingJob>>;
  processingGroupId: Scalars['ID'];
};

export enum ProcessingGroupStageProgressScope {
  Simple = 'SIMPLE',
  ByWeight = 'BY_WEIGHT',
  ByDays = 'BY_DAYS'
}

export enum ProcessingGroupStageSchedulingScope {
  StrainsTogether = 'STRAINS_TOGETHER',
  StrainsIndependent = 'STRAINS_INDEPENDENT'
}

export enum ProcessingGroupStageStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE'
}

export enum ProcessingGroupStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE'
}

export type ProcessingGroupStrain = {
  __typename?: 'ProcessingGroupStrain';
  id: Scalars['UUID'];
  status: ProcessingGroupStrainStatus;
  processingGroup: ProcessingGroup;
  strain: Strain;
  orderInProcess?: Maybe<Scalars['Int']>;
  inventoryGrams?: Maybe<Scalars['GenericScalar']>;
  totalInputGrams?: Maybe<Scalars['GenericScalar']>;
  totalOutputGrams?: Maybe<Scalars['GenericScalar']>;
  totalPendingGrams?: Maybe<Scalars['GenericScalar']>;
  totalPartialGrams?: Maybe<Scalars['GenericScalar']>;
  totalAdjustmentGrams?: Maybe<Scalars['GenericScalar']>;
  inventoryAdjustments: Array<ProcessingGroupStrainInventoryAdjustment>;
};

export type ProcessingGroupStrainInventoryAdjustment = {
  __typename?: 'ProcessingGroupStrainInventoryAdjustment';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  processingGroupStrain: ProcessingGroupStrain;
  adjustmentGrams?: Maybe<Scalars['GenericScalar']>;
  description: Scalars['String'];
  createdBy: UserProfile;
};

export type ProcessingGroupStrainResult = ProcessingGroupStrain | Error;

export type ProcessingGroupStrainStage = {
  __typename?: 'ProcessingGroupStrainStage';
  id: Scalars['UUID'];
  name: Scalars['String'];
  status: ProcessingGroupStrainStageStatus;
  stage: ProcessingGroupStage;
  processingGroupStrain: ProcessingGroupStrain;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  totalInputGrams?: Maybe<Scalars['GenericScalar']>;
  totalOutputGrams?: Maybe<Scalars['GenericScalar']>;
  totalPendingGrams?: Maybe<Scalars['GenericScalar']>;
  totalPartialGrams?: Maybe<Scalars['GenericScalar']>;
  processingJobs: Array<ProcessingJob>;
  summary: ProcessingGroupStrainStageSummary;
};

export enum ProcessingGroupStrainStageStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE'
}

export type ProcessingGroupStrainStageSummary = {
  __typename?: 'ProcessingGroupStrainStageSummary';
  progressPercent: Scalars['Float'];
  daysElapsed: Scalars['Int'];
  numDays?: Maybe<Scalars['Int']>;
  weightCompletedGrams: Scalars['Float'];
  weightTotalGrams: Scalars['Float'];
  remainingInventoryGrams: Scalars['Float'];
};

export enum ProcessingGroupStrainStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE'
}

export type ProcessingGroupTemplate = {
  __typename?: 'ProcessingGroupTemplate';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  activeTemplateVersion?: Maybe<ProcessingGroupTemplateVersion>;
  processingGroupTemplateVersions: Array<ProcessingGroupTemplateVersion>;
};

export type ProcessingGroupTemplateFilterSetInput = {
  id?: Maybe<Scalars['String']>;
  unarchived?: Maybe<Scalars['String']>;
};

export type ProcessingGroupTemplateResult = ProcessingGroupTemplate | Error;

export type ProcessingGroupTemplateVersion = {
  __typename?: 'ProcessingGroupTemplateVersion';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  version: Scalars['Int'];
  processingGroupTemplate?: Maybe<ProcessingGroupTemplate>;
  processinggroupSet: Array<ProcessingGroup>;
  processingStageTemplates: Array<ProcessingStageTemplate>;
  activeForTemplates: Array<ProcessingGroupTemplate>;
};

export type ProcessingJob = {
  __typename?: 'ProcessingJob';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  strainStage: ProcessingGroupStrainStage;
  jobStatus: ProcessingJobJobStatus;
  createdBy: UserProfile;
  assignee?: Maybe<UserProfile>;
  totalWorkedMinutes: Scalars['Decimal'];
  inputType: BudFormFactor;
  inputGrams: Scalars['Decimal'];
  dontTrackUserGpm?: Maybe<Scalars['Boolean']>;
  totalOutputGrams?: Maybe<Scalars['GenericScalar']>;
  processingJobLogs: Array<ProcessingJobLog>;
  startedAt?: Maybe<Scalars['DateTime']>;
};

export type ProcessingJobFilterSetInput = {
  strainStage_Stage_ProcessingGroup_ArchivedAt_Isnull?: Maybe<Scalars['Boolean']>;
  strainStage_Stage_ProcessingGroupId?: Maybe<Scalars['String']>;
  strainStage_Stage_ProcessingStageTemplateId?: Maybe<Scalars['String']>;
  strainStage_StageId?: Maybe<Scalars['String']>;
  strainStage_Stage_ProcessingStageTemplate_TrackPerformance?: Maybe<Scalars['Boolean']>;
  strainStage_ProcessingGroupStrain_StrainId?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['Float']>;
};

export enum ProcessingJobJobStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE'
}

export type ProcessingJobLog = {
  __typename?: 'ProcessingJobLog';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  job: ProcessingJob;
  logType: ProcessingJobLogLogType;
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  breakMinutes: Scalars['Int'];
  workedMinutes: Scalars['Decimal'];
  leftoverInputGrams?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<UserProfile>;
  performer?: Maybe<UserProfile>;
  outputGrams?: Maybe<Scalars['GenericScalar']>;
};

export enum ProcessingJobLogLogType {
  Created = 'CREATED',
  PartialLog = 'PARTIAL_LOG',
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED'
}

export type ProcessingJobLogResult = ProcessingJobLog | Error;

export type ProcessingJobResult = ProcessingJob | Error;

export type ProcessingStageTemplate = {
  __typename?: 'ProcessingStageTemplate';
  id: Scalars['UUID'];
  name: Scalars['String'];
  numberOfDays: Scalars['Int'];
  createdStageName?: Maybe<Scalars['String']>;
  trackPerformance: Scalars['Boolean'];
  processingGroupTemplateVersion: ProcessingGroupTemplateVersion;
  orderInProcess: Scalars['Int'];
  progressScope: ProcessingStageTemplateProgressScope;
  schedulingScope: ProcessingStageTemplateSchedulingScope;
  inputTypes: Array<BudFormFactor>;
  outputTypes: Array<BudFormFactor>;
};

export enum ProcessingStageTemplateProgressScope {
  Simple = 'SIMPLE',
  ByWeight = 'BY_WEIGHT',
  ByDays = 'BY_DAYS'
}

export enum ProcessingStageTemplateSchedulingScope {
  StrainsTogether = 'STRAINS_TOGETHER',
  StrainsIndependent = 'STRAINS_INDEPENDENT'
}

export type Query = {
  __typename?: 'Query';
  expenseReportAllocationBreakdown: ExpenseReportAllocationBreakdown;
  expenseReportByCategory: ExpenseReportByCategory;
  expenseReportMonthlySummary: ExpenseReportMonthlySummary;
  expenses?: Maybe<Array<Expense>>;
  expense?: Maybe<Expense>;
  expenseCategories?: Maybe<Array<ExpenseCategory>>;
  calendarBranches?: Maybe<Array<CalendarBranch>>;
  calendarBranch: CalendarBranch;
  calendarBranchMergeLogEntries?: Maybe<Array<CalendarBranchMergeLogEntry>>;
  processingGroups?: Maybe<Array<ProcessingGroup>>;
  processingGroup?: Maybe<ProcessingGroup>;
  processingGroupTemplates?: Maybe<Array<ProcessingGroupTemplate>>;
  processingGroupTemplate?: Maybe<ProcessingGroupTemplate>;
  processingGroupTemplateVersion?: Maybe<ProcessingGroupTemplateVersion>;
  processingJobs?: Maybe<Array<ProcessingJob>>;
  processingJob?: Maybe<ProcessingJob>;
  processingGroupStrainStage?: Maybe<ProcessingGroupStrainStage>;
  harvestGroups?: Maybe<Array<HarvestGroup>>;
  completedHarvestGroupsPaginated?: Maybe<HarvestGroupQuerysetResult>;
  harvestGroup: HarvestGroup;
  harvestGroupTemplates?: Maybe<Array<HarvestGroupTemplate>>;
  harvestGroupTemplate: HarvestGroupTemplate;
  budFormFactors?: Maybe<Array<BudFormFactor>>;
  zones?: Maybe<Array<Zone>>;
  strains?: Maybe<Array<Strain>>;
  recipes?: Maybe<Array<Recipe>>;
  recipe: Recipe;
  recipeComponents?: Maybe<Array<RecipeComponent>>;
  nutrients?: Maybe<Array<Nutrient>>;
  locations?: Maybe<Array<Location>>;
  tags?: Maybe<Array<Tag>>;
  maintenanceRequests?: Maybe<Array<MaintenanceRequest>>;
  strainPerformance: StrainPerformanceResult;
  environmentalFiles?: Maybe<Array<EnvironmentalFileType>>;
  environmentalFile: EnvironmentalFileType;
  environmentalAnalytics: EnvironmentalAnalyticsResult;
  paperlyticsImages?: Maybe<Array<PaperlyticsImage>>;
  myProfile?: Maybe<UserProfile>;
  staffOrganizations?: Maybe<Array<Maybe<Organization>>>;
  staffOrganization?: Maybe<Organization>;
  users?: Maybe<Array<Maybe<UserProfile>>>;
  activeUsers?: Maybe<Array<Maybe<UserProfile>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  toppingStyles?: Maybe<Array<Maybe<ToppingStyle>>>;
};


export type QueryExpenseReportAllocationBreakdownArgs = {
  filters?: Maybe<ExpenseReportAllocationFiltersInput>;
};


export type QueryExpenseReportByCategoryArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryExpenseReportMonthlySummaryArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryExpensesArgs = {
  filters?: Maybe<ExpenseFilterSetInput>;
};


export type QueryExpenseArgs = {
  id: Scalars['String'];
};


export type QueryExpenseCategoriesArgs = {
  filters?: Maybe<ExpenseCategoryFilterSetInput>;
};


export type QueryCalendarBranchesArgs = {
  filters?: Maybe<CalendarBranchFilterSetInput>;
};


export type QueryCalendarBranchArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCalendarBranchMergeLogEntriesArgs = {
  filters?: Maybe<CalendarBranchMergeLogEntryFilterSetInput>;
};


export type QueryProcessingGroupsArgs = {
  filters?: Maybe<ProcessingGroupFilterSetInput>;
};


export type QueryProcessingGroupArgs = {
  id: Scalars['String'];
};


export type QueryProcessingGroupTemplatesArgs = {
  filters?: Maybe<ProcessingGroupTemplateFilterSetInput>;
};


export type QueryProcessingGroupTemplateArgs = {
  id: Scalars['String'];
};


export type QueryProcessingGroupTemplateVersionArgs = {
  id: Scalars['String'];
};


export type QueryProcessingJobsArgs = {
  filters?: Maybe<ProcessingJobFilterSetInput>;
};


export type QueryProcessingJobArgs = {
  id: Scalars['ID'];
};


export type QueryProcessingGroupStrainStageArgs = {
  id: Scalars['String'];
};


export type QueryHarvestGroupsArgs = {
  filters?: Maybe<HarvestGroupFilterSetInput>;
  logEntryOptions?: Maybe<LogsFilterInput>;
};


export type QueryCompletedHarvestGroupsPaginatedArgs = {
  options?: Maybe<HarvestGroupQuerysetOptionsInput>;
};


export type QueryHarvestGroupArgs = {
  id: Scalars['String'];
};


export type QueryHarvestGroupTemplatesArgs = {
  filters?: Maybe<HarvestGroupTemplateFilterSetInput>;
};


export type QueryHarvestGroupTemplateArgs = {
  id: Scalars['String'];
};


export type QueryBudFormFactorsArgs = {
  filters?: Maybe<BudFormFactorFilterSetInput>;
};


export type QueryZonesArgs = {
  filters?: Maybe<ZoneFilterSetInput>;
};


export type QueryStrainsArgs = {
  filters?: Maybe<StrainFilterSetInput>;
};


export type QueryRecipesArgs = {
  filters?: Maybe<RecipeFilterSetInput>;
};


export type QueryRecipeArgs = {
  id: Scalars['String'];
};


export type QueryRecipeComponentsArgs = {
  filters?: Maybe<RecipeComponentFilterSetInput>;
};


export type QueryNutrientsArgs = {
  filters?: Maybe<NutrientFilterSetInput>;
};


export type QueryLocationsArgs = {
  filters?: Maybe<LocationFilterSetInput>;
};


export type QueryTagsArgs = {
  filters?: Maybe<TagFilterSetInput>;
};


export type QueryMaintenanceRequestsArgs = {
  filters?: Maybe<MaintenanceRequestFilterSetInput>;
};


export type QueryStrainPerformanceArgs = {
  strainId1: Scalars['String'];
  strainId2: Scalars['String'];
};


export type QueryEnvironmentalFilesArgs = {
  filters?: Maybe<EnvironmentalFileFilterSetInput>;
};


export type QueryEnvironmentalFileArgs = {
  id: Scalars['String'];
};


export type QueryEnvironmentalAnalyticsArgs = {
  filters?: Maybe<EnvironmentalAnalyticsFilterInput>;
};


export type QueryPaperlyticsImagesArgs = {
  filters?: Maybe<PaperlyticsFilterSetInput>;
};


export type QueryStaffOrganizationsArgs = {
  filters?: Maybe<OrganizationFilterSetInput>;
};


export type QueryStaffOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  filters?: Maybe<UserFilterSetInput>;
};


export type QueryActiveUsersArgs = {
  filters?: Maybe<ActiveUserFilterSetInput>;
};


export type QueryRolesArgs = {
  filters?: Maybe<RoleFilterSetInput>;
};


export type QueryToppingStylesArgs = {
  filters?: Maybe<ToppingStyleFilterSetInput>;
};

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['UUID'];
  name: Scalars['String'];
  applicationType: RecipeApplicationType;
  createdBy: UserProfile;
  active: Scalars['Boolean'];
  components: Array<RecipeComponent>;
};

export enum RecipeApplicationType {
  Foliar = 'FOLIAR',
  Irrigation = 'IRRIGATION'
}

export type RecipeComponent = {
  __typename?: 'RecipeComponent';
  id: Scalars['UUID'];
  nutrient: Nutrient;
  volume: Scalars['Decimal'];
  unitOfMeasure: RecipeComponentUnitOfMeasure;
  assignedRecipe: Recipe;
};

export type RecipeComponentFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export enum RecipeComponentUnitOfMeasure {
  MillilitersPerGallon = 'MILLILITERS_PER_GALLON',
  GramsPerGallon = 'GRAMS_PER_GALLON'
}

export type RecipeFilterSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  applicationType?: Maybe<Scalars['String']>;
};

export type RecipeResult = Recipe | Error;

export type RecurringExpense = {
  __typename?: 'RecurringExpense';
  id: Scalars['UUID'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  category: ExpenseCategory;
  costInCents: Scalars['Int'];
  allocationRatio: Scalars['Decimal'];
  notes?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProfile>;
};

export type ReorderBudFormFactorsInput = {
  id: Scalars['ID'];
  direction?: Maybe<Scalars['String']>;
};

export type ResolveMaintenanceRequestInput = {
  maintenanceRequestId: Scalars['ID'];
  resolvedAt: Scalars['Date'];
  notes?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  rolePermissions: Array<RolePermissions>;
  userSet: Array<UserProfile>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoleFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export type RolePermissions = {
  __typename?: 'RolePermissions';
  role: Role;
  permission: Permission;
};

export type RoleResult = Role | Error;

export type SendAccountInvitation = {
  __typename?: 'SendAccountInvitation';
  user?: Maybe<UserProfile>;
};

export type SendPasswordResetEmailResult = SendPasswordResetEmailSuccess | Error;

export type SendPasswordResetEmailSuccess = {
  __typename?: 'SendPasswordResetEmailSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type Stage = {
  __typename?: 'Stage';
  id: Scalars['UUID'];
  name: Scalars['String'];
  harvestGroup: HarvestGroup;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  zone: Zone;
  template: StageTemplate;
  calendarBranch?: Maybe<CalendarBranch>;
  strainsInStage: Array<StrainInStage>;
  calendarBranchId: Scalars['String'];
  currentDayOfStage: Scalars['Int'];
  daysInStage: Scalars['Int'];
};

export type StageConflict = {
  __typename?: 'StageConflict';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  schedulingRule: StageConflictSchedulingRule;
  message: Scalars['String'];
  justification?: Maybe<Scalars['String']>;
  stageUpdate: StageUpdate;
  stageconflictoverrideSet: Array<StageConflictOverride>;
};

export type StageConflictOverride = {
  __typename?: 'StageConflictOverride';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stageConflict: StageConflict;
  schedulingRule: StageConflictOverrideSchedulingRule;
  reason: Scalars['String'];
  createdBy?: Maybe<UserProfile>;
};

export enum StageConflictOverrideSchedulingRule {
  StageLength = 'STAGE_LENGTH',
  ZoneOverlap = 'ZONE_OVERLAP',
  LaborShortage = 'LABOR_SHORTAGE',
  EquipmentShortage = 'EQUIPMENT_SHORTAGE',
  TaskOutsideStage = 'TASK_OUTSIDE_STAGE',
  TaskOutOfOrder = 'TASK_OUT_OF_ORDER'
}

export enum StageConflictSchedulingRule {
  StageLength = 'STAGE_LENGTH',
  ZoneOverlap = 'ZONE_OVERLAP',
  LaborShortage = 'LABOR_SHORTAGE',
  EquipmentShortage = 'EQUIPMENT_SHORTAGE',
  TaskOutsideStage = 'TASK_OUTSIDE_STAGE',
  TaskOutOfOrder = 'TASK_OUT_OF_ORDER'
}

export type StageTemplate = {
  __typename?: 'StageTemplate';
  id: Scalars['UUID'];
  name: Scalars['String'];
  createdStageName: Scalars['String'];
  numberOfDays: Scalars['Int'];
  maxNumberOfDays: Scalars['Int'];
  minNumberOfDays: Scalars['Int'];
  orderInProcess: Scalars['Int'];
  environmentalTargetType: StageTemplateEnvironmentalTargetType;
  designatedZones: Array<Zone>;
  taskTemplates: Array<TaskTemplate>;
};

export enum StageTemplateEnvironmentalTargetType {
  Veg = 'VEG',
  Flower = 'FLOWER',
  NoTargets = 'NO_TARGETS'
}

export type StageUpdate = {
  __typename?: 'StageUpdate';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  newStartDate?: Maybe<Scalars['Date']>;
  newEndDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<UserProfile>;
  calendarBranch: CalendarBranch;
  stage: Stage;
  conflicts: Array<StageConflict>;
};

export type Strain = {
  __typename?: 'Strain';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  createdBy: UserProfile;
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  subspecies: StrainSubspecies;
  active: Scalars['Boolean'];
  documentationUrl?: Maybe<Scalars['String']>;
};

export type StrainFilterSetInput = {
  active?: Maybe<Scalars['Boolean']>;
};

export type StrainInStage = {
  __typename?: 'StrainInStage';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  strain: Strain;
  stage: Stage;
  numberOfPlants: Scalars['Int'];
};

export type StrainPerformance = {
  __typename?: 'StrainPerformance';
  id: Scalars['String'];
  name: Scalars['String'];
  harvests: Array<HarvestStrainPerformance>;
  avgGramsPerPlant: Scalars['Float'];
};

export type StrainPerformanceResult = {
  __typename?: 'StrainPerformanceResult';
  strainPerformance1: StrainPerformance;
  strainPerformance2: StrainPerformance;
  strain1: Strain;
  strain2: Strain;
  allStrains: Array<Strain>;
  allBudFormFactors: Array<BudFormFactor>;
};

export type StrainResult = Strain | Error;

export enum StrainSubspecies {
  Indica = 'INDICA',
  Sativa = 'SATIVA',
  Hybrid = 'HYBRID',
  Ruderalis = 'RUDERALIS',
  Cbd = 'CBD',
  Hemp = 'HEMP'
}

export type SuccessOk = {
  __typename?: 'SuccessOK';
  ok: Scalars['Boolean'];
};

export type SuccessOkResult = SuccessOk | Error;

export type Tag = {
  __typename?: 'Tag';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  colorHexCode?: Maybe<Scalars['String']>;
  maintenanceRequestTags: Array<MaintenanceRequestTag>;
};

export type TagFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export enum TargetTypeEnum {
  Task = 'TASK',
  Stage = 'STAGE'
}

export type Task = {
  __typename?: 'Task';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  originalId: Scalars['String'];
  isCurrentRow: Scalars['Boolean'];
  isComplete: Scalars['Boolean'];
  createdBy: UserProfile;
  assignedTo?: Maybe<UserProfile>;
  harvestGroup: HarvestGroup;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  priority: TaskPriority;
  notes?: Maybe<Scalars['String']>;
  template?: Maybe<TaskTemplate>;
};

export type TaskConflict = {
  __typename?: 'TaskConflict';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  schedulingRule: TaskConflictSchedulingRule;
  message: Scalars['String'];
  justification?: Maybe<Scalars['String']>;
  taskUpdate: TaskUpdate;
  taskconflictoverrideSet: Array<TaskConflictOverride>;
};

export type TaskConflictOverride = {
  __typename?: 'TaskConflictOverride';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  taskConflict: TaskConflict;
  schedulingRule: TaskConflictOverrideSchedulingRule;
  reason: Scalars['String'];
  createdBy?: Maybe<UserProfile>;
};

export enum TaskConflictOverrideSchedulingRule {
  StageLength = 'STAGE_LENGTH',
  ZoneOverlap = 'ZONE_OVERLAP',
  LaborShortage = 'LABOR_SHORTAGE',
  EquipmentShortage = 'EQUIPMENT_SHORTAGE',
  TaskOutsideStage = 'TASK_OUTSIDE_STAGE',
  TaskOutOfOrder = 'TASK_OUT_OF_ORDER'
}

export enum TaskConflictSchedulingRule {
  StageLength = 'STAGE_LENGTH',
  ZoneOverlap = 'ZONE_OVERLAP',
  LaborShortage = 'LABOR_SHORTAGE',
  EquipmentShortage = 'EQUIPMENT_SHORTAGE',
  TaskOutsideStage = 'TASK_OUTSIDE_STAGE',
  TaskOutOfOrder = 'TASK_OUT_OF_ORDER'
}

export enum TaskPriority {
  Low = 'LOW',
  Normal = 'NORMAL',
  High = 'HIGH',
  Urgent = 'URGENT'
}

export type TaskResult = Task | Error;

export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  createdTaskName: Scalars['String'];
  stageTemplate: StageTemplate;
  orderInProcess: Scalars['Int'];
  stageDayStart: Scalars['Int'];
  stageDayEnd: Scalars['Int'];
  tasks: Array<Task>;
};

export type TaskUpdate = {
  __typename?: 'TaskUpdate';
  v1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  newStartDate?: Maybe<Scalars['Date']>;
  newEndDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<UserProfile>;
  calendarBranch: CalendarBranch;
  task: Task;
  conflicts: Array<TaskConflict>;
};


export type TokenAuthResult = TokenAuthSuccess | Error;

export type TokenAuthSuccess = {
  __typename?: 'TokenAuthSuccess';
  token?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  user?: Maybe<UserProfile>;
};

export type ToppingStyle = {
  __typename?: 'ToppingStyle';
  id: Scalars['UUID'];
  name: Scalars['String'];
  organization: Organization;
};

export type ToppingStyleFilterSetInput = {
  id?: Maybe<Scalars['String']>;
};

export type ToppingStyleResult = ToppingStyle | Error;


export type UndoBranchCalendarStageUpdateInput = {
  calendarBranchId: Scalars['ID'];
  stageId: Scalars['ID'];
};

export type UndoBranchCalendarTaskUpdateInput = {
  calendarBranchId: Scalars['ID'];
  taskId: Scalars['ID'];
};

export type UpdateActiveHarvestGroupTemplateVersionInput = {
  id: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type UpdateActiveProcessingGroupTemplateVersionInput = {
  id: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type UpdateBranchCalendarStagePatchInput = {
  newStartDate?: Maybe<Scalars['Date']>;
  newEndDate?: Maybe<Scalars['Date']>;
};

export type UpdateBranchCalendarTaskPatchInput = {
  newStartDate?: Maybe<Scalars['Date']>;
  newEndDate?: Maybe<Scalars['Date']>;
};

export type UpdateBudFormFactorInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UpdateCalendarBranchStageInput = {
  calendarBranchId: Scalars['ID'];
  stageId: Scalars['ID'];
  patch?: Maybe<UpdateBranchCalendarStagePatchInput>;
};

export type UpdateCalendarBranchTaskInput = {
  calendarBranchId: Scalars['ID'];
  taskId: Scalars['ID'];
  patch?: Maybe<UpdateBranchCalendarTaskPatchInput>;
};

export type UpdateDisplay = {
  __typename?: 'UpdateDisplay';
  id: Scalars['String'];
  changeDescription: Scalars['String'];
  harvestGroupName: Scalars['String'];
  name: Scalars['String'];
  updateType: UpdateTypeEnum;
  targetType: TargetTypeEnum;
  colorHexCode: Scalars['String'];
  undoable: Scalars['Boolean'];
};

export type UpdateEnvironmentalTargetSetInput = {
  id: Scalars['ID'];
  dayStartTime?: Maybe<Scalars['String']>;
  nightStartTime?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['ID']>;
  daysInStageStart?: Maybe<Scalars['Int']>;
  daysInStageEnd?: Maybe<Scalars['Int']>;
  dayTempLow?: Maybe<Scalars['Int']>;
  dayTempHigh?: Maybe<Scalars['Int']>;
  nightTempLow?: Maybe<Scalars['Int']>;
  nightTempHigh?: Maybe<Scalars['Int']>;
  dayVpdLow?: Maybe<Scalars['Float']>;
  dayVpdHigh?: Maybe<Scalars['Float']>;
  nightVpdLow?: Maybe<Scalars['Float']>;
  nightVpdHigh?: Maybe<Scalars['Float']>;
  dayHumidityLow?: Maybe<Scalars['Int']>;
  dayHumidityHigh?: Maybe<Scalars['Int']>;
  nightHumidityLow?: Maybe<Scalars['Int']>;
  nightHumidityHigh?: Maybe<Scalars['Int']>;
  dayCo2Low?: Maybe<Scalars['Int']>;
  dayCo2High?: Maybe<Scalars['Int']>;
  nightCo2Low?: Maybe<Scalars['Int']>;
  nightCo2High?: Maybe<Scalars['Int']>;
};

export type UpdateExpenseInput = {
  date?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  costInCents?: Maybe<Scalars['Int']>;
  hourlyCostInCents?: Maybe<Scalars['Int']>;
  hoursWorked?: Maybe<Scalars['Float']>;
  allocationRatio?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updateRecurring?: Maybe<Scalars['Boolean']>;
};

export type UpdateHarvestGroupArchivedInput = {
  id?: Maybe<Scalars['ID']>;
  archivedAt?: Maybe<Scalars['String']>;
};

export type UpdateHarvestGroupInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  flowerZoneId?: Maybe<Scalars['String']>;
  stages?: Maybe<Array<UpdateStageInput>>;
  zoneIteration?: Maybe<Scalars['String']>;
  colorHexCode?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<UpdateTaskInput>>;
};

export type UpdateHarvestGroupTemplateArchivedInput = {
  id: Scalars['ID'];
  archivedAt?: Maybe<Scalars['String']>;
};

export type UpdateHarvestGroupTemplateInput = {
  id: Scalars['ID'];
  setNewTemplateAsActive?: Maybe<Scalars['Boolean']>;
  newTemplateVersion?: Maybe<CreateHarvestGroupTemplateVersionInput>;
};

export type UpdateHarvestProjectionInput = {
  harvestGroupId: Scalars['ID'];
  strainId: Scalars['ID'];
  plantCount: Scalars['Int'];
};

export type UpdateHarvestReportInput = {
  harvestGroupId: Scalars['ID'];
  strainYields?: Maybe<Array<CreateHarvestStrainYieldInput>>;
};

export type UpdateNutrientInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  priceInCentsPerUnit?: Maybe<Scalars['Float']>;
  boughtPrice?: Maybe<Scalars['Float']>;
  boughtAmount?: Maybe<Scalars['Float']>;
  boughtUnit?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrganizationSettingsInput = {
  timezone?: Maybe<Scalars['String']>;
  optionConvertGramsAbove?: Maybe<Scalars['Int']>;
  normalizeWindowMinutes?: Maybe<Scalars['Int']>;
  alertConsecutiveThreshold?: Maybe<Scalars['Int']>;
};

export type UpdateProcessingGroupArchivedInput = {
  id: Scalars['ID'];
  archivedAt?: Maybe<Scalars['String']>;
};

export type UpdateProcessingGroupTemplateArchivedInput = {
  id: Scalars['ID'];
  archivedAt?: Maybe<Scalars['String']>;
};

export type UpdateProcessingJobInput = {
  id: Scalars['ID'];
  assigneeId?: Maybe<Scalars['ID']>;
  newInputGrams: Scalars['Decimal'];
};

export type UpdateProcessingJobLogInput = {
  id: Scalars['ID'];
  jobId: Scalars['ID'];
  performerId?: Maybe<Scalars['ID']>;
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  breakMinutes: Scalars['Decimal'];
  leftoverInputGrams?: Maybe<Scalars['Decimal']>;
  outputGrams: Array<JobLogOutputGramsInput>;
};

export type UpdateRecipeComponentInput = {
  id: Scalars['ID'];
  nutrientId?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Decimal']>;
};

export type UpdateRecipeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  applicationType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  componentsToCreate?: Maybe<Array<Maybe<CreateRecipeComponentInput>>>;
  componentsToUpdate?: Maybe<Array<Maybe<UpdateRecipeComponentInput>>>;
  componentsToDelete?: Maybe<Array<Maybe<DeleteRecipeComponentInput>>>;
};

export type UpdateRoleInput = {
  id: Scalars['ID'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateStageConflictInput = {
  schedulingRule?: Maybe<Scalars['String']>;
  calendarBranchId: Scalars['ID'];
  stageId: Scalars['ID'];
  patch?: Maybe<UpdateStageConflictPatchInput>;
};

export type UpdateStageConflictPatchInput = {
  justification: Scalars['String'];
};

export type UpdateStageInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  zoneId?: Maybe<Scalars['String']>;
  upsertStrainsInStage: Array<UpdateStrainInStageInput>;
  removeStrainsInStage?: Maybe<Array<DeleteStrainInStageInput>>;
};

export type UpdateStrainInStageInput = {
  strainId: Scalars['ID'];
  numberOfPlants: Scalars['Int'];
};

export type UpdateStrainInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  subspecies?: Maybe<Scalars['String']>;
  documentationUrl?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UpdateTaskConflictInput = {
  schedulingRule?: Maybe<Scalars['String']>;
  calendarBranchId: Scalars['ID'];
  taskId: Scalars['ID'];
  patch?: Maybe<UpdateTaskConflictPatchInput>;
};

export type UpdateTaskConflictPatchInput = {
  justification: Scalars['String'];
};

export type UpdateTaskInput = {
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type UpdateTimeOffsetInput = {
  fileId: Scalars['ID'];
  offsetSeconds: Scalars['Int'];
};

export enum UpdateTypeEnum {
  NewEvent = 'NEW_EVENT',
  DateChange = 'DATE_CHANGE'
}

export type UpdateUserActiveInput = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  isNoLogin?: Maybe<Scalars['Boolean']>;
  userPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateUserProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileResult = UpdateUserProfileSuccess | Error;

export type UpdateUserProfileSuccess = {
  __typename?: 'UpdateUserProfileSuccess';
  user?: Maybe<UserProfile>;
};

export type UpdateZoneInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  maxConcurrentHarvestGroups?: Maybe<Scalars['Int']>;
  maxConcurrentPlants?: Maybe<Scalars['Int']>;
  cultivationStyle?: Maybe<Scalars['String']>;
  cultivationStyleOther?: Maybe<Scalars['String']>;
  lightingType?: Maybe<Scalars['String']>;
  lightingTypeOther?: Maybe<Scalars['String']>;
  growingMedia?: Maybe<Scalars['String']>;
  growingMediaOther?: Maybe<Scalars['String']>;
  irrigationStyle?: Maybe<Scalars['String']>;
  irrigationStyleOther?: Maybe<Scalars['String']>;
  irrigationFrequency?: Maybe<Scalars['String']>;
  irrigationFrequencyOther?: Maybe<Scalars['String']>;
  lengthFeet?: Maybe<Scalars['Float']>;
  lengthInches?: Maybe<Scalars['Float']>;
  heightFeet?: Maybe<Scalars['Float']>;
  heightInches?: Maybe<Scalars['Float']>;
  widthFeet?: Maybe<Scalars['Float']>;
  widthInches?: Maybe<Scalars['Float']>;
  growMediaSize?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  acSizeByTon?: Maybe<Scalars['Float']>;
  acNumUnits?: Maybe<Scalars['Int']>;
  acBrand?: Maybe<Scalars['String']>;
  dehumidifierSizeByPintsPerDay?: Maybe<Scalars['Float']>;
  dehumidifierNumUnits?: Maybe<Scalars['Int']>;
  dehumidifierBrand?: Maybe<Scalars['String']>;
};

export type UserFilterSetInput = {
  email_Icontains?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  isSuperuser: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isStaff: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  id: Scalars['String'];
  organization?: Maybe<Organization>;
  isNoLogin: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  userPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationId?: Maybe<Scalars['String']>;
};

export type UserProfileResult = UserProfile | Error;

export type Zone = {
  __typename?: 'Zone';
  id: Scalars['String'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  maxConcurrentHarvestGroups?: Maybe<Scalars['Int']>;
  maxConcurrentPlants?: Maybe<Scalars['Int']>;
  cultivationStyle?: Maybe<ZoneCultivationStyle>;
  cultivationStyleOther?: Maybe<Scalars['String']>;
  lightingType?: Maybe<ZoneLightingType>;
  lightingTypeOther?: Maybe<Scalars['String']>;
  growingMedia?: Maybe<ZoneGrowingMedia>;
  growingMediaOther?: Maybe<Scalars['String']>;
  irrigationStyle?: Maybe<ZoneIrrigationStyle>;
  irrigationStyleOther?: Maybe<Scalars['String']>;
  irrigationFrequency?: Maybe<ZoneIrrigationFrequency>;
  irrigationFrequencyOther?: Maybe<Scalars['String']>;
  lengthFeet?: Maybe<Scalars['Int']>;
  lengthInches?: Maybe<Scalars['Int']>;
  heightFeet?: Maybe<Scalars['Int']>;
  heightInches?: Maybe<Scalars['Int']>;
  widthFeet?: Maybe<Scalars['Int']>;
  widthInches?: Maybe<Scalars['Int']>;
  growMediaSize?: Maybe<Scalars['Int']>;
  acSizeByTon?: Maybe<Scalars['Int']>;
  acNumUnits?: Maybe<Scalars['Int']>;
  acBrand?: Maybe<Scalars['String']>;
  dehumidifierSizeByPintsPerDay?: Maybe<Scalars['Int']>;
  dehumidifierNumUnits?: Maybe<Scalars['Int']>;
  dehumidifierBrand?: Maybe<Scalars['String']>;
  rows?: Maybe<Scalars['Int']>;
  currentHarvestGroups: Array<HarvestGroup>;
};

export enum ZoneCultivationStyle {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
  Greenhouse = 'GREENHOUSE',
  SingleStack = 'SINGLE_STACK',
  DoubleStack = 'DOUBLE_STACK',
  TripleStack = 'TRIPLE_STACK',
  HoopHouse = 'HOOP_HOUSE',
  LightDeprivation = 'LIGHT_DEPRIVATION',
  SupplementalLight = 'SUPPLEMENTAL_LIGHT',
  Other = 'OTHER'
}

export type ZoneFilterSetInput = {
  active?: Maybe<Scalars['Boolean']>;
};

export enum ZoneGrowingMedia {
  Soil = 'SOIL',
  LivingSoil = 'LIVING_SOIL',
  Coco = 'COCO',
  Rockwool = 'ROCKWOOL',
  Other = 'OTHER'
}

export enum ZoneIrrigationFrequency {
  MultipleShotsPerDay = 'MULTIPLE_SHOTS_PER_DAY',
  SingleLargeVolumePerDay = 'SINGLE_LARGE_VOLUME_PER_DAY',
  Other = 'OTHER'
}

export enum ZoneIrrigationStyle {
  DripEmitters = 'DRIP_EMITTERS',
  HandWater = 'HAND_WATER',
  EbbNFlow = 'EBB_N_FLOW',
  Nft = 'NFT',
  Other = 'OTHER'
}

export enum ZoneLightingType {
  DoubleEndedHps = 'DOUBLE_ENDED_HPS',
  Cmh = 'CMH',
  Led = 'LED',
  Other = 'OTHER'
}

export type ZoneResult = Zone | Error;

export type CalendarBranchQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRangeInput>;
}>;


export type CalendarBranchQuery = { __typename?: 'Query', calendarBranch: (
    { __typename?: 'CalendarBranch' }
    & CalendarBranchFieldsFragment
  ) };

export type CalendarBranchesQueryVariables = Exact<{ [key: string]: never; }>;


export type CalendarBranchesQuery = { __typename?: 'Query', calendarBranches?: Maybe<Array<(
    { __typename?: 'CalendarBranch' }
    & CalendarBranchesGeneralInfoFieldsFragment
  )>> };

export type CalendarBranchMergeLogEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CalendarBranchMergeLogEntriesQuery = { __typename?: 'Query', calendarBranchMergeLogEntries?: Maybe<Array<{ __typename?: 'CalendarBranchMergeLogEntry', id: any, calendarBranchName: string, mergedAt: any, changelog?: Maybe<any>, mergedBy?: Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string }> }>> };

export type CalendarBranchFieldsFragment = (
  { __typename?: 'CalendarBranch', id: any, name: string, status: CalendarBranchStatus, updatedAt: any, createdBy?: Maybe<{ __typename?: 'UserProfile', id: string }>, stages: Array<(
    { __typename?: 'Stage' }
    & StageCalendarFieldsFragment
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & TaskCalendarFieldsFragment
  )> }
  & UpdateAndConflictDisplaysFragment
);

export type ShortCalendarBranchFieldsFragment = { __typename?: 'CalendarBranch', id: any, name: string, status: CalendarBranchStatus, updatedAt: any, createdBy?: Maybe<{ __typename?: 'UserProfile', id: string }> };

export type CalendarBranchesGeneralInfoFieldsFragment = { __typename?: 'CalendarBranch', id: any, name: string, createdAt: any, updatedAt: any, status: CalendarBranchStatus, updateCount: number, conflictCount: number, createdBy?: Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string }>, lastUpdatedBy?: Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string }> };

export type UpdateAndConflictDisplaysFragment = { __typename?: 'CalendarBranch', updates: Array<{ __typename?: 'UpdateDisplay', changeDescription: string, harvestGroupName: string, name: string, id: string, updateType: UpdateTypeEnum, colorHexCode: string, undoable: boolean, targetType: TargetTypeEnum }>, conflicts: Array<{ __typename?: 'ConflictDisplay', id: string, conflictType: string, description: string, harvestGroupName: string, name: string, colorHexCode: string, calendarBranchId: string, justification: string, schedulingRule: string }> };

export type CreateCalendarBranchMutationVariables = Exact<{
  input: CreateCalendarBranchInput;
}>;


export type CreateCalendarBranchMutation = { __typename?: 'Mutation', createCalendarBranch?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & ShortCalendarBranchFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type DuplicateCalendarBranchMutationVariables = Exact<{
  input: DuplicateCalendarBranchInput;
}>;


export type DuplicateCalendarBranchMutation = { __typename?: 'Mutation', duplicateCalendarBranch?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & ShortCalendarBranchFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateCalendarBranchTaskMutationVariables = Exact<{
  input: UpdateCalendarBranchTaskInput;
  dateRange?: Maybe<DateRangeInput>;
}>;


export type UpdateCalendarBranchTaskMutation = { __typename?: 'Mutation', updateCalendarBranchTask?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & CalendarBranchFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UpdateCalendarBranchStageMutationVariables = Exact<{
  input: UpdateCalendarBranchStageInput;
  dateRange?: Maybe<DateRangeInput>;
}>;


export type UpdateCalendarBranchStageMutation = { __typename?: 'Mutation', updateCalendarBranchStage?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & CalendarBranchFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationVariables = Exact<{
  input: UpdateCalendarBranchStageInput;
}>;


export type UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation = { __typename?: 'Mutation', updateCalendarBranchStageAndFollowingEventsInHarvestGroup?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & ShortCalendarBranchFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UndoBranchCalendarTaskUpdateMutationVariables = Exact<{
  input: UndoBranchCalendarTaskUpdateInput;
  dateRange?: Maybe<DateRangeInput>;
}>;


export type UndoBranchCalendarTaskUpdateMutation = { __typename?: 'Mutation', undoBranchCalendarTaskUpdate?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & CalendarBranchFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UndoBranchCalendarStageUpdateMutationVariables = Exact<{
  input: UndoBranchCalendarStageUpdateInput;
  dateRange?: Maybe<DateRangeInput>;
}>;


export type UndoBranchCalendarStageUpdateMutation = { __typename?: 'Mutation', undoBranchCalendarStageUpdate?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & CalendarBranchFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UpdateStageConflictMutationVariables = Exact<{
  input: UpdateStageConflictInput;
}>;


export type UpdateStageConflictMutation = { __typename?: 'Mutation', updateStageConflict?: Maybe<{ __typename?: 'CalendarBranch', id: any, name: string, status: CalendarBranchStatus, updatedAt: any, createdBy?: Maybe<{ __typename?: 'UserProfile', id: string }> } | { __typename?: 'Error', message: string }> };

export type UpdateTaskConflictMutationVariables = Exact<{
  input: UpdateTaskConflictInput;
}>;


export type UpdateTaskConflictMutation = { __typename?: 'Mutation', updateTaskConflict?: Maybe<{ __typename?: 'CalendarBranch', id: any, name: string, status: CalendarBranchStatus, updatedAt: any, createdBy?: Maybe<{ __typename?: 'UserProfile', id: string }> } | { __typename?: 'Error', message: string }> };

export type ApproveCalendarBranchMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveCalendarBranchMutation = { __typename?: 'Mutation', approveCalendarBranch?: Maybe<(
    { __typename?: 'CalendarBranch' }
    & CalendarBranchesGeneralInfoFieldsFragment
  ) | { __typename?: 'Error' }> };

export type DeleteCalendarBranchMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCalendarBranchMutation = { __typename?: 'Mutation', deleteCalendarBranch?: Maybe<{ __typename?: 'SuccessOK', ok: boolean } | { __typename?: 'Error', message: string }> };

export type StrainPerformanceQueryVariables = Exact<{
  strainId1: Scalars['String'];
  strainId2: Scalars['String'];
}>;


export type StrainPerformanceQuery = { __typename?: 'Query', strainPerformance: { __typename?: 'StrainPerformanceResult', strain1: { __typename?: 'Strain', id: any, name: string }, strainPerformance1: { __typename?: 'StrainPerformance', id: string, name: string, avgGramsPerPlant: number, harvests: Array<{ __typename?: 'HarvestStrainPerformance', finalPlantCount: number, gramsPerPlant: number, harvestDate: string, id: string, name: string, totalGrams: number, totalFlowerGrams: number, byFormFactor: Array<{ __typename?: 'BudFormFactorYield', budFormFactorId: string, numberOfGrams: number }> }> }, strain2: { __typename?: 'Strain', id: any, name: string }, strainPerformance2: { __typename?: 'StrainPerformance', id: string, name: string, avgGramsPerPlant: number, harvests: Array<{ __typename?: 'HarvestStrainPerformance', finalPlantCount: number, gramsPerPlant: number, harvestDate: string, id: string, name: string, totalGrams: number, totalFlowerGrams: number, byFormFactor: Array<{ __typename?: 'BudFormFactorYield', budFormFactorId: string, numberOfGrams: number }> }> }, allStrains: Array<{ __typename?: 'Strain', id: any, name: string }>, allBudFormFactors: Array<(
      { __typename?: 'BudFormFactor' }
      & BudFormFactorFieldsFragment
    )> } };

export type LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsQuery = { __typename?: 'Query', locations?: Maybe<Array<{ __typename?: 'Location', id: any, name: string }>> };

export type BudFormFactorsQueryVariables = Exact<{
  filters?: Maybe<BudFormFactorFilterSetInput>;
}>;


export type BudFormFactorsQuery = { __typename?: 'Query', budFormFactors?: Maybe<Array<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  )>> };

export type CreateBudFormFactorMutationVariables = Exact<{
  input: CreateBudFormFactorInput;
}>;


export type CreateBudFormFactorMutation = { __typename?: 'Mutation', createBudFormFactor?: Maybe<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateBudFormFactorMutationVariables = Exact<{
  input: UpdateBudFormFactorInput;
}>;


export type UpdateBudFormFactorMutation = { __typename?: 'Mutation', updateBudFormFactor?: Maybe<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ReorderBudFormFactorsMutationVariables = Exact<{
  input: ReorderBudFormFactorsInput;
}>;


export type ReorderBudFormFactorsMutation = { __typename?: 'Mutation', reorderBudFormFactors?: Maybe<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ActivateBudFormFactorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateBudFormFactorMutation = { __typename?: 'Mutation', updateBudFormFactor?: Maybe<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type DeactivateBudFormFactorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateBudFormFactorMutation = { __typename?: 'Mutation', updateBudFormFactor?: Maybe<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type BudFormFactorFieldsFragment = { __typename?: 'BudFormFactor', id: any, name: string, active: boolean, createdAt: any, description: string, orderPosition: number, classification: BudFormFactorClassification, createdBy: { __typename?: 'UserProfile', id: string, email: string, firstName: string, lastName: string } };

export type UploadEnvironmentalFileMutationVariables = Exact<{
  input: EnvironmentalFileInput;
}>;


export type UploadEnvironmentalFileMutation = { __typename?: 'Mutation', uploadEnvironmentalFile?: Maybe<(
    { __typename?: 'EnvironmentalFileType' }
    & EnvironmentalFileFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateTimeOffsetMutationVariables = Exact<{
  input: UpdateTimeOffsetInput;
}>;


export type UpdateTimeOffsetMutation = { __typename?: 'Mutation', updateTimeOffset?: Maybe<(
    { __typename?: 'EnvironmentalFileType' }
    & EnvironmentalFileFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ProcessEnvironmentalFileMutationVariables = Exact<{
  input: ProcessEnvironmentalFileInput;
}>;


export type ProcessEnvironmentalFileMutation = { __typename?: 'Mutation', processEnvironmentalFile?: Maybe<(
    { __typename?: 'EnvironmentalFileType' }
    & EnvironmentalFileFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type EnvironmentalFilesQueryVariables = Exact<{
  filters?: Maybe<EnvironmentalFileFilterSetInput>;
}>;


export type EnvironmentalFilesQuery = { __typename?: 'Query', environmentalFiles?: Maybe<Array<(
    { __typename?: 'EnvironmentalFileType' }
    & EnvironmentalFileFieldsFragment
  )>> };

export type EnvironmentalFileQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnvironmentalFileQuery = { __typename?: 'Query', environmentalFile: (
    { __typename?: 'EnvironmentalFileType' }
    & PreviewFileFieldsFragment
  ) };

export type EnvironmentalFileFieldsFragment = { __typename?: 'EnvironmentalFileType', id: any, createdAt: any, dataFile: string, fileType?: Maybe<EnvironmentalFileFileType>, fileStatus: EnvironmentalFileFileStatus, autodetectStatus: EnvironmentalFileAutodetectStatus, timeOffsetSeconds: number, zone: { __typename?: 'Zone', id: string, name: string } };

export type PreviewFileFieldsFragment = { __typename?: 'EnvironmentalFileType', id: any, fileType?: Maybe<EnvironmentalFileFileType>, autodetectStatus: EnvironmentalFileAutodetectStatus, guessEnvType?: Maybe<string>, timeOffsetSeconds: number, sevenDayPreview?: Maybe<Array<{ __typename?: 'AnalyticsDataPoint', x: any, y: number }>>, guessTargetSet?: Maybe<{ __typename?: 'EnvironmentalTargetSet', id: any, dayStartTime: any, nightStartTime: any, displayName?: Maybe<string> }>, zone: { __typename?: 'Zone', id: string, name: string }, currentHgs?: Maybe<Array<{ __typename?: 'HarvestGroup', id: string, name: string }>>, currentStages?: Maybe<Array<{ __typename?: 'Stage', id: any, name: string }>> };

export type AlertWarningFragment = { __typename?: 'AlertWarning', id: any, metric: AlertWarningMetric, updatedAt: any, createdAt: any, startTime: any, endTime: any, avgDeviation: number, maxDeviation: number, position: AlertWarningPosition, message: string, zone: { __typename?: 'Zone', id: string, name: string }, stage: { __typename?: 'Stage', id: any, name: string, harvestGroup: { __typename?: 'HarvestGroup', id: string, name: string } } };

export type AnalyticsChartDataFragment = { __typename?: 'AnalyticsChartData', name: string, data: Array<{ __typename?: 'AnalyticsDataPoint', x: any, y: number }>, alertWarnings: Array<(
    { __typename?: 'AlertWarning' }
    & AlertWarningFragment
  )> };

export type EnvironmentalAnalyticsQueryVariables = Exact<{
  filters?: Maybe<EnvironmentalAnalyticsFilterInput>;
}>;


export type EnvironmentalAnalyticsQuery = { __typename?: 'Query', environmentalAnalytics: { __typename?: 'EnvironmentalAnalyticsResult', temperature: (
      { __typename?: 'AnalyticsChartData' }
      & AnalyticsChartDataFragment
    ), humidity: (
      { __typename?: 'AnalyticsChartData' }
      & AnalyticsChartDataFragment
    ), co2: (
      { __typename?: 'AnalyticsChartData' }
      & AnalyticsChartDataFragment
    ), vpd: (
      { __typename?: 'AnalyticsChartData' }
      & AnalyticsChartDataFragment
    ) } };

export type CurrentAndUpcomingHarvestGroupsQueryVariables = Exact<{
  unarchived?: Maybe<Scalars['String']>;
}>;


export type CurrentAndUpcomingHarvestGroupsQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
    & HarvestGroupOverviewFieldsFragment
    & HarvestGroupCurrentStageFieldsFragment
  )>> };

export type CurrentAndUpcomingHarvestGroupsForSelectQueryVariables = Exact<{
  filters?: Maybe<HarvestGroupFilterSetInput>;
}>;


export type CurrentAndUpcomingHarvestGroupsForSelectQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<{ __typename?: 'HarvestGroup', name: string, id: string, colorHexCode?: Maybe<string> }>> };

export type CompletedHarvestGroupsPaginatedQueryVariables = Exact<{
  options?: Maybe<HarvestGroupQuerysetOptionsInput>;
}>;


export type CompletedHarvestGroupsPaginatedQuery = { __typename?: 'Query', completedHarvestGroupsPaginated?: Maybe<(
    { __typename?: 'HarvestGroupQuerysetResult' }
    & CompletedHarvestGroupsPaginatedResultFragment
  )> };

export type PagingInfoFragment = { __typename?: 'PagingInfo', totalCount: number, pageCount: number, size: number, start: number, page: number, hasNextPage: boolean, hasPreviousPage: boolean };

export type CompletedHarvestGroupsPaginatedResultFragment = { __typename?: 'HarvestGroupQuerysetResult', options: { __typename?: 'HarvestGroupQuerysetOptionsOutput', paging: (
      { __typename?: 'PagingInfo' }
      & PagingInfoFragment
    ), filters: { __typename?: 'CompletedHarvestGroupFilterSetOutput', search?: Maybe<string> } }, items: Array<Maybe<(
    { __typename?: 'HarvestGroup' }
    & CompletedHarvestGroupFieldsFragment
  )>> };

export type CompletedHarvestGroupsQueryVariables = Exact<{
  unarchived?: Maybe<Scalars['String']>;
}>;


export type CompletedHarvestGroupsQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<(
    { __typename?: 'HarvestGroup' }
    & CompletedHarvestGroupFieldsFragment
  )>> };

export type CompletedHarvestGroupsNamesQueryVariables = Exact<{
  unarchived?: Maybe<Scalars['String']>;
}>;


export type CompletedHarvestGroupsNamesQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<{ __typename?: 'HarvestGroup', id: string, name: string, harvestDate?: Maybe<any> }>> };

export type CompletedHarvestGroupsListQueryVariables = Exact<{
  unarchived?: Maybe<Scalars['String']>;
}>;


export type CompletedHarvestGroupsListQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<{ __typename?: 'HarvestGroup', id: string, name: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, archivedAt?: Maybe<any>, zoneIteration: string, strains: Array<(
      { __typename?: 'Strain' }
      & StrainReportFieldsFragment
    )>, flowerZone: (
      { __typename?: 'Zone' }
      & ZoneIdAndNameFragment
    ) }>> };

export type CompletedHarvestGroupQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompletedHarvestGroupQuery = { __typename?: 'Query', harvestGroup: (
    { __typename?: 'HarvestGroup' }
    & CompletedHarvestGroupFieldsFragment
  ) };

export type AnalyticsHarvestGroupsQueryVariables = Exact<{
  unarchived?: Maybe<Scalars['String']>;
}>;


export type AnalyticsHarvestGroupsQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<(
    { __typename?: 'HarvestGroup' }
    & AnalyticsHarvestGroupFieldsFragment
  )>> };

export type AnalyticsHarvestGroupsOverviewFragment = { __typename?: 'HarvestGroup', name: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, archivedAt?: Maybe<any>, yields: Array<{ __typename?: 'HarvestStrainYield', id: any, strain: (
      { __typename?: 'Strain' }
      & StrainReportFieldsFragment
    ) }> };

export type AnalyticsHarvestGroupsOverviewQueryVariables = Exact<{
  unarchived?: Maybe<Scalars['String']>;
}>;


export type AnalyticsHarvestGroupsOverviewQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<(
    { __typename?: 'HarvestGroup' }
    & AnalyticsHarvestGroupsOverviewFragment
  )>> };

export type CompletedHarvestGroupWithLogsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompletedHarvestGroupWithLogsQuery = { __typename?: 'Query', harvestGroup: (
    { __typename?: 'HarvestGroup' }
    & CompletedHarvestGroupWithLogsFieldsFragment
  ) };

export type HarvestReportComparisonQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type HarvestReportComparisonQuery = { __typename?: 'Query', harvestGroup: (
    { __typename?: 'HarvestGroup' }
    & HarvestGroupReportComparisonFieldsFragment
  ) };

export type HarvestGroupQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type HarvestGroupQuery = { __typename?: 'Query', harvestGroup: (
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
  ) };

export type HarvestGroupWithJustLogEntriesAndCompletionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type HarvestGroupWithJustLogEntriesAndCompletionQuery = { __typename?: 'Query', harvestGroup: { __typename?: 'HarvestGroup', id: string, name: string, colorHexCode?: Maybe<string>, logEntries: Array<(
      { __typename?: 'LogEntry' }
      & LogEntryFieldsFragment
    )>, harvestCompletion?: Maybe<{ __typename?: 'HarvestCompletion', cosignedAt?: Maybe<any> }> } };

export type AllHarvestGroupsWithLogEntriesQueryVariables = Exact<{
  logEntryOptions?: Maybe<LogsFilterInput>;
}>;


export type AllHarvestGroupsWithLogEntriesQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupWithLogEntriesFragment
  )>> };

export type AllHarvestGroupsShortOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type AllHarvestGroupsShortOverviewQuery = { __typename?: 'Query', harvestGroups?: Maybe<Array<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupShortOverviewFieldsFragment
  )>> };

export type HarvestGroupWithJustStrainsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type HarvestGroupWithJustStrainsQuery = { __typename?: 'Query', harvestGroup: { __typename?: 'HarvestGroup', strains: Array<(
      { __typename?: 'Strain' }
      & StrainReportFieldsFragment
    )> } };

export type CreateHarvestGroupMutationVariables = Exact<{
  input: CreateHarvestGroupInput;
}>;


export type CreateHarvestGroupMutation = { __typename?: 'Mutation', createHarvestGroup?: Maybe<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateHarvestGroupMutationVariables = Exact<{
  input: UpdateHarvestGroupInput;
}>;


export type UpdateHarvestGroupMutation = { __typename?: 'Mutation', updateHarvestGroup?: Maybe<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateHarvestGroupArchivedMutationVariables = Exact<{
  input: UpdateHarvestGroupArchivedInput;
}>;


export type UpdateHarvestGroupArchivedMutation = { __typename?: 'Mutation', updateHarvestGroupArchived?: Maybe<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type CreateHarvestReportMutationVariables = Exact<{
  input: CreateHarvestReportInput;
}>;


export type CreateHarvestReportMutation = { __typename?: 'Mutation', createHarvestReport?: Maybe<{ __typename?: 'HarvestGroup', id: string, yields: Array<(
      { __typename?: 'HarvestStrainYield' }
      & YieldFieldsFragment
    )> } | { __typename?: 'Error', message: string }> };

export type UpdateHarvestReportMutationVariables = Exact<{
  input: UpdateHarvestReportInput;
}>;


export type UpdateHarvestReportMutation = { __typename?: 'Mutation', updateHarvestReport?: Maybe<{ __typename?: 'HarvestGroup', id: string, yields: Array<(
      { __typename?: 'HarvestStrainYield' }
      & YieldFieldsFragment
    )> } | { __typename?: 'Error', message: string }> };

export type UpdateHarvestProjectionMutationVariables = Exact<{
  input: UpdateHarvestProjectionInput;
}>;


export type UpdateHarvestProjectionMutation = { __typename?: 'Mutation', updateHarvestProjection?: Maybe<{ __typename?: 'HarvestProjection', id: any, strainId: string, plantCount: number, harvestGroupId: string, strain: { __typename?: 'Strain', id: any, name: string } } | { __typename?: 'Error' }> };

export type HarvestReportQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type HarvestReportQuery = { __typename?: 'Query', harvestGroup: { __typename?: 'HarvestGroup', id: string, yields: Array<(
      { __typename?: 'HarvestStrainYield' }
      & YieldFieldsFragment
    )> } };

export type CompleteHarvestMutationVariables = Exact<{
  input: CompleteHarvestInput;
}>;


export type CompleteHarvestMutation = { __typename?: 'Mutation', completeHarvest?: Maybe<(
    { __typename?: 'HarvestCompletion' }
    & HarvestCompletionFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ApproveHarvestCompletionMutationVariables = Exact<{
  input: ApproveHarvestCompletionInput;
}>;


export type ApproveHarvestCompletionMutation = { __typename?: 'Mutation', approveHarvestCompletion?: Maybe<(
    { __typename?: 'HarvestCompletion' }
    & HarvestCompletionFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ArchiveHarvestGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveHarvestGroupMutation = { __typename?: 'Mutation', updateHarvestGroupArchived?: Maybe<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UnarchiveHarvestGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnarchiveHarvestGroupMutation = { __typename?: 'Mutation', updateHarvestGroupArchived?: Maybe<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type HarvestGroupWithLogEntriesFragment = (
  { __typename?: 'HarvestGroup', logEntries: Array<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  )> }
  & HarvestGroupOverviewFieldsFragment
  & HarvestGroupCurrentStageFieldsFragment
);

export type HarvestGroupCalendarFieldsFragment = { __typename?: 'HarvestGroup', id: string, name: string, colorHexCode?: Maybe<string>, tasks: Array<(
    { __typename?: 'Task', template?: Maybe<{ __typename?: 'TaskTemplate', id: any }> }
    & TaskCalendarFieldsFragment
  )>, stages: Array<(
    { __typename?: 'Stage' }
    & StageCalendarFieldsFragment
  )> };

export type HarvestGroupFieldsFragment = (
  { __typename?: 'HarvestGroup', id: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, archivedAt?: Maybe<any>, zoneIteration: string, flowerZone: { __typename?: 'Zone', id: string, name: string }, template: (
    { __typename?: 'HarvestGroupTemplateVersion' }
    & HarvestGroupTemplateVersionFieldsFragment
  ), stages: Array<(
    { __typename?: 'Stage', zone: { __typename?: 'Zone', id: string, name: string }, strainsInStage: Array<{ __typename?: 'StrainInStage', numberOfPlants: number, strain: { __typename?: 'Strain', id: any, name: string } }>, template: { __typename?: 'StageTemplate', id: any } }
    & StageCalendarFieldsFragment
  )>, strains: Array<(
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  )>, harvestCompletion?: Maybe<{ __typename?: 'HarvestCompletion', createdAt: any, cosignedAt?: Maybe<any>, createdBy: (
      { __typename?: 'UserProfile', id: string }
      & NameFragment
    ), cosignedBy?: Maybe<(
      { __typename?: 'UserProfile', id: string }
      & NameFragment
    )> }> }
  & HarvestGroupCalendarFieldsFragment
);

export type HarvestProjectionFragment = { __typename?: 'HarvestProjection', id: any, harvestGroupId: string, strainId: string, plantCount: number };

export type HarvestGroupCurrentStageFieldsFragment = { __typename?: 'HarvestGroup', currentStage: { __typename?: 'Stage', id: any, name: string, currentDayOfStage: number, daysInStage: number, calendarBranchId: string, strainsInStage: Array<{ __typename?: 'StrainInStage', numberOfPlants: number, strain: { __typename?: 'Strain', name: string, id: any } }> }, projections?: Maybe<Array<(
    { __typename?: 'HarvestProjection' }
    & HarvestProjectionFragment
  )>> };

export type HarvestCompletionFieldsFragment = { __typename?: 'HarvestCompletion', id: any, cosignedBy?: Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string }>, harvestGroup: (
    { __typename?: 'HarvestGroup' }
    & HarvestGroupFieldsFragment
  ), createdBy: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } };

export type CompletedHarvestGroupFieldsFragment = (
  { __typename?: 'HarvestGroup', id: string, name: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, archivedAt?: Maybe<any>, template: (
    { __typename?: 'HarvestGroupTemplateVersion' }
    & HarvestGroupTemplateVersionFieldsFragment
  ), stages: Array<(
    { __typename?: 'Stage' }
    & StageReportFieldsFragment
  )>, strains: Array<(
    { __typename?: 'Strain' }
    & StrainReportFieldsFragment
  )>, yields: Array<(
    { __typename?: 'HarvestStrainYield' }
    & YieldReportFieldsFragment
  )>, flowerZone: (
    { __typename?: 'Zone' }
    & ZoneIdAndNameFragment
  ), becameProcessingGroup?: Maybe<{ __typename?: 'ProcessingGroup', id: string, name: string }> }
  & HarvestGroupReportFieldsFragment
);

export type AnalyticsHarvestGroupFieldsFragment = (
  { __typename?: 'HarvestGroup', name: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, archivedAt?: Maybe<any>, stages: Array<(
    { __typename?: 'Stage' }
    & StageReportFieldsFragment
  )>, strains: Array<(
    { __typename?: 'Strain' }
    & StrainReportFieldsFragment
  )>, yields: Array<(
    { __typename?: 'HarvestStrainYield' }
    & YieldReportFieldsFragment
  )>, flowerZone: (
    { __typename?: 'Zone' }
    & ZoneIdAndNameFragment
  ), becameProcessingGroup?: Maybe<{ __typename?: 'ProcessingGroup', id: string, name: string }> }
  & HarvestGroupReportFieldsFragment
  & HarvestGroupFieldsFragment
);

export type CompletedHarvestGroupWithLogsFieldsFragment = (
  { __typename?: 'HarvestGroup', logEntries: Array<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  )> }
  & CompletedHarvestGroupFieldsFragment
);

export type HarvestGroupReportComparisonFieldsFragment = (
  { __typename?: 'HarvestGroup', logEntries: Array<{ __typename?: 'LogEntry', id: any, logType: LogEntryLogType }> }
  & CompletedHarvestGroupFieldsFragment
);

export type HarvestGroupReportFieldsFragment = { __typename?: 'HarvestGroup', id: string, name: string, zoneIteration: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, colorHexCode?: Maybe<string> };

export type HarvestGroupWithLogEntriesQueryVariables = Exact<{
  id: Scalars['String'];
  logEntryOptions?: Maybe<LogsFilterInput>;
}>;


export type HarvestGroupWithLogEntriesQuery = { __typename?: 'Query', harvestGroup: (
    { __typename?: 'HarvestGroup' }
    & HarvestGroupWithLogEntriesFragment
  ) };

export type HarvestGroupWithProcessingAndFormFactorsQueryVariables = Exact<{
  id: Scalars['String'];
  filters?: Maybe<BudFormFactorFilterSetInput>;
}>;


export type HarvestGroupWithProcessingAndFormFactorsQuery = { __typename?: 'Query', harvestGroup: (
    { __typename?: 'HarvestGroup' }
    & HarvestGroupDetailFieldsFragment
    & HarvestBecameProcessingFieldsFragment
  ), budFormFactors?: Maybe<Array<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  )>> };

export type HarvestBecameProcessingFieldsFragment = { __typename?: 'HarvestGroup', id: string, becameProcessingGroup?: Maybe<{ __typename?: 'ProcessingGroup', id: string, name: string, processingGroupStrains: Array<(
      { __typename?: 'ProcessingGroupStrain' }
      & ProcessingStrainYieldFieldsFragment
    )> }> };

export type HarvestGroupDetailFieldsFragment = (
  { __typename?: 'HarvestGroup', id: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, archivedAt?: Maybe<any>, zoneIteration: string, template: (
    { __typename?: 'HarvestGroupTemplateVersion' }
    & HarvestGroupTemplateVersionFieldsFragment
  ), stages: Array<(
    { __typename?: 'Stage', zone: { __typename?: 'Zone', id: string, name: string }, strainsInStage: Array<{ __typename?: 'StrainInStage', id: any, numberOfPlants: number, strain: { __typename?: 'Strain', id: any, name: string } }>, template: { __typename?: 'StageTemplate', id: any } }
    & StageCalendarFieldsFragment
  )>, strains: Array<(
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  )> }
  & HarvestGroupCalendarFieldsFragment
);

export type HarvestGroupShortOverviewFieldsFragment = { __typename?: 'HarvestGroup', id: string, name: string, startDate?: Maybe<any>, harvestDate?: Maybe<any>, archivedAt?: Maybe<any>, zoneIteration: string, colorHexCode?: Maybe<string>, flowerZone: { __typename?: 'Zone', id: string, name: string }, currentStage: { __typename?: 'Stage', id: any, name: string } };

export type HarvestGroupOverviewFieldsFragment = (
  { __typename?: 'HarvestGroup', strains: Array<{ __typename?: 'Strain', id: any, name: string }>, flowerZone: { __typename?: 'Zone', id: string, name: string }, harvestCompletion?: Maybe<{ __typename?: 'HarvestCompletion', createdAt: any, cosignedAt?: Maybe<any>, createdBy: (
      { __typename?: 'UserProfile', id: string }
      & NameFragment
    ), cosignedBy?: Maybe<(
      { __typename?: 'UserProfile', id: string }
      & NameFragment
    )> }>, yields: Array<(
    { __typename?: 'HarvestStrainYield' }
    & YieldFieldsFragment
  )> }
  & HarvestGroupShortOverviewFieldsFragment
);

export type AddObservationMutationVariables = Exact<{
  input: LogEntryInput;
}>;


export type AddObservationMutation = { __typename?: 'Mutation', addObservationLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type AddIssueMutationVariables = Exact<{
  input: LogEntryInput;
}>;


export type AddIssueMutation = { __typename?: 'Mutation', addIssueLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type AddIrrigationActivityMutationVariables = Exact<{
  input: LogEntryInput;
}>;


export type AddIrrigationActivityMutation = { __typename?: 'Mutation', addIrrigationLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type AddFoliarActivityMutationVariables = Exact<{
  input: LogEntryInput;
}>;


export type AddFoliarActivityMutation = { __typename?: 'Mutation', addFoliarLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type AddPruningActivityMutationVariables = Exact<{
  input: LogEntryInput;
}>;


export type AddPruningActivityMutation = { __typename?: 'Mutation', addPruningLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type AddToppingActivityMutationVariables = Exact<{
  input: LogEntryInput;
}>;


export type AddToppingActivityMutation = { __typename?: 'Mutation', addToppingLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type AddGreenWasteActivityMutationVariables = Exact<{
  input: LogEntryInput;
}>;


export type AddGreenWasteActivityMutation = { __typename?: 'Mutation', addGreenWasteLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type DeleteLogEntryMutationVariables = Exact<{
  input: DeleteLogEntryInput;
}>;


export type DeleteLogEntryMutation = { __typename?: 'Mutation', deleteLogEntry?: Maybe<{ __typename?: 'DeleteLogEntryResult', success?: Maybe<boolean> }> };

export type ApproveLogEntryMutationVariables = Exact<{
  input: ApproveLogEntryInput;
}>;


export type ApproveLogEntryMutation = { __typename?: 'Mutation', approveLogEntry?: Maybe<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type LogEntryFieldsFragment = { __typename?: 'LogEntry', id: any, logType: LogEntryLogType, date: any, logEntryData?: Maybe<any>, isDraft: boolean, readyForApproval: boolean, approvedAt?: Maybe<any>, paperlyticsIssuesList?: Maybe<any>, paperlyticsBoundaries?: Maybe<any>, paperlyticsAccuracyScore?: Maybe<number>, performedBy?: Maybe<(
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  )>, stage?: Maybe<{ __typename?: 'Stage', id: any, name: string, startDate: any, calendarBranchId: string, harvestGroup: { __typename?: 'HarvestGroup', id: string, name: string }, zone: { __typename?: 'Zone', id: string, name: string } }>, approvedBy?: Maybe<(
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  )>, paperlyticsImage?: Maybe<{ __typename?: 'PaperlyticsImage', imageUrl?: Maybe<string> }> };

export type LogEntriesFragment = { __typename?: 'HarvestGroup', id: string, logEntries: Array<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  )> };

export type MaintenanceRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MaintenanceRequestsQuery = { __typename?: 'Query', maintenanceRequests?: Maybe<Array<(
    { __typename?: 'MaintenanceRequest' }
    & MaintenanceRequestFieldsFragment
  )>> };

export type CreateMaintenanceRequestMutationVariables = Exact<{
  input: CreateMaintenanceRequestInput;
}>;


export type CreateMaintenanceRequestMutation = { __typename?: 'Mutation', createMaintenanceRequest?: Maybe<(
    { __typename?: 'MaintenanceRequest' }
    & MaintenanceRequestFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ResolveMaintenanceRequestMutationVariables = Exact<{
  input: ResolveMaintenanceRequestInput;
}>;


export type ResolveMaintenanceRequestMutation = { __typename?: 'Mutation', resolveMaintenanceRequest?: Maybe<(
    { __typename?: 'MaintenanceRequest', id: any }
    & MaintenanceRequestResolutionFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type MaintenanceRequestFieldsFragment = (
  { __typename?: 'MaintenanceRequest', id: any, createdAt: any, firstObservedAt: any, dueDate: any, urgent: boolean, equipmentInoperable: boolean, lockOutTagOut: boolean, description: string, imageUrl?: Maybe<string>, requestedBy: (
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  ), location: { __typename?: 'Location', id: any, name: string }, tags?: Maybe<Array<Maybe<{ __typename?: 'MaintenanceRequestTag', name?: Maybe<string> }>>> }
  & MaintenanceRequestResolutionFieldsFragment
);

export type MaintenanceRequestResolutionFieldsFragment = { __typename?: 'MaintenanceRequest', resolution?: Maybe<{ __typename?: 'MaintenanceRequestResolution', createdAt: any, resolvedAt: any, notes?: Maybe<string>, resolvedBy: (
      { __typename?: 'UserProfile', id: string }
      & NameFragment
    ) }> };

export type NutrientsQueryVariables = Exact<{
  filters?: Maybe<NutrientFilterSetInput>;
}>;


export type NutrientsQuery = { __typename?: 'Query', nutrients?: Maybe<Array<(
    { __typename?: 'Nutrient' }
    & NutrientFieldsFragment
  )>> };

export type CreateNutrientMutationVariables = Exact<{
  input: CreateNutrientInput;
}>;


export type CreateNutrientMutation = { __typename?: 'Mutation', createNutrient?: Maybe<(
    { __typename?: 'Nutrient' }
    & NutrientFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateNutrientMutationVariables = Exact<{
  input: UpdateNutrientInput;
}>;


export type UpdateNutrientMutation = { __typename?: 'Mutation', updateNutrient?: Maybe<(
    { __typename?: 'Nutrient' }
    & NutrientFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ActivateNutrientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateNutrientMutation = { __typename?: 'Mutation', updateNutrient?: Maybe<(
    { __typename?: 'Nutrient' }
    & NutrientFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type DeactivateNutrientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateNutrientMutation = { __typename?: 'Mutation', updateNutrient?: Maybe<(
    { __typename?: 'Nutrient' }
    & NutrientFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type NutrientFieldsFragment = { __typename?: 'Nutrient', id: any, name: string, active: boolean, unitOfMeasure: NutrientUnitOfMeasure, priceInCentsPerUnit: any, boughtPrice?: Maybe<any>, boughtAmount?: Maybe<any>, boughtUnit?: Maybe<string> };

export type UploadPaperlyticsImageMutationVariables = Exact<{
  imageUrl: Scalars['String'];
  harvestGroupId: Scalars['String'];
}>;


export type UploadPaperlyticsImageMutation = { __typename?: 'Mutation', uploadPaperlyticsImage?: Maybe<(
    { __typename?: 'PaperlyticsImage' }
    & PaperlyticsImageFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type RetryPaperlyticsImageMutationVariables = Exact<{
  imageId: Scalars['String'];
}>;


export type RetryPaperlyticsImageMutation = { __typename?: 'Mutation', retryPaperlyticsImage?: Maybe<(
    { __typename?: 'PaperlyticsImage' }
    & PaperlyticsImageFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type PaperlyticsImagesQueryVariables = Exact<{
  filters?: Maybe<PaperlyticsFilterSetInput>;
}>;


export type PaperlyticsImagesQuery = { __typename?: 'Query', paperlyticsImages?: Maybe<Array<(
    { __typename?: 'PaperlyticsImage' }
    & PaperlyticsImageFieldsFragment
  )>> };

export type PaperlyticsImageFieldsFragment = { __typename?: 'PaperlyticsImage', id: any, imageUrl?: Maybe<string>, imageUpload?: Maybe<string>, startedAt?: Maybe<any>, completedAt?: Maybe<any>, success: boolean, targetType?: Maybe<string>, result?: Maybe<any>, accuracyScore?: Maybe<number>, issuesList?: Maybe<any>, harvestGroup?: Maybe<{ __typename?: 'HarvestGroup', id: string, name: string }>, uploadedBy?: Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string, email: string }> };

export type RecipesQueryVariables = Exact<{
  filters?: Maybe<RecipeFilterSetInput>;
}>;


export type RecipesQuery = { __typename?: 'Query', recipes?: Maybe<Array<(
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  )>> };

export type RecipeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RecipeQuery = { __typename?: 'Query', recipe: (
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  ) };

export type RecipesAndNutrientsQueryVariables = Exact<{
  filters?: Maybe<RecipeFilterSetInput>;
}>;


export type RecipesAndNutrientsQuery = { __typename?: 'Query', recipes?: Maybe<Array<(
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  )>>, nutrients?: Maybe<Array<(
    { __typename?: 'Nutrient' }
    & NutrientFieldsFragment
  )>> };

export type CreateRecipeMutationVariables = Exact<{
  input: CreateRecipeInput;
}>;


export type CreateRecipeMutation = { __typename?: 'Mutation', createRecipe?: Maybe<(
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateRecipeMutationVariables = Exact<{
  input: UpdateRecipeInput;
}>;


export type UpdateRecipeMutation = { __typename?: 'Mutation', updateRecipe?: Maybe<(
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ActivateRecipeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateRecipeMutation = { __typename?: 'Mutation', updateRecipe?: Maybe<(
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type DeactivateRecipeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateRecipeMutation = { __typename?: 'Mutation', updateRecipe?: Maybe<(
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type RecipeFieldsFragment = { __typename?: 'Recipe', id: any, name: string, active: boolean, applicationType: RecipeApplicationType, components: Array<{ __typename?: 'RecipeComponent', id: any, volume: any, nutrient: (
      { __typename?: 'Nutrient' }
      & NutrientFieldsFragment
    ) }> };

export type StageReportFieldsFragment = { __typename?: 'Stage', id: any, name: string, daysInStage: number, startDate: any, endDate: any, calendarBranchId: string };

export type StageCalendarFieldsFragment = { __typename?: 'Stage', id: any, name: string, startDate: any, calendarBranchId: string, harvestGroup: { __typename?: 'HarvestGroup', id: string, name: string, colorHexCode?: Maybe<string>, flowerZone: { __typename?: 'Zone', id: string, name: string } }, template: { __typename?: 'StageTemplate', createdStageName: string } };

export type StrainsQueryVariables = Exact<{
  filters?: Maybe<StrainFilterSetInput>;
}>;


export type StrainsQuery = { __typename?: 'Query', strains?: Maybe<Array<(
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  )>> };

export type CreateStrainMutationVariables = Exact<{
  input: CreateStrainInput;
}>;


export type CreateStrainMutation = { __typename?: 'Mutation', createStrain?: Maybe<(
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateStrainMutationVariables = Exact<{
  input: UpdateStrainInput;
}>;


export type UpdateStrainMutation = { __typename?: 'Mutation', updateStrain?: Maybe<(
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ActivateStrainMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateStrainMutation = { __typename?: 'Mutation', updateStrain?: Maybe<(
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type DeactivateStrainMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateStrainMutation = { __typename?: 'Mutation', updateStrain?: Maybe<(
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type StrainsAndRecipesAndNutrientsQueryVariables = Exact<{
  harvestGroupId: Scalars['String'];
  filters?: Maybe<RecipeFilterSetInput>;
}>;


export type StrainsAndRecipesAndNutrientsQuery = { __typename?: 'Query', harvestGroup: { __typename?: 'HarvestGroup', strains: Array<(
      { __typename?: 'Strain' }
      & StrainReportFieldsFragment
    )> }, recipes?: Maybe<Array<(
    { __typename?: 'Recipe' }
    & RecipeFieldsFragment
  )>>, nutrients?: Maybe<Array<(
    { __typename?: 'Nutrient' }
    & NutrientFieldsFragment
  )>> };

export type StrainFieldsFragment = { __typename?: 'Strain', id: any, name: string, active: boolean, subspecies: StrainSubspecies, abbreviation: string, documentationUrl?: Maybe<string>, createdBy: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } };

export type StrainReportFieldsFragment = { __typename?: 'Strain', id: any, name: string };

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type TaskFieldsFragment = { __typename?: 'Task', id: any, name: string, startDate?: Maybe<any>, endDate?: Maybe<any>, completedAt?: Maybe<any>, priority: TaskPriority, notes?: Maybe<string>, assignedTo?: Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string }>, createdBy: { __typename?: 'UserProfile', id: string, firstName: string, lastName: string } };

export type TaskCalendarFieldsFragment = { __typename?: 'Task', id: any, name: string, startDate?: Maybe<any>, endDate?: Maybe<any>, priority: TaskPriority, isComplete: boolean, notes?: Maybe<string>, assignedTo?: Maybe<(
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  )>, template?: Maybe<{ __typename?: 'TaskTemplate', id: any }>, harvestGroup: { __typename?: 'HarvestGroup', id: string, name: string, colorHexCode?: Maybe<string>, flowerZone: { __typename?: 'Zone', id: string, name: string } } };

export type AddHarvestGroupTemplateMutationVariables = Exact<{
  input: CreateHarvestGroupTemplateInput;
}>;


export type AddHarvestGroupTemplateMutation = { __typename?: 'Mutation', createHarvestGroupTemplate?: Maybe<(
    { __typename?: 'HarvestGroupTemplate' }
    & HarvestGroupTemplateFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateHarvestGroupTemplateMutationVariables = Exact<{
  input: UpdateHarvestGroupTemplateInput;
}>;


export type UpdateHarvestGroupTemplateMutation = { __typename?: 'Mutation', updateHarvestGroupTemplate?: Maybe<(
    { __typename?: 'HarvestGroupTemplate' }
    & HarvestGroupTemplateFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type HarvestGroupTemplateFieldsFragment = { __typename?: 'HarvestGroupTemplate', id: any, name: string, archivedAt?: Maybe<any>, activeTemplateVersion: (
    { __typename?: 'HarvestGroupTemplateVersion' }
    & HarvestGroupTemplateVersionFieldsFragment
  ), versions: Array<{ __typename?: 'HarvestGroupTemplateVersion', id: any, version: number }> };

export type HarvestGroupTemplateVersionFieldsFragment = { __typename?: 'HarvestGroupTemplateVersion', id: any, name: string, version: number, stageTemplates: Array<(
    { __typename?: 'StageTemplate' }
    & HarvestGroupStageTemplateFieldsFragment
  )> };

export type HarvestGroupStageTemplateFieldsFragment = { __typename?: 'StageTemplate', id: any, name: string, createdStageName: string, numberOfDays: number, maxNumberOfDays: number, minNumberOfDays: number, orderInProcess: number, environmentalTargetType: StageTemplateEnvironmentalTargetType, designatedZones: Array<(
    { __typename?: 'Zone' }
    & ZoneFieldsFragment
  )>, taskTemplates: Array<(
    { __typename?: 'TaskTemplate' }
    & HarvestGroupTaskTemplateFieldsFragment
  )> };

export type HarvestGroupTaskTemplateFieldsFragment = { __typename?: 'TaskTemplate', id: any, createdTaskName: string, stageDayStart: number, stageDayEnd: number };

export type HarvestGroupTemplatesQueryVariables = Exact<{
  filters?: Maybe<HarvestGroupTemplateFilterSetInput>;
}>;


export type HarvestGroupTemplatesQuery = { __typename?: 'Query', harvestGroupTemplates?: Maybe<Array<(
    { __typename?: 'HarvestGroupTemplate' }
    & HarvestGroupTemplateFieldsFragment
  )>> };

export type ArchiveHarvestGroupTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveHarvestGroupTemplateMutation = { __typename?: 'Mutation', updateHarvestGroupTemplateArchived?: Maybe<(
    { __typename?: 'HarvestGroupTemplate' }
    & HarvestGroupTemplateFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UnarchiveHarvestGroupTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnarchiveHarvestGroupTemplateMutation = { __typename?: 'Mutation', updateHarvestGroupTemplateArchived?: Maybe<(
    { __typename?: 'HarvestGroupTemplate' }
    & HarvestGroupTemplateFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateActiveHarvestGroupTemplateVersionMutationVariables = Exact<{
  input: UpdateActiveHarvestGroupTemplateVersionInput;
}>;


export type UpdateActiveHarvestGroupTemplateVersionMutation = { __typename?: 'Mutation', updateActiveHarvestGroupTemplateVersion?: Maybe<(
    { __typename?: 'HarvestGroupTemplate' }
    & HarvestGroupTemplateFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type YieldFieldsFragment = { __typename?: 'HarvestStrainYield', harvestGroupId: string, totalGrams: number, finalPlantCount: number, strainId: string, strain: (
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  ), budFormFactorYields: Array<(
    { __typename?: 'BudFormFactorYield' }
    & BudFormFactorYieldFragment
  )> };

export type YieldReportFieldsFragment = (
  { __typename?: 'HarvestStrainYield', strain: (
    { __typename?: 'Strain' }
    & StrainReportFieldsFragment
  ) }
  & YieldFieldsFragment
);

export type BudFormFactorYieldFragment = { __typename?: 'BudFormFactorYield', budFormFactorId: string, numberOfGrams: number, budFormFactor: (
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  ) };

export type ZonesWithHarvestGroupsQueryVariables = Exact<{
  zoneOptions?: Maybe<LogsFilterInput>;
  filters?: Maybe<ZoneFilterSetInput>;
}>;


export type ZonesWithHarvestGroupsQuery = { __typename?: 'Query', zones?: Maybe<Array<(
    { __typename?: 'Zone' }
    & ZoneWithHarvestGroupFieldsFragment
  )>> };

export type ZonesQueryVariables = Exact<{ [key: string]: never; }>;


export type ZonesQuery = { __typename?: 'Query', zones?: Maybe<Array<(
    { __typename?: 'Zone' }
    & ZoneFieldsFragment
  )>> };

export type AppIndexPageQueryVariables = Exact<{
  filters?: Maybe<ZoneFilterSetInput>;
}>;


export type AppIndexPageQuery = { __typename?: 'Query', zones?: Maybe<Array<(
    { __typename?: 'Zone' }
    & ZoneCardFieldsFragment
  )>> };

export type CreateZoneMutationVariables = Exact<{
  input: CreateZoneInput;
}>;


export type CreateZoneMutation = { __typename?: 'Mutation', createZone?: Maybe<(
    { __typename?: 'Zone' }
    & ZoneFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateZoneMutationVariables = Exact<{
  input: UpdateZoneInput;
}>;


export type UpdateZoneMutation = { __typename?: 'Mutation', updateZone?: Maybe<(
    { __typename?: 'Zone' }
    & ZoneFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ActivateZoneMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateZoneMutation = { __typename?: 'Mutation', updateZone?: Maybe<(
    { __typename?: 'Zone' }
    & ZoneFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type DeactivateZoneMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateZoneMutation = { __typename?: 'Mutation', updateZone?: Maybe<(
    { __typename?: 'Zone' }
    & ZoneFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ZoneNamesQueryVariables = Exact<{
  filters?: Maybe<ZoneFilterSetInput>;
}>;


export type ZoneNamesQuery = { __typename?: 'Query', zones?: Maybe<Array<(
    { __typename?: 'Zone' }
    & ZoneIdAndNameFragment
  )>> };

export type HarvestGroupZoneCardFieldsFragment = (
  { __typename?: 'HarvestGroup', id: string, logEntries: Array<(
    { __typename?: 'LogEntry' }
    & LogEntryFieldsFragment
  )> }
  & HarvestGroupCurrentStageFieldsFragment
);

export type ZoneWithHarvestGroupFieldsFragment = (
  { __typename?: 'Zone', currentHarvestGroups: Array<(
    { __typename?: 'HarvestGroup', logEntries: Array<(
      { __typename?: 'LogEntry' }
      & LogEntryFieldsFragment
    )> }
    & HarvestGroupOverviewFieldsFragment
    & HarvestGroupCurrentStageFieldsFragment
  )> }
  & ZoneFieldsFragment
);

export type ZoneIdAndNameFragment = { __typename?: 'Zone', id: string, name: string };

export type ZoneFieldsFragment = { __typename?: 'Zone', id: string, name: string, active: boolean, maxConcurrentHarvestGroups?: Maybe<number>, maxConcurrentPlants?: Maybe<number>, cultivationStyle?: Maybe<ZoneCultivationStyle>, cultivationStyleOther?: Maybe<string>, irrigationStyle?: Maybe<ZoneIrrigationStyle>, irrigationStyleOther?: Maybe<string>, irrigationFrequency?: Maybe<ZoneIrrigationFrequency>, irrigationFrequencyOther?: Maybe<string>, growingMedia?: Maybe<ZoneGrowingMedia>, growingMediaOther?: Maybe<string>, growMediaSize?: Maybe<number>, lengthFeet?: Maybe<number>, lengthInches?: Maybe<number>, heightFeet?: Maybe<number>, heightInches?: Maybe<number>, widthFeet?: Maybe<number>, widthInches?: Maybe<number>, acSizeByTon?: Maybe<number>, acNumUnits?: Maybe<number>, acBrand?: Maybe<string>, dehumidifierSizeByPintsPerDay?: Maybe<number>, dehumidifierNumUnits?: Maybe<number>, dehumidifierBrand?: Maybe<string>, lightingType?: Maybe<ZoneLightingType>, lightingTypeOther?: Maybe<string>, rows?: Maybe<number> };

export type ZoneCardFieldsFragment = (
  { __typename?: 'Zone', currentHarvestGroups: Array<(
    { __typename?: 'HarvestGroup' }
    & HarvestGroupOverviewFieldsFragment
    & HarvestGroupCurrentStageFieldsFragment
  )> }
  & ZoneFieldsFragment
);

export type ZonesOverviewQueryVariables = Exact<{
  filters?: Maybe<ZoneFilterSetInput>;
}>;


export type ZonesOverviewQuery = { __typename?: 'Query', zones?: Maybe<Array<(
    { __typename?: 'Zone' }
    & ZoneFieldsFragment
  )>> };

export type TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsQuery = { __typename?: 'Query', tags?: Maybe<Array<{ __typename?: 'Tag', id: any, name: string, colorHexCode?: Maybe<string> }>> };

export type ExpensesQueryVariables = Exact<{
  filters?: Maybe<ExpenseFilterSetInput>;
}>;


export type ExpensesQuery = { __typename?: 'Query', expenses?: Maybe<Array<(
    { __typename?: 'Expense' }
    & ExpenseFieldsFragment
  )>> };

export type ExpenseCategoriesQueryVariables = Exact<{
  filters?: Maybe<ExpenseCategoryFilterSetInput>;
}>;


export type ExpenseCategoriesQuery = { __typename?: 'Query', expenseCategories?: Maybe<Array<(
    { __typename?: 'ExpenseCategory' }
    & ExpenseCategoryFieldsFragment
  )>> };

export type ExpenseReportAllocationBreakdownQueryVariables = Exact<{
  filters?: Maybe<ExpenseReportAllocationFiltersInput>;
}>;


export type ExpenseReportAllocationBreakdownQuery = { __typename?: 'Query', expenseReportAllocationBreakdown: (
    { __typename?: 'ExpenseReportAllocationBreakdown' }
    & ExpenseReportAllocationBreakdownFragment
  ) };

export type ExpenseReportByCategoryQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;


export type ExpenseReportByCategoryQuery = { __typename?: 'Query', expenseReportByCategory: { __typename?: 'ExpenseReportByCategory', period: string, entries?: Maybe<Array<(
      { __typename?: 'ExpenseReportByCategoryEntry' }
      & ExpenseReportByCategoryEntryFragment
    )>> } };

export type ExpenseReportMonthlySummaryQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;


export type ExpenseReportMonthlySummaryQuery = { __typename?: 'Query', expenseReportMonthlySummary: { __typename?: 'ExpenseReportMonthlySummary', period: string, months?: Maybe<Array<{ __typename?: 'ExpenseReportMonthlySummaryMonth', month: string, cultivationCostInCents: number, processingCostInCents: number }>> } };

export type CreateExpenseMutationVariables = Exact<{
  input: CreateExpenseInput;
}>;


export type CreateExpenseMutation = { __typename?: 'Mutation', createExpense?: Maybe<(
    { __typename?: 'Expense' }
    & ExpenseFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UpdateExpenseMutationVariables = Exact<{
  input: UpdateExpenseInput;
}>;


export type UpdateExpenseMutation = { __typename?: 'Mutation', updateExpense?: Maybe<(
    { __typename?: 'Expense' }
    & ExpenseFieldsFragment
  ) | { __typename?: 'Error' }> };

export type ExpenseFieldsFragment = { __typename?: 'Expense', id: any, date: any, type: ExpenseType, costInCents: number, hourlyCostInCents?: Maybe<number>, hoursWorked?: Maybe<any>, allocationRatio: any, notes?: Maybe<string>, category: (
    { __typename?: 'ExpenseCategory' }
    & ExpenseCategoryFieldsFragment
  ), recurringExpense?: Maybe<(
    { __typename?: 'RecurringExpense' }
    & RecurringExpenseFieldsFragment
  )> };

export type RecurringExpenseFieldsFragment = { __typename?: 'RecurringExpense', id: any, startDate: any, endDate?: Maybe<any>, costInCents: number, allocationRatio: any, notes?: Maybe<string>, category: (
    { __typename?: 'ExpenseCategory' }
    & ExpenseCategoryFieldsFragment
  ) };

export type ExpenseCategoryFieldsFragment = { __typename?: 'ExpenseCategory', id: any, name: string };

export type ExpenseReportByCategoryEntryFragment = { __typename?: 'ExpenseReportByCategoryEntry', totalCostInCents: number, category: (
    { __typename?: 'ExpenseCategory' }
    & ExpenseCategoryFieldsFragment
  ) };

export type ExpenseReportAllocationBreakdownEntryFragment = { __typename?: 'ExpenseReportAllocationBreakdownEntry', id: string, cultivationComplete?: Maybe<boolean>, cultivationDays?: Maybe<number>, cultivationNumPlants?: Maybe<number>, cultivationStartDate?: Maybe<any>, cultivationEndDate?: Maybe<any>, cultivationCostInCents?: Maybe<number>, processingHours?: Maybe<number>, processingCostInCents?: Maybe<number>, processingStartDate?: Maybe<any>, processingEndDate?: Maybe<any>, strain: { __typename?: 'Strain', id: any, name: string }, harvestGroup?: Maybe<{ __typename?: 'HarvestGroup', id: string, name: string }>, harvestProjection?: Maybe<{ __typename?: 'HarvestProjection', id: any, strainId: string, plantCount: number, harvestGroupId: string }>, processingGroup?: Maybe<{ __typename?: 'ProcessingGroup', id: string, name: string }>, strainMonthlySummaries?: Maybe<Array<(
    { __typename?: 'ExpenseReportAllocationBreakdownStrainMonthlySummary' }
    & ExpenseReportAllocationBreakdownStrainMonthlySummaryFragment
  )>> };

export type ExpenseReportAllocationBreakdownStrainMonthlySummaryFragment = { __typename?: 'ExpenseReportAllocationBreakdownStrainMonthlySummary', id: string, month?: Maybe<any>, cultivationCostInCents?: Maybe<number>, processingCostInCents?: Maybe<number>, plantDays?: Maybe<number>, processingHours?: Maybe<number> };

export type ExpenseReportAllocationBreakdownMonthlySummaryFragment = { __typename?: 'ExpenseReportAllocationBreakdownMonthlySummary', id: string, month?: Maybe<any>, cultivationCostInCents?: Maybe<number>, processingCostInCents?: Maybe<number>, totalPlantDays?: Maybe<number>, totalProcessingHours?: Maybe<number> };

export type ExpenseReportAllocationBreakdownFragment = { __typename?: 'ExpenseReportAllocationBreakdown', entries?: Maybe<Array<(
    { __typename?: 'ExpenseReportAllocationBreakdownEntry' }
    & ExpenseReportAllocationBreakdownEntryFragment
  )>>, monthlySummaries?: Maybe<Array<(
    { __typename?: 'ExpenseReportAllocationBreakdownMonthlySummary' }
    & ExpenseReportAllocationBreakdownMonthlySummaryFragment
  )>> };

export type ProcessingJobsQueryVariables = Exact<{
  filters?: Maybe<ProcessingJobFilterSetInput>;
}>;


export type ProcessingJobsQuery = { __typename?: 'Query', processingJobs?: Maybe<Array<(
    { __typename?: 'ProcessingJob' }
    & ProcessingJobOverviewWithGroupFieldsFragment
  )>> };

export type ProcessingGroupJobQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProcessingGroupJobQuery = { __typename?: 'Query', processingJob?: Maybe<(
    { __typename?: 'ProcessingJob' }
    & ProcessingJobOverviewWithLogsFieldsFragment
  )> };

export type CreateProcessingJobMutationVariables = Exact<{
  input: CreateProcessingJobInput;
}>;


export type CreateProcessingJobMutation = { __typename?: 'Mutation', createProcessingJob?: Maybe<(
    { __typename?: 'ProcessingJob' }
    & ProcessingJobOverviewFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type AssignProcessingJobToUserMutationVariables = Exact<{
  input: AssignProcessingJobToUserInput;
}>;


export type AssignProcessingJobToUserMutation = { __typename?: 'Mutation', assignProcessingJobToUser?: Maybe<(
    { __typename?: 'ProcessingJob' }
    & ProcessingJobOverviewFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateProcessingJobMutationVariables = Exact<{
  input: UpdateProcessingJobInput;
}>;


export type UpdateProcessingJobMutation = { __typename?: 'Mutation', updateProcessingJob?: Maybe<(
    { __typename?: 'ProcessingJob' }
    & ProcessingJobOverviewFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type CreateProcessingJobLogMutationVariables = Exact<{
  input: CreateProcessingJobLogInput;
}>;


export type CreateProcessingJobLogMutation = { __typename?: 'Mutation', createProcessingJobLog?: Maybe<(
    { __typename?: 'ProcessingJobLog', job: (
      { __typename?: 'ProcessingJob' }
      & ProcessingJobOverviewFieldsFragment
    ) }
    & ProcessingJobLogFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateProcessingJobLogMutationVariables = Exact<{
  input: UpdateProcessingJobLogInput;
}>;


export type UpdateProcessingJobLogMutation = { __typename?: 'Mutation', updateProcessingJobLog?: Maybe<(
    { __typename?: 'ProcessingJobLog', job: (
      { __typename?: 'ProcessingJob' }
      & ProcessingJobOverviewFieldsFragment
    ) }
    & ProcessingJobLogFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ProcessingJobOverviewWithLogsFieldsFragment = (
  { __typename?: 'ProcessingJob', processingJobLogs: Array<(
    { __typename?: 'ProcessingJobLog' }
    & ProcessingJobLogFieldsFragment
  )> }
  & ProcessingJobOverviewFieldsFragment
);

export type ProcessingJobOverviewFieldsFragment = { __typename?: 'ProcessingJob', id: any, jobStatus: ProcessingJobJobStatus, inputGrams: any, totalOutputGrams?: Maybe<any>, createdAt: any, startedAt?: Maybe<any>, totalWorkedMinutes: any, assignee?: Maybe<(
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  )>, createdBy: (
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  ), inputType: (
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  ), strainStage: { __typename?: 'ProcessingGroupStrainStage', status: ProcessingGroupStrainStageStatus, id: any, stage: (
      { __typename?: 'ProcessingGroupStage' }
      & ProcessingGroupStageWithTemplateFieldsFragment
    ), processingGroupStrain: { __typename?: 'ProcessingGroupStrain', id: any, strain: { __typename?: 'Strain', id: any, name: string } } } };

export type ProcessingJobOverviewWithGroupFieldsFragment = (
  { __typename?: 'ProcessingJob' }
  & ProcessingJobOverviewFieldsFragment
);

export type ProcessingJobLogFieldsFragment = { __typename?: 'ProcessingJobLog', id: any, logType: ProcessingJobLogLogType, startedAt: any, endedAt: any, createdAt: any, breakMinutes: number, workedMinutes: any, outputGrams?: Maybe<any>, leftoverInputGrams?: Maybe<number>, createdBy?: Maybe<(
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  )>, performer?: Maybe<(
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  )> };

export type ProcessingGroupsQueryVariables = Exact<{
  filters?: Maybe<ProcessingGroupFilterSetInput>;
}>;


export type ProcessingGroupsQuery = { __typename?: 'Query', processingGroups?: Maybe<Array<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupFieldsFragment
  )>> };

export type CurrentAndUpcomingProcessingGroupsQueryVariables = Exact<{
  unarchived?: Maybe<Scalars['String']>;
}>;


export type CurrentAndUpcomingProcessingGroupsQuery = { __typename?: 'Query', processingGroups?: Maybe<Array<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupFieldsFragment
  )>> };

export type CompletedProcessingGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedProcessingGroupsQuery = { __typename?: 'Query', processingGroups?: Maybe<Array<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupOverviewFieldsFragment
  )>> };

export type ProcessingGroupQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProcessingGroupQuery = { __typename?: 'Query', processingGroup?: Maybe<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupOverviewFieldsFragment
  )> };

export type ProcessingGroupWithJobsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProcessingGroupWithJobsQuery = { __typename?: 'Query', processingGroup?: Maybe<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupOverviewFieldsFragment
    & ProcessingGroupFieldsWithStageJobsFragment
  )> };

export type ProcessingGroupWithStrainsAndStagesQueryVariables = Exact<{
  id: Scalars['String'];
  strainId?: Maybe<Scalars['String']>;
}>;


export type ProcessingGroupWithStrainsAndStagesQuery = { __typename?: 'Query', processingGroup?: Maybe<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupWithStrainsAndStagesFieldsFragment
  )> };

export type ProcessingGroupWithStrainsAndStagesAndJobsQueryVariables = Exact<{
  id: Scalars['String'];
  strainId?: Maybe<Scalars['String']>;
}>;


export type ProcessingGroupWithStrainsAndStagesAndJobsQuery = { __typename?: 'Query', processingGroup?: Maybe<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupWithStrainsAndStagesFieldsAndJobsFragment
  )> };

export type ProcessingGroupStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProcessingGroupStatusQuery = { __typename?: 'Query', processingGroup?: Maybe<{ __typename?: 'ProcessingGroup', id: string, name: string, status: ProcessingGroupStatus, processingGroupStrains: Array<{ __typename?: 'ProcessingGroupStrain', id: any, status: ProcessingGroupStrainStatus }> }> };

export type AllProcessingGroupsWithStrainsAndStagesQueryVariables = Exact<{
  strainId?: Maybe<Scalars['String']>;
}>;


export type AllProcessingGroupsWithStrainsAndStagesQuery = { __typename?: 'Query', processingGroups?: Maybe<Array<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupShortOverviewFragment
    & ProcessingGroupWithStrainsAndStagesFieldsFragment
  )>> };

export type AllProcessingGroupsShortOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type AllProcessingGroupsShortOverviewQuery = { __typename?: 'Query', processingGroups?: Maybe<Array<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupShortOverviewFragment
  )>> };

export type CreateProcessingGroupMutationVariables = Exact<{
  input: CreateProcessingGroupInput;
}>;


export type CreateProcessingGroupMutation = { __typename?: 'Mutation', createProcessingGroup?: Maybe<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type CreateProcessingGroupStrainInventoryAdjustmentMutationVariables = Exact<{
  input: CreateProcessingGroupStrainInventoryAdjustmentInput;
}>;


export type CreateProcessingGroupStrainInventoryAdjustmentMutation = { __typename?: 'Mutation', createProcessingGroupStrainInventoryAdjustment?: Maybe<(
    { __typename?: 'ProcessingGroupStrain' }
    & ProcessingGroupStrainFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ArchiveProcessingGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveProcessingGroupMutation = { __typename?: 'Mutation', updateProcessingGroupArchived?: Maybe<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UnarchiveProcessingGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnarchiveProcessingGroupMutation = { __typename?: 'Mutation', updateProcessingGroupArchived?: Maybe<(
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupFieldsFragment
  ) | { __typename?: 'Error' }> };

export type MoveUpProcessingGroupStrainMutationVariables = Exact<{
  input: MoveUpProcessingGroupStrainInput;
}>;


export type MoveUpProcessingGroupStrainMutation = { __typename?: 'Mutation', moveUpProcessingGroupStrain?: Maybe<(
    { __typename?: 'ProcessingGroupStrain' }
    & ProcessingGroupStrainFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type FinishProcessingGroupStrainMutationVariables = Exact<{
  input: FinishProcessingGroupStrainInput;
}>;


export type FinishProcessingGroupStrainMutation = { __typename?: 'Mutation', finishProcessingGroupStrain?: Maybe<(
    { __typename?: 'ProcessingGroupStrain' }
    & ProcessingGroupStrainFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type ProcessingGroupFieldsFragment = { __typename?: 'ProcessingGroup', id: string, name: string, status: ProcessingGroupStatus, archivedAt?: Maybe<any>, colorHexCode?: Maybe<string>, startDate?: Maybe<any>, createdBy: (
    { __typename?: 'UserProfile' }
    & NameFragment
  ), latestStage?: Maybe<(
    { __typename?: 'ProcessingGroupStage' }
    & ProcessingGroupStageFieldsFragment
  )>, processingGroupStrains: Array<{ __typename?: 'ProcessingGroupStrain', id: any }> };

export type ProcessingGroupOverviewFieldsFragment = (
  { __typename?: 'ProcessingGroup', status: ProcessingGroupStatus, processingGroupStages: Array<(
    { __typename?: 'ProcessingGroupStage' }
    & ProcessingGroupStageWithSummaryFieldsFragment
  )>, processingGroupStrains: Array<(
    { __typename?: 'ProcessingGroupStrain' }
    & ProcessingGroupStrainFieldsFragment
  )> }
  & ProcessingGroupFieldsFragment
);

export type ProcessingGroupNameFragment = { __typename?: 'ProcessingGroup', id: string, name: string };

export type ProcessingGroupStageFieldsFragment = { __typename?: 'ProcessingGroupStage', id: any, name: string, status: ProcessingGroupStageStatus, startDate: any, endDate?: Maybe<any>, schedulingScope: ProcessingGroupStageSchedulingScope, totalInputGrams?: Maybe<any>, totalOutputGrams?: Maybe<any>, totalPendingGrams?: Maybe<any>, totalPartialGrams?: Maybe<any>, processingGroup: (
    { __typename?: 'ProcessingGroup' }
    & ProcessingGroupNameFragment
  ), summary: { __typename?: 'ProcessingGroupStrainStageSummary', progressPercent: number, weightCompletedGrams: number, weightTotalGrams: number, numDays?: Maybe<number>, daysElapsed: number, remainingInventoryGrams: number }, processingStageTemplate: { __typename?: 'ProcessingStageTemplate', id: any, name: string, inputTypes: Array<{ __typename?: 'BudFormFactor', id: any, name: string }> } };

export type ProcessingGroupStageWithTemplateFieldsFragment = (
  { __typename?: 'ProcessingGroupStage', processingStageTemplate: (
    { __typename?: 'ProcessingStageTemplate' }
    & ProcessingStageTemplateFieldsFragment
  ) }
  & ProcessingGroupStageFieldsFragment
);

export type ProcessingGroupStrainFieldsFragment = { __typename?: 'ProcessingGroupStrain', id: any, status: ProcessingGroupStrainStatus, orderInProcess?: Maybe<number>, inventoryGrams?: Maybe<any>, totalInputGrams?: Maybe<any>, totalPendingGrams?: Maybe<any>, totalPartialGrams?: Maybe<any>, totalOutputGrams?: Maybe<any>, totalAdjustmentGrams?: Maybe<any>, strain: (
    { __typename?: 'Strain' }
    & StrainFieldsFragment
  ), inventoryAdjustments: Array<(
    { __typename?: 'ProcessingGroupStrainInventoryAdjustment' }
    & AdjustmentFieldsFragment
  )> };

export type AdjustmentFieldsFragment = { __typename?: 'ProcessingGroupStrainInventoryAdjustment', id: any, createdAt: any, adjustmentGrams?: Maybe<any>, description: string, createdBy: (
    { __typename?: 'UserProfile', id: string }
    & NameFragment
  ) };

export type ProcessingGroupWithStrainsAndStagesFieldsFragment = (
  { __typename?: 'ProcessingGroup', inUnfinishedJobs?: Maybe<any>, processingGroupStages: Array<(
    { __typename?: 'ProcessingGroupStage' }
    & ProcessingGroupStageWithSummaryFieldsFragment
  )>, processingGroupStrains: Array<(
    { __typename?: 'ProcessingGroupStrain' }
    & ProcessingGroupStrainFieldsFragment
  )>, processingGroupStrainStages: Array<(
    { __typename?: 'ProcessingGroupStrainStage', id: any, stage: (
      { __typename?: 'ProcessingGroupStage' }
      & ProcessingGroupStageWithSummaryFieldsFragment
    ), processingGroupStrain: { __typename?: 'ProcessingGroupStrain', id: any, strain: (
        { __typename?: 'Strain' }
        & StrainFieldsFragment
      ) } }
    & ProcessingGroupStrainStageOverviewFieldsFragment
  )> }
  & ProcessingGroupShortOverviewFragment
);

export type ProcessingGroupWithStrainsAndStagesFieldsAndJobsFragment = (
  { __typename?: 'ProcessingGroup', processingGroupStages: Array<{ __typename?: 'ProcessingGroupStage', jobs?: Maybe<Array<(
      { __typename?: 'ProcessingJob' }
      & ProcessingJobOverviewFieldsFragment
    )>> }> }
  & ProcessingGroupWithStrainsAndStagesFieldsFragment
);

export type ProcessingGroupStageWithSummaryFieldsFragment = (
  { __typename?: 'ProcessingGroupStage', summary: { __typename?: 'ProcessingGroupStrainStageSummary', progressPercent: number, daysElapsed: number, numDays?: Maybe<number>, weightCompletedGrams: number, weightTotalGrams: number, remainingInventoryGrams: number } }
  & ProcessingGroupStageWithTemplateFieldsFragment
);

export type ProcessingGroupStrainStageSummaryFieldsFragment = { __typename?: 'ProcessingGroupStrainStage', id: any, summary: { __typename?: 'ProcessingGroupStrainStageSummary', progressPercent: number, daysElapsed: number, numDays?: Maybe<number>, weightCompletedGrams: number, weightTotalGrams: number, remainingInventoryGrams: number } };

export type ProcessingGroupStrainStageOverviewFieldsFragment = (
  { __typename?: 'ProcessingGroupStrainStage', id: any, status: ProcessingGroupStrainStageStatus, startDate?: Maybe<any>, endDate?: Maybe<any>, totalInputGrams?: Maybe<any>, totalPendingGrams?: Maybe<any>, totalPartialGrams?: Maybe<any>, totalOutputGrams?: Maybe<any>, processingGroupStrain: { __typename?: 'ProcessingGroupStrain', inventoryAdjustments: Array<(
      { __typename?: 'ProcessingGroupStrainInventoryAdjustment' }
      & AdjustmentFieldsFragment
    )>, strain: (
      { __typename?: 'Strain' }
      & StrainFieldsFragment
    ) }, stage: (
    { __typename?: 'ProcessingGroupStage', processingGroup: { __typename?: 'ProcessingGroup', id: string } }
    & ProcessingGroupStageWithSummaryFieldsFragment
  ) }
  & ProcessingGroupStrainStageSummaryFieldsFragment
);

export type ProcessingGroupShortOverviewFragment = { __typename?: 'ProcessingGroup', id: string, name: string, status: ProcessingGroupStatus, colorHexCode?: Maybe<string> };

export type ProcessingStrainYieldFieldsFragment = { __typename?: 'ProcessingGroupStrain', id: any, status: ProcessingGroupStrainStatus, totalOutputGrams?: Maybe<any>, totalInputGrams?: Maybe<any>, inventoryGrams?: Maybe<any>, strain: { __typename?: 'Strain', id: any } };

export type ProcessingGroupFieldsWithStageJobsFragment = (
  { __typename?: 'ProcessingGroup', id: string, stages?: Maybe<Array<{ __typename?: 'ProcessingGroupStage', processingStageTemplate: { __typename?: 'ProcessingStageTemplate', trackPerformance: boolean, outputTypes: Array<{ __typename?: 'BudFormFactor', id: any, name: string }> }, jobs?: Maybe<Array<{ __typename?: 'ProcessingJob', jobStatus: ProcessingJobJobStatus, totalOutputGrams?: Maybe<any>, totalWorkedMinutes: any, inputType: (
        { __typename?: 'BudFormFactor' }
        & BudFormFactorFieldsFragment
      ), assignee?: Maybe<(
        { __typename?: 'UserProfile', id: string }
        & NameFragment
      )>, strainStage: { __typename?: 'ProcessingGroupStrainStage', processingGroupStrain: { __typename?: 'ProcessingGroupStrain', id: any, strain: { __typename?: 'Strain', id: any, name: string } } } }>> }>> }
  & ProcessingGroupOverviewFieldsFragment
);

export type ProcessingGroupTemplatesQueryVariables = Exact<{
  filters?: Maybe<ProcessingGroupTemplateFilterSetInput>;
}>;


export type ProcessingGroupTemplatesQuery = { __typename?: 'Query', processingGroupTemplates?: Maybe<Array<(
    { __typename?: 'ProcessingGroupTemplate' }
    & ProcessingGroupTemplateFieldsFragment
  )>> };

export type ProcessingGroupTemplateQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProcessingGroupTemplateQuery = { __typename?: 'Query', processingGroupTemplate?: Maybe<(
    { __typename?: 'ProcessingGroupTemplate' }
    & ProcessingGroupTemplateFieldsFragment
  )> };

export type ProcessingGroupTemplateFieldsFragment = { __typename?: 'ProcessingGroupTemplate', id: any, name: string, archivedAt?: Maybe<any>, activeTemplateVersion?: Maybe<(
    { __typename?: 'ProcessingGroupTemplateVersion' }
    & ProcessingGroupTemplateVersionFieldsFragment
  )>, processingGroupTemplateVersions: Array<(
    { __typename?: 'ProcessingGroupTemplateVersion' }
    & ProcessingGroupTemplateVersionFieldsFragment
  )> };

export type ProcessingGroupTemplateVersionFieldsFragment = { __typename?: 'ProcessingGroupTemplateVersion', id: any, name: string, version: number, processingStageTemplates: Array<(
    { __typename?: 'ProcessingStageTemplate' }
    & ProcessingStageTemplateFieldsFragment
  )> };

export type ProcessingStageTemplateFieldsFragment = { __typename?: 'ProcessingStageTemplate', id: any, name: string, numberOfDays: number, trackPerformance: boolean, orderInProcess: number, progressScope: ProcessingStageTemplateProgressScope, schedulingScope: ProcessingStageTemplateSchedulingScope, inputTypes: Array<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  )>, outputTypes: Array<(
    { __typename?: 'BudFormFactor' }
    & BudFormFactorFieldsFragment
  )> };

export type AddProcessingGroupTemplateMutationVariables = Exact<{
  input: CreateProcessingGroupTemplateInput;
}>;


export type AddProcessingGroupTemplateMutation = { __typename?: 'Mutation', createProcessingGroupTemplate?: Maybe<(
    { __typename?: 'ProcessingGroupTemplate' }
    & ProcessingGroupTemplateFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UpdateActiveProcessingGroupTemplateVersionMutationVariables = Exact<{
  input: UpdateActiveProcessingGroupTemplateVersionInput;
}>;


export type UpdateActiveProcessingGroupTemplateVersionMutation = { __typename?: 'Mutation', updateActiveProcessingGroupTemplateVersion?: Maybe<(
    { __typename?: 'ProcessingGroupTemplate' }
    & ProcessingGroupTemplateFieldsFragment
  ) | { __typename?: 'Error' }> };

export type ArchiveProcessingGroupTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveProcessingGroupTemplateMutation = { __typename?: 'Mutation', updateProcessingGroupTemplateArchived?: Maybe<(
    { __typename?: 'ProcessingGroupTemplate' }
    & ProcessingGroupTemplateFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UnarchiveProcessingGroupTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnarchiveProcessingGroupTemplateMutation = { __typename?: 'Mutation', updateProcessingGroupTemplateArchived?: Maybe<(
    { __typename?: 'ProcessingGroupTemplate' }
    & ProcessingGroupTemplateFieldsFragment
  ) | { __typename?: 'Error' }> };

export type UserProfileFragment = (
  { __typename?: 'UserProfile', organization?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )> }
  & UserAttributesFragment
);

export type NameFragment = { __typename?: 'UserProfile', firstName: string, lastName: string };

export type UserAttributesFragment = { __typename?: 'UserProfile', id: string, firstName: string, lastName: string, email: string, organizationId?: Maybe<string>, userPermissions?: Maybe<Array<Maybe<string>>>, isNoLogin: boolean, isStaff: boolean, isSuperuser: boolean, isActive: boolean, role?: Maybe<(
    { __typename?: 'Role' }
    & RoleFieldsFragment
  )> };

export type OrganizationFieldsFragment = { __typename?: 'Organization', id: string, name: string, timezone: string, moduleEnabledCultivation: boolean, moduleEnabledProcessing: boolean, moduleEnabledFinance: boolean, optionConvertGramsAbove: number, normalizeWindowMinutes: number, alertConsecutiveThreshold: number, environmentalTargetSets: Array<(
    { __typename?: 'EnvironmentalTargetSet' }
    & EnvironmentalTargetSetFieldsFragment
  )> };

export type EnvironmentalTargetSetFieldsFragment = { __typename?: 'EnvironmentalTargetSet', id: any, displayName?: Maybe<string>, dayStartTime: any, nightStartTime: any, environmentalTargetType: EnvironmentalTargetSetEnvironmentalTargetType, daysInStageStart?: Maybe<number>, daysInStageEnd?: Maybe<number>, dayTempLow: number, dayTempHigh: number, nightTempLow: number, nightTempHigh: number, dayVpdLow: number, dayVpdHigh: number, nightVpdLow: number, nightVpdHigh: number, dayHumidityLow: number, dayHumidityHigh: number, nightHumidityLow: number, nightHumidityHigh: number, dayCo2Low: number, dayCo2High: number, nightCo2Low: number, nightCo2High: number, organization: { __typename?: 'Organization', id: string }, zone?: Maybe<{ __typename?: 'Zone', id: string, name: string }> };

export type RoleFieldsFragment = { __typename?: 'Role', id: any, name: string, permissions?: Maybe<Array<Maybe<string>>> };

export type CreateToppingStyleMutationVariables = Exact<{
  input: CreateToppingStyleInput;
}>;


export type CreateToppingStyleMutation = { __typename?: 'Mutation', createToppingStyle?: Maybe<{ __typename?: 'ToppingStyle', id: any, name: string } | { __typename?: 'Error', message: string }> };

export type DeleteToppingStyleMutationVariables = Exact<{
  input: DeleteToppingStyleInput;
}>;


export type DeleteToppingStyleMutation = { __typename?: 'Mutation', deleteToppingStyle?: Maybe<{ __typename?: 'DeleteToppingStyleResult', success?: Maybe<boolean> }> };

export type UpdateUserActiveMutationVariables = Exact<{
  input: UpdateUserActiveInput;
}>;


export type UpdateUserActiveMutation = { __typename?: 'Mutation', updateUserActive?: Maybe<(
    { __typename?: 'UserProfile' }
    & UserProfileFragment
  ) | { __typename?: 'Error', message: string }> };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: Maybe<(
    { __typename?: 'UserProfile' }
    & UserProfileFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: Maybe<(
    { __typename?: 'UserProfile' }
    & UserProfileFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole?: Maybe<(
    { __typename?: 'Role' }
    & RoleFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type TokenAuthMutationVariables = Exact<{
  username_Iexact: Scalars['String'];
  password: Scalars['String'];
}>;


export type TokenAuthMutation = { __typename?: 'Mutation', tokenAuth?: Maybe<{ __typename: 'TokenAuthSuccess', token?: Maybe<string>, refreshToken?: Maybe<string> } | { __typename: 'Error', message: string }> };

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetEmailMutation = { __typename?: 'Mutation', sendPasswordResetEmail?: Maybe<{ __typename: 'SendPasswordResetEmailSuccess', success?: Maybe<boolean> } | { __typename: 'Error', message: string }> };

export type SendAccountInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendAccountInvitationMutation = { __typename?: 'Mutation', sendAccountInvitation?: Maybe<{ __typename?: 'SendAccountInvitation', user?: Maybe<(
      { __typename?: 'UserProfile' }
      & UserProfileFragment
    )> }> };

export type PasswordResetMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
}>;


export type PasswordResetMutation = { __typename?: 'Mutation', passwordReset?: Maybe<{ __typename: 'PasswordResetSuccess', success?: Maybe<boolean> } | { __typename: 'Error', message: string }> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: Maybe<{ __typename: 'LogoutSuccess', success?: Maybe<boolean> } | { __typename: 'Error', message: string }> };

export type UpdateUserProfileMutationVariables = Exact<{
  input?: Maybe<UpdateUserProfileInput>;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile?: Maybe<{ __typename: 'UpdateUserProfileSuccess', user?: Maybe<(
      { __typename?: 'UserProfile' }
      & UserProfileFragment
    )> } | { __typename: 'Error', message: string }> };

export type CreateOrganizationAndFirstUserMutationVariables = Exact<{
  input: CreateOrganizationAndFirstUserInput;
}>;


export type CreateOrganizationAndFirstUserMutation = { __typename?: 'Mutation', createOrganizationAndFirstUser?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateOrganizationSettingsMutationVariables = Exact<{
  input?: Maybe<UpdateOrganizationSettingsInput>;
}>;


export type UpdateOrganizationSettingsMutation = { __typename?: 'Mutation', updateOrganizationSettings?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type UpdateEnvironmentalTargetSetMutationVariables = Exact<{
  input: UpdateEnvironmentalTargetSetInput;
}>;


export type UpdateEnvironmentalTargetSetMutation = { __typename?: 'Mutation', updateEnvironmentalTargetSet?: Maybe<(
    { __typename?: 'EnvironmentalTargetSet' }
    & EnvironmentalTargetSetFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type CreateEnvironmentalTargetSetMutationVariables = Exact<{
  input: CreateEnvironmentalTargetSetInput;
}>;


export type CreateEnvironmentalTargetSetMutation = { __typename?: 'Mutation', createEnvironmentalTargetSet?: Maybe<(
    { __typename?: 'EnvironmentalTargetSet' }
    & EnvironmentalTargetSetFieldsFragment
  ) | { __typename?: 'Error', message: string }> };

export type GetSignedUploadUrlMutationVariables = Exact<{
  input: GetSignedUploadUrlInput;
}>;


export type GetSignedUploadUrlMutation = { __typename?: 'Mutation', getSignedUploadUrl?: Maybe<{ __typename?: 'GetSignedUploadURLResult', signedUploadUrl?: Maybe<string>, success: boolean }> };

export type MyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProfileQuery = { __typename?: 'Query', myProfile?: Maybe<(
    { __typename?: 'UserProfile' }
    & UserProfileFragment
  )> };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users?: Maybe<Array<Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string }>>> };

export type ActiveUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveUsersQuery = { __typename?: 'Query', activeUsers?: Maybe<Array<Maybe<{ __typename?: 'UserProfile', id: string, firstName: string, lastName: string }>>> };

export type UserManagementRowsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserManagementRowsQuery = { __typename?: 'Query', users?: Maybe<Array<Maybe<(
    { __typename?: 'UserProfile' }
    & UserAttributesFragment
  )>>> };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles?: Maybe<Array<Maybe<{ __typename?: 'Role', id: any, name: string, permissions?: Maybe<Array<Maybe<string>>> }>>> };

export type ToppingStylesQueryVariables = Exact<{ [key: string]: never; }>;


export type ToppingStylesQuery = { __typename?: 'Query', toppingStyles?: Maybe<Array<Maybe<{ __typename?: 'ToppingStyle', id: any, name: string }>>> };

export type StaffOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffOrganizationsQuery = { __typename?: 'Query', staffOrganizations?: Maybe<Array<Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )>>> };

export type StaffOrganizationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StaffOrganizationQuery = { __typename?: 'Query', staffOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )> };

export const StageCalendarFieldsFragmentDoc = gql`
    fragment StageCalendarFields on Stage {
  id
  name
  startDate
  calendarBranchId
  harvestGroup {
    id
    name
    colorHexCode
    flowerZone {
      id
      name
    }
  }
  template {
    createdStageName
  }
}
    `;
export const NameFragmentDoc = gql`
    fragment Name on UserProfile {
  firstName
  lastName
}
    `;
export const TaskCalendarFieldsFragmentDoc = gql`
    fragment TaskCalendarFields on Task {
  id
  name
  startDate
  endDate
  priority
  isComplete
  assignedTo {
    id
    ...Name
  }
  notes
  template {
    id
  }
  harvestGroup {
    id
    name
    colorHexCode
    flowerZone {
      id
      name
    }
  }
}
    ${NameFragmentDoc}`;
export const UpdateAndConflictDisplaysFragmentDoc = gql`
    fragment UpdateAndConflictDisplays on CalendarBranch {
  updates {
    changeDescription
    harvestGroupName
    name
    id
    updateType
    colorHexCode
    undoable
    targetType
  }
  conflicts {
    id
    conflictType
    description
    harvestGroupName
    name
    colorHexCode
    calendarBranchId
    justification
    schedulingRule
  }
}
    `;
export const CalendarBranchFieldsFragmentDoc = gql`
    fragment CalendarBranchFields on CalendarBranch {
  id
  name
  status
  updatedAt
  createdBy {
    id
  }
  stages(dateRange: $dateRange) {
    ...StageCalendarFields
  }
  tasks(dateRange: $dateRange) {
    ...TaskCalendarFields
  }
  ...UpdateAndConflictDisplays
}
    ${StageCalendarFieldsFragmentDoc}
${TaskCalendarFieldsFragmentDoc}
${UpdateAndConflictDisplaysFragmentDoc}`;
export const ShortCalendarBranchFieldsFragmentDoc = gql`
    fragment ShortCalendarBranchFields on CalendarBranch {
  id
  name
  status
  updatedAt
  createdBy {
    id
  }
}
    `;
export const CalendarBranchesGeneralInfoFieldsFragmentDoc = gql`
    fragment CalendarBranchesGeneralInfoFields on CalendarBranch {
  id
  name
  createdBy {
    id
    firstName
    lastName
  }
  createdAt
  lastUpdatedBy {
    id
    firstName
    lastName
  }
  updatedAt
  status
  updateCount
  conflictCount
}
    `;
export const EnvironmentalFileFieldsFragmentDoc = gql`
    fragment EnvironmentalFileFields on EnvironmentalFileType {
  id
  createdAt
  dataFile
  fileType
  fileStatus
  autodetectStatus
  timeOffsetSeconds
  zone {
    id
    name
  }
}
    `;
export const PreviewFileFieldsFragmentDoc = gql`
    fragment PreviewFileFields on EnvironmentalFileType {
  id
  fileType
  autodetectStatus
  sevenDayPreview {
    x
    y
  }
  guessEnvType
  guessTargetSet {
    id
    dayStartTime
    nightStartTime
    displayName
  }
  timeOffsetSeconds
  zone {
    id
    name
  }
  currentHgs {
    id
    name
  }
  currentStages {
    id
    name
  }
}
    `;
export const AlertWarningFragmentDoc = gql`
    fragment AlertWarning on AlertWarning {
  id
  metric
  updatedAt
  createdAt
  startTime
  endTime
  avgDeviation
  maxDeviation
  position
  message
  zone {
    id
    name
  }
  stage {
    id
    name
    harvestGroup {
      id
      name
    }
  }
}
    `;
export const AnalyticsChartDataFragmentDoc = gql`
    fragment AnalyticsChartData on AnalyticsChartData {
  data {
    x
    y
  }
  name
  alertWarnings {
    ...AlertWarning
  }
}
    ${AlertWarningFragmentDoc}`;
export const PagingInfoFragmentDoc = gql`
    fragment PagingInfo on PagingInfo {
  totalCount
  pageCount
  size
  start
  page
  hasNextPage
  hasPreviousPage
}
    `;
export const ZoneFieldsFragmentDoc = gql`
    fragment ZoneFields on Zone {
  id
  name
  active
  maxConcurrentHarvestGroups
  maxConcurrentPlants
  cultivationStyle
  cultivationStyleOther
  irrigationStyle
  irrigationStyleOther
  irrigationFrequency
  irrigationFrequencyOther
  growingMedia
  growingMediaOther
  growMediaSize
  lengthFeet
  lengthInches
  heightFeet
  heightInches
  widthFeet
  widthInches
  acSizeByTon
  acNumUnits
  acBrand
  dehumidifierSizeByPintsPerDay
  dehumidifierNumUnits
  dehumidifierBrand
  lightingType
  lightingTypeOther
  rows
}
    `;
export const HarvestGroupTaskTemplateFieldsFragmentDoc = gql`
    fragment HarvestGroupTaskTemplateFields on TaskTemplate {
  id
  createdTaskName
  stageDayStart
  stageDayEnd
}
    `;
export const HarvestGroupStageTemplateFieldsFragmentDoc = gql`
    fragment HarvestGroupStageTemplateFields on StageTemplate {
  id
  name
  createdStageName
  numberOfDays
  maxNumberOfDays
  minNumberOfDays
  orderInProcess
  designatedZones {
    ...ZoneFields
  }
  taskTemplates {
    ...HarvestGroupTaskTemplateFields
  }
  environmentalTargetType
}
    ${ZoneFieldsFragmentDoc}
${HarvestGroupTaskTemplateFieldsFragmentDoc}`;
export const HarvestGroupTemplateVersionFieldsFragmentDoc = gql`
    fragment HarvestGroupTemplateVersionFields on HarvestGroupTemplateVersion {
  id
  name
  version
  stageTemplates {
    ...HarvestGroupStageTemplateFields
  }
}
    ${HarvestGroupStageTemplateFieldsFragmentDoc}`;
export const HarvestGroupReportFieldsFragmentDoc = gql`
    fragment HarvestGroupReportFields on HarvestGroup {
  id
  name
  zoneIteration
  startDate
  harvestDate
  colorHexCode
}
    `;
export const StageReportFieldsFragmentDoc = gql`
    fragment StageReportFields on Stage {
  id
  name
  daysInStage
  startDate
  endDate
  calendarBranchId
}
    `;
export const StrainReportFieldsFragmentDoc = gql`
    fragment StrainReportFields on Strain {
  id
  name
}
    `;
export const StrainFieldsFragmentDoc = gql`
    fragment StrainFields on Strain {
  id
  name
  active
  createdBy {
    id
    firstName
    lastName
  }
  subspecies
  abbreviation
  documentationUrl
}
    `;
export const BudFormFactorFieldsFragmentDoc = gql`
    fragment BudFormFactorFields on BudFormFactor {
  id
  name
  active
  createdAt
  description
  orderPosition
  classification
  createdBy {
    id
    email
    firstName
    lastName
  }
}
    `;
export const BudFormFactorYieldFragmentDoc = gql`
    fragment BudFormFactorYield on BudFormFactorYield {
  budFormFactorId
  budFormFactor {
    ...BudFormFactorFields
  }
  numberOfGrams
}
    ${BudFormFactorFieldsFragmentDoc}`;
export const YieldFieldsFragmentDoc = gql`
    fragment YieldFields on HarvestStrainYield {
  harvestGroupId
  totalGrams
  finalPlantCount
  strainId
  strain {
    ...StrainFields
  }
  budFormFactorYields {
    ...BudFormFactorYield
  }
}
    ${StrainFieldsFragmentDoc}
${BudFormFactorYieldFragmentDoc}`;
export const YieldReportFieldsFragmentDoc = gql`
    fragment YieldReportFields on HarvestStrainYield {
  ...YieldFields
  strain {
    ...StrainReportFields
  }
}
    ${YieldFieldsFragmentDoc}
${StrainReportFieldsFragmentDoc}`;
export const ZoneIdAndNameFragmentDoc = gql`
    fragment ZoneIdAndName on Zone {
  id
  name
}
    `;
export const CompletedHarvestGroupFieldsFragmentDoc = gql`
    fragment CompletedHarvestGroupFields on HarvestGroup {
  id
  name
  startDate
  harvestDate
  archivedAt
  template {
    ...HarvestGroupTemplateVersionFields
  }
  ...HarvestGroupReportFields
  stages {
    ...StageReportFields
  }
  strains {
    ...StrainReportFields
  }
  yields {
    ...YieldReportFields
  }
  flowerZone {
    ...ZoneIdAndName
  }
  becameProcessingGroup {
    id
    name
  }
}
    ${HarvestGroupTemplateVersionFieldsFragmentDoc}
${HarvestGroupReportFieldsFragmentDoc}
${StageReportFieldsFragmentDoc}
${StrainReportFieldsFragmentDoc}
${YieldReportFieldsFragmentDoc}
${ZoneIdAndNameFragmentDoc}`;
export const CompletedHarvestGroupsPaginatedResultFragmentDoc = gql`
    fragment CompletedHarvestGroupsPaginatedResult on HarvestGroupQuerysetResult {
  options {
    paging {
      ...PagingInfo
    }
    filters {
      search
    }
  }
  items {
    ...CompletedHarvestGroupFields
  }
}
    ${PagingInfoFragmentDoc}
${CompletedHarvestGroupFieldsFragmentDoc}`;
export const AnalyticsHarvestGroupsOverviewFragmentDoc = gql`
    fragment AnalyticsHarvestGroupsOverview on HarvestGroup {
  name
  startDate
  harvestDate
  archivedAt
  yields {
    id
    strain {
      ...StrainReportFields
    }
  }
}
    ${StrainReportFieldsFragmentDoc}`;
export const HarvestGroupShortOverviewFieldsFragmentDoc = gql`
    fragment HarvestGroupShortOverviewFields on HarvestGroup {
  id
  name
  startDate
  harvestDate
  archivedAt
  zoneIteration
  colorHexCode
  flowerZone {
    id
    name
  }
  currentStage {
    id
    name
  }
}
    `;
export const HarvestGroupOverviewFieldsFragmentDoc = gql`
    fragment HarvestGroupOverviewFields on HarvestGroup {
  ...HarvestGroupShortOverviewFields
  strains {
    id
    name
  }
  flowerZone {
    id
    name
  }
  harvestCompletion {
    createdAt
    createdBy {
      id
      ...Name
    }
    cosignedAt
    cosignedBy {
      id
      ...Name
    }
  }
  yields {
    ...YieldFields
  }
}
    ${HarvestGroupShortOverviewFieldsFragmentDoc}
${NameFragmentDoc}
${YieldFieldsFragmentDoc}`;
export const HarvestProjectionFragmentDoc = gql`
    fragment HarvestProjection on HarvestProjection {
  id
  harvestGroupId
  strainId
  plantCount
}
    `;
export const HarvestGroupCurrentStageFieldsFragmentDoc = gql`
    fragment HarvestGroupCurrentStageFields on HarvestGroup {
  currentStage {
    id
    name
    currentDayOfStage
    daysInStage
    calendarBranchId
    strainsInStage {
      strain {
        name
        id
      }
      numberOfPlants
    }
  }
  projections {
    ...HarvestProjection
  }
}
    ${HarvestProjectionFragmentDoc}`;
export const LogEntryFieldsFragmentDoc = gql`
    fragment LogEntryFields on LogEntry {
  id
  logType
  date
  logEntryData
  performedBy {
    id
    ...Name
  }
  stage {
    harvestGroup {
      id
      name
    }
    id
    name
    startDate
    calendarBranchId
    zone {
      id
      name
    }
  }
  isDraft
  readyForApproval
  approvedBy {
    id
    ...Name
  }
  approvedAt
  paperlyticsImage {
    imageUrl
  }
  paperlyticsIssuesList
  paperlyticsBoundaries
  paperlyticsAccuracyScore
}
    ${NameFragmentDoc}`;
export const HarvestGroupWithLogEntriesFragmentDoc = gql`
    fragment HarvestGroupWithLogEntries on HarvestGroup {
  ...HarvestGroupOverviewFields
  ...HarvestGroupCurrentStageFields
  logEntries(logEntryOptions: $logEntryOptions) {
    ...LogEntryFields
  }
}
    ${HarvestGroupOverviewFieldsFragmentDoc}
${HarvestGroupCurrentStageFieldsFragmentDoc}
${LogEntryFieldsFragmentDoc}`;
export const HarvestGroupCalendarFieldsFragmentDoc = gql`
    fragment HarvestGroupCalendarFields on HarvestGroup {
  id
  name
  colorHexCode
  tasks {
    ...TaskCalendarFields
    template {
      id
    }
  }
  stages {
    ...StageCalendarFields
  }
}
    ${TaskCalendarFieldsFragmentDoc}
${StageCalendarFieldsFragmentDoc}`;
export const HarvestGroupFieldsFragmentDoc = gql`
    fragment HarvestGroupFields on HarvestGroup {
  id
  startDate
  harvestDate
  flowerZone {
    id
    name
  }
  archivedAt
  ...HarvestGroupCalendarFields
  template {
    ...HarvestGroupTemplateVersionFields
  }
  stages {
    ...StageCalendarFields
    zone {
      id
      name
    }
    strainsInStage {
      numberOfPlants
      strain {
        id
        name
      }
    }
    template {
      id
    }
  }
  strains {
    ...StrainFields
  }
  harvestCompletion {
    createdAt
    createdBy {
      id
      ...Name
    }
    cosignedAt
    cosignedBy {
      id
      ...Name
    }
  }
  zoneIteration
}
    ${HarvestGroupCalendarFieldsFragmentDoc}
${HarvestGroupTemplateVersionFieldsFragmentDoc}
${StageCalendarFieldsFragmentDoc}
${StrainFieldsFragmentDoc}
${NameFragmentDoc}`;
export const HarvestCompletionFieldsFragmentDoc = gql`
    fragment HarvestCompletionFields on HarvestCompletion {
  id
  cosignedBy {
    id
    firstName
    lastName
  }
  harvestGroup {
    ...HarvestGroupFields
  }
  createdBy {
    id
    firstName
    lastName
  }
}
    ${HarvestGroupFieldsFragmentDoc}`;
export const AnalyticsHarvestGroupFieldsFragmentDoc = gql`
    fragment AnalyticsHarvestGroupFields on HarvestGroup {
  name
  startDate
  harvestDate
  archivedAt
  ...HarvestGroupReportFields
  stages {
    ...StageReportFields
  }
  strains {
    ...StrainReportFields
  }
  yields {
    ...YieldReportFields
  }
  flowerZone {
    ...ZoneIdAndName
  }
  becameProcessingGroup {
    id
    name
  }
  ...HarvestGroupFields
}
    ${HarvestGroupReportFieldsFragmentDoc}
${StageReportFieldsFragmentDoc}
${StrainReportFieldsFragmentDoc}
${YieldReportFieldsFragmentDoc}
${ZoneIdAndNameFragmentDoc}
${HarvestGroupFieldsFragmentDoc}`;
export const CompletedHarvestGroupWithLogsFieldsFragmentDoc = gql`
    fragment CompletedHarvestGroupWithLogsFields on HarvestGroup {
  ...CompletedHarvestGroupFields
  logEntries {
    ...LogEntryFields
  }
}
    ${CompletedHarvestGroupFieldsFragmentDoc}
${LogEntryFieldsFragmentDoc}`;
export const HarvestGroupReportComparisonFieldsFragmentDoc = gql`
    fragment HarvestGroupReportComparisonFields on HarvestGroup {
  ...CompletedHarvestGroupFields
  logEntries {
    id
    logType
  }
}
    ${CompletedHarvestGroupFieldsFragmentDoc}`;
export const ProcessingStrainYieldFieldsFragmentDoc = gql`
    fragment ProcessingStrainYieldFields on ProcessingGroupStrain {
  id
  status
  totalOutputGrams
  totalInputGrams
  inventoryGrams
  strain {
    id
  }
}
    `;
export const HarvestBecameProcessingFieldsFragmentDoc = gql`
    fragment HarvestBecameProcessingFields on HarvestGroup {
  id
  becameProcessingGroup {
    id
    name
    processingGroupStrains {
      ...ProcessingStrainYieldFields
    }
  }
}
    ${ProcessingStrainYieldFieldsFragmentDoc}`;
export const HarvestGroupDetailFieldsFragmentDoc = gql`
    fragment HarvestGroupDetailFields on HarvestGroup {
  id
  startDate
  harvestDate
  archivedAt
  ...HarvestGroupCalendarFields
  template {
    ...HarvestGroupTemplateVersionFields
  }
  stages {
    ...StageCalendarFields
    zone {
      id
      name
    }
    strainsInStage {
      id
      numberOfPlants
      strain {
        id
        name
      }
    }
    template {
      id
    }
  }
  zoneIteration
  strains {
    ...StrainFields
  }
}
    ${HarvestGroupCalendarFieldsFragmentDoc}
${HarvestGroupTemplateVersionFieldsFragmentDoc}
${StageCalendarFieldsFragmentDoc}
${StrainFieldsFragmentDoc}`;
export const LogEntriesFragmentDoc = gql`
    fragment LogEntries on HarvestGroup {
  id
  logEntries(logEntryOptions: $zoneOptions) {
    ...LogEntryFields
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export const MaintenanceRequestResolutionFieldsFragmentDoc = gql`
    fragment MaintenanceRequestResolutionFields on MaintenanceRequest {
  resolution {
    createdAt
    resolvedAt
    notes
    resolvedBy {
      id
      ...Name
    }
  }
}
    ${NameFragmentDoc}`;
export const MaintenanceRequestFieldsFragmentDoc = gql`
    fragment MaintenanceRequestFields on MaintenanceRequest {
  id
  createdAt
  firstObservedAt
  dueDate
  urgent
  equipmentInoperable
  lockOutTagOut
  requestedBy {
    id
    ...Name
  }
  description
  location {
    id
    name
  }
  imageUrl
  tags {
    name
  }
  ...MaintenanceRequestResolutionFields
}
    ${NameFragmentDoc}
${MaintenanceRequestResolutionFieldsFragmentDoc}`;
export const PaperlyticsImageFieldsFragmentDoc = gql`
    fragment PaperlyticsImageFields on PaperlyticsImage {
  id
  harvestGroup {
    id
    name
  }
  imageUrl
  imageUpload
  startedAt
  completedAt
  success
  targetType
  result
  accuracyScore
  issuesList
  uploadedBy {
    id
    firstName
    lastName
    email
  }
}
    `;
export const NutrientFieldsFragmentDoc = gql`
    fragment NutrientFields on Nutrient {
  id
  name
  active
  unitOfMeasure
  priceInCentsPerUnit
  boughtPrice
  boughtAmount
  boughtUnit
}
    `;
export const RecipeFieldsFragmentDoc = gql`
    fragment RecipeFields on Recipe {
  id
  name
  active
  applicationType
  components {
    id
    nutrient {
      ...NutrientFields
    }
    volume
  }
}
    ${NutrientFieldsFragmentDoc}`;
export const TaskFieldsFragmentDoc = gql`
    fragment TaskFields on Task {
  id
  name
  startDate
  endDate
  assignedTo {
    id
    firstName
    lastName
  }
  completedAt
  createdBy {
    id
    firstName
    lastName
  }
  priority
  notes
}
    `;
export const HarvestGroupTemplateFieldsFragmentDoc = gql`
    fragment HarvestGroupTemplateFields on HarvestGroupTemplate {
  id
  name
  archivedAt
  activeTemplateVersion {
    ...HarvestGroupTemplateVersionFields
  }
  versions {
    id
    version
  }
}
    ${HarvestGroupTemplateVersionFieldsFragmentDoc}`;
export const HarvestGroupZoneCardFieldsFragmentDoc = gql`
    fragment HarvestGroupZoneCardFields on HarvestGroup {
  id
  logEntries(logEntryOptions: $zoneOptions) {
    ...LogEntryFields
  }
  ...HarvestGroupCurrentStageFields
}
    ${LogEntryFieldsFragmentDoc}
${HarvestGroupCurrentStageFieldsFragmentDoc}`;
export const ZoneWithHarvestGroupFieldsFragmentDoc = gql`
    fragment ZoneWithHarvestGroupFields on Zone {
  ...ZoneFields
  currentHarvestGroups {
    ...HarvestGroupOverviewFields
    ...HarvestGroupCurrentStageFields
    logEntries(logEntryOptions: $zoneOptions) {
      ...LogEntryFields
    }
  }
}
    ${ZoneFieldsFragmentDoc}
${HarvestGroupOverviewFieldsFragmentDoc}
${HarvestGroupCurrentStageFieldsFragmentDoc}
${LogEntryFieldsFragmentDoc}`;
export const ZoneCardFieldsFragmentDoc = gql`
    fragment ZoneCardFields on Zone {
  ...ZoneFields
  currentHarvestGroups {
    ...HarvestGroupOverviewFields
    ...HarvestGroupCurrentStageFields
  }
}
    ${ZoneFieldsFragmentDoc}
${HarvestGroupOverviewFieldsFragmentDoc}
${HarvestGroupCurrentStageFieldsFragmentDoc}`;
export const ExpenseCategoryFieldsFragmentDoc = gql`
    fragment ExpenseCategoryFields on ExpenseCategory {
  id
  name
}
    `;
export const RecurringExpenseFieldsFragmentDoc = gql`
    fragment RecurringExpenseFields on RecurringExpense {
  id
  startDate
  endDate
  category {
    ...ExpenseCategoryFields
  }
  costInCents
  allocationRatio
  notes
}
    ${ExpenseCategoryFieldsFragmentDoc}`;
export const ExpenseFieldsFragmentDoc = gql`
    fragment ExpenseFields on Expense {
  id
  date
  type
  category {
    ...ExpenseCategoryFields
  }
  costInCents
  hourlyCostInCents
  hoursWorked
  allocationRatio
  notes
  recurringExpense {
    ...RecurringExpenseFields
  }
}
    ${ExpenseCategoryFieldsFragmentDoc}
${RecurringExpenseFieldsFragmentDoc}`;
export const ExpenseReportByCategoryEntryFragmentDoc = gql`
    fragment ExpenseReportByCategoryEntry on ExpenseReportByCategoryEntry {
  category {
    ...ExpenseCategoryFields
  }
  totalCostInCents
}
    ${ExpenseCategoryFieldsFragmentDoc}`;
export const ExpenseReportAllocationBreakdownStrainMonthlySummaryFragmentDoc = gql`
    fragment ExpenseReportAllocationBreakdownStrainMonthlySummary on ExpenseReportAllocationBreakdownStrainMonthlySummary {
  id
  month
  cultivationCostInCents
  processingCostInCents
  plantDays
  processingHours
}
    `;
export const ExpenseReportAllocationBreakdownEntryFragmentDoc = gql`
    fragment ExpenseReportAllocationBreakdownEntry on ExpenseReportAllocationBreakdownEntry {
  id
  strain {
    id
    name
  }
  harvestGroup {
    id
    name
  }
  harvestProjection {
    id
    strainId
    plantCount
    harvestGroupId
  }
  cultivationComplete
  cultivationDays
  cultivationNumPlants
  cultivationStartDate
  cultivationEndDate
  cultivationCostInCents
  processingGroup {
    id
    name
  }
  processingHours
  processingCostInCents
  processingStartDate
  processingEndDate
  strainMonthlySummaries {
    ...ExpenseReportAllocationBreakdownStrainMonthlySummary
  }
}
    ${ExpenseReportAllocationBreakdownStrainMonthlySummaryFragmentDoc}`;
export const ExpenseReportAllocationBreakdownMonthlySummaryFragmentDoc = gql`
    fragment ExpenseReportAllocationBreakdownMonthlySummary on ExpenseReportAllocationBreakdownMonthlySummary {
  id
  month
  cultivationCostInCents
  processingCostInCents
  totalPlantDays
  totalProcessingHours
}
    `;
export const ExpenseReportAllocationBreakdownFragmentDoc = gql`
    fragment ExpenseReportAllocationBreakdown on ExpenseReportAllocationBreakdown {
  entries {
    ...ExpenseReportAllocationBreakdownEntry
  }
  monthlySummaries {
    ...ExpenseReportAllocationBreakdownMonthlySummary
  }
}
    ${ExpenseReportAllocationBreakdownEntryFragmentDoc}
${ExpenseReportAllocationBreakdownMonthlySummaryFragmentDoc}`;
export const ProcessingGroupNameFragmentDoc = gql`
    fragment ProcessingGroupName on ProcessingGroup {
  id
  name
}
    `;
export const ProcessingGroupStageFieldsFragmentDoc = gql`
    fragment ProcessingGroupStageFields on ProcessingGroupStage {
  id
  name
  status
  startDate
  endDate
  schedulingScope
  totalInputGrams
  totalOutputGrams
  totalPendingGrams
  totalPartialGrams
  processingGroup {
    ...ProcessingGroupName
  }
  summary {
    progressPercent
    weightCompletedGrams
    weightTotalGrams
    numDays
    daysElapsed
    remainingInventoryGrams
  }
  processingStageTemplate {
    id
    name
    inputTypes {
      id
      name
    }
  }
}
    ${ProcessingGroupNameFragmentDoc}`;
export const ProcessingStageTemplateFieldsFragmentDoc = gql`
    fragment ProcessingStageTemplateFields on ProcessingStageTemplate {
  id
  name
  numberOfDays
  trackPerformance
  orderInProcess
  progressScope
  schedulingScope
  inputTypes {
    ...BudFormFactorFields
  }
  outputTypes {
    ...BudFormFactorFields
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;
export const ProcessingGroupStageWithTemplateFieldsFragmentDoc = gql`
    fragment ProcessingGroupStageWithTemplateFields on ProcessingGroupStage {
  ...ProcessingGroupStageFields
  processingStageTemplate {
    ...ProcessingStageTemplateFields
  }
}
    ${ProcessingGroupStageFieldsFragmentDoc}
${ProcessingStageTemplateFieldsFragmentDoc}`;
export const ProcessingJobOverviewFieldsFragmentDoc = gql`
    fragment ProcessingJobOverviewFields on ProcessingJob {
  id
  jobStatus
  assignee {
    id
    ...Name
  }
  createdBy {
    id
    ...Name
  }
  inputType {
    ...BudFormFactorFields
  }
  inputGrams
  totalOutputGrams
  createdAt
  startedAt
  totalWorkedMinutes
  strainStage {
    status
    id
    stage {
      ...ProcessingGroupStageWithTemplateFields
    }
    processingGroupStrain {
      id
      strain {
        id
        name
      }
    }
  }
}
    ${NameFragmentDoc}
${BudFormFactorFieldsFragmentDoc}
${ProcessingGroupStageWithTemplateFieldsFragmentDoc}`;
export const ProcessingJobLogFieldsFragmentDoc = gql`
    fragment ProcessingJobLogFields on ProcessingJobLog {
  id
  logType
  createdBy {
    id
    ...Name
  }
  performer {
    id
    ...Name
  }
  startedAt
  endedAt
  createdAt
  breakMinutes
  workedMinutes
  outputGrams
  leftoverInputGrams
}
    ${NameFragmentDoc}`;
export const ProcessingJobOverviewWithLogsFieldsFragmentDoc = gql`
    fragment ProcessingJobOverviewWithLogsFields on ProcessingJob {
  ...ProcessingJobOverviewFields
  processingJobLogs {
    ...ProcessingJobLogFields
  }
}
    ${ProcessingJobOverviewFieldsFragmentDoc}
${ProcessingJobLogFieldsFragmentDoc}`;
export const ProcessingJobOverviewWithGroupFieldsFragmentDoc = gql`
    fragment ProcessingJobOverviewWithGroupFields on ProcessingJob {
  ...ProcessingJobOverviewFields
}
    ${ProcessingJobOverviewFieldsFragmentDoc}`;
export const ProcessingGroupShortOverviewFragmentDoc = gql`
    fragment ProcessingGroupShortOverview on ProcessingGroup {
  id
  name
  status
  colorHexCode
}
    `;
export const ProcessingGroupStageWithSummaryFieldsFragmentDoc = gql`
    fragment ProcessingGroupStageWithSummaryFields on ProcessingGroupStage {
  ...ProcessingGroupStageWithTemplateFields
  summary {
    progressPercent
    daysElapsed
    numDays
    weightCompletedGrams
    weightTotalGrams
    remainingInventoryGrams
  }
}
    ${ProcessingGroupStageWithTemplateFieldsFragmentDoc}`;
export const AdjustmentFieldsFragmentDoc = gql`
    fragment AdjustmentFields on ProcessingGroupStrainInventoryAdjustment {
  id
  createdAt
  adjustmentGrams
  createdBy {
    id
    ...Name
  }
  description
}
    ${NameFragmentDoc}`;
export const ProcessingGroupStrainFieldsFragmentDoc = gql`
    fragment ProcessingGroupStrainFields on ProcessingGroupStrain {
  id
  strain {
    ...StrainFields
  }
  status
  orderInProcess
  inventoryGrams
  totalInputGrams
  totalPendingGrams
  totalPartialGrams
  totalOutputGrams
  totalAdjustmentGrams
  inventoryAdjustments {
    ...AdjustmentFields
  }
}
    ${StrainFieldsFragmentDoc}
${AdjustmentFieldsFragmentDoc}`;
export const ProcessingGroupStrainStageSummaryFieldsFragmentDoc = gql`
    fragment ProcessingGroupStrainStageSummaryFields on ProcessingGroupStrainStage {
  id
  summary {
    progressPercent
    daysElapsed
    numDays
    weightCompletedGrams
    weightTotalGrams
    remainingInventoryGrams
  }
}
    `;
export const ProcessingGroupStrainStageOverviewFieldsFragmentDoc = gql`
    fragment ProcessingGroupStrainStageOverviewFields on ProcessingGroupStrainStage {
  id
  status
  startDate
  endDate
  processingGroupStrain {
    inventoryAdjustments {
      ...AdjustmentFields
    }
    strain {
      ...StrainFields
    }
  }
  stage {
    ...ProcessingGroupStageWithSummaryFields
    processingGroup {
      id
    }
  }
  totalInputGrams
  totalPendingGrams
  totalPartialGrams
  totalOutputGrams
  ...ProcessingGroupStrainStageSummaryFields
}
    ${AdjustmentFieldsFragmentDoc}
${StrainFieldsFragmentDoc}
${ProcessingGroupStageWithSummaryFieldsFragmentDoc}
${ProcessingGroupStrainStageSummaryFieldsFragmentDoc}`;
export const ProcessingGroupWithStrainsAndStagesFieldsFragmentDoc = gql`
    fragment ProcessingGroupWithStrainsAndStagesFields on ProcessingGroup {
  ...ProcessingGroupShortOverview
  inUnfinishedJobs(strainId: $strainId)
  processingGroupStages {
    ...ProcessingGroupStageWithSummaryFields
  }
  processingGroupStrains {
    ...ProcessingGroupStrainFields
  }
  processingGroupStrainStages(strainId: $strainId) {
    id
    ...ProcessingGroupStrainStageOverviewFields
    stage {
      ...ProcessingGroupStageWithSummaryFields
    }
    processingGroupStrain {
      id
      strain {
        ...StrainFields
      }
    }
  }
}
    ${ProcessingGroupShortOverviewFragmentDoc}
${ProcessingGroupStageWithSummaryFieldsFragmentDoc}
${ProcessingGroupStrainFieldsFragmentDoc}
${ProcessingGroupStrainStageOverviewFieldsFragmentDoc}
${StrainFieldsFragmentDoc}`;
export const ProcessingGroupWithStrainsAndStagesFieldsAndJobsFragmentDoc = gql`
    fragment ProcessingGroupWithStrainsAndStagesFieldsAndJobs on ProcessingGroup {
  ...ProcessingGroupWithStrainsAndStagesFields
  processingGroupStages {
    jobs {
      ...ProcessingJobOverviewFields
    }
  }
}
    ${ProcessingGroupWithStrainsAndStagesFieldsFragmentDoc}
${ProcessingJobOverviewFieldsFragmentDoc}`;
export const ProcessingGroupFieldsFragmentDoc = gql`
    fragment ProcessingGroupFields on ProcessingGroup {
  id
  name
  status
  archivedAt
  colorHexCode
  startDate
  createdBy {
    ...Name
  }
  latestStage {
    ...ProcessingGroupStageFields
  }
  processingGroupStrains {
    id
  }
}
    ${NameFragmentDoc}
${ProcessingGroupStageFieldsFragmentDoc}`;
export const ProcessingGroupOverviewFieldsFragmentDoc = gql`
    fragment ProcessingGroupOverviewFields on ProcessingGroup {
  ...ProcessingGroupFields
  processingGroupStages {
    ...ProcessingGroupStageWithSummaryFields
  }
  status
  processingGroupStrains {
    ...ProcessingGroupStrainFields
  }
}
    ${ProcessingGroupFieldsFragmentDoc}
${ProcessingGroupStageWithSummaryFieldsFragmentDoc}
${ProcessingGroupStrainFieldsFragmentDoc}`;
export const ProcessingGroupFieldsWithStageJobsFragmentDoc = gql`
    fragment ProcessingGroupFieldsWithStageJobs on ProcessingGroup {
  ...ProcessingGroupOverviewFields
  id
  stages {
    processingStageTemplate {
      trackPerformance
      outputTypes {
        id
        name
      }
    }
    jobs {
      jobStatus
      inputType {
        ...BudFormFactorFields
      }
      assignee {
        id
        ...Name
      }
      totalOutputGrams
      totalWorkedMinutes
      strainStage {
        processingGroupStrain {
          strain {
            id
            name
          }
          id
        }
      }
    }
  }
}
    ${ProcessingGroupOverviewFieldsFragmentDoc}
${BudFormFactorFieldsFragmentDoc}
${NameFragmentDoc}`;
export const ProcessingGroupTemplateVersionFieldsFragmentDoc = gql`
    fragment ProcessingGroupTemplateVersionFields on ProcessingGroupTemplateVersion {
  id
  name
  version
  processingStageTemplates {
    ...ProcessingStageTemplateFields
  }
}
    ${ProcessingStageTemplateFieldsFragmentDoc}`;
export const ProcessingGroupTemplateFieldsFragmentDoc = gql`
    fragment ProcessingGroupTemplateFields on ProcessingGroupTemplate {
  id
  name
  archivedAt
  activeTemplateVersion {
    ...ProcessingGroupTemplateVersionFields
  }
  processingGroupTemplateVersions {
    ...ProcessingGroupTemplateVersionFields
  }
}
    ${ProcessingGroupTemplateVersionFieldsFragmentDoc}`;
export const RoleFieldsFragmentDoc = gql`
    fragment RoleFields on Role {
  id
  name
  permissions
}
    `;
export const UserAttributesFragmentDoc = gql`
    fragment UserAttributes on UserProfile {
  id
  firstName
  lastName
  email
  organizationId
  role {
    ...RoleFields
  }
  userPermissions
  isNoLogin
  isStaff
  isSuperuser
  isActive
}
    ${RoleFieldsFragmentDoc}`;
export const EnvironmentalTargetSetFieldsFragmentDoc = gql`
    fragment EnvironmentalTargetSetFields on EnvironmentalTargetSet {
  id
  displayName
  organization {
    id
  }
  dayStartTime
  nightStartTime
  environmentalTargetType
  zone {
    id
    name
  }
  daysInStageStart
  daysInStageEnd
  dayTempLow
  dayTempHigh
  nightTempLow
  nightTempHigh
  dayVpdLow
  dayVpdHigh
  nightVpdLow
  nightVpdHigh
  dayHumidityLow
  dayHumidityHigh
  nightHumidityLow
  nightHumidityHigh
  dayCo2Low
  dayCo2High
  nightCo2Low
  nightCo2High
}
    `;
export const OrganizationFieldsFragmentDoc = gql`
    fragment OrganizationFields on Organization {
  id
  name
  timezone
  moduleEnabledCultivation
  moduleEnabledProcessing
  moduleEnabledFinance
  optionConvertGramsAbove
  normalizeWindowMinutes
  alertConsecutiveThreshold
  environmentalTargetSets {
    ...EnvironmentalTargetSetFields
  }
}
    ${EnvironmentalTargetSetFieldsFragmentDoc}`;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on UserProfile {
  ...UserAttributes
  organization {
    ...OrganizationFields
  }
}
    ${UserAttributesFragmentDoc}
${OrganizationFieldsFragmentDoc}`;
export const CalendarBranchDocument = gql`
    query calendarBranch($id: String, $dateRange: DateRangeInput) {
  calendarBranch(id: $id) {
    ...CalendarBranchFields
  }
}
    ${CalendarBranchFieldsFragmentDoc}`;

/**
 * __useCalendarBranchQuery__
 *
 * To run a query within a React component, call `useCalendarBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarBranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useCalendarBranchQuery(baseOptions?: Apollo.QueryHookOptions<CalendarBranchQuery, CalendarBranchQueryVariables>) {
        return Apollo.useQuery<CalendarBranchQuery, CalendarBranchQueryVariables>(CalendarBranchDocument, baseOptions);
      }
export function useCalendarBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarBranchQuery, CalendarBranchQueryVariables>) {
          return Apollo.useLazyQuery<CalendarBranchQuery, CalendarBranchQueryVariables>(CalendarBranchDocument, baseOptions);
        }
export type CalendarBranchQueryHookResult = ReturnType<typeof useCalendarBranchQuery>;
export type CalendarBranchLazyQueryHookResult = ReturnType<typeof useCalendarBranchLazyQuery>;
export type CalendarBranchQueryResult = Apollo.QueryResult<CalendarBranchQuery, CalendarBranchQueryVariables>;
export const CalendarBranchesDocument = gql`
    query calendarBranches {
  calendarBranches {
    ...CalendarBranchesGeneralInfoFields
  }
}
    ${CalendarBranchesGeneralInfoFieldsFragmentDoc}`;

/**
 * __useCalendarBranchesQuery__
 *
 * To run a query within a React component, call `useCalendarBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarBranchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarBranchesQuery(baseOptions?: Apollo.QueryHookOptions<CalendarBranchesQuery, CalendarBranchesQueryVariables>) {
        return Apollo.useQuery<CalendarBranchesQuery, CalendarBranchesQueryVariables>(CalendarBranchesDocument, baseOptions);
      }
export function useCalendarBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarBranchesQuery, CalendarBranchesQueryVariables>) {
          return Apollo.useLazyQuery<CalendarBranchesQuery, CalendarBranchesQueryVariables>(CalendarBranchesDocument, baseOptions);
        }
export type CalendarBranchesQueryHookResult = ReturnType<typeof useCalendarBranchesQuery>;
export type CalendarBranchesLazyQueryHookResult = ReturnType<typeof useCalendarBranchesLazyQuery>;
export type CalendarBranchesQueryResult = Apollo.QueryResult<CalendarBranchesQuery, CalendarBranchesQueryVariables>;
export const CalendarBranchMergeLogEntriesDocument = gql`
    query calendarBranchMergeLogEntries {
  calendarBranchMergeLogEntries {
    id
    calendarBranchName
    mergedAt
    mergedBy {
      id
      firstName
      lastName
    }
    changelog
  }
}
    `;

/**
 * __useCalendarBranchMergeLogEntriesQuery__
 *
 * To run a query within a React component, call `useCalendarBranchMergeLogEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarBranchMergeLogEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarBranchMergeLogEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarBranchMergeLogEntriesQuery(baseOptions?: Apollo.QueryHookOptions<CalendarBranchMergeLogEntriesQuery, CalendarBranchMergeLogEntriesQueryVariables>) {
        return Apollo.useQuery<CalendarBranchMergeLogEntriesQuery, CalendarBranchMergeLogEntriesQueryVariables>(CalendarBranchMergeLogEntriesDocument, baseOptions);
      }
export function useCalendarBranchMergeLogEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarBranchMergeLogEntriesQuery, CalendarBranchMergeLogEntriesQueryVariables>) {
          return Apollo.useLazyQuery<CalendarBranchMergeLogEntriesQuery, CalendarBranchMergeLogEntriesQueryVariables>(CalendarBranchMergeLogEntriesDocument, baseOptions);
        }
export type CalendarBranchMergeLogEntriesQueryHookResult = ReturnType<typeof useCalendarBranchMergeLogEntriesQuery>;
export type CalendarBranchMergeLogEntriesLazyQueryHookResult = ReturnType<typeof useCalendarBranchMergeLogEntriesLazyQuery>;
export type CalendarBranchMergeLogEntriesQueryResult = Apollo.QueryResult<CalendarBranchMergeLogEntriesQuery, CalendarBranchMergeLogEntriesQueryVariables>;
export const CreateCalendarBranchDocument = gql`
    mutation createCalendarBranch($input: CreateCalendarBranchInput!) {
  createCalendarBranch(input: $input) {
    ... on CalendarBranch {
      ...ShortCalendarBranchFields
    }
    ... on Error {
      message
    }
  }
}
    ${ShortCalendarBranchFieldsFragmentDoc}`;
export type CreateCalendarBranchMutationFn = Apollo.MutationFunction<CreateCalendarBranchMutation, CreateCalendarBranchMutationVariables>;

/**
 * __useCreateCalendarBranchMutation__
 *
 * To run a mutation, you first call `useCreateCalendarBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarBranchMutation, { data, loading, error }] = useCreateCalendarBranchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateCalendarBranchMutation, CreateCalendarBranchMutationVariables>) {
        return Apollo.useMutation<CreateCalendarBranchMutation, CreateCalendarBranchMutationVariables>(CreateCalendarBranchDocument, baseOptions);
      }
export type CreateCalendarBranchMutationHookResult = ReturnType<typeof useCreateCalendarBranchMutation>;
export type CreateCalendarBranchMutationResult = Apollo.MutationResult<CreateCalendarBranchMutation>;
export type CreateCalendarBranchMutationOptions = Apollo.BaseMutationOptions<CreateCalendarBranchMutation, CreateCalendarBranchMutationVariables>;
export const DuplicateCalendarBranchDocument = gql`
    mutation duplicateCalendarBranch($input: DuplicateCalendarBranchInput!) {
  duplicateCalendarBranch(input: $input) {
    ... on CalendarBranch {
      ...ShortCalendarBranchFields
    }
    ... on Error {
      message
    }
  }
}
    ${ShortCalendarBranchFieldsFragmentDoc}`;
export type DuplicateCalendarBranchMutationFn = Apollo.MutationFunction<DuplicateCalendarBranchMutation, DuplicateCalendarBranchMutationVariables>;

/**
 * __useDuplicateCalendarBranchMutation__
 *
 * To run a mutation, you first call `useDuplicateCalendarBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCalendarBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCalendarBranchMutation, { data, loading, error }] = useDuplicateCalendarBranchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateCalendarBranchMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateCalendarBranchMutation, DuplicateCalendarBranchMutationVariables>) {
        return Apollo.useMutation<DuplicateCalendarBranchMutation, DuplicateCalendarBranchMutationVariables>(DuplicateCalendarBranchDocument, baseOptions);
      }
export type DuplicateCalendarBranchMutationHookResult = ReturnType<typeof useDuplicateCalendarBranchMutation>;
export type DuplicateCalendarBranchMutationResult = Apollo.MutationResult<DuplicateCalendarBranchMutation>;
export type DuplicateCalendarBranchMutationOptions = Apollo.BaseMutationOptions<DuplicateCalendarBranchMutation, DuplicateCalendarBranchMutationVariables>;
export const UpdateCalendarBranchTaskDocument = gql`
    mutation updateCalendarBranchTask($input: UpdateCalendarBranchTaskInput!, $dateRange: DateRangeInput) {
  updateCalendarBranchTask(input: $input) {
    ...CalendarBranchFields
  }
}
    ${CalendarBranchFieldsFragmentDoc}`;
export type UpdateCalendarBranchTaskMutationFn = Apollo.MutationFunction<UpdateCalendarBranchTaskMutation, UpdateCalendarBranchTaskMutationVariables>;

/**
 * __useUpdateCalendarBranchTaskMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarBranchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarBranchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarBranchTaskMutation, { data, loading, error }] = useUpdateCalendarBranchTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useUpdateCalendarBranchTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCalendarBranchTaskMutation, UpdateCalendarBranchTaskMutationVariables>) {
        return Apollo.useMutation<UpdateCalendarBranchTaskMutation, UpdateCalendarBranchTaskMutationVariables>(UpdateCalendarBranchTaskDocument, baseOptions);
      }
export type UpdateCalendarBranchTaskMutationHookResult = ReturnType<typeof useUpdateCalendarBranchTaskMutation>;
export type UpdateCalendarBranchTaskMutationResult = Apollo.MutationResult<UpdateCalendarBranchTaskMutation>;
export type UpdateCalendarBranchTaskMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarBranchTaskMutation, UpdateCalendarBranchTaskMutationVariables>;
export const UpdateCalendarBranchStageDocument = gql`
    mutation updateCalendarBranchStage($input: UpdateCalendarBranchStageInput!, $dateRange: DateRangeInput) {
  updateCalendarBranchStage(input: $input) {
    ...CalendarBranchFields
  }
}
    ${CalendarBranchFieldsFragmentDoc}`;
export type UpdateCalendarBranchStageMutationFn = Apollo.MutationFunction<UpdateCalendarBranchStageMutation, UpdateCalendarBranchStageMutationVariables>;

/**
 * __useUpdateCalendarBranchStageMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarBranchStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarBranchStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarBranchStageMutation, { data, loading, error }] = useUpdateCalendarBranchStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useUpdateCalendarBranchStageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCalendarBranchStageMutation, UpdateCalendarBranchStageMutationVariables>) {
        return Apollo.useMutation<UpdateCalendarBranchStageMutation, UpdateCalendarBranchStageMutationVariables>(UpdateCalendarBranchStageDocument, baseOptions);
      }
export type UpdateCalendarBranchStageMutationHookResult = ReturnType<typeof useUpdateCalendarBranchStageMutation>;
export type UpdateCalendarBranchStageMutationResult = Apollo.MutationResult<UpdateCalendarBranchStageMutation>;
export type UpdateCalendarBranchStageMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarBranchStageMutation, UpdateCalendarBranchStageMutationVariables>;
export const UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupDocument = gql`
    mutation updateCalendarBranchStageAndFollowingEventsInHarvestGroup($input: UpdateCalendarBranchStageInput!) {
  updateCalendarBranchStageAndFollowingEventsInHarvestGroup(input: $input) {
    ...ShortCalendarBranchFields
  }
}
    ${ShortCalendarBranchFieldsFragmentDoc}`;
export type UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationFn = Apollo.MutationFunction<UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation, UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationVariables>;

/**
 * __useUpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation, { data, loading, error }] = useUpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation, UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationVariables>) {
        return Apollo.useMutation<UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation, UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationVariables>(UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupDocument, baseOptions);
      }
export type UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationHookResult = ReturnType<typeof useUpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation>;
export type UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationResult = Apollo.MutationResult<UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation>;
export type UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutation, UpdateCalendarBranchStageAndFollowingEventsInHarvestGroupMutationVariables>;
export const UndoBranchCalendarTaskUpdateDocument = gql`
    mutation undoBranchCalendarTaskUpdate($input: UndoBranchCalendarTaskUpdateInput!, $dateRange: DateRangeInput) {
  undoBranchCalendarTaskUpdate(input: $input) {
    ...CalendarBranchFields
  }
}
    ${CalendarBranchFieldsFragmentDoc}`;
export type UndoBranchCalendarTaskUpdateMutationFn = Apollo.MutationFunction<UndoBranchCalendarTaskUpdateMutation, UndoBranchCalendarTaskUpdateMutationVariables>;

/**
 * __useUndoBranchCalendarTaskUpdateMutation__
 *
 * To run a mutation, you first call `useUndoBranchCalendarTaskUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoBranchCalendarTaskUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoBranchCalendarTaskUpdateMutation, { data, loading, error }] = useUndoBranchCalendarTaskUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useUndoBranchCalendarTaskUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UndoBranchCalendarTaskUpdateMutation, UndoBranchCalendarTaskUpdateMutationVariables>) {
        return Apollo.useMutation<UndoBranchCalendarTaskUpdateMutation, UndoBranchCalendarTaskUpdateMutationVariables>(UndoBranchCalendarTaskUpdateDocument, baseOptions);
      }
export type UndoBranchCalendarTaskUpdateMutationHookResult = ReturnType<typeof useUndoBranchCalendarTaskUpdateMutation>;
export type UndoBranchCalendarTaskUpdateMutationResult = Apollo.MutationResult<UndoBranchCalendarTaskUpdateMutation>;
export type UndoBranchCalendarTaskUpdateMutationOptions = Apollo.BaseMutationOptions<UndoBranchCalendarTaskUpdateMutation, UndoBranchCalendarTaskUpdateMutationVariables>;
export const UndoBranchCalendarStageUpdateDocument = gql`
    mutation undoBranchCalendarStageUpdate($input: UndoBranchCalendarStageUpdateInput!, $dateRange: DateRangeInput) {
  undoBranchCalendarStageUpdate(input: $input) {
    ...CalendarBranchFields
  }
}
    ${CalendarBranchFieldsFragmentDoc}`;
export type UndoBranchCalendarStageUpdateMutationFn = Apollo.MutationFunction<UndoBranchCalendarStageUpdateMutation, UndoBranchCalendarStageUpdateMutationVariables>;

/**
 * __useUndoBranchCalendarStageUpdateMutation__
 *
 * To run a mutation, you first call `useUndoBranchCalendarStageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoBranchCalendarStageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoBranchCalendarStageUpdateMutation, { data, loading, error }] = useUndoBranchCalendarStageUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useUndoBranchCalendarStageUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UndoBranchCalendarStageUpdateMutation, UndoBranchCalendarStageUpdateMutationVariables>) {
        return Apollo.useMutation<UndoBranchCalendarStageUpdateMutation, UndoBranchCalendarStageUpdateMutationVariables>(UndoBranchCalendarStageUpdateDocument, baseOptions);
      }
export type UndoBranchCalendarStageUpdateMutationHookResult = ReturnType<typeof useUndoBranchCalendarStageUpdateMutation>;
export type UndoBranchCalendarStageUpdateMutationResult = Apollo.MutationResult<UndoBranchCalendarStageUpdateMutation>;
export type UndoBranchCalendarStageUpdateMutationOptions = Apollo.BaseMutationOptions<UndoBranchCalendarStageUpdateMutation, UndoBranchCalendarStageUpdateMutationVariables>;
export const UpdateStageConflictDocument = gql`
    mutation updateStageConflict($input: UpdateStageConflictInput!) {
  updateStageConflict(input: $input) {
    ... on Error {
      message
    }
    ... on CalendarBranch {
      id
      name
      status
      updatedAt
      createdBy {
        id
      }
    }
  }
}
    `;
export type UpdateStageConflictMutationFn = Apollo.MutationFunction<UpdateStageConflictMutation, UpdateStageConflictMutationVariables>;

/**
 * __useUpdateStageConflictMutation__
 *
 * To run a mutation, you first call `useUpdateStageConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStageConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStageConflictMutation, { data, loading, error }] = useUpdateStageConflictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStageConflictMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStageConflictMutation, UpdateStageConflictMutationVariables>) {
        return Apollo.useMutation<UpdateStageConflictMutation, UpdateStageConflictMutationVariables>(UpdateStageConflictDocument, baseOptions);
      }
export type UpdateStageConflictMutationHookResult = ReturnType<typeof useUpdateStageConflictMutation>;
export type UpdateStageConflictMutationResult = Apollo.MutationResult<UpdateStageConflictMutation>;
export type UpdateStageConflictMutationOptions = Apollo.BaseMutationOptions<UpdateStageConflictMutation, UpdateStageConflictMutationVariables>;
export const UpdateTaskConflictDocument = gql`
    mutation updateTaskConflict($input: UpdateTaskConflictInput!) {
  updateTaskConflict(input: $input) {
    ... on Error {
      message
    }
    ... on CalendarBranch {
      id
      name
      status
      updatedAt
      createdBy {
        id
      }
    }
  }
}
    `;
export type UpdateTaskConflictMutationFn = Apollo.MutationFunction<UpdateTaskConflictMutation, UpdateTaskConflictMutationVariables>;

/**
 * __useUpdateTaskConflictMutation__
 *
 * To run a mutation, you first call `useUpdateTaskConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskConflictMutation, { data, loading, error }] = useUpdateTaskConflictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskConflictMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskConflictMutation, UpdateTaskConflictMutationVariables>) {
        return Apollo.useMutation<UpdateTaskConflictMutation, UpdateTaskConflictMutationVariables>(UpdateTaskConflictDocument, baseOptions);
      }
export type UpdateTaskConflictMutationHookResult = ReturnType<typeof useUpdateTaskConflictMutation>;
export type UpdateTaskConflictMutationResult = Apollo.MutationResult<UpdateTaskConflictMutation>;
export type UpdateTaskConflictMutationOptions = Apollo.BaseMutationOptions<UpdateTaskConflictMutation, UpdateTaskConflictMutationVariables>;
export const ApproveCalendarBranchDocument = gql`
    mutation approveCalendarBranch($id: ID!) {
  approveCalendarBranch(id: $id) {
    ...CalendarBranchesGeneralInfoFields
  }
}
    ${CalendarBranchesGeneralInfoFieldsFragmentDoc}`;
export type ApproveCalendarBranchMutationFn = Apollo.MutationFunction<ApproveCalendarBranchMutation, ApproveCalendarBranchMutationVariables>;

/**
 * __useApproveCalendarBranchMutation__
 *
 * To run a mutation, you first call `useApproveCalendarBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCalendarBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCalendarBranchMutation, { data, loading, error }] = useApproveCalendarBranchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveCalendarBranchMutation(baseOptions?: Apollo.MutationHookOptions<ApproveCalendarBranchMutation, ApproveCalendarBranchMutationVariables>) {
        return Apollo.useMutation<ApproveCalendarBranchMutation, ApproveCalendarBranchMutationVariables>(ApproveCalendarBranchDocument, baseOptions);
      }
export type ApproveCalendarBranchMutationHookResult = ReturnType<typeof useApproveCalendarBranchMutation>;
export type ApproveCalendarBranchMutationResult = Apollo.MutationResult<ApproveCalendarBranchMutation>;
export type ApproveCalendarBranchMutationOptions = Apollo.BaseMutationOptions<ApproveCalendarBranchMutation, ApproveCalendarBranchMutationVariables>;
export const DeleteCalendarBranchDocument = gql`
    mutation deleteCalendarBranch($id: ID!) {
  deleteCalendarBranch(id: $id) {
    ... on Error {
      message
    }
    ... on SuccessOK {
      ok
    }
  }
}
    `;
export type DeleteCalendarBranchMutationFn = Apollo.MutationFunction<DeleteCalendarBranchMutation, DeleteCalendarBranchMutationVariables>;

/**
 * __useDeleteCalendarBranchMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarBranchMutation, { data, loading, error }] = useDeleteCalendarBranchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalendarBranchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCalendarBranchMutation, DeleteCalendarBranchMutationVariables>) {
        return Apollo.useMutation<DeleteCalendarBranchMutation, DeleteCalendarBranchMutationVariables>(DeleteCalendarBranchDocument, baseOptions);
      }
export type DeleteCalendarBranchMutationHookResult = ReturnType<typeof useDeleteCalendarBranchMutation>;
export type DeleteCalendarBranchMutationResult = Apollo.MutationResult<DeleteCalendarBranchMutation>;
export type DeleteCalendarBranchMutationOptions = Apollo.BaseMutationOptions<DeleteCalendarBranchMutation, DeleteCalendarBranchMutationVariables>;
export const StrainPerformanceDocument = gql`
    query strainPerformance($strainId1: String!, $strainId2: String!) {
  strainPerformance(strainId1: $strainId1, strainId2: $strainId2) {
    strain1 {
      id
      name
    }
    strainPerformance1 {
      id
      name
      avgGramsPerPlant
      harvests {
        finalPlantCount
        gramsPerPlant
        harvestDate
        id
        name
        totalGrams
        totalFlowerGrams
        byFormFactor {
          budFormFactorId
          numberOfGrams
        }
      }
    }
    strain2 {
      id
      name
    }
    strainPerformance2 {
      id
      name
      avgGramsPerPlant
      harvests {
        finalPlantCount
        gramsPerPlant
        harvestDate
        id
        name
        totalGrams
        totalFlowerGrams
        byFormFactor {
          budFormFactorId
          numberOfGrams
        }
      }
    }
    allStrains {
      id
      name
    }
    allBudFormFactors {
      ...BudFormFactorFields
    }
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;

/**
 * __useStrainPerformanceQuery__
 *
 * To run a query within a React component, call `useStrainPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrainPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrainPerformanceQuery({
 *   variables: {
 *      strainId1: // value for 'strainId1'
 *      strainId2: // value for 'strainId2'
 *   },
 * });
 */
export function useStrainPerformanceQuery(baseOptions?: Apollo.QueryHookOptions<StrainPerformanceQuery, StrainPerformanceQueryVariables>) {
        return Apollo.useQuery<StrainPerformanceQuery, StrainPerformanceQueryVariables>(StrainPerformanceDocument, baseOptions);
      }
export function useStrainPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrainPerformanceQuery, StrainPerformanceQueryVariables>) {
          return Apollo.useLazyQuery<StrainPerformanceQuery, StrainPerformanceQueryVariables>(StrainPerformanceDocument, baseOptions);
        }
export type StrainPerformanceQueryHookResult = ReturnType<typeof useStrainPerformanceQuery>;
export type StrainPerformanceLazyQueryHookResult = ReturnType<typeof useStrainPerformanceLazyQuery>;
export type StrainPerformanceQueryResult = Apollo.QueryResult<StrainPerformanceQuery, StrainPerformanceQueryVariables>;
export const LocationsDocument = gql`
    query locations {
  locations {
    id
    name
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const BudFormFactorsDocument = gql`
    query budFormFactors($filters: BudFormFactorFilterSetInput) {
  budFormFactors(filters: $filters) {
    ...BudFormFactorFields
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;

/**
 * __useBudFormFactorsQuery__
 *
 * To run a query within a React component, call `useBudFormFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudFormFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudFormFactorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBudFormFactorsQuery(baseOptions?: Apollo.QueryHookOptions<BudFormFactorsQuery, BudFormFactorsQueryVariables>) {
        return Apollo.useQuery<BudFormFactorsQuery, BudFormFactorsQueryVariables>(BudFormFactorsDocument, baseOptions);
      }
export function useBudFormFactorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudFormFactorsQuery, BudFormFactorsQueryVariables>) {
          return Apollo.useLazyQuery<BudFormFactorsQuery, BudFormFactorsQueryVariables>(BudFormFactorsDocument, baseOptions);
        }
export type BudFormFactorsQueryHookResult = ReturnType<typeof useBudFormFactorsQuery>;
export type BudFormFactorsLazyQueryHookResult = ReturnType<typeof useBudFormFactorsLazyQuery>;
export type BudFormFactorsQueryResult = Apollo.QueryResult<BudFormFactorsQuery, BudFormFactorsQueryVariables>;
export const CreateBudFormFactorDocument = gql`
    mutation createBudFormFactor($input: CreateBudFormFactorInput!) {
  createBudFormFactor(input: $input) {
    ... on BudFormFactor {
      ...BudFormFactorFields
    }
    ... on Error {
      message
    }
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;
export type CreateBudFormFactorMutationFn = Apollo.MutationFunction<CreateBudFormFactorMutation, CreateBudFormFactorMutationVariables>;

/**
 * __useCreateBudFormFactorMutation__
 *
 * To run a mutation, you first call `useCreateBudFormFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudFormFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudFormFactorMutation, { data, loading, error }] = useCreateBudFormFactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBudFormFactorMutation(baseOptions?: Apollo.MutationHookOptions<CreateBudFormFactorMutation, CreateBudFormFactorMutationVariables>) {
        return Apollo.useMutation<CreateBudFormFactorMutation, CreateBudFormFactorMutationVariables>(CreateBudFormFactorDocument, baseOptions);
      }
export type CreateBudFormFactorMutationHookResult = ReturnType<typeof useCreateBudFormFactorMutation>;
export type CreateBudFormFactorMutationResult = Apollo.MutationResult<CreateBudFormFactorMutation>;
export type CreateBudFormFactorMutationOptions = Apollo.BaseMutationOptions<CreateBudFormFactorMutation, CreateBudFormFactorMutationVariables>;
export const UpdateBudFormFactorDocument = gql`
    mutation updateBudFormFactor($input: UpdateBudFormFactorInput!) {
  updateBudFormFactor(input: $input) {
    ... on BudFormFactor {
      ...BudFormFactorFields
    }
    ... on Error {
      message
    }
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;
export type UpdateBudFormFactorMutationFn = Apollo.MutationFunction<UpdateBudFormFactorMutation, UpdateBudFormFactorMutationVariables>;

/**
 * __useUpdateBudFormFactorMutation__
 *
 * To run a mutation, you first call `useUpdateBudFormFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudFormFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudFormFactorMutation, { data, loading, error }] = useUpdateBudFormFactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBudFormFactorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudFormFactorMutation, UpdateBudFormFactorMutationVariables>) {
        return Apollo.useMutation<UpdateBudFormFactorMutation, UpdateBudFormFactorMutationVariables>(UpdateBudFormFactorDocument, baseOptions);
      }
export type UpdateBudFormFactorMutationHookResult = ReturnType<typeof useUpdateBudFormFactorMutation>;
export type UpdateBudFormFactorMutationResult = Apollo.MutationResult<UpdateBudFormFactorMutation>;
export type UpdateBudFormFactorMutationOptions = Apollo.BaseMutationOptions<UpdateBudFormFactorMutation, UpdateBudFormFactorMutationVariables>;
export const ReorderBudFormFactorsDocument = gql`
    mutation reorderBudFormFactors($input: ReorderBudFormFactorsInput!) {
  reorderBudFormFactors(input: $input) {
    ... on BudFormFactor {
      ...BudFormFactorFields
    }
    ... on Error {
      message
    }
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;
export type ReorderBudFormFactorsMutationFn = Apollo.MutationFunction<ReorderBudFormFactorsMutation, ReorderBudFormFactorsMutationVariables>;

/**
 * __useReorderBudFormFactorsMutation__
 *
 * To run a mutation, you first call `useReorderBudFormFactorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderBudFormFactorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderBudFormFactorsMutation, { data, loading, error }] = useReorderBudFormFactorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderBudFormFactorsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderBudFormFactorsMutation, ReorderBudFormFactorsMutationVariables>) {
        return Apollo.useMutation<ReorderBudFormFactorsMutation, ReorderBudFormFactorsMutationVariables>(ReorderBudFormFactorsDocument, baseOptions);
      }
export type ReorderBudFormFactorsMutationHookResult = ReturnType<typeof useReorderBudFormFactorsMutation>;
export type ReorderBudFormFactorsMutationResult = Apollo.MutationResult<ReorderBudFormFactorsMutation>;
export type ReorderBudFormFactorsMutationOptions = Apollo.BaseMutationOptions<ReorderBudFormFactorsMutation, ReorderBudFormFactorsMutationVariables>;
export const ActivateBudFormFactorDocument = gql`
    mutation activateBudFormFactor($id: ID!) {
  updateBudFormFactor(input: {active: true, id: $id}) {
    ... on BudFormFactor {
      ...BudFormFactorFields
    }
    ... on Error {
      message
    }
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;
export type ActivateBudFormFactorMutationFn = Apollo.MutationFunction<ActivateBudFormFactorMutation, ActivateBudFormFactorMutationVariables>;

/**
 * __useActivateBudFormFactorMutation__
 *
 * To run a mutation, you first call `useActivateBudFormFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBudFormFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBudFormFactorMutation, { data, loading, error }] = useActivateBudFormFactorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateBudFormFactorMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBudFormFactorMutation, ActivateBudFormFactorMutationVariables>) {
        return Apollo.useMutation<ActivateBudFormFactorMutation, ActivateBudFormFactorMutationVariables>(ActivateBudFormFactorDocument, baseOptions);
      }
export type ActivateBudFormFactorMutationHookResult = ReturnType<typeof useActivateBudFormFactorMutation>;
export type ActivateBudFormFactorMutationResult = Apollo.MutationResult<ActivateBudFormFactorMutation>;
export type ActivateBudFormFactorMutationOptions = Apollo.BaseMutationOptions<ActivateBudFormFactorMutation, ActivateBudFormFactorMutationVariables>;
export const DeactivateBudFormFactorDocument = gql`
    mutation deactivateBudFormFactor($id: ID!) {
  updateBudFormFactor(input: {active: false, id: $id}) {
    ... on BudFormFactor {
      ...BudFormFactorFields
    }
    ... on Error {
      message
    }
  }
}
    ${BudFormFactorFieldsFragmentDoc}`;
export type DeactivateBudFormFactorMutationFn = Apollo.MutationFunction<DeactivateBudFormFactorMutation, DeactivateBudFormFactorMutationVariables>;

/**
 * __useDeactivateBudFormFactorMutation__
 *
 * To run a mutation, you first call `useDeactivateBudFormFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateBudFormFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateBudFormFactorMutation, { data, loading, error }] = useDeactivateBudFormFactorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateBudFormFactorMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateBudFormFactorMutation, DeactivateBudFormFactorMutationVariables>) {
        return Apollo.useMutation<DeactivateBudFormFactorMutation, DeactivateBudFormFactorMutationVariables>(DeactivateBudFormFactorDocument, baseOptions);
      }
export type DeactivateBudFormFactorMutationHookResult = ReturnType<typeof useDeactivateBudFormFactorMutation>;
export type DeactivateBudFormFactorMutationResult = Apollo.MutationResult<DeactivateBudFormFactorMutation>;
export type DeactivateBudFormFactorMutationOptions = Apollo.BaseMutationOptions<DeactivateBudFormFactorMutation, DeactivateBudFormFactorMutationVariables>;
export const UploadEnvironmentalFileDocument = gql`
    mutation uploadEnvironmentalFile($input: EnvironmentalFileInput!) {
  uploadEnvironmentalFile(input: $input) {
    ... on EnvironmentalFileType {
      ...EnvironmentalFileFields
    }
    ... on Error {
      message
    }
  }
}
    ${EnvironmentalFileFieldsFragmentDoc}`;
export type UploadEnvironmentalFileMutationFn = Apollo.MutationFunction<UploadEnvironmentalFileMutation, UploadEnvironmentalFileMutationVariables>;

/**
 * __useUploadEnvironmentalFileMutation__
 *
 * To run a mutation, you first call `useUploadEnvironmentalFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEnvironmentalFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEnvironmentalFileMutation, { data, loading, error }] = useUploadEnvironmentalFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadEnvironmentalFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadEnvironmentalFileMutation, UploadEnvironmentalFileMutationVariables>) {
        return Apollo.useMutation<UploadEnvironmentalFileMutation, UploadEnvironmentalFileMutationVariables>(UploadEnvironmentalFileDocument, baseOptions);
      }
export type UploadEnvironmentalFileMutationHookResult = ReturnType<typeof useUploadEnvironmentalFileMutation>;
export type UploadEnvironmentalFileMutationResult = Apollo.MutationResult<UploadEnvironmentalFileMutation>;
export type UploadEnvironmentalFileMutationOptions = Apollo.BaseMutationOptions<UploadEnvironmentalFileMutation, UploadEnvironmentalFileMutationVariables>;
export const UpdateTimeOffsetDocument = gql`
    mutation updateTimeOffset($input: UpdateTimeOffsetInput!) {
  updateTimeOffset(input: $input) {
    ... on EnvironmentalFileType {
      ...EnvironmentalFileFields
    }
    ... on Error {
      message
    }
  }
}
    ${EnvironmentalFileFieldsFragmentDoc}`;
export type UpdateTimeOffsetMutationFn = Apollo.MutationFunction<UpdateTimeOffsetMutation, UpdateTimeOffsetMutationVariables>;

/**
 * __useUpdateTimeOffsetMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffsetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffsetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffsetMutation, { data, loading, error }] = useUpdateTimeOffsetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeOffsetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffsetMutation, UpdateTimeOffsetMutationVariables>) {
        return Apollo.useMutation<UpdateTimeOffsetMutation, UpdateTimeOffsetMutationVariables>(UpdateTimeOffsetDocument, baseOptions);
      }
export type UpdateTimeOffsetMutationHookResult = ReturnType<typeof useUpdateTimeOffsetMutation>;
export type UpdateTimeOffsetMutationResult = Apollo.MutationResult<UpdateTimeOffsetMutation>;
export type UpdateTimeOffsetMutationOptions = Apollo.BaseMutationOptions<UpdateTimeOffsetMutation, UpdateTimeOffsetMutationVariables>;
export const ProcessEnvironmentalFileDocument = gql`
    mutation processEnvironmentalFile($input: ProcessEnvironmentalFileInput!) {
  processEnvironmentalFile(input: $input) {
    ... on EnvironmentalFileType {
      ...EnvironmentalFileFields
    }
    ... on Error {
      message
    }
  }
}
    ${EnvironmentalFileFieldsFragmentDoc}`;
export type ProcessEnvironmentalFileMutationFn = Apollo.MutationFunction<ProcessEnvironmentalFileMutation, ProcessEnvironmentalFileMutationVariables>;

/**
 * __useProcessEnvironmentalFileMutation__
 *
 * To run a mutation, you first call `useProcessEnvironmentalFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessEnvironmentalFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processEnvironmentalFileMutation, { data, loading, error }] = useProcessEnvironmentalFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessEnvironmentalFileMutation(baseOptions?: Apollo.MutationHookOptions<ProcessEnvironmentalFileMutation, ProcessEnvironmentalFileMutationVariables>) {
        return Apollo.useMutation<ProcessEnvironmentalFileMutation, ProcessEnvironmentalFileMutationVariables>(ProcessEnvironmentalFileDocument, baseOptions);
      }
export type ProcessEnvironmentalFileMutationHookResult = ReturnType<typeof useProcessEnvironmentalFileMutation>;
export type ProcessEnvironmentalFileMutationResult = Apollo.MutationResult<ProcessEnvironmentalFileMutation>;
export type ProcessEnvironmentalFileMutationOptions = Apollo.BaseMutationOptions<ProcessEnvironmentalFileMutation, ProcessEnvironmentalFileMutationVariables>;
export const EnvironmentalFilesDocument = gql`
    query environmentalFiles($filters: EnvironmentalFileFilterSetInput) {
  environmentalFiles(filters: $filters) {
    ...EnvironmentalFileFields
  }
}
    ${EnvironmentalFileFieldsFragmentDoc}`;

/**
 * __useEnvironmentalFilesQuery__
 *
 * To run a query within a React component, call `useEnvironmentalFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentalFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentalFilesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEnvironmentalFilesQuery(baseOptions?: Apollo.QueryHookOptions<EnvironmentalFilesQuery, EnvironmentalFilesQueryVariables>) {
        return Apollo.useQuery<EnvironmentalFilesQuery, EnvironmentalFilesQueryVariables>(EnvironmentalFilesDocument, baseOptions);
      }
export function useEnvironmentalFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentalFilesQuery, EnvironmentalFilesQueryVariables>) {
          return Apollo.useLazyQuery<EnvironmentalFilesQuery, EnvironmentalFilesQueryVariables>(EnvironmentalFilesDocument, baseOptions);
        }
export type EnvironmentalFilesQueryHookResult = ReturnType<typeof useEnvironmentalFilesQuery>;
export type EnvironmentalFilesLazyQueryHookResult = ReturnType<typeof useEnvironmentalFilesLazyQuery>;
export type EnvironmentalFilesQueryResult = Apollo.QueryResult<EnvironmentalFilesQuery, EnvironmentalFilesQueryVariables>;
export const EnvironmentalFileDocument = gql`
    query environmentalFile($id: String!) {
  environmentalFile(id: $id) {
    ...PreviewFileFields
  }
}
    ${PreviewFileFieldsFragmentDoc}`;

/**
 * __useEnvironmentalFileQuery__
 *
 * To run a query within a React component, call `useEnvironmentalFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentalFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentalFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnvironmentalFileQuery(baseOptions?: Apollo.QueryHookOptions<EnvironmentalFileQuery, EnvironmentalFileQueryVariables>) {
        return Apollo.useQuery<EnvironmentalFileQuery, EnvironmentalFileQueryVariables>(EnvironmentalFileDocument, baseOptions);
      }
export function useEnvironmentalFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentalFileQuery, EnvironmentalFileQueryVariables>) {
          return Apollo.useLazyQuery<EnvironmentalFileQuery, EnvironmentalFileQueryVariables>(EnvironmentalFileDocument, baseOptions);
        }
export type EnvironmentalFileQueryHookResult = ReturnType<typeof useEnvironmentalFileQuery>;
export type EnvironmentalFileLazyQueryHookResult = ReturnType<typeof useEnvironmentalFileLazyQuery>;
export type EnvironmentalFileQueryResult = Apollo.QueryResult<EnvironmentalFileQuery, EnvironmentalFileQueryVariables>;
export const EnvironmentalAnalyticsDocument = gql`
    query environmentalAnalytics($filters: EnvironmentalAnalyticsFilterInput) {
  environmentalAnalytics(filters: $filters) {
    temperature {
      ...AnalyticsChartData
    }
    humidity {
      ...AnalyticsChartData
    }
    co2 {
      ...AnalyticsChartData
    }
    vpd {
      ...AnalyticsChartData
    }
  }
}
    ${AnalyticsChartDataFragmentDoc}`;

/**
 * __useEnvironmentalAnalyticsQuery__
 *
 * To run a query within a React component, call `useEnvironmentalAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentalAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentalAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEnvironmentalAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<EnvironmentalAnalyticsQuery, EnvironmentalAnalyticsQueryVariables>) {
        return Apollo.useQuery<EnvironmentalAnalyticsQuery, EnvironmentalAnalyticsQueryVariables>(EnvironmentalAnalyticsDocument, baseOptions);
      }
export function useEnvironmentalAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentalAnalyticsQuery, EnvironmentalAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<EnvironmentalAnalyticsQuery, EnvironmentalAnalyticsQueryVariables>(EnvironmentalAnalyticsDocument, baseOptions);
        }
export type EnvironmentalAnalyticsQueryHookResult = ReturnType<typeof useEnvironmentalAnalyticsQuery>;
export type EnvironmentalAnalyticsLazyQueryHookResult = ReturnType<typeof useEnvironmentalAnalyticsLazyQuery>;
export type EnvironmentalAnalyticsQueryResult = Apollo.QueryResult<EnvironmentalAnalyticsQuery, EnvironmentalAnalyticsQueryVariables>;
export const CurrentAndUpcomingHarvestGroupsDocument = gql`
    query currentAndUpcomingHarvestGroups($unarchived: String) {
  harvestGroups(filters: {unarchived: $unarchived, completed: "false"}) {
    ...HarvestGroupFields
    ...HarvestGroupOverviewFields
    ...HarvestGroupCurrentStageFields
  }
}
    ${HarvestGroupFieldsFragmentDoc}
${HarvestGroupOverviewFieldsFragmentDoc}
${HarvestGroupCurrentStageFieldsFragmentDoc}`;

/**
 * __useCurrentAndUpcomingHarvestGroupsQuery__
 *
 * To run a query within a React component, call `useCurrentAndUpcomingHarvestGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAndUpcomingHarvestGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAndUpcomingHarvestGroupsQuery({
 *   variables: {
 *      unarchived: // value for 'unarchived'
 *   },
 * });
 */
export function useCurrentAndUpcomingHarvestGroupsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAndUpcomingHarvestGroupsQuery, CurrentAndUpcomingHarvestGroupsQueryVariables>) {
        return Apollo.useQuery<CurrentAndUpcomingHarvestGroupsQuery, CurrentAndUpcomingHarvestGroupsQueryVariables>(CurrentAndUpcomingHarvestGroupsDocument, baseOptions);
      }
export function useCurrentAndUpcomingHarvestGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAndUpcomingHarvestGroupsQuery, CurrentAndUpcomingHarvestGroupsQueryVariables>) {
          return Apollo.useLazyQuery<CurrentAndUpcomingHarvestGroupsQuery, CurrentAndUpcomingHarvestGroupsQueryVariables>(CurrentAndUpcomingHarvestGroupsDocument, baseOptions);
        }
export type CurrentAndUpcomingHarvestGroupsQueryHookResult = ReturnType<typeof useCurrentAndUpcomingHarvestGroupsQuery>;
export type CurrentAndUpcomingHarvestGroupsLazyQueryHookResult = ReturnType<typeof useCurrentAndUpcomingHarvestGroupsLazyQuery>;
export type CurrentAndUpcomingHarvestGroupsQueryResult = Apollo.QueryResult<CurrentAndUpcomingHarvestGroupsQuery, CurrentAndUpcomingHarvestGroupsQueryVariables>;
export const CurrentAndUpcomingHarvestGroupsForSelectDocument = gql`
    query currentAndUpcomingHarvestGroupsForSelect($filters: HarvestGroupFilterSetInput) {
  harvestGroups(filters: $filters) {
    name
    id
    colorHexCode
  }
}
    `;

/**
 * __useCurrentAndUpcomingHarvestGroupsForSelectQuery__
 *
 * To run a query within a React component, call `useCurrentAndUpcomingHarvestGroupsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAndUpcomingHarvestGroupsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAndUpcomingHarvestGroupsForSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCurrentAndUpcomingHarvestGroupsForSelectQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAndUpcomingHarvestGroupsForSelectQuery, CurrentAndUpcomingHarvestGroupsForSelectQueryVariables>) {
        return Apollo.useQuery<CurrentAndUpcomingHarvestGroupsForSelectQuery, CurrentAndUpcomingHarvestGroupsForSelectQueryVariables>(CurrentAndUpcomingHarvestGroupsForSelectDocument, baseOptions);
      }
export function useCurrentAndUpcomingHarvestGroupsForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAndUpcomingHarvestGroupsForSelectQuery, CurrentAndUpcomingHarvestGroupsForSelectQueryVariables>) {
          return Apollo.useLazyQuery<CurrentAndUpcomingHarvestGroupsForSelectQuery, CurrentAndUpcomingHarvestGroupsForSelectQueryVariables>(CurrentAndUpcomingHarvestGroupsForSelectDocument, baseOptions);
        }
export type CurrentAndUpcomingHarvestGroupsForSelectQueryHookResult = ReturnType<typeof useCurrentAndUpcomingHarvestGroupsForSelectQuery>;
export type CurrentAndUpcomingHarvestGroupsForSelectLazyQueryHookResult = ReturnType<typeof useCurrentAndUpcomingHarvestGroupsForSelectLazyQuery>;
export type CurrentAndUpcomingHarvestGroupsForSelectQueryResult = Apollo.QueryResult<CurrentAndUpcomingHarvestGroupsForSelectQuery, CurrentAndUpcomingHarvestGroupsForSelectQueryVariables>;
export const CompletedHarvestGroupsPaginatedDocument = gql`
    query completedHarvestGroupsPaginated($options: HarvestGroupQuerysetOptionsInput) {
  completedHarvestGroupsPaginated(options: $options) {
    ...CompletedHarvestGroupsPaginatedResult
  }
}
    ${CompletedHarvestGroupsPaginatedResultFragmentDoc}`;

/**
 * __useCompletedHarvestGroupsPaginatedQuery__
 *
 * To run a query within a React component, call `useCompletedHarvestGroupsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedHarvestGroupsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedHarvestGroupsPaginatedQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCompletedHarvestGroupsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<CompletedHarvestGroupsPaginatedQuery, CompletedHarvestGroupsPaginatedQueryVariables>) {
        return Apollo.useQuery<CompletedHarvestGroupsPaginatedQuery, CompletedHarvestGroupsPaginatedQueryVariables>(CompletedHarvestGroupsPaginatedDocument, baseOptions);
      }
export function useCompletedHarvestGroupsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedHarvestGroupsPaginatedQuery, CompletedHarvestGroupsPaginatedQueryVariables>) {
          return Apollo.useLazyQuery<CompletedHarvestGroupsPaginatedQuery, CompletedHarvestGroupsPaginatedQueryVariables>(CompletedHarvestGroupsPaginatedDocument, baseOptions);
        }
export type CompletedHarvestGroupsPaginatedQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsPaginatedQuery>;
export type CompletedHarvestGroupsPaginatedLazyQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsPaginatedLazyQuery>;
export type CompletedHarvestGroupsPaginatedQueryResult = Apollo.QueryResult<CompletedHarvestGroupsPaginatedQuery, CompletedHarvestGroupsPaginatedQueryVariables>;
export const CompletedHarvestGroupsDocument = gql`
    query completedHarvestGroups($unarchived: String) {
  harvestGroups(filters: {unarchived: $unarchived, completed: "true"}) {
    ...CompletedHarvestGroupFields
  }
}
    ${CompletedHarvestGroupFieldsFragmentDoc}`;

/**
 * __useCompletedHarvestGroupsQuery__
 *
 * To run a query within a React component, call `useCompletedHarvestGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedHarvestGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedHarvestGroupsQuery({
 *   variables: {
 *      unarchived: // value for 'unarchived'
 *   },
 * });
 */
export function useCompletedHarvestGroupsQuery(baseOptions?: Apollo.QueryHookOptions<CompletedHarvestGroupsQuery, CompletedHarvestGroupsQueryVariables>) {
        return Apollo.useQuery<CompletedHarvestGroupsQuery, CompletedHarvestGroupsQueryVariables>(CompletedHarvestGroupsDocument, baseOptions);
      }
export function useCompletedHarvestGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedHarvestGroupsQuery, CompletedHarvestGroupsQueryVariables>) {
          return Apollo.useLazyQuery<CompletedHarvestGroupsQuery, CompletedHarvestGroupsQueryVariables>(CompletedHarvestGroupsDocument, baseOptions);
        }
export type CompletedHarvestGroupsQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsQuery>;
export type CompletedHarvestGroupsLazyQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsLazyQuery>;
export type CompletedHarvestGroupsQueryResult = Apollo.QueryResult<CompletedHarvestGroupsQuery, CompletedHarvestGroupsQueryVariables>;
export const CompletedHarvestGroupsNamesDocument = gql`
    query completedHarvestGroupsNames($unarchived: String) {
  harvestGroups(filters: {unarchived: $unarchived, completed: "true"}) {
    id
    name
    harvestDate
  }
}
    `;

/**
 * __useCompletedHarvestGroupsNamesQuery__
 *
 * To run a query within a React component, call `useCompletedHarvestGroupsNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedHarvestGroupsNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedHarvestGroupsNamesQuery({
 *   variables: {
 *      unarchived: // value for 'unarchived'
 *   },
 * });
 */
export function useCompletedHarvestGroupsNamesQuery(baseOptions?: Apollo.QueryHookOptions<CompletedHarvestGroupsNamesQuery, CompletedHarvestGroupsNamesQueryVariables>) {
        return Apollo.useQuery<CompletedHarvestGroupsNamesQuery, CompletedHarvestGroupsNamesQueryVariables>(CompletedHarvestGroupsNamesDocument, baseOptions);
      }
export function useCompletedHarvestGroupsNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedHarvestGroupsNamesQuery, CompletedHarvestGroupsNamesQueryVariables>) {
          return Apollo.useLazyQuery<CompletedHarvestGroupsNamesQuery, CompletedHarvestGroupsNamesQueryVariables>(CompletedHarvestGroupsNamesDocument, baseOptions);
        }
export type CompletedHarvestGroupsNamesQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsNamesQuery>;
export type CompletedHarvestGroupsNamesLazyQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsNamesLazyQuery>;
export type CompletedHarvestGroupsNamesQueryResult = Apollo.QueryResult<CompletedHarvestGroupsNamesQuery, CompletedHarvestGroupsNamesQueryVariables>;
export const CompletedHarvestGroupsListDocument = gql`
    query completedHarvestGroupsList($unarchived: String) {
  harvestGroups(filters: {unarchived: $unarchived, completed: "true"}) {
    id
    name
    startDate
    harvestDate
    archivedAt
    zoneIteration
    strains {
      ...StrainReportFields
    }
    flowerZone {
      ...ZoneIdAndName
    }
  }
}
    ${StrainReportFieldsFragmentDoc}
${ZoneIdAndNameFragmentDoc}`;

/**
 * __useCompletedHarvestGroupsListQuery__
 *
 * To run a query within a React component, call `useCompletedHarvestGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedHarvestGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedHarvestGroupsListQuery({
 *   variables: {
 *      unarchived: // value for 'unarchived'
 *   },
 * });
 */
export function useCompletedHarvestGroupsListQuery(baseOptions?: Apollo.QueryHookOptions<CompletedHarvestGroupsListQuery, CompletedHarvestGroupsListQueryVariables>) {
        return Apollo.useQuery<CompletedHarvestGroupsListQuery, CompletedHarvestGroupsListQueryVariables>(CompletedHarvestGroupsListDocument, baseOptions);
      }
export function useCompletedHarvestGroupsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedHarvestGroupsListQuery, CompletedHarvestGroupsListQueryVariables>) {
          return Apollo.useLazyQuery<CompletedHarvestGroupsListQuery, CompletedHarvestGroupsListQueryVariables>(CompletedHarvestGroupsListDocument, baseOptions);
        }
export type CompletedHarvestGroupsListQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsListQuery>;
export type CompletedHarvestGroupsListLazyQueryHookResult = ReturnType<typeof useCompletedHarvestGroupsListLazyQuery>;
export type CompletedHarvestGroupsListQueryResult = Apollo.QueryResult<CompletedHarvestGroupsListQuery, CompletedHarvestGroupsListQueryVariables>;
export const CompletedHarvestGroupDocument = gql`
    query completedHarvestGroup($id: String!) {
  harvestGroup(id: $id) {
    ...CompletedHarvestGroupFields
  }
}
    ${CompletedHarvestGroupFieldsFragmentDoc}`;

/**
 * __useCompletedHarvestGroupQuery__
 *
 * To run a query within a React component, call `useCompletedHarvestGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedHarvestGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedHarvestGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletedHarvestGroupQuery(baseOptions?: Apollo.QueryHookOptions<CompletedHarvestGroupQuery, CompletedHarvestGroupQueryVariables>) {
        return Apollo.useQuery<CompletedHarvestGroupQuery, CompletedHarvestGroupQueryVariables>(CompletedHarvestGroupDocument, baseOptions);
      }
export function useCompletedHarvestGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedHarvestGroupQuery, CompletedHarvestGroupQueryVariables>) {
          return Apollo.useLazyQuery<CompletedHarvestGroupQuery, CompletedHarvestGroupQueryVariables>(CompletedHarvestGroupDocument, baseOptions);
        }
export type CompletedHarvestGroupQueryHookResult = ReturnType<typeof useCompletedHarvestGroupQuery>;
export type CompletedHarvestGroupLazyQueryHookResult = ReturnType<typeof useCompletedHarvestGroupLazyQuery>;
export type CompletedHarvestGroupQueryResult = Apollo.QueryResult<CompletedHarvestGroupQuery, CompletedHarvestGroupQueryVariables>;
export const AnalyticsHarvestGroupsDocument = gql`
    query analyticsHarvestGroups($unarchived: String) {
  harvestGroups(filters: {unarchived: $unarchived, completed: "true"}) {
    ...AnalyticsHarvestGroupFields
  }
}
    ${AnalyticsHarvestGroupFieldsFragmentDoc}`;

/**
 * __useAnalyticsHarvestGroupsQuery__
 *
 * To run a query within a React component, call `useAnalyticsHarvestGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsHarvestGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsHarvestGroupsQuery({
 *   variables: {
 *      unarchived: // value for 'unarchived'
 *   },
 * });
 */
export function useAnalyticsHarvestGroupsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsHarvestGroupsQuery, AnalyticsHarvestGroupsQueryVariables>) {
        return Apollo.useQuery<AnalyticsHarvestGroupsQuery, AnalyticsHarvestGroupsQueryVariables>(AnalyticsHarvestGroupsDocument, baseOptions);
      }
export function useAnalyticsHarvestGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsHarvestGroupsQuery, AnalyticsHarvestGroupsQueryVariables>) {
          return Apollo.useLazyQuery<AnalyticsHarvestGroupsQuery, AnalyticsHarvestGroupsQueryVariables>(AnalyticsHarvestGroupsDocument, baseOptions);
        }
export type AnalyticsHarvestGroupsQueryHookResult = ReturnType<typeof useAnalyticsHarvestGroupsQuery>;
export type AnalyticsHarvestGroupsLazyQueryHookResult = ReturnType<typeof useAnalyticsHarvestGroupsLazyQuery>;
export type AnalyticsHarvestGroupsQueryResult = Apollo.QueryResult<AnalyticsHarvestGroupsQuery, AnalyticsHarvestGroupsQueryVariables>;
export const AnalyticsHarvestGroupsOverviewDocument = gql`
    query analyticsHarvestGroupsOverview($unarchived: String) {
  harvestGroups(filters: {unarchived: $unarchived, completed: "true"}) {
    ...AnalyticsHarvestGroupsOverview
  }
}
    ${AnalyticsHarvestGroupsOverviewFragmentDoc}`;

/**
 * __useAnalyticsHarvestGroupsOverviewQuery__
 *
 * To run a query within a React component, call `useAnalyticsHarvestGroupsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsHarvestGroupsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsHarvestGroupsOverviewQuery({
 *   variables: {
 *      unarchived: // value for 'unarchived'
 *   },
 * });
 */
export function useAnalyticsHarvestGroupsOverviewQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsHarvestGroupsOverviewQuery, AnalyticsHarvestGroupsOverviewQueryVariables>) {
        return Apollo.useQuery<AnalyticsHarvestGroupsOverviewQuery, AnalyticsHarvestGroupsOverviewQueryVariables>(AnalyticsHarvestGroupsOverviewDocument, baseOptions);
      }
export function useAnalyticsHarvestGroupsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsHarvestGroupsOverviewQuery, AnalyticsHarvestGroupsOverviewQueryVariables>) {
          return Apollo.useLazyQuery<AnalyticsHarvestGroupsOverviewQuery, AnalyticsHarvestGroupsOverviewQueryVariables>(AnalyticsHarvestGroupsOverviewDocument, baseOptions);
        }
export type AnalyticsHarvestGroupsOverviewQueryHookResult = ReturnType<typeof useAnalyticsHarvestGroupsOverviewQuery>;
export type AnalyticsHarvestGroupsOverviewLazyQueryHookResult = ReturnType<typeof useAnalyticsHarvestGroupsOverviewLazyQuery>;
export type AnalyticsHarvestGroupsOverviewQueryResult = Apollo.QueryResult<AnalyticsHarvestGroupsOverviewQuery, AnalyticsHarvestGroupsOverviewQueryVariables>;
export const CompletedHarvestGroupWithLogsDocument = gql`
    query completedHarvestGroupWithLogs($id: String!) {
  harvestGroup(id: $id) {
    ...CompletedHarvestGroupWithLogsFields
  }
}
    ${CompletedHarvestGroupWithLogsFieldsFragmentDoc}`;

/**
 * __useCompletedHarvestGroupWithLogsQuery__
 *
 * To run a query within a React component, call `useCompletedHarvestGroupWithLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedHarvestGroupWithLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedHarvestGroupWithLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletedHarvestGroupWithLogsQuery(baseOptions?: Apollo.QueryHookOptions<CompletedHarvestGroupWithLogsQuery, CompletedHarvestGroupWithLogsQueryVariables>) {
        return Apollo.useQuery<CompletedHarvestGroupWithLogsQuery, CompletedHarvestGroupWithLogsQueryVariables>(CompletedHarvestGroupWithLogsDocument, baseOptions);
      }
export function useCompletedHarvestGroupWithLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedHarvestGroupWithLogsQuery, CompletedHarvestGroupWithLogsQueryVariables>) {
          return Apollo.useLazyQuery<CompletedHarvestGroupWithLogsQuery, CompletedHarvestGroupWithLogsQueryVariables>(CompletedHarvestGroupWithLogsDocument, baseOptions);
        }
export type CompletedHarvestGroupWithLogsQueryHookResult = ReturnType<typeof useCompletedHarvestGroupWithLogsQuery>;
export type CompletedHarvestGroupWithLogsLazyQueryHookResult = ReturnType<typeof useCompletedHarvestGroupWithLogsLazyQuery>;
export type CompletedHarvestGroupWithLogsQueryResult = Apollo.QueryResult<CompletedHarvestGroupWithLogsQuery, CompletedHarvestGroupWithLogsQueryVariables>;
export const HarvestReportComparisonDocument = gql`
    query harvestReportComparison($id: String!) {
  harvestGroup(id: $id) {
    ...HarvestGroupReportComparisonFields
  }
}
    ${HarvestGroupReportComparisonFieldsFragmentDoc}`;

/**
 * __useHarvestReportComparisonQuery__
 *
 * To run a query within a React component, call `useHarvestReportComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestReportComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestReportComparisonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHarvestReportComparisonQuery(baseOptions?: Apollo.QueryHookOptions<HarvestReportComparisonQuery, HarvestReportComparisonQueryVariables>) {
        return Apollo.useQuery<HarvestReportComparisonQuery, HarvestReportComparisonQueryVariables>(HarvestReportComparisonDocument, baseOptions);
      }
export function useHarvestReportComparisonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestReportComparisonQuery, HarvestReportComparisonQueryVariables>) {
          return Apollo.useLazyQuery<HarvestReportComparisonQuery, HarvestReportComparisonQueryVariables>(HarvestReportComparisonDocument, baseOptions);
        }
export type HarvestReportComparisonQueryHookResult = ReturnType<typeof useHarvestReportComparisonQuery>;
export type HarvestReportComparisonLazyQueryHookResult = ReturnType<typeof useHarvestReportComparisonLazyQuery>;
export type HarvestReportComparisonQueryResult = Apollo.QueryResult<HarvestReportComparisonQuery, HarvestReportComparisonQueryVariables>;
export const HarvestGroupDocument = gql`
    query harvestGroup($id: String!) {
  harvestGroup(id: $id) {
    ...HarvestGroupFields
  }
}
    ${HarvestGroupFieldsFragmentDoc}`;

/**
 * __useHarvestGroupQuery__
 *
 * To run a query within a React component, call `useHarvestGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHarvestGroupQuery(baseOptions?: Apollo.QueryHookOptions<HarvestGroupQuery, HarvestGroupQueryVariables>) {
        return Apollo.useQuery<HarvestGroupQuery, HarvestGroupQueryVariables>(HarvestGroupDocument, baseOptions);
      }
export function useHarvestGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestGroupQuery, HarvestGroupQueryVariables>) {
          return Apollo.useLazyQuery<HarvestGroupQuery, HarvestGroupQueryVariables>(HarvestGroupDocument, baseOptions);
        }
export type HarvestGroupQueryHookResult = ReturnType<typeof useHarvestGroupQuery>;
export type HarvestGroupLazyQueryHookResult = ReturnType<typeof useHarvestGroupLazyQuery>;
export type HarvestGroupQueryResult = Apollo.QueryResult<HarvestGroupQuery, HarvestGroupQueryVariables>;
export const HarvestGroupWithJustLogEntriesAndCompletionDocument = gql`
    query harvestGroupWithJustLogEntriesAndCompletion($id: String!) {
  harvestGroup(id: $id) {
    id
    name
    colorHexCode
    logEntries {
      ...LogEntryFields
    }
    harvestCompletion {
      cosignedAt
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;

/**
 * __useHarvestGroupWithJustLogEntriesAndCompletionQuery__
 *
 * To run a query within a React component, call `useHarvestGroupWithJustLogEntriesAndCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestGroupWithJustLogEntriesAndCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestGroupWithJustLogEntriesAndCompletionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHarvestGroupWithJustLogEntriesAndCompletionQuery(baseOptions?: Apollo.QueryHookOptions<HarvestGroupWithJustLogEntriesAndCompletionQuery, HarvestGroupWithJustLogEntriesAndCompletionQueryVariables>) {
        return Apollo.useQuery<HarvestGroupWithJustLogEntriesAndCompletionQuery, HarvestGroupWithJustLogEntriesAndCompletionQueryVariables>(HarvestGroupWithJustLogEntriesAndCompletionDocument, baseOptions);
      }
export function useHarvestGroupWithJustLogEntriesAndCompletionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestGroupWithJustLogEntriesAndCompletionQuery, HarvestGroupWithJustLogEntriesAndCompletionQueryVariables>) {
          return Apollo.useLazyQuery<HarvestGroupWithJustLogEntriesAndCompletionQuery, HarvestGroupWithJustLogEntriesAndCompletionQueryVariables>(HarvestGroupWithJustLogEntriesAndCompletionDocument, baseOptions);
        }
export type HarvestGroupWithJustLogEntriesAndCompletionQueryHookResult = ReturnType<typeof useHarvestGroupWithJustLogEntriesAndCompletionQuery>;
export type HarvestGroupWithJustLogEntriesAndCompletionLazyQueryHookResult = ReturnType<typeof useHarvestGroupWithJustLogEntriesAndCompletionLazyQuery>;
export type HarvestGroupWithJustLogEntriesAndCompletionQueryResult = Apollo.QueryResult<HarvestGroupWithJustLogEntriesAndCompletionQuery, HarvestGroupWithJustLogEntriesAndCompletionQueryVariables>;
export const AllHarvestGroupsWithLogEntriesDocument = gql`
    query allHarvestGroupsWithLogEntries($logEntryOptions: LogsFilterInput) {
  harvestGroups(filters: {unarchived: "true"}, logEntryOptions: $logEntryOptions) {
    ...HarvestGroupWithLogEntries
  }
}
    ${HarvestGroupWithLogEntriesFragmentDoc}`;

/**
 * __useAllHarvestGroupsWithLogEntriesQuery__
 *
 * To run a query within a React component, call `useAllHarvestGroupsWithLogEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllHarvestGroupsWithLogEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllHarvestGroupsWithLogEntriesQuery({
 *   variables: {
 *      logEntryOptions: // value for 'logEntryOptions'
 *   },
 * });
 */
export function useAllHarvestGroupsWithLogEntriesQuery(baseOptions?: Apollo.QueryHookOptions<AllHarvestGroupsWithLogEntriesQuery, AllHarvestGroupsWithLogEntriesQueryVariables>) {
        return Apollo.useQuery<AllHarvestGroupsWithLogEntriesQuery, AllHarvestGroupsWithLogEntriesQueryVariables>(AllHarvestGroupsWithLogEntriesDocument, baseOptions);
      }
export function useAllHarvestGroupsWithLogEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllHarvestGroupsWithLogEntriesQuery, AllHarvestGroupsWithLogEntriesQueryVariables>) {
          return Apollo.useLazyQuery<AllHarvestGroupsWithLogEntriesQuery, AllHarvestGroupsWithLogEntriesQueryVariables>(AllHarvestGroupsWithLogEntriesDocument, baseOptions);
        }
export type AllHarvestGroupsWithLogEntriesQueryHookResult = ReturnType<typeof useAllHarvestGroupsWithLogEntriesQuery>;
export type AllHarvestGroupsWithLogEntriesLazyQueryHookResult = ReturnType<typeof useAllHarvestGroupsWithLogEntriesLazyQuery>;
export type AllHarvestGroupsWithLogEntriesQueryResult = Apollo.QueryResult<AllHarvestGroupsWithLogEntriesQuery, AllHarvestGroupsWithLogEntriesQueryVariables>;
export const AllHarvestGroupsShortOverviewDocument = gql`
    query allHarvestGroupsShortOverview {
  harvestGroups(filters: {unarchived: "true"}) {
    ...HarvestGroupShortOverviewFields
  }
}
    ${HarvestGroupShortOverviewFieldsFragmentDoc}`;

/**
 * __useAllHarvestGroupsShortOverviewQuery__
 *
 * To run a query within a React component, call `useAllHarvestGroupsShortOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllHarvestGroupsShortOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllHarvestGroupsShortOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllHarvestGroupsShortOverviewQuery(baseOptions?: Apollo.QueryHookOptions<AllHarvestGroupsShortOverviewQuery, AllHarvestGroupsShortOverviewQueryVariables>) {
        return Apollo.useQuery<AllHarvestGroupsShortOverviewQuery, AllHarvestGroupsShortOverviewQueryVariables>(AllHarvestGroupsShortOverviewDocument, baseOptions);
      }
export function useAllHarvestGroupsShortOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllHarvestGroupsShortOverviewQuery, AllHarvestGroupsShortOverviewQueryVariables>) {
          return Apollo.useLazyQuery<AllHarvestGroupsShortOverviewQuery, AllHarvestGroupsShortOverviewQueryVariables>(AllHarvestGroupsShortOverviewDocument, baseOptions);
        }
export type AllHarvestGroupsShortOverviewQueryHookResult = ReturnType<typeof useAllHarvestGroupsShortOverviewQuery>;
export type AllHarvestGroupsShortOverviewLazyQueryHookResult = ReturnType<typeof useAllHarvestGroupsShortOverviewLazyQuery>;
export type AllHarvestGroupsShortOverviewQueryResult = Apollo.QueryResult<AllHarvestGroupsShortOverviewQuery, AllHarvestGroupsShortOverviewQueryVariables>;
export const HarvestGroupWithJustStrainsDocument = gql`
    query harvestGroupWithJustStrains($id: String!) {
  harvestGroup(id: $id) {
    strains {
      ...StrainReportFields
    }
  }
}
    ${StrainReportFieldsFragmentDoc}`;

/**
 * __useHarvestGroupWithJustStrainsQuery__
 *
 * To run a query within a React component, call `useHarvestGroupWithJustStrainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestGroupWithJustStrainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestGroupWithJustStrainsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHarvestGroupWithJustStrainsQuery(baseOptions?: Apollo.QueryHookOptions<HarvestGroupWithJustStrainsQuery, HarvestGroupWithJustStrainsQueryVariables>) {
        return Apollo.useQuery<HarvestGroupWithJustStrainsQuery, HarvestGroupWithJustStrainsQueryVariables>(HarvestGroupWithJustStrainsDocument, baseOptions);
      }
export function useHarvestGroupWithJustStrainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestGroupWithJustStrainsQuery, HarvestGroupWithJustStrainsQueryVariables>) {
          return Apollo.useLazyQuery<HarvestGroupWithJustStrainsQuery, HarvestGroupWithJustStrainsQueryVariables>(HarvestGroupWithJustStrainsDocument, baseOptions);
        }
export type HarvestGroupWithJustStrainsQueryHookResult = ReturnType<typeof useHarvestGroupWithJustStrainsQuery>;
export type HarvestGroupWithJustStrainsLazyQueryHookResult = ReturnType<typeof useHarvestGroupWithJustStrainsLazyQuery>;
export type HarvestGroupWithJustStrainsQueryResult = Apollo.QueryResult<HarvestGroupWithJustStrainsQuery, HarvestGroupWithJustStrainsQueryVariables>;
export const CreateHarvestGroupDocument = gql`
    mutation createHarvestGroup($input: CreateHarvestGroupInput!) {
  createHarvestGroup(input: $input) {
    ... on HarvestGroup {
      ...HarvestGroupFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupFieldsFragmentDoc}`;
export type CreateHarvestGroupMutationFn = Apollo.MutationFunction<CreateHarvestGroupMutation, CreateHarvestGroupMutationVariables>;

/**
 * __useCreateHarvestGroupMutation__
 *
 * To run a mutation, you first call `useCreateHarvestGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHarvestGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHarvestGroupMutation, { data, loading, error }] = useCreateHarvestGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHarvestGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateHarvestGroupMutation, CreateHarvestGroupMutationVariables>) {
        return Apollo.useMutation<CreateHarvestGroupMutation, CreateHarvestGroupMutationVariables>(CreateHarvestGroupDocument, baseOptions);
      }
export type CreateHarvestGroupMutationHookResult = ReturnType<typeof useCreateHarvestGroupMutation>;
export type CreateHarvestGroupMutationResult = Apollo.MutationResult<CreateHarvestGroupMutation>;
export type CreateHarvestGroupMutationOptions = Apollo.BaseMutationOptions<CreateHarvestGroupMutation, CreateHarvestGroupMutationVariables>;
export const UpdateHarvestGroupDocument = gql`
    mutation updateHarvestGroup($input: UpdateHarvestGroupInput!) {
  updateHarvestGroup(input: $input) {
    ... on HarvestGroup {
      ...HarvestGroupFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupFieldsFragmentDoc}`;
export type UpdateHarvestGroupMutationFn = Apollo.MutationFunction<UpdateHarvestGroupMutation, UpdateHarvestGroupMutationVariables>;

/**
 * __useUpdateHarvestGroupMutation__
 *
 * To run a mutation, you first call `useUpdateHarvestGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHarvestGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHarvestGroupMutation, { data, loading, error }] = useUpdateHarvestGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHarvestGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHarvestGroupMutation, UpdateHarvestGroupMutationVariables>) {
        return Apollo.useMutation<UpdateHarvestGroupMutation, UpdateHarvestGroupMutationVariables>(UpdateHarvestGroupDocument, baseOptions);
      }
export type UpdateHarvestGroupMutationHookResult = ReturnType<typeof useUpdateHarvestGroupMutation>;
export type UpdateHarvestGroupMutationResult = Apollo.MutationResult<UpdateHarvestGroupMutation>;
export type UpdateHarvestGroupMutationOptions = Apollo.BaseMutationOptions<UpdateHarvestGroupMutation, UpdateHarvestGroupMutationVariables>;
export const UpdateHarvestGroupArchivedDocument = gql`
    mutation updateHarvestGroupArchived($input: UpdateHarvestGroupArchivedInput!) {
  updateHarvestGroupArchived(input: $input) {
    ... on HarvestGroup {
      ...HarvestGroupFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupFieldsFragmentDoc}`;
export type UpdateHarvestGroupArchivedMutationFn = Apollo.MutationFunction<UpdateHarvestGroupArchivedMutation, UpdateHarvestGroupArchivedMutationVariables>;

/**
 * __useUpdateHarvestGroupArchivedMutation__
 *
 * To run a mutation, you first call `useUpdateHarvestGroupArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHarvestGroupArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHarvestGroupArchivedMutation, { data, loading, error }] = useUpdateHarvestGroupArchivedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHarvestGroupArchivedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHarvestGroupArchivedMutation, UpdateHarvestGroupArchivedMutationVariables>) {
        return Apollo.useMutation<UpdateHarvestGroupArchivedMutation, UpdateHarvestGroupArchivedMutationVariables>(UpdateHarvestGroupArchivedDocument, baseOptions);
      }
export type UpdateHarvestGroupArchivedMutationHookResult = ReturnType<typeof useUpdateHarvestGroupArchivedMutation>;
export type UpdateHarvestGroupArchivedMutationResult = Apollo.MutationResult<UpdateHarvestGroupArchivedMutation>;
export type UpdateHarvestGroupArchivedMutationOptions = Apollo.BaseMutationOptions<UpdateHarvestGroupArchivedMutation, UpdateHarvestGroupArchivedMutationVariables>;
export const CreateHarvestReportDocument = gql`
    mutation createHarvestReport($input: CreateHarvestReportInput!) {
  createHarvestReport(input: $input) {
    ... on HarvestGroup {
      id
      yields {
        ...YieldFields
      }
    }
    ... on Error {
      message
    }
  }
}
    ${YieldFieldsFragmentDoc}`;
export type CreateHarvestReportMutationFn = Apollo.MutationFunction<CreateHarvestReportMutation, CreateHarvestReportMutationVariables>;

/**
 * __useCreateHarvestReportMutation__
 *
 * To run a mutation, you first call `useCreateHarvestReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHarvestReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHarvestReportMutation, { data, loading, error }] = useCreateHarvestReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHarvestReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateHarvestReportMutation, CreateHarvestReportMutationVariables>) {
        return Apollo.useMutation<CreateHarvestReportMutation, CreateHarvestReportMutationVariables>(CreateHarvestReportDocument, baseOptions);
      }
export type CreateHarvestReportMutationHookResult = ReturnType<typeof useCreateHarvestReportMutation>;
export type CreateHarvestReportMutationResult = Apollo.MutationResult<CreateHarvestReportMutation>;
export type CreateHarvestReportMutationOptions = Apollo.BaseMutationOptions<CreateHarvestReportMutation, CreateHarvestReportMutationVariables>;
export const UpdateHarvestReportDocument = gql`
    mutation updateHarvestReport($input: UpdateHarvestReportInput!) {
  updateHarvestReport(input: $input) {
    ... on HarvestGroup {
      id
      yields {
        ...YieldFields
      }
    }
    ... on Error {
      message
    }
  }
}
    ${YieldFieldsFragmentDoc}`;
export type UpdateHarvestReportMutationFn = Apollo.MutationFunction<UpdateHarvestReportMutation, UpdateHarvestReportMutationVariables>;

/**
 * __useUpdateHarvestReportMutation__
 *
 * To run a mutation, you first call `useUpdateHarvestReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHarvestReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHarvestReportMutation, { data, loading, error }] = useUpdateHarvestReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHarvestReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHarvestReportMutation, UpdateHarvestReportMutationVariables>) {
        return Apollo.useMutation<UpdateHarvestReportMutation, UpdateHarvestReportMutationVariables>(UpdateHarvestReportDocument, baseOptions);
      }
export type UpdateHarvestReportMutationHookResult = ReturnType<typeof useUpdateHarvestReportMutation>;
export type UpdateHarvestReportMutationResult = Apollo.MutationResult<UpdateHarvestReportMutation>;
export type UpdateHarvestReportMutationOptions = Apollo.BaseMutationOptions<UpdateHarvestReportMutation, UpdateHarvestReportMutationVariables>;
export const UpdateHarvestProjectionDocument = gql`
    mutation updateHarvestProjection($input: UpdateHarvestProjectionInput!) {
  updateHarvestProjection(input: $input) {
    ... on HarvestProjection {
      id
      strainId
      plantCount
      harvestGroupId
      strain {
        id
        name
      }
    }
  }
}
    `;
export type UpdateHarvestProjectionMutationFn = Apollo.MutationFunction<UpdateHarvestProjectionMutation, UpdateHarvestProjectionMutationVariables>;

/**
 * __useUpdateHarvestProjectionMutation__
 *
 * To run a mutation, you first call `useUpdateHarvestProjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHarvestProjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHarvestProjectionMutation, { data, loading, error }] = useUpdateHarvestProjectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHarvestProjectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHarvestProjectionMutation, UpdateHarvestProjectionMutationVariables>) {
        return Apollo.useMutation<UpdateHarvestProjectionMutation, UpdateHarvestProjectionMutationVariables>(UpdateHarvestProjectionDocument, baseOptions);
      }
export type UpdateHarvestProjectionMutationHookResult = ReturnType<typeof useUpdateHarvestProjectionMutation>;
export type UpdateHarvestProjectionMutationResult = Apollo.MutationResult<UpdateHarvestProjectionMutation>;
export type UpdateHarvestProjectionMutationOptions = Apollo.BaseMutationOptions<UpdateHarvestProjectionMutation, UpdateHarvestProjectionMutationVariables>;
export const HarvestReportDocument = gql`
    query harvestReport($id: String!) {
  harvestGroup(id: $id) {
    id
    yields {
      ...YieldFields
    }
  }
}
    ${YieldFieldsFragmentDoc}`;

/**
 * __useHarvestReportQuery__
 *
 * To run a query within a React component, call `useHarvestReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHarvestReportQuery(baseOptions?: Apollo.QueryHookOptions<HarvestReportQuery, HarvestReportQueryVariables>) {
        return Apollo.useQuery<HarvestReportQuery, HarvestReportQueryVariables>(HarvestReportDocument, baseOptions);
      }
export function useHarvestReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestReportQuery, HarvestReportQueryVariables>) {
          return Apollo.useLazyQuery<HarvestReportQuery, HarvestReportQueryVariables>(HarvestReportDocument, baseOptions);
        }
export type HarvestReportQueryHookResult = ReturnType<typeof useHarvestReportQuery>;
export type HarvestReportLazyQueryHookResult = ReturnType<typeof useHarvestReportLazyQuery>;
export type HarvestReportQueryResult = Apollo.QueryResult<HarvestReportQuery, HarvestReportQueryVariables>;
export const CompleteHarvestDocument = gql`
    mutation completeHarvest($input: CompleteHarvestInput!) {
  completeHarvest(input: $input) {
    ... on HarvestCompletion {
      ...HarvestCompletionFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestCompletionFieldsFragmentDoc}`;
export type CompleteHarvestMutationFn = Apollo.MutationFunction<CompleteHarvestMutation, CompleteHarvestMutationVariables>;

/**
 * __useCompleteHarvestMutation__
 *
 * To run a mutation, you first call `useCompleteHarvestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteHarvestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeHarvestMutation, { data, loading, error }] = useCompleteHarvestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteHarvestMutation(baseOptions?: Apollo.MutationHookOptions<CompleteHarvestMutation, CompleteHarvestMutationVariables>) {
        return Apollo.useMutation<CompleteHarvestMutation, CompleteHarvestMutationVariables>(CompleteHarvestDocument, baseOptions);
      }
export type CompleteHarvestMutationHookResult = ReturnType<typeof useCompleteHarvestMutation>;
export type CompleteHarvestMutationResult = Apollo.MutationResult<CompleteHarvestMutation>;
export type CompleteHarvestMutationOptions = Apollo.BaseMutationOptions<CompleteHarvestMutation, CompleteHarvestMutationVariables>;
export const ApproveHarvestCompletionDocument = gql`
    mutation approveHarvestCompletion($input: ApproveHarvestCompletionInput!) {
  approveHarvestCompletion(input: $input) {
    ... on HarvestCompletion {
      ...HarvestCompletionFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestCompletionFieldsFragmentDoc}`;
export type ApproveHarvestCompletionMutationFn = Apollo.MutationFunction<ApproveHarvestCompletionMutation, ApproveHarvestCompletionMutationVariables>;

/**
 * __useApproveHarvestCompletionMutation__
 *
 * To run a mutation, you first call `useApproveHarvestCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveHarvestCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveHarvestCompletionMutation, { data, loading, error }] = useApproveHarvestCompletionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveHarvestCompletionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveHarvestCompletionMutation, ApproveHarvestCompletionMutationVariables>) {
        return Apollo.useMutation<ApproveHarvestCompletionMutation, ApproveHarvestCompletionMutationVariables>(ApproveHarvestCompletionDocument, baseOptions);
      }
export type ApproveHarvestCompletionMutationHookResult = ReturnType<typeof useApproveHarvestCompletionMutation>;
export type ApproveHarvestCompletionMutationResult = Apollo.MutationResult<ApproveHarvestCompletionMutation>;
export type ApproveHarvestCompletionMutationOptions = Apollo.BaseMutationOptions<ApproveHarvestCompletionMutation, ApproveHarvestCompletionMutationVariables>;
export const ArchiveHarvestGroupDocument = gql`
    mutation archiveHarvestGroup($id: ID!) {
  updateHarvestGroupArchived(input: {archivedAt: "true", id: $id}) {
    ... on HarvestGroup {
      ...HarvestGroupFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupFieldsFragmentDoc}`;
export type ArchiveHarvestGroupMutationFn = Apollo.MutationFunction<ArchiveHarvestGroupMutation, ArchiveHarvestGroupMutationVariables>;

/**
 * __useArchiveHarvestGroupMutation__
 *
 * To run a mutation, you first call `useArchiveHarvestGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveHarvestGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveHarvestGroupMutation, { data, loading, error }] = useArchiveHarvestGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveHarvestGroupMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveHarvestGroupMutation, ArchiveHarvestGroupMutationVariables>) {
        return Apollo.useMutation<ArchiveHarvestGroupMutation, ArchiveHarvestGroupMutationVariables>(ArchiveHarvestGroupDocument, baseOptions);
      }
export type ArchiveHarvestGroupMutationHookResult = ReturnType<typeof useArchiveHarvestGroupMutation>;
export type ArchiveHarvestGroupMutationResult = Apollo.MutationResult<ArchiveHarvestGroupMutation>;
export type ArchiveHarvestGroupMutationOptions = Apollo.BaseMutationOptions<ArchiveHarvestGroupMutation, ArchiveHarvestGroupMutationVariables>;
export const UnarchiveHarvestGroupDocument = gql`
    mutation unarchiveHarvestGroup($id: ID!) {
  updateHarvestGroupArchived(input: {archivedAt: "false", id: $id}) {
    ... on HarvestGroup {
      ...HarvestGroupFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupFieldsFragmentDoc}`;
export type UnarchiveHarvestGroupMutationFn = Apollo.MutationFunction<UnarchiveHarvestGroupMutation, UnarchiveHarvestGroupMutationVariables>;

/**
 * __useUnarchiveHarvestGroupMutation__
 *
 * To run a mutation, you first call `useUnarchiveHarvestGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveHarvestGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveHarvestGroupMutation, { data, loading, error }] = useUnarchiveHarvestGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveHarvestGroupMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveHarvestGroupMutation, UnarchiveHarvestGroupMutationVariables>) {
        return Apollo.useMutation<UnarchiveHarvestGroupMutation, UnarchiveHarvestGroupMutationVariables>(UnarchiveHarvestGroupDocument, baseOptions);
      }
export type UnarchiveHarvestGroupMutationHookResult = ReturnType<typeof useUnarchiveHarvestGroupMutation>;
export type UnarchiveHarvestGroupMutationResult = Apollo.MutationResult<UnarchiveHarvestGroupMutation>;
export type UnarchiveHarvestGroupMutationOptions = Apollo.BaseMutationOptions<UnarchiveHarvestGroupMutation, UnarchiveHarvestGroupMutationVariables>;
export const HarvestGroupWithLogEntriesDocument = gql`
    query harvestGroupWithLogEntries($id: String!, $logEntryOptions: LogsFilterInput) {
  harvestGroup(id: $id) {
    ...HarvestGroupWithLogEntries
  }
}
    ${HarvestGroupWithLogEntriesFragmentDoc}`;

/**
 * __useHarvestGroupWithLogEntriesQuery__
 *
 * To run a query within a React component, call `useHarvestGroupWithLogEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestGroupWithLogEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestGroupWithLogEntriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      logEntryOptions: // value for 'logEntryOptions'
 *   },
 * });
 */
export function useHarvestGroupWithLogEntriesQuery(baseOptions?: Apollo.QueryHookOptions<HarvestGroupWithLogEntriesQuery, HarvestGroupWithLogEntriesQueryVariables>) {
        return Apollo.useQuery<HarvestGroupWithLogEntriesQuery, HarvestGroupWithLogEntriesQueryVariables>(HarvestGroupWithLogEntriesDocument, baseOptions);
      }
export function useHarvestGroupWithLogEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestGroupWithLogEntriesQuery, HarvestGroupWithLogEntriesQueryVariables>) {
          return Apollo.useLazyQuery<HarvestGroupWithLogEntriesQuery, HarvestGroupWithLogEntriesQueryVariables>(HarvestGroupWithLogEntriesDocument, baseOptions);
        }
export type HarvestGroupWithLogEntriesQueryHookResult = ReturnType<typeof useHarvestGroupWithLogEntriesQuery>;
export type HarvestGroupWithLogEntriesLazyQueryHookResult = ReturnType<typeof useHarvestGroupWithLogEntriesLazyQuery>;
export type HarvestGroupWithLogEntriesQueryResult = Apollo.QueryResult<HarvestGroupWithLogEntriesQuery, HarvestGroupWithLogEntriesQueryVariables>;
export const HarvestGroupWithProcessingAndFormFactorsDocument = gql`
    query harvestGroupWithProcessingAndFormFactors($id: String!, $filters: BudFormFactorFilterSetInput) {
  harvestGroup(id: $id) {
    ...HarvestGroupDetailFields
    ...HarvestBecameProcessingFields
  }
  budFormFactors(filters: $filters) {
    ...BudFormFactorFields
  }
}
    ${HarvestGroupDetailFieldsFragmentDoc}
${HarvestBecameProcessingFieldsFragmentDoc}
${BudFormFactorFieldsFragmentDoc}`;

/**
 * __useHarvestGroupWithProcessingAndFormFactorsQuery__
 *
 * To run a query within a React component, call `useHarvestGroupWithProcessingAndFormFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestGroupWithProcessingAndFormFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestGroupWithProcessingAndFormFactorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useHarvestGroupWithProcessingAndFormFactorsQuery(baseOptions?: Apollo.QueryHookOptions<HarvestGroupWithProcessingAndFormFactorsQuery, HarvestGroupWithProcessingAndFormFactorsQueryVariables>) {
        return Apollo.useQuery<HarvestGroupWithProcessingAndFormFactorsQuery, HarvestGroupWithProcessingAndFormFactorsQueryVariables>(HarvestGroupWithProcessingAndFormFactorsDocument, baseOptions);
      }
export function useHarvestGroupWithProcessingAndFormFactorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestGroupWithProcessingAndFormFactorsQuery, HarvestGroupWithProcessingAndFormFactorsQueryVariables>) {
          return Apollo.useLazyQuery<HarvestGroupWithProcessingAndFormFactorsQuery, HarvestGroupWithProcessingAndFormFactorsQueryVariables>(HarvestGroupWithProcessingAndFormFactorsDocument, baseOptions);
        }
export type HarvestGroupWithProcessingAndFormFactorsQueryHookResult = ReturnType<typeof useHarvestGroupWithProcessingAndFormFactorsQuery>;
export type HarvestGroupWithProcessingAndFormFactorsLazyQueryHookResult = ReturnType<typeof useHarvestGroupWithProcessingAndFormFactorsLazyQuery>;
export type HarvestGroupWithProcessingAndFormFactorsQueryResult = Apollo.QueryResult<HarvestGroupWithProcessingAndFormFactorsQuery, HarvestGroupWithProcessingAndFormFactorsQueryVariables>;
export const AddObservationDocument = gql`
    mutation addObservation($input: LogEntryInput!) {
  addObservationLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type AddObservationMutationFn = Apollo.MutationFunction<AddObservationMutation, AddObservationMutationVariables>;

/**
 * __useAddObservationMutation__
 *
 * To run a mutation, you first call `useAddObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addObservationMutation, { data, loading, error }] = useAddObservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddObservationMutation(baseOptions?: Apollo.MutationHookOptions<AddObservationMutation, AddObservationMutationVariables>) {
        return Apollo.useMutation<AddObservationMutation, AddObservationMutationVariables>(AddObservationDocument, baseOptions);
      }
export type AddObservationMutationHookResult = ReturnType<typeof useAddObservationMutation>;
export type AddObservationMutationResult = Apollo.MutationResult<AddObservationMutation>;
export type AddObservationMutationOptions = Apollo.BaseMutationOptions<AddObservationMutation, AddObservationMutationVariables>;
export const AddIssueDocument = gql`
    mutation addIssue($input: LogEntryInput!) {
  addIssueLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type AddIssueMutationFn = Apollo.MutationFunction<AddIssueMutation, AddIssueMutationVariables>;

/**
 * __useAddIssueMutation__
 *
 * To run a mutation, you first call `useAddIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIssueMutation, { data, loading, error }] = useAddIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIssueMutation(baseOptions?: Apollo.MutationHookOptions<AddIssueMutation, AddIssueMutationVariables>) {
        return Apollo.useMutation<AddIssueMutation, AddIssueMutationVariables>(AddIssueDocument, baseOptions);
      }
export type AddIssueMutationHookResult = ReturnType<typeof useAddIssueMutation>;
export type AddIssueMutationResult = Apollo.MutationResult<AddIssueMutation>;
export type AddIssueMutationOptions = Apollo.BaseMutationOptions<AddIssueMutation, AddIssueMutationVariables>;
export const AddIrrigationActivityDocument = gql`
    mutation addIrrigationActivity($input: LogEntryInput!) {
  addIrrigationLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type AddIrrigationActivityMutationFn = Apollo.MutationFunction<AddIrrigationActivityMutation, AddIrrigationActivityMutationVariables>;

/**
 * __useAddIrrigationActivityMutation__
 *
 * To run a mutation, you first call `useAddIrrigationActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIrrigationActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIrrigationActivityMutation, { data, loading, error }] = useAddIrrigationActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIrrigationActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddIrrigationActivityMutation, AddIrrigationActivityMutationVariables>) {
        return Apollo.useMutation<AddIrrigationActivityMutation, AddIrrigationActivityMutationVariables>(AddIrrigationActivityDocument, baseOptions);
      }
export type AddIrrigationActivityMutationHookResult = ReturnType<typeof useAddIrrigationActivityMutation>;
export type AddIrrigationActivityMutationResult = Apollo.MutationResult<AddIrrigationActivityMutation>;
export type AddIrrigationActivityMutationOptions = Apollo.BaseMutationOptions<AddIrrigationActivityMutation, AddIrrigationActivityMutationVariables>;
export const AddFoliarActivityDocument = gql`
    mutation addFoliarActivity($input: LogEntryInput!) {
  addFoliarLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type AddFoliarActivityMutationFn = Apollo.MutationFunction<AddFoliarActivityMutation, AddFoliarActivityMutationVariables>;

/**
 * __useAddFoliarActivityMutation__
 *
 * To run a mutation, you first call `useAddFoliarActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFoliarActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFoliarActivityMutation, { data, loading, error }] = useAddFoliarActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFoliarActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddFoliarActivityMutation, AddFoliarActivityMutationVariables>) {
        return Apollo.useMutation<AddFoliarActivityMutation, AddFoliarActivityMutationVariables>(AddFoliarActivityDocument, baseOptions);
      }
export type AddFoliarActivityMutationHookResult = ReturnType<typeof useAddFoliarActivityMutation>;
export type AddFoliarActivityMutationResult = Apollo.MutationResult<AddFoliarActivityMutation>;
export type AddFoliarActivityMutationOptions = Apollo.BaseMutationOptions<AddFoliarActivityMutation, AddFoliarActivityMutationVariables>;
export const AddPruningActivityDocument = gql`
    mutation addPruningActivity($input: LogEntryInput!) {
  addPruningLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type AddPruningActivityMutationFn = Apollo.MutationFunction<AddPruningActivityMutation, AddPruningActivityMutationVariables>;

/**
 * __useAddPruningActivityMutation__
 *
 * To run a mutation, you first call `useAddPruningActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPruningActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPruningActivityMutation, { data, loading, error }] = useAddPruningActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPruningActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddPruningActivityMutation, AddPruningActivityMutationVariables>) {
        return Apollo.useMutation<AddPruningActivityMutation, AddPruningActivityMutationVariables>(AddPruningActivityDocument, baseOptions);
      }
export type AddPruningActivityMutationHookResult = ReturnType<typeof useAddPruningActivityMutation>;
export type AddPruningActivityMutationResult = Apollo.MutationResult<AddPruningActivityMutation>;
export type AddPruningActivityMutationOptions = Apollo.BaseMutationOptions<AddPruningActivityMutation, AddPruningActivityMutationVariables>;
export const AddToppingActivityDocument = gql`
    mutation addToppingActivity($input: LogEntryInput!) {
  addToppingLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type AddToppingActivityMutationFn = Apollo.MutationFunction<AddToppingActivityMutation, AddToppingActivityMutationVariables>;

/**
 * __useAddToppingActivityMutation__
 *
 * To run a mutation, you first call `useAddToppingActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToppingActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToppingActivityMutation, { data, loading, error }] = useAddToppingActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToppingActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddToppingActivityMutation, AddToppingActivityMutationVariables>) {
        return Apollo.useMutation<AddToppingActivityMutation, AddToppingActivityMutationVariables>(AddToppingActivityDocument, baseOptions);
      }
export type AddToppingActivityMutationHookResult = ReturnType<typeof useAddToppingActivityMutation>;
export type AddToppingActivityMutationResult = Apollo.MutationResult<AddToppingActivityMutation>;
export type AddToppingActivityMutationOptions = Apollo.BaseMutationOptions<AddToppingActivityMutation, AddToppingActivityMutationVariables>;
export const AddGreenWasteActivityDocument = gql`
    mutation addGreenWasteActivity($input: LogEntryInput!) {
  addGreenWasteLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type AddGreenWasteActivityMutationFn = Apollo.MutationFunction<AddGreenWasteActivityMutation, AddGreenWasteActivityMutationVariables>;

/**
 * __useAddGreenWasteActivityMutation__
 *
 * To run a mutation, you first call `useAddGreenWasteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGreenWasteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGreenWasteActivityMutation, { data, loading, error }] = useAddGreenWasteActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGreenWasteActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddGreenWasteActivityMutation, AddGreenWasteActivityMutationVariables>) {
        return Apollo.useMutation<AddGreenWasteActivityMutation, AddGreenWasteActivityMutationVariables>(AddGreenWasteActivityDocument, baseOptions);
      }
export type AddGreenWasteActivityMutationHookResult = ReturnType<typeof useAddGreenWasteActivityMutation>;
export type AddGreenWasteActivityMutationResult = Apollo.MutationResult<AddGreenWasteActivityMutation>;
export type AddGreenWasteActivityMutationOptions = Apollo.BaseMutationOptions<AddGreenWasteActivityMutation, AddGreenWasteActivityMutationVariables>;
export const DeleteLogEntryDocument = gql`
    mutation deleteLogEntry($input: DeleteLogEntryInput!) {
  deleteLogEntry(input: $input) {
    success
  }
}
    `;
export type DeleteLogEntryMutationFn = Apollo.MutationFunction<DeleteLogEntryMutation, DeleteLogEntryMutationVariables>;

/**
 * __useDeleteLogEntryMutation__
 *
 * To run a mutation, you first call `useDeleteLogEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogEntryMutation, { data, loading, error }] = useDeleteLogEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLogEntryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLogEntryMutation, DeleteLogEntryMutationVariables>) {
        return Apollo.useMutation<DeleteLogEntryMutation, DeleteLogEntryMutationVariables>(DeleteLogEntryDocument, baseOptions);
      }
export type DeleteLogEntryMutationHookResult = ReturnType<typeof useDeleteLogEntryMutation>;
export type DeleteLogEntryMutationResult = Apollo.MutationResult<DeleteLogEntryMutation>;
export type DeleteLogEntryMutationOptions = Apollo.BaseMutationOptions<DeleteLogEntryMutation, DeleteLogEntryMutationVariables>;
export const ApproveLogEntryDocument = gql`
    mutation approveLogEntry($input: ApproveLogEntryInput!) {
  approveLogEntry(input: $input) {
    ... on LogEntry {
      ...LogEntryFields
    }
    ... on Error {
      message
    }
  }
}
    ${LogEntryFieldsFragmentDoc}`;
export type ApproveLogEntryMutationFn = Apollo.MutationFunction<ApproveLogEntryMutation, ApproveLogEntryMutationVariables>;

/**
 * __useApproveLogEntryMutation__
 *
 * To run a mutation, you first call `useApproveLogEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLogEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLogEntryMutation, { data, loading, error }] = useApproveLogEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveLogEntryMutation(baseOptions?: Apollo.MutationHookOptions<ApproveLogEntryMutation, ApproveLogEntryMutationVariables>) {
        return Apollo.useMutation<ApproveLogEntryMutation, ApproveLogEntryMutationVariables>(ApproveLogEntryDocument, baseOptions);
      }
export type ApproveLogEntryMutationHookResult = ReturnType<typeof useApproveLogEntryMutation>;
export type ApproveLogEntryMutationResult = Apollo.MutationResult<ApproveLogEntryMutation>;
export type ApproveLogEntryMutationOptions = Apollo.BaseMutationOptions<ApproveLogEntryMutation, ApproveLogEntryMutationVariables>;
export const MaintenanceRequestsDocument = gql`
    query maintenanceRequests {
  maintenanceRequests {
    ...MaintenanceRequestFields
  }
}
    ${MaintenanceRequestFieldsFragmentDoc}`;

/**
 * __useMaintenanceRequestsQuery__
 *
 * To run a query within a React component, call `useMaintenanceRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaintenanceRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MaintenanceRequestsQuery, MaintenanceRequestsQueryVariables>) {
        return Apollo.useQuery<MaintenanceRequestsQuery, MaintenanceRequestsQueryVariables>(MaintenanceRequestsDocument, baseOptions);
      }
export function useMaintenanceRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceRequestsQuery, MaintenanceRequestsQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceRequestsQuery, MaintenanceRequestsQueryVariables>(MaintenanceRequestsDocument, baseOptions);
        }
export type MaintenanceRequestsQueryHookResult = ReturnType<typeof useMaintenanceRequestsQuery>;
export type MaintenanceRequestsLazyQueryHookResult = ReturnType<typeof useMaintenanceRequestsLazyQuery>;
export type MaintenanceRequestsQueryResult = Apollo.QueryResult<MaintenanceRequestsQuery, MaintenanceRequestsQueryVariables>;
export const CreateMaintenanceRequestDocument = gql`
    mutation createMaintenanceRequest($input: CreateMaintenanceRequestInput!) {
  createMaintenanceRequest(input: $input) {
    ... on MaintenanceRequest {
      ...MaintenanceRequestFields
    }
    ... on Error {
      message
    }
  }
}
    ${MaintenanceRequestFieldsFragmentDoc}`;
export type CreateMaintenanceRequestMutationFn = Apollo.MutationFunction<CreateMaintenanceRequestMutation, CreateMaintenanceRequestMutationVariables>;

/**
 * __useCreateMaintenanceRequestMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceRequestMutation, { data, loading, error }] = useCreateMaintenanceRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMaintenanceRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaintenanceRequestMutation, CreateMaintenanceRequestMutationVariables>) {
        return Apollo.useMutation<CreateMaintenanceRequestMutation, CreateMaintenanceRequestMutationVariables>(CreateMaintenanceRequestDocument, baseOptions);
      }
export type CreateMaintenanceRequestMutationHookResult = ReturnType<typeof useCreateMaintenanceRequestMutation>;
export type CreateMaintenanceRequestMutationResult = Apollo.MutationResult<CreateMaintenanceRequestMutation>;
export type CreateMaintenanceRequestMutationOptions = Apollo.BaseMutationOptions<CreateMaintenanceRequestMutation, CreateMaintenanceRequestMutationVariables>;
export const ResolveMaintenanceRequestDocument = gql`
    mutation resolveMaintenanceRequest($input: ResolveMaintenanceRequestInput!) {
  resolveMaintenanceRequest(input: $input) {
    ... on MaintenanceRequest {
      id
      ...MaintenanceRequestResolutionFields
    }
    ... on Error {
      message
    }
  }
}
    ${MaintenanceRequestResolutionFieldsFragmentDoc}`;
export type ResolveMaintenanceRequestMutationFn = Apollo.MutationFunction<ResolveMaintenanceRequestMutation, ResolveMaintenanceRequestMutationVariables>;

/**
 * __useResolveMaintenanceRequestMutation__
 *
 * To run a mutation, you first call `useResolveMaintenanceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveMaintenanceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveMaintenanceRequestMutation, { data, loading, error }] = useResolveMaintenanceRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveMaintenanceRequestMutation(baseOptions?: Apollo.MutationHookOptions<ResolveMaintenanceRequestMutation, ResolveMaintenanceRequestMutationVariables>) {
        return Apollo.useMutation<ResolveMaintenanceRequestMutation, ResolveMaintenanceRequestMutationVariables>(ResolveMaintenanceRequestDocument, baseOptions);
      }
export type ResolveMaintenanceRequestMutationHookResult = ReturnType<typeof useResolveMaintenanceRequestMutation>;
export type ResolveMaintenanceRequestMutationResult = Apollo.MutationResult<ResolveMaintenanceRequestMutation>;
export type ResolveMaintenanceRequestMutationOptions = Apollo.BaseMutationOptions<ResolveMaintenanceRequestMutation, ResolveMaintenanceRequestMutationVariables>;
export const NutrientsDocument = gql`
    query nutrients($filters: NutrientFilterSetInput) {
  nutrients(filters: $filters) {
    ...NutrientFields
  }
}
    ${NutrientFieldsFragmentDoc}`;

/**
 * __useNutrientsQuery__
 *
 * To run a query within a React component, call `useNutrientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutrientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutrientsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNutrientsQuery(baseOptions?: Apollo.QueryHookOptions<NutrientsQuery, NutrientsQueryVariables>) {
        return Apollo.useQuery<NutrientsQuery, NutrientsQueryVariables>(NutrientsDocument, baseOptions);
      }
export function useNutrientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NutrientsQuery, NutrientsQueryVariables>) {
          return Apollo.useLazyQuery<NutrientsQuery, NutrientsQueryVariables>(NutrientsDocument, baseOptions);
        }
export type NutrientsQueryHookResult = ReturnType<typeof useNutrientsQuery>;
export type NutrientsLazyQueryHookResult = ReturnType<typeof useNutrientsLazyQuery>;
export type NutrientsQueryResult = Apollo.QueryResult<NutrientsQuery, NutrientsQueryVariables>;
export const CreateNutrientDocument = gql`
    mutation createNutrient($input: CreateNutrientInput!) {
  createNutrient(input: $input) {
    ... on Nutrient {
      ...NutrientFields
    }
    ... on Error {
      message
    }
  }
}
    ${NutrientFieldsFragmentDoc}`;
export type CreateNutrientMutationFn = Apollo.MutationFunction<CreateNutrientMutation, CreateNutrientMutationVariables>;

/**
 * __useCreateNutrientMutation__
 *
 * To run a mutation, you first call `useCreateNutrientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNutrientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNutrientMutation, { data, loading, error }] = useCreateNutrientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNutrientMutation(baseOptions?: Apollo.MutationHookOptions<CreateNutrientMutation, CreateNutrientMutationVariables>) {
        return Apollo.useMutation<CreateNutrientMutation, CreateNutrientMutationVariables>(CreateNutrientDocument, baseOptions);
      }
export type CreateNutrientMutationHookResult = ReturnType<typeof useCreateNutrientMutation>;
export type CreateNutrientMutationResult = Apollo.MutationResult<CreateNutrientMutation>;
export type CreateNutrientMutationOptions = Apollo.BaseMutationOptions<CreateNutrientMutation, CreateNutrientMutationVariables>;
export const UpdateNutrientDocument = gql`
    mutation updateNutrient($input: UpdateNutrientInput!) {
  updateNutrient(input: $input) {
    ... on Nutrient {
      ...NutrientFields
    }
    ... on Error {
      message
    }
  }
}
    ${NutrientFieldsFragmentDoc}`;
export type UpdateNutrientMutationFn = Apollo.MutationFunction<UpdateNutrientMutation, UpdateNutrientMutationVariables>;

/**
 * __useUpdateNutrientMutation__
 *
 * To run a mutation, you first call `useUpdateNutrientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNutrientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNutrientMutation, { data, loading, error }] = useUpdateNutrientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNutrientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNutrientMutation, UpdateNutrientMutationVariables>) {
        return Apollo.useMutation<UpdateNutrientMutation, UpdateNutrientMutationVariables>(UpdateNutrientDocument, baseOptions);
      }
export type UpdateNutrientMutationHookResult = ReturnType<typeof useUpdateNutrientMutation>;
export type UpdateNutrientMutationResult = Apollo.MutationResult<UpdateNutrientMutation>;
export type UpdateNutrientMutationOptions = Apollo.BaseMutationOptions<UpdateNutrientMutation, UpdateNutrientMutationVariables>;
export const ActivateNutrientDocument = gql`
    mutation activateNutrient($id: ID!) {
  updateNutrient(input: {active: true, id: $id}) {
    ... on Nutrient {
      ...NutrientFields
    }
    ... on Error {
      message
    }
  }
}
    ${NutrientFieldsFragmentDoc}`;
export type ActivateNutrientMutationFn = Apollo.MutationFunction<ActivateNutrientMutation, ActivateNutrientMutationVariables>;

/**
 * __useActivateNutrientMutation__
 *
 * To run a mutation, you first call `useActivateNutrientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateNutrientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateNutrientMutation, { data, loading, error }] = useActivateNutrientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateNutrientMutation(baseOptions?: Apollo.MutationHookOptions<ActivateNutrientMutation, ActivateNutrientMutationVariables>) {
        return Apollo.useMutation<ActivateNutrientMutation, ActivateNutrientMutationVariables>(ActivateNutrientDocument, baseOptions);
      }
export type ActivateNutrientMutationHookResult = ReturnType<typeof useActivateNutrientMutation>;
export type ActivateNutrientMutationResult = Apollo.MutationResult<ActivateNutrientMutation>;
export type ActivateNutrientMutationOptions = Apollo.BaseMutationOptions<ActivateNutrientMutation, ActivateNutrientMutationVariables>;
export const DeactivateNutrientDocument = gql`
    mutation deactivateNutrient($id: ID!) {
  updateNutrient(input: {active: false, id: $id}) {
    ... on Nutrient {
      ...NutrientFields
    }
    ... on Error {
      message
    }
  }
}
    ${NutrientFieldsFragmentDoc}`;
export type DeactivateNutrientMutationFn = Apollo.MutationFunction<DeactivateNutrientMutation, DeactivateNutrientMutationVariables>;

/**
 * __useDeactivateNutrientMutation__
 *
 * To run a mutation, you first call `useDeactivateNutrientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateNutrientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateNutrientMutation, { data, loading, error }] = useDeactivateNutrientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateNutrientMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateNutrientMutation, DeactivateNutrientMutationVariables>) {
        return Apollo.useMutation<DeactivateNutrientMutation, DeactivateNutrientMutationVariables>(DeactivateNutrientDocument, baseOptions);
      }
export type DeactivateNutrientMutationHookResult = ReturnType<typeof useDeactivateNutrientMutation>;
export type DeactivateNutrientMutationResult = Apollo.MutationResult<DeactivateNutrientMutation>;
export type DeactivateNutrientMutationOptions = Apollo.BaseMutationOptions<DeactivateNutrientMutation, DeactivateNutrientMutationVariables>;
export const UploadPaperlyticsImageDocument = gql`
    mutation uploadPaperlyticsImage($imageUrl: String!, $harvestGroupId: String!) {
  uploadPaperlyticsImage(imageUrl: $imageUrl, harvestGroupId: $harvestGroupId) {
    ... on PaperlyticsImage {
      ...PaperlyticsImageFields
    }
    ... on Error {
      message
    }
  }
}
    ${PaperlyticsImageFieldsFragmentDoc}`;
export type UploadPaperlyticsImageMutationFn = Apollo.MutationFunction<UploadPaperlyticsImageMutation, UploadPaperlyticsImageMutationVariables>;

/**
 * __useUploadPaperlyticsImageMutation__
 *
 * To run a mutation, you first call `useUploadPaperlyticsImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPaperlyticsImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPaperlyticsImageMutation, { data, loading, error }] = useUploadPaperlyticsImageMutation({
 *   variables: {
 *      imageUrl: // value for 'imageUrl'
 *      harvestGroupId: // value for 'harvestGroupId'
 *   },
 * });
 */
export function useUploadPaperlyticsImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadPaperlyticsImageMutation, UploadPaperlyticsImageMutationVariables>) {
        return Apollo.useMutation<UploadPaperlyticsImageMutation, UploadPaperlyticsImageMutationVariables>(UploadPaperlyticsImageDocument, baseOptions);
      }
export type UploadPaperlyticsImageMutationHookResult = ReturnType<typeof useUploadPaperlyticsImageMutation>;
export type UploadPaperlyticsImageMutationResult = Apollo.MutationResult<UploadPaperlyticsImageMutation>;
export type UploadPaperlyticsImageMutationOptions = Apollo.BaseMutationOptions<UploadPaperlyticsImageMutation, UploadPaperlyticsImageMutationVariables>;
export const RetryPaperlyticsImageDocument = gql`
    mutation retryPaperlyticsImage($imageId: String!) {
  retryPaperlyticsImage(imageId: $imageId) {
    ... on PaperlyticsImage {
      ...PaperlyticsImageFields
    }
    ... on Error {
      message
    }
  }
}
    ${PaperlyticsImageFieldsFragmentDoc}`;
export type RetryPaperlyticsImageMutationFn = Apollo.MutationFunction<RetryPaperlyticsImageMutation, RetryPaperlyticsImageMutationVariables>;

/**
 * __useRetryPaperlyticsImageMutation__
 *
 * To run a mutation, you first call `useRetryPaperlyticsImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryPaperlyticsImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryPaperlyticsImageMutation, { data, loading, error }] = useRetryPaperlyticsImageMutation({
 *   variables: {
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useRetryPaperlyticsImageMutation(baseOptions?: Apollo.MutationHookOptions<RetryPaperlyticsImageMutation, RetryPaperlyticsImageMutationVariables>) {
        return Apollo.useMutation<RetryPaperlyticsImageMutation, RetryPaperlyticsImageMutationVariables>(RetryPaperlyticsImageDocument, baseOptions);
      }
export type RetryPaperlyticsImageMutationHookResult = ReturnType<typeof useRetryPaperlyticsImageMutation>;
export type RetryPaperlyticsImageMutationResult = Apollo.MutationResult<RetryPaperlyticsImageMutation>;
export type RetryPaperlyticsImageMutationOptions = Apollo.BaseMutationOptions<RetryPaperlyticsImageMutation, RetryPaperlyticsImageMutationVariables>;
export const PaperlyticsImagesDocument = gql`
    query paperlyticsImages($filters: PaperlyticsFilterSetInput) {
  paperlyticsImages(filters: $filters) {
    ...PaperlyticsImageFields
  }
}
    ${PaperlyticsImageFieldsFragmentDoc}`;

/**
 * __usePaperlyticsImagesQuery__
 *
 * To run a query within a React component, call `usePaperlyticsImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperlyticsImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperlyticsImagesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaperlyticsImagesQuery(baseOptions?: Apollo.QueryHookOptions<PaperlyticsImagesQuery, PaperlyticsImagesQueryVariables>) {
        return Apollo.useQuery<PaperlyticsImagesQuery, PaperlyticsImagesQueryVariables>(PaperlyticsImagesDocument, baseOptions);
      }
export function usePaperlyticsImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaperlyticsImagesQuery, PaperlyticsImagesQueryVariables>) {
          return Apollo.useLazyQuery<PaperlyticsImagesQuery, PaperlyticsImagesQueryVariables>(PaperlyticsImagesDocument, baseOptions);
        }
export type PaperlyticsImagesQueryHookResult = ReturnType<typeof usePaperlyticsImagesQuery>;
export type PaperlyticsImagesLazyQueryHookResult = ReturnType<typeof usePaperlyticsImagesLazyQuery>;
export type PaperlyticsImagesQueryResult = Apollo.QueryResult<PaperlyticsImagesQuery, PaperlyticsImagesQueryVariables>;
export const RecipesDocument = gql`
    query recipes($filters: RecipeFilterSetInput) {
  recipes(filters: $filters) {
    ...RecipeFields
  }
}
    ${RecipeFieldsFragmentDoc}`;

/**
 * __useRecipesQuery__
 *
 * To run a query within a React component, call `useRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRecipesQuery(baseOptions?: Apollo.QueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
        return Apollo.useQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, baseOptions);
      }
export function useRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
          return Apollo.useLazyQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, baseOptions);
        }
export type RecipesQueryHookResult = ReturnType<typeof useRecipesQuery>;
export type RecipesLazyQueryHookResult = ReturnType<typeof useRecipesLazyQuery>;
export type RecipesQueryResult = Apollo.QueryResult<RecipesQuery, RecipesQueryVariables>;
export const RecipeDocument = gql`
    query recipe($id: String!) {
  recipe(id: $id) {
    ...RecipeFields
  }
}
    ${RecipeFieldsFragmentDoc}`;

/**
 * __useRecipeQuery__
 *
 * To run a query within a React component, call `useRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeQuery(baseOptions?: Apollo.QueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
        return Apollo.useQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, baseOptions);
      }
export function useRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
          return Apollo.useLazyQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, baseOptions);
        }
export type RecipeQueryHookResult = ReturnType<typeof useRecipeQuery>;
export type RecipeLazyQueryHookResult = ReturnType<typeof useRecipeLazyQuery>;
export type RecipeQueryResult = Apollo.QueryResult<RecipeQuery, RecipeQueryVariables>;
export const RecipesAndNutrientsDocument = gql`
    query recipesAndNutrients($filters: RecipeFilterSetInput) {
  recipes(filters: $filters) {
    ...RecipeFields
  }
  nutrients {
    ...NutrientFields
  }
}
    ${RecipeFieldsFragmentDoc}
${NutrientFieldsFragmentDoc}`;

/**
 * __useRecipesAndNutrientsQuery__
 *
 * To run a query within a React component, call `useRecipesAndNutrientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesAndNutrientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesAndNutrientsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRecipesAndNutrientsQuery(baseOptions?: Apollo.QueryHookOptions<RecipesAndNutrientsQuery, RecipesAndNutrientsQueryVariables>) {
        return Apollo.useQuery<RecipesAndNutrientsQuery, RecipesAndNutrientsQueryVariables>(RecipesAndNutrientsDocument, baseOptions);
      }
export function useRecipesAndNutrientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipesAndNutrientsQuery, RecipesAndNutrientsQueryVariables>) {
          return Apollo.useLazyQuery<RecipesAndNutrientsQuery, RecipesAndNutrientsQueryVariables>(RecipesAndNutrientsDocument, baseOptions);
        }
export type RecipesAndNutrientsQueryHookResult = ReturnType<typeof useRecipesAndNutrientsQuery>;
export type RecipesAndNutrientsLazyQueryHookResult = ReturnType<typeof useRecipesAndNutrientsLazyQuery>;
export type RecipesAndNutrientsQueryResult = Apollo.QueryResult<RecipesAndNutrientsQuery, RecipesAndNutrientsQueryVariables>;
export const CreateRecipeDocument = gql`
    mutation createRecipe($input: CreateRecipeInput!) {
  createRecipe(input: $input) {
    ... on Recipe {
      ...RecipeFields
    }
    ... on Error {
      message
    }
  }
}
    ${RecipeFieldsFragmentDoc}`;
export type CreateRecipeMutationFn = Apollo.MutationFunction<CreateRecipeMutation, CreateRecipeMutationVariables>;

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecipeMutation, CreateRecipeMutationVariables>) {
        return Apollo.useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CreateRecipeDocument, baseOptions);
      }
export type CreateRecipeMutationHookResult = ReturnType<typeof useCreateRecipeMutation>;
export type CreateRecipeMutationResult = Apollo.MutationResult<CreateRecipeMutation>;
export type CreateRecipeMutationOptions = Apollo.BaseMutationOptions<CreateRecipeMutation, CreateRecipeMutationVariables>;
export const UpdateRecipeDocument = gql`
    mutation updateRecipe($input: UpdateRecipeInput!) {
  updateRecipe(input: $input) {
    ... on Recipe {
      ...RecipeFields
    }
    ... on Error {
      message
    }
  }
}
    ${RecipeFieldsFragmentDoc}`;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>) {
        return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, baseOptions);
      }
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>;
export const ActivateRecipeDocument = gql`
    mutation activateRecipe($id: ID!) {
  updateRecipe(input: {active: true, id: $id}) {
    ... on Recipe {
      ...RecipeFields
    }
    ... on Error {
      message
    }
  }
}
    ${RecipeFieldsFragmentDoc}`;
export type ActivateRecipeMutationFn = Apollo.MutationFunction<ActivateRecipeMutation, ActivateRecipeMutationVariables>;

/**
 * __useActivateRecipeMutation__
 *
 * To run a mutation, you first call `useActivateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateRecipeMutation, { data, loading, error }] = useActivateRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<ActivateRecipeMutation, ActivateRecipeMutationVariables>) {
        return Apollo.useMutation<ActivateRecipeMutation, ActivateRecipeMutationVariables>(ActivateRecipeDocument, baseOptions);
      }
export type ActivateRecipeMutationHookResult = ReturnType<typeof useActivateRecipeMutation>;
export type ActivateRecipeMutationResult = Apollo.MutationResult<ActivateRecipeMutation>;
export type ActivateRecipeMutationOptions = Apollo.BaseMutationOptions<ActivateRecipeMutation, ActivateRecipeMutationVariables>;
export const DeactivateRecipeDocument = gql`
    mutation deactivateRecipe($id: ID!) {
  updateRecipe(input: {active: false, id: $id}) {
    ... on Recipe {
      ...RecipeFields
    }
    ... on Error {
      message
    }
  }
}
    ${RecipeFieldsFragmentDoc}`;
export type DeactivateRecipeMutationFn = Apollo.MutationFunction<DeactivateRecipeMutation, DeactivateRecipeMutationVariables>;

/**
 * __useDeactivateRecipeMutation__
 *
 * To run a mutation, you first call `useDeactivateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateRecipeMutation, { data, loading, error }] = useDeactivateRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateRecipeMutation, DeactivateRecipeMutationVariables>) {
        return Apollo.useMutation<DeactivateRecipeMutation, DeactivateRecipeMutationVariables>(DeactivateRecipeDocument, baseOptions);
      }
export type DeactivateRecipeMutationHookResult = ReturnType<typeof useDeactivateRecipeMutation>;
export type DeactivateRecipeMutationResult = Apollo.MutationResult<DeactivateRecipeMutation>;
export type DeactivateRecipeMutationOptions = Apollo.BaseMutationOptions<DeactivateRecipeMutation, DeactivateRecipeMutationVariables>;
export const StrainsDocument = gql`
    query strains($filters: StrainFilterSetInput) {
  strains(filters: $filters) {
    ...StrainFields
  }
}
    ${StrainFieldsFragmentDoc}`;

/**
 * __useStrainsQuery__
 *
 * To run a query within a React component, call `useStrainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrainsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useStrainsQuery(baseOptions?: Apollo.QueryHookOptions<StrainsQuery, StrainsQueryVariables>) {
        return Apollo.useQuery<StrainsQuery, StrainsQueryVariables>(StrainsDocument, baseOptions);
      }
export function useStrainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrainsQuery, StrainsQueryVariables>) {
          return Apollo.useLazyQuery<StrainsQuery, StrainsQueryVariables>(StrainsDocument, baseOptions);
        }
export type StrainsQueryHookResult = ReturnType<typeof useStrainsQuery>;
export type StrainsLazyQueryHookResult = ReturnType<typeof useStrainsLazyQuery>;
export type StrainsQueryResult = Apollo.QueryResult<StrainsQuery, StrainsQueryVariables>;
export const CreateStrainDocument = gql`
    mutation createStrain($input: CreateStrainInput!) {
  createStrain(input: $input) {
    ... on Strain {
      ...StrainFields
    }
    ... on Error {
      message
    }
  }
}
    ${StrainFieldsFragmentDoc}`;
export type CreateStrainMutationFn = Apollo.MutationFunction<CreateStrainMutation, CreateStrainMutationVariables>;

/**
 * __useCreateStrainMutation__
 *
 * To run a mutation, you first call `useCreateStrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStrainMutation, { data, loading, error }] = useCreateStrainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStrainMutation(baseOptions?: Apollo.MutationHookOptions<CreateStrainMutation, CreateStrainMutationVariables>) {
        return Apollo.useMutation<CreateStrainMutation, CreateStrainMutationVariables>(CreateStrainDocument, baseOptions);
      }
export type CreateStrainMutationHookResult = ReturnType<typeof useCreateStrainMutation>;
export type CreateStrainMutationResult = Apollo.MutationResult<CreateStrainMutation>;
export type CreateStrainMutationOptions = Apollo.BaseMutationOptions<CreateStrainMutation, CreateStrainMutationVariables>;
export const UpdateStrainDocument = gql`
    mutation updateStrain($input: UpdateStrainInput!) {
  updateStrain(input: $input) {
    ... on Strain {
      ...StrainFields
    }
    ... on Error {
      message
    }
  }
}
    ${StrainFieldsFragmentDoc}`;
export type UpdateStrainMutationFn = Apollo.MutationFunction<UpdateStrainMutation, UpdateStrainMutationVariables>;

/**
 * __useUpdateStrainMutation__
 *
 * To run a mutation, you first call `useUpdateStrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrainMutation, { data, loading, error }] = useUpdateStrainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrainMutation, UpdateStrainMutationVariables>) {
        return Apollo.useMutation<UpdateStrainMutation, UpdateStrainMutationVariables>(UpdateStrainDocument, baseOptions);
      }
export type UpdateStrainMutationHookResult = ReturnType<typeof useUpdateStrainMutation>;
export type UpdateStrainMutationResult = Apollo.MutationResult<UpdateStrainMutation>;
export type UpdateStrainMutationOptions = Apollo.BaseMutationOptions<UpdateStrainMutation, UpdateStrainMutationVariables>;
export const ActivateStrainDocument = gql`
    mutation activateStrain($id: ID!) {
  updateStrain(input: {active: true, id: $id}) {
    ... on Strain {
      ...StrainFields
    }
    ... on Error {
      message
    }
  }
}
    ${StrainFieldsFragmentDoc}`;
export type ActivateStrainMutationFn = Apollo.MutationFunction<ActivateStrainMutation, ActivateStrainMutationVariables>;

/**
 * __useActivateStrainMutation__
 *
 * To run a mutation, you first call `useActivateStrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateStrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateStrainMutation, { data, loading, error }] = useActivateStrainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateStrainMutation(baseOptions?: Apollo.MutationHookOptions<ActivateStrainMutation, ActivateStrainMutationVariables>) {
        return Apollo.useMutation<ActivateStrainMutation, ActivateStrainMutationVariables>(ActivateStrainDocument, baseOptions);
      }
export type ActivateStrainMutationHookResult = ReturnType<typeof useActivateStrainMutation>;
export type ActivateStrainMutationResult = Apollo.MutationResult<ActivateStrainMutation>;
export type ActivateStrainMutationOptions = Apollo.BaseMutationOptions<ActivateStrainMutation, ActivateStrainMutationVariables>;
export const DeactivateStrainDocument = gql`
    mutation deactivateStrain($id: ID!) {
  updateStrain(input: {active: false, id: $id}) {
    ... on Strain {
      ...StrainFields
    }
    ... on Error {
      message
    }
  }
}
    ${StrainFieldsFragmentDoc}`;
export type DeactivateStrainMutationFn = Apollo.MutationFunction<DeactivateStrainMutation, DeactivateStrainMutationVariables>;

/**
 * __useDeactivateStrainMutation__
 *
 * To run a mutation, you first call `useDeactivateStrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateStrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateStrainMutation, { data, loading, error }] = useDeactivateStrainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateStrainMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateStrainMutation, DeactivateStrainMutationVariables>) {
        return Apollo.useMutation<DeactivateStrainMutation, DeactivateStrainMutationVariables>(DeactivateStrainDocument, baseOptions);
      }
export type DeactivateStrainMutationHookResult = ReturnType<typeof useDeactivateStrainMutation>;
export type DeactivateStrainMutationResult = Apollo.MutationResult<DeactivateStrainMutation>;
export type DeactivateStrainMutationOptions = Apollo.BaseMutationOptions<DeactivateStrainMutation, DeactivateStrainMutationVariables>;
export const StrainsAndRecipesAndNutrientsDocument = gql`
    query strainsAndRecipesAndNutrients($harvestGroupId: String!, $filters: RecipeFilterSetInput) {
  harvestGroup(id: $harvestGroupId) {
    strains {
      ...StrainReportFields
    }
  }
  recipes(filters: $filters) {
    ...RecipeFields
  }
  nutrients {
    ...NutrientFields
  }
}
    ${StrainReportFieldsFragmentDoc}
${RecipeFieldsFragmentDoc}
${NutrientFieldsFragmentDoc}`;

/**
 * __useStrainsAndRecipesAndNutrientsQuery__
 *
 * To run a query within a React component, call `useStrainsAndRecipesAndNutrientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrainsAndRecipesAndNutrientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrainsAndRecipesAndNutrientsQuery({
 *   variables: {
 *      harvestGroupId: // value for 'harvestGroupId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useStrainsAndRecipesAndNutrientsQuery(baseOptions?: Apollo.QueryHookOptions<StrainsAndRecipesAndNutrientsQuery, StrainsAndRecipesAndNutrientsQueryVariables>) {
        return Apollo.useQuery<StrainsAndRecipesAndNutrientsQuery, StrainsAndRecipesAndNutrientsQueryVariables>(StrainsAndRecipesAndNutrientsDocument, baseOptions);
      }
export function useStrainsAndRecipesAndNutrientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrainsAndRecipesAndNutrientsQuery, StrainsAndRecipesAndNutrientsQueryVariables>) {
          return Apollo.useLazyQuery<StrainsAndRecipesAndNutrientsQuery, StrainsAndRecipesAndNutrientsQueryVariables>(StrainsAndRecipesAndNutrientsDocument, baseOptions);
        }
export type StrainsAndRecipesAndNutrientsQueryHookResult = ReturnType<typeof useStrainsAndRecipesAndNutrientsQuery>;
export type StrainsAndRecipesAndNutrientsLazyQueryHookResult = ReturnType<typeof useStrainsAndRecipesAndNutrientsLazyQuery>;
export type StrainsAndRecipesAndNutrientsQueryResult = Apollo.QueryResult<StrainsAndRecipesAndNutrientsQuery, StrainsAndRecipesAndNutrientsQueryVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($input: CreateTaskInput!) {
  createTask(input: $input) {
    ... on Task {
      ...TaskFields
    }
    ... on Error {
      message
    }
  }
}
    ${TaskFieldsFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, baseOptions);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($input: UpdateTaskInput!) {
  updateTask(input: $input) {
    ... on Task {
      ...TaskFields
    }
    ... on Error {
      message
    }
  }
}
    ${TaskFieldsFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, baseOptions);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const AddHarvestGroupTemplateDocument = gql`
    mutation addHarvestGroupTemplate($input: CreateHarvestGroupTemplateInput!) {
  createHarvestGroupTemplate(input: $input) {
    ... on HarvestGroupTemplate {
      ...HarvestGroupTemplateFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupTemplateFieldsFragmentDoc}`;
export type AddHarvestGroupTemplateMutationFn = Apollo.MutationFunction<AddHarvestGroupTemplateMutation, AddHarvestGroupTemplateMutationVariables>;

/**
 * __useAddHarvestGroupTemplateMutation__
 *
 * To run a mutation, you first call `useAddHarvestGroupTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHarvestGroupTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHarvestGroupTemplateMutation, { data, loading, error }] = useAddHarvestGroupTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHarvestGroupTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddHarvestGroupTemplateMutation, AddHarvestGroupTemplateMutationVariables>) {
        return Apollo.useMutation<AddHarvestGroupTemplateMutation, AddHarvestGroupTemplateMutationVariables>(AddHarvestGroupTemplateDocument, baseOptions);
      }
export type AddHarvestGroupTemplateMutationHookResult = ReturnType<typeof useAddHarvestGroupTemplateMutation>;
export type AddHarvestGroupTemplateMutationResult = Apollo.MutationResult<AddHarvestGroupTemplateMutation>;
export type AddHarvestGroupTemplateMutationOptions = Apollo.BaseMutationOptions<AddHarvestGroupTemplateMutation, AddHarvestGroupTemplateMutationVariables>;
export const UpdateHarvestGroupTemplateDocument = gql`
    mutation updateHarvestGroupTemplate($input: UpdateHarvestGroupTemplateInput!) {
  updateHarvestGroupTemplate(input: $input) {
    ... on HarvestGroupTemplate {
      ...HarvestGroupTemplateFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupTemplateFieldsFragmentDoc}`;
export type UpdateHarvestGroupTemplateMutationFn = Apollo.MutationFunction<UpdateHarvestGroupTemplateMutation, UpdateHarvestGroupTemplateMutationVariables>;

/**
 * __useUpdateHarvestGroupTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateHarvestGroupTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHarvestGroupTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHarvestGroupTemplateMutation, { data, loading, error }] = useUpdateHarvestGroupTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHarvestGroupTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHarvestGroupTemplateMutation, UpdateHarvestGroupTemplateMutationVariables>) {
        return Apollo.useMutation<UpdateHarvestGroupTemplateMutation, UpdateHarvestGroupTemplateMutationVariables>(UpdateHarvestGroupTemplateDocument, baseOptions);
      }
export type UpdateHarvestGroupTemplateMutationHookResult = ReturnType<typeof useUpdateHarvestGroupTemplateMutation>;
export type UpdateHarvestGroupTemplateMutationResult = Apollo.MutationResult<UpdateHarvestGroupTemplateMutation>;
export type UpdateHarvestGroupTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateHarvestGroupTemplateMutation, UpdateHarvestGroupTemplateMutationVariables>;
export const HarvestGroupTemplatesDocument = gql`
    query harvestGroupTemplates($filters: HarvestGroupTemplateFilterSetInput) {
  harvestGroupTemplates(filters: $filters) {
    ...HarvestGroupTemplateFields
  }
}
    ${HarvestGroupTemplateFieldsFragmentDoc}`;

/**
 * __useHarvestGroupTemplatesQuery__
 *
 * To run a query within a React component, call `useHarvestGroupTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHarvestGroupTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHarvestGroupTemplatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useHarvestGroupTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<HarvestGroupTemplatesQuery, HarvestGroupTemplatesQueryVariables>) {
        return Apollo.useQuery<HarvestGroupTemplatesQuery, HarvestGroupTemplatesQueryVariables>(HarvestGroupTemplatesDocument, baseOptions);
      }
export function useHarvestGroupTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HarvestGroupTemplatesQuery, HarvestGroupTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<HarvestGroupTemplatesQuery, HarvestGroupTemplatesQueryVariables>(HarvestGroupTemplatesDocument, baseOptions);
        }
export type HarvestGroupTemplatesQueryHookResult = ReturnType<typeof useHarvestGroupTemplatesQuery>;
export type HarvestGroupTemplatesLazyQueryHookResult = ReturnType<typeof useHarvestGroupTemplatesLazyQuery>;
export type HarvestGroupTemplatesQueryResult = Apollo.QueryResult<HarvestGroupTemplatesQuery, HarvestGroupTemplatesQueryVariables>;
export const ArchiveHarvestGroupTemplateDocument = gql`
    mutation archiveHarvestGroupTemplate($id: ID!) {
  updateHarvestGroupTemplateArchived(input: {id: $id, archivedAt: "now"}) {
    ... on HarvestGroupTemplate {
      ...HarvestGroupTemplateFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupTemplateFieldsFragmentDoc}`;
export type ArchiveHarvestGroupTemplateMutationFn = Apollo.MutationFunction<ArchiveHarvestGroupTemplateMutation, ArchiveHarvestGroupTemplateMutationVariables>;

/**
 * __useArchiveHarvestGroupTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveHarvestGroupTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveHarvestGroupTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveHarvestGroupTemplateMutation, { data, loading, error }] = useArchiveHarvestGroupTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveHarvestGroupTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveHarvestGroupTemplateMutation, ArchiveHarvestGroupTemplateMutationVariables>) {
        return Apollo.useMutation<ArchiveHarvestGroupTemplateMutation, ArchiveHarvestGroupTemplateMutationVariables>(ArchiveHarvestGroupTemplateDocument, baseOptions);
      }
export type ArchiveHarvestGroupTemplateMutationHookResult = ReturnType<typeof useArchiveHarvestGroupTemplateMutation>;
export type ArchiveHarvestGroupTemplateMutationResult = Apollo.MutationResult<ArchiveHarvestGroupTemplateMutation>;
export type ArchiveHarvestGroupTemplateMutationOptions = Apollo.BaseMutationOptions<ArchiveHarvestGroupTemplateMutation, ArchiveHarvestGroupTemplateMutationVariables>;
export const UnarchiveHarvestGroupTemplateDocument = gql`
    mutation unarchiveHarvestGroupTemplate($id: ID!) {
  updateHarvestGroupTemplateArchived(input: {id: $id, archivedAt: "false"}) {
    ... on HarvestGroupTemplate {
      ...HarvestGroupTemplateFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupTemplateFieldsFragmentDoc}`;
export type UnarchiveHarvestGroupTemplateMutationFn = Apollo.MutationFunction<UnarchiveHarvestGroupTemplateMutation, UnarchiveHarvestGroupTemplateMutationVariables>;

/**
 * __useUnarchiveHarvestGroupTemplateMutation__
 *
 * To run a mutation, you first call `useUnarchiveHarvestGroupTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveHarvestGroupTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveHarvestGroupTemplateMutation, { data, loading, error }] = useUnarchiveHarvestGroupTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveHarvestGroupTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveHarvestGroupTemplateMutation, UnarchiveHarvestGroupTemplateMutationVariables>) {
        return Apollo.useMutation<UnarchiveHarvestGroupTemplateMutation, UnarchiveHarvestGroupTemplateMutationVariables>(UnarchiveHarvestGroupTemplateDocument, baseOptions);
      }
export type UnarchiveHarvestGroupTemplateMutationHookResult = ReturnType<typeof useUnarchiveHarvestGroupTemplateMutation>;
export type UnarchiveHarvestGroupTemplateMutationResult = Apollo.MutationResult<UnarchiveHarvestGroupTemplateMutation>;
export type UnarchiveHarvestGroupTemplateMutationOptions = Apollo.BaseMutationOptions<UnarchiveHarvestGroupTemplateMutation, UnarchiveHarvestGroupTemplateMutationVariables>;
export const UpdateActiveHarvestGroupTemplateVersionDocument = gql`
    mutation updateActiveHarvestGroupTemplateVersion($input: UpdateActiveHarvestGroupTemplateVersionInput!) {
  updateActiveHarvestGroupTemplateVersion(input: $input) {
    ... on HarvestGroupTemplate {
      ...HarvestGroupTemplateFields
    }
    ... on Error {
      message
    }
  }
}
    ${HarvestGroupTemplateFieldsFragmentDoc}`;
export type UpdateActiveHarvestGroupTemplateVersionMutationFn = Apollo.MutationFunction<UpdateActiveHarvestGroupTemplateVersionMutation, UpdateActiveHarvestGroupTemplateVersionMutationVariables>;

/**
 * __useUpdateActiveHarvestGroupTemplateVersionMutation__
 *
 * To run a mutation, you first call `useUpdateActiveHarvestGroupTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveHarvestGroupTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveHarvestGroupTemplateVersionMutation, { data, loading, error }] = useUpdateActiveHarvestGroupTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActiveHarvestGroupTemplateVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActiveHarvestGroupTemplateVersionMutation, UpdateActiveHarvestGroupTemplateVersionMutationVariables>) {
        return Apollo.useMutation<UpdateActiveHarvestGroupTemplateVersionMutation, UpdateActiveHarvestGroupTemplateVersionMutationVariables>(UpdateActiveHarvestGroupTemplateVersionDocument, baseOptions);
      }
export type UpdateActiveHarvestGroupTemplateVersionMutationHookResult = ReturnType<typeof useUpdateActiveHarvestGroupTemplateVersionMutation>;
export type UpdateActiveHarvestGroupTemplateVersionMutationResult = Apollo.MutationResult<UpdateActiveHarvestGroupTemplateVersionMutation>;
export type UpdateActiveHarvestGroupTemplateVersionMutationOptions = Apollo.BaseMutationOptions<UpdateActiveHarvestGroupTemplateVersionMutation, UpdateActiveHarvestGroupTemplateVersionMutationVariables>;
export const ZonesWithHarvestGroupsDocument = gql`
    query zonesWithHarvestGroups($zoneOptions: LogsFilterInput, $filters: ZoneFilterSetInput) {
  zones(filters: $filters) {
    ...ZoneWithHarvestGroupFields
  }
}
    ${ZoneWithHarvestGroupFieldsFragmentDoc}`;

/**
 * __useZonesWithHarvestGroupsQuery__
 *
 * To run a query within a React component, call `useZonesWithHarvestGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonesWithHarvestGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonesWithHarvestGroupsQuery({
 *   variables: {
 *      zoneOptions: // value for 'zoneOptions'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useZonesWithHarvestGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ZonesWithHarvestGroupsQuery, ZonesWithHarvestGroupsQueryVariables>) {
        return Apollo.useQuery<ZonesWithHarvestGroupsQuery, ZonesWithHarvestGroupsQueryVariables>(ZonesWithHarvestGroupsDocument, baseOptions);
      }
export function useZonesWithHarvestGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZonesWithHarvestGroupsQuery, ZonesWithHarvestGroupsQueryVariables>) {
          return Apollo.useLazyQuery<ZonesWithHarvestGroupsQuery, ZonesWithHarvestGroupsQueryVariables>(ZonesWithHarvestGroupsDocument, baseOptions);
        }
export type ZonesWithHarvestGroupsQueryHookResult = ReturnType<typeof useZonesWithHarvestGroupsQuery>;
export type ZonesWithHarvestGroupsLazyQueryHookResult = ReturnType<typeof useZonesWithHarvestGroupsLazyQuery>;
export type ZonesWithHarvestGroupsQueryResult = Apollo.QueryResult<ZonesWithHarvestGroupsQuery, ZonesWithHarvestGroupsQueryVariables>;
export const ZonesDocument = gql`
    query zones {
  zones {
    ...ZoneFields
  }
}
    ${ZoneFieldsFragmentDoc}`;

/**
 * __useZonesQuery__
 *
 * To run a query within a React component, call `useZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useZonesQuery(baseOptions?: Apollo.QueryHookOptions<ZonesQuery, ZonesQueryVariables>) {
        return Apollo.useQuery<ZonesQuery, ZonesQueryVariables>(ZonesDocument, baseOptions);
      }
export function useZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZonesQuery, ZonesQueryVariables>) {
          return Apollo.useLazyQuery<ZonesQuery, ZonesQueryVariables>(ZonesDocument, baseOptions);
        }
export type ZonesQueryHookResult = ReturnType<typeof useZonesQuery>;
export type ZonesLazyQueryHookResult = ReturnType<typeof useZonesLazyQuery>;
export type ZonesQueryResult = Apollo.QueryResult<ZonesQuery, ZonesQueryVariables>;
export const AppIndexPageDocument = gql`
    query AppIndexPage($filters: ZoneFilterSetInput) {
  zones(filters: $filters) {
    ...ZoneCardFields
  }
}
    ${ZoneCardFieldsFragmentDoc}`;

/**
 * __useAppIndexPageQuery__
 *
 * To run a query within a React component, call `useAppIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppIndexPageQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAppIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<AppIndexPageQuery, AppIndexPageQueryVariables>) {
        return Apollo.useQuery<AppIndexPageQuery, AppIndexPageQueryVariables>(AppIndexPageDocument, baseOptions);
      }
export function useAppIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppIndexPageQuery, AppIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<AppIndexPageQuery, AppIndexPageQueryVariables>(AppIndexPageDocument, baseOptions);
        }
export type AppIndexPageQueryHookResult = ReturnType<typeof useAppIndexPageQuery>;
export type AppIndexPageLazyQueryHookResult = ReturnType<typeof useAppIndexPageLazyQuery>;
export type AppIndexPageQueryResult = Apollo.QueryResult<AppIndexPageQuery, AppIndexPageQueryVariables>;
export const CreateZoneDocument = gql`
    mutation createZone($input: CreateZoneInput!) {
  createZone(input: $input) {
    ... on Zone {
      ...ZoneFields
    }
    ... on Error {
      message
    }
  }
}
    ${ZoneFieldsFragmentDoc}`;
export type CreateZoneMutationFn = Apollo.MutationFunction<CreateZoneMutation, CreateZoneMutationVariables>;

/**
 * __useCreateZoneMutation__
 *
 * To run a mutation, you first call `useCreateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createZoneMutation, { data, loading, error }] = useCreateZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateZoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateZoneMutation, CreateZoneMutationVariables>) {
        return Apollo.useMutation<CreateZoneMutation, CreateZoneMutationVariables>(CreateZoneDocument, baseOptions);
      }
export type CreateZoneMutationHookResult = ReturnType<typeof useCreateZoneMutation>;
export type CreateZoneMutationResult = Apollo.MutationResult<CreateZoneMutation>;
export type CreateZoneMutationOptions = Apollo.BaseMutationOptions<CreateZoneMutation, CreateZoneMutationVariables>;
export const UpdateZoneDocument = gql`
    mutation updateZone($input: UpdateZoneInput!) {
  updateZone(input: $input) {
    ... on Zone {
      ...ZoneFields
    }
    ... on Error {
      message
    }
  }
}
    ${ZoneFieldsFragmentDoc}`;
export type UpdateZoneMutationFn = Apollo.MutationFunction<UpdateZoneMutation, UpdateZoneMutationVariables>;

/**
 * __useUpdateZoneMutation__
 *
 * To run a mutation, you first call `useUpdateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoneMutation, { data, loading, error }] = useUpdateZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateZoneMutation, UpdateZoneMutationVariables>) {
        return Apollo.useMutation<UpdateZoneMutation, UpdateZoneMutationVariables>(UpdateZoneDocument, baseOptions);
      }
export type UpdateZoneMutationHookResult = ReturnType<typeof useUpdateZoneMutation>;
export type UpdateZoneMutationResult = Apollo.MutationResult<UpdateZoneMutation>;
export type UpdateZoneMutationOptions = Apollo.BaseMutationOptions<UpdateZoneMutation, UpdateZoneMutationVariables>;
export const ActivateZoneDocument = gql`
    mutation activateZone($id: ID!) {
  updateZone(input: {active: true, id: $id}) {
    ... on Zone {
      ...ZoneFields
    }
    ... on Error {
      message
    }
  }
}
    ${ZoneFieldsFragmentDoc}`;
export type ActivateZoneMutationFn = Apollo.MutationFunction<ActivateZoneMutation, ActivateZoneMutationVariables>;

/**
 * __useActivateZoneMutation__
 *
 * To run a mutation, you first call `useActivateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateZoneMutation, { data, loading, error }] = useActivateZoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateZoneMutation(baseOptions?: Apollo.MutationHookOptions<ActivateZoneMutation, ActivateZoneMutationVariables>) {
        return Apollo.useMutation<ActivateZoneMutation, ActivateZoneMutationVariables>(ActivateZoneDocument, baseOptions);
      }
export type ActivateZoneMutationHookResult = ReturnType<typeof useActivateZoneMutation>;
export type ActivateZoneMutationResult = Apollo.MutationResult<ActivateZoneMutation>;
export type ActivateZoneMutationOptions = Apollo.BaseMutationOptions<ActivateZoneMutation, ActivateZoneMutationVariables>;
export const DeactivateZoneDocument = gql`
    mutation deactivateZone($id: ID!) {
  updateZone(input: {active: false, id: $id}) {
    ... on Zone {
      ...ZoneFields
    }
    ... on Error {
      message
    }
  }
}
    ${ZoneFieldsFragmentDoc}`;
export type DeactivateZoneMutationFn = Apollo.MutationFunction<DeactivateZoneMutation, DeactivateZoneMutationVariables>;

/**
 * __useDeactivateZoneMutation__
 *
 * To run a mutation, you first call `useDeactivateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateZoneMutation, { data, loading, error }] = useDeactivateZoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateZoneMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateZoneMutation, DeactivateZoneMutationVariables>) {
        return Apollo.useMutation<DeactivateZoneMutation, DeactivateZoneMutationVariables>(DeactivateZoneDocument, baseOptions);
      }
export type DeactivateZoneMutationHookResult = ReturnType<typeof useDeactivateZoneMutation>;
export type DeactivateZoneMutationResult = Apollo.MutationResult<DeactivateZoneMutation>;
export type DeactivateZoneMutationOptions = Apollo.BaseMutationOptions<DeactivateZoneMutation, DeactivateZoneMutationVariables>;
export const ZoneNamesDocument = gql`
    query zoneNames($filters: ZoneFilterSetInput) {
  zones(filters: $filters) {
    ...ZoneIdAndName
  }
}
    ${ZoneIdAndNameFragmentDoc}`;

/**
 * __useZoneNamesQuery__
 *
 * To run a query within a React component, call `useZoneNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useZoneNamesQuery(baseOptions?: Apollo.QueryHookOptions<ZoneNamesQuery, ZoneNamesQueryVariables>) {
        return Apollo.useQuery<ZoneNamesQuery, ZoneNamesQueryVariables>(ZoneNamesDocument, baseOptions);
      }
export function useZoneNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneNamesQuery, ZoneNamesQueryVariables>) {
          return Apollo.useLazyQuery<ZoneNamesQuery, ZoneNamesQueryVariables>(ZoneNamesDocument, baseOptions);
        }
export type ZoneNamesQueryHookResult = ReturnType<typeof useZoneNamesQuery>;
export type ZoneNamesLazyQueryHookResult = ReturnType<typeof useZoneNamesLazyQuery>;
export type ZoneNamesQueryResult = Apollo.QueryResult<ZoneNamesQuery, ZoneNamesQueryVariables>;
export const ZonesOverviewDocument = gql`
    query zonesOverview($filters: ZoneFilterSetInput) {
  zones(filters: $filters) {
    ...ZoneFields
  }
}
    ${ZoneFieldsFragmentDoc}`;

/**
 * __useZonesOverviewQuery__
 *
 * To run a query within a React component, call `useZonesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonesOverviewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useZonesOverviewQuery(baseOptions?: Apollo.QueryHookOptions<ZonesOverviewQuery, ZonesOverviewQueryVariables>) {
        return Apollo.useQuery<ZonesOverviewQuery, ZonesOverviewQueryVariables>(ZonesOverviewDocument, baseOptions);
      }
export function useZonesOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZonesOverviewQuery, ZonesOverviewQueryVariables>) {
          return Apollo.useLazyQuery<ZonesOverviewQuery, ZonesOverviewQueryVariables>(ZonesOverviewDocument, baseOptions);
        }
export type ZonesOverviewQueryHookResult = ReturnType<typeof useZonesOverviewQuery>;
export type ZonesOverviewLazyQueryHookResult = ReturnType<typeof useZonesOverviewLazyQuery>;
export type ZonesOverviewQueryResult = Apollo.QueryResult<ZonesOverviewQuery, ZonesOverviewQueryVariables>;
export const TagsDocument = gql`
    query tags {
  tags {
    id
    name
    colorHexCode
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const ExpensesDocument = gql`
    query expenses($filters: ExpenseFilterSetInput) {
  expenses(filters: $filters) {
    ...ExpenseFields
  }
}
    ${ExpenseFieldsFragmentDoc}`;

/**
 * __useExpensesQuery__
 *
 * To run a query within a React component, call `useExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExpensesQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
        return Apollo.useQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, baseOptions);
      }
export function useExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
          return Apollo.useLazyQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, baseOptions);
        }
export type ExpensesQueryHookResult = ReturnType<typeof useExpensesQuery>;
export type ExpensesLazyQueryHookResult = ReturnType<typeof useExpensesLazyQuery>;
export type ExpensesQueryResult = Apollo.QueryResult<ExpensesQuery, ExpensesQueryVariables>;
export const ExpenseCategoriesDocument = gql`
    query expenseCategories($filters: ExpenseCategoryFilterSetInput) {
  expenseCategories(filters: $filters) {
    ...ExpenseCategoryFields
  }
}
    ${ExpenseCategoryFieldsFragmentDoc}`;

/**
 * __useExpenseCategoriesQuery__
 *
 * To run a query within a React component, call `useExpenseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExpenseCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>) {
        return Apollo.useQuery<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>(ExpenseCategoriesDocument, baseOptions);
      }
export function useExpenseCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>(ExpenseCategoriesDocument, baseOptions);
        }
export type ExpenseCategoriesQueryHookResult = ReturnType<typeof useExpenseCategoriesQuery>;
export type ExpenseCategoriesLazyQueryHookResult = ReturnType<typeof useExpenseCategoriesLazyQuery>;
export type ExpenseCategoriesQueryResult = Apollo.QueryResult<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>;
export const ExpenseReportAllocationBreakdownDocument = gql`
    query expenseReportAllocationBreakdown($filters: ExpenseReportAllocationFiltersInput) {
  expenseReportAllocationBreakdown(filters: $filters) {
    ...ExpenseReportAllocationBreakdown
  }
}
    ${ExpenseReportAllocationBreakdownFragmentDoc}`;

/**
 * __useExpenseReportAllocationBreakdownQuery__
 *
 * To run a query within a React component, call `useExpenseReportAllocationBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseReportAllocationBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseReportAllocationBreakdownQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExpenseReportAllocationBreakdownQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseReportAllocationBreakdownQuery, ExpenseReportAllocationBreakdownQueryVariables>) {
        return Apollo.useQuery<ExpenseReportAllocationBreakdownQuery, ExpenseReportAllocationBreakdownQueryVariables>(ExpenseReportAllocationBreakdownDocument, baseOptions);
      }
export function useExpenseReportAllocationBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseReportAllocationBreakdownQuery, ExpenseReportAllocationBreakdownQueryVariables>) {
          return Apollo.useLazyQuery<ExpenseReportAllocationBreakdownQuery, ExpenseReportAllocationBreakdownQueryVariables>(ExpenseReportAllocationBreakdownDocument, baseOptions);
        }
export type ExpenseReportAllocationBreakdownQueryHookResult = ReturnType<typeof useExpenseReportAllocationBreakdownQuery>;
export type ExpenseReportAllocationBreakdownLazyQueryHookResult = ReturnType<typeof useExpenseReportAllocationBreakdownLazyQuery>;
export type ExpenseReportAllocationBreakdownQueryResult = Apollo.QueryResult<ExpenseReportAllocationBreakdownQuery, ExpenseReportAllocationBreakdownQueryVariables>;
export const ExpenseReportByCategoryDocument = gql`
    query expenseReportByCategory($startDate: Date!, $endDate: Date!) {
  expenseReportByCategory(startDate: $startDate, endDate: $endDate) {
    period
    entries {
      ...ExpenseReportByCategoryEntry
    }
  }
}
    ${ExpenseReportByCategoryEntryFragmentDoc}`;

/**
 * __useExpenseReportByCategoryQuery__
 *
 * To run a query within a React component, call `useExpenseReportByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseReportByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseReportByCategoryQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExpenseReportByCategoryQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseReportByCategoryQuery, ExpenseReportByCategoryQueryVariables>) {
        return Apollo.useQuery<ExpenseReportByCategoryQuery, ExpenseReportByCategoryQueryVariables>(ExpenseReportByCategoryDocument, baseOptions);
      }
export function useExpenseReportByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseReportByCategoryQuery, ExpenseReportByCategoryQueryVariables>) {
          return Apollo.useLazyQuery<ExpenseReportByCategoryQuery, ExpenseReportByCategoryQueryVariables>(ExpenseReportByCategoryDocument, baseOptions);
        }
export type ExpenseReportByCategoryQueryHookResult = ReturnType<typeof useExpenseReportByCategoryQuery>;
export type ExpenseReportByCategoryLazyQueryHookResult = ReturnType<typeof useExpenseReportByCategoryLazyQuery>;
export type ExpenseReportByCategoryQueryResult = Apollo.QueryResult<ExpenseReportByCategoryQuery, ExpenseReportByCategoryQueryVariables>;
export const ExpenseReportMonthlySummaryDocument = gql`
    query expenseReportMonthlySummary($startDate: Date!, $endDate: Date!) {
  expenseReportMonthlySummary(startDate: $startDate, endDate: $endDate) {
    period
    months {
      month
      cultivationCostInCents
      processingCostInCents
    }
  }
}
    `;

/**
 * __useExpenseReportMonthlySummaryQuery__
 *
 * To run a query within a React component, call `useExpenseReportMonthlySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseReportMonthlySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseReportMonthlySummaryQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExpenseReportMonthlySummaryQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseReportMonthlySummaryQuery, ExpenseReportMonthlySummaryQueryVariables>) {
        return Apollo.useQuery<ExpenseReportMonthlySummaryQuery, ExpenseReportMonthlySummaryQueryVariables>(ExpenseReportMonthlySummaryDocument, baseOptions);
      }
export function useExpenseReportMonthlySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseReportMonthlySummaryQuery, ExpenseReportMonthlySummaryQueryVariables>) {
          return Apollo.useLazyQuery<ExpenseReportMonthlySummaryQuery, ExpenseReportMonthlySummaryQueryVariables>(ExpenseReportMonthlySummaryDocument, baseOptions);
        }
export type ExpenseReportMonthlySummaryQueryHookResult = ReturnType<typeof useExpenseReportMonthlySummaryQuery>;
export type ExpenseReportMonthlySummaryLazyQueryHookResult = ReturnType<typeof useExpenseReportMonthlySummaryLazyQuery>;
export type ExpenseReportMonthlySummaryQueryResult = Apollo.QueryResult<ExpenseReportMonthlySummaryQuery, ExpenseReportMonthlySummaryQueryVariables>;
export const CreateExpenseDocument = gql`
    mutation createExpense($input: CreateExpenseInput!) {
  createExpense(input: $input) {
    ...ExpenseFields
  }
}
    ${ExpenseFieldsFragmentDoc}`;
export type CreateExpenseMutationFn = Apollo.MutationFunction<CreateExpenseMutation, CreateExpenseMutationVariables>;

/**
 * __useCreateExpenseMutation__
 *
 * To run a mutation, you first call `useCreateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseMutation, { data, loading, error }] = useCreateExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseMutation, CreateExpenseMutationVariables>) {
        return Apollo.useMutation<CreateExpenseMutation, CreateExpenseMutationVariables>(CreateExpenseDocument, baseOptions);
      }
export type CreateExpenseMutationHookResult = ReturnType<typeof useCreateExpenseMutation>;
export type CreateExpenseMutationResult = Apollo.MutationResult<CreateExpenseMutation>;
export type CreateExpenseMutationOptions = Apollo.BaseMutationOptions<CreateExpenseMutation, CreateExpenseMutationVariables>;
export const UpdateExpenseDocument = gql`
    mutation updateExpense($input: UpdateExpenseInput!) {
  updateExpense(input: $input) {
    ...ExpenseFields
  }
}
    ${ExpenseFieldsFragmentDoc}`;
export type UpdateExpenseMutationFn = Apollo.MutationFunction<UpdateExpenseMutation, UpdateExpenseMutationVariables>;

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
        return Apollo.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, baseOptions);
      }
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>;
export const ProcessingJobsDocument = gql`
    query processingJobs($filters: ProcessingJobFilterSetInput) {
  processingJobs(filters: $filters) {
    ...ProcessingJobOverviewWithGroupFields
  }
}
    ${ProcessingJobOverviewWithGroupFieldsFragmentDoc}`;

/**
 * __useProcessingJobsQuery__
 *
 * To run a query within a React component, call `useProcessingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingJobsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProcessingJobsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingJobsQuery, ProcessingJobsQueryVariables>) {
        return Apollo.useQuery<ProcessingJobsQuery, ProcessingJobsQueryVariables>(ProcessingJobsDocument, baseOptions);
      }
export function useProcessingJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingJobsQuery, ProcessingJobsQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingJobsQuery, ProcessingJobsQueryVariables>(ProcessingJobsDocument, baseOptions);
        }
export type ProcessingJobsQueryHookResult = ReturnType<typeof useProcessingJobsQuery>;
export type ProcessingJobsLazyQueryHookResult = ReturnType<typeof useProcessingJobsLazyQuery>;
export type ProcessingJobsQueryResult = Apollo.QueryResult<ProcessingJobsQuery, ProcessingJobsQueryVariables>;
export const ProcessingGroupJobDocument = gql`
    query processingGroupJob($id: ID!) {
  processingJob(id: $id) {
    ...ProcessingJobOverviewWithLogsFields
  }
}
    ${ProcessingJobOverviewWithLogsFieldsFragmentDoc}`;

/**
 * __useProcessingGroupJobQuery__
 *
 * To run a query within a React component, call `useProcessingGroupJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessingGroupJobQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupJobQuery, ProcessingGroupJobQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupJobQuery, ProcessingGroupJobQueryVariables>(ProcessingGroupJobDocument, baseOptions);
      }
export function useProcessingGroupJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupJobQuery, ProcessingGroupJobQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupJobQuery, ProcessingGroupJobQueryVariables>(ProcessingGroupJobDocument, baseOptions);
        }
export type ProcessingGroupJobQueryHookResult = ReturnType<typeof useProcessingGroupJobQuery>;
export type ProcessingGroupJobLazyQueryHookResult = ReturnType<typeof useProcessingGroupJobLazyQuery>;
export type ProcessingGroupJobQueryResult = Apollo.QueryResult<ProcessingGroupJobQuery, ProcessingGroupJobQueryVariables>;
export const CreateProcessingJobDocument = gql`
    mutation createProcessingJob($input: CreateProcessingJobInput!) {
  createProcessingJob(input: $input) {
    ... on ProcessingJob {
      ...ProcessingJobOverviewFields
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingJobOverviewFieldsFragmentDoc}`;
export type CreateProcessingJobMutationFn = Apollo.MutationFunction<CreateProcessingJobMutation, CreateProcessingJobMutationVariables>;

/**
 * __useCreateProcessingJobMutation__
 *
 * To run a mutation, you first call `useCreateProcessingJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessingJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessingJobMutation, { data, loading, error }] = useCreateProcessingJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProcessingJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateProcessingJobMutation, CreateProcessingJobMutationVariables>) {
        return Apollo.useMutation<CreateProcessingJobMutation, CreateProcessingJobMutationVariables>(CreateProcessingJobDocument, baseOptions);
      }
export type CreateProcessingJobMutationHookResult = ReturnType<typeof useCreateProcessingJobMutation>;
export type CreateProcessingJobMutationResult = Apollo.MutationResult<CreateProcessingJobMutation>;
export type CreateProcessingJobMutationOptions = Apollo.BaseMutationOptions<CreateProcessingJobMutation, CreateProcessingJobMutationVariables>;
export const AssignProcessingJobToUserDocument = gql`
    mutation assignProcessingJobToUser($input: AssignProcessingJobToUserInput!) {
  assignProcessingJobToUser(input: $input) {
    ... on ProcessingJob {
      ...ProcessingJobOverviewFields
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingJobOverviewFieldsFragmentDoc}`;
export type AssignProcessingJobToUserMutationFn = Apollo.MutationFunction<AssignProcessingJobToUserMutation, AssignProcessingJobToUserMutationVariables>;

/**
 * __useAssignProcessingJobToUserMutation__
 *
 * To run a mutation, you first call `useAssignProcessingJobToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProcessingJobToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProcessingJobToUserMutation, { data, loading, error }] = useAssignProcessingJobToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignProcessingJobToUserMutation(baseOptions?: Apollo.MutationHookOptions<AssignProcessingJobToUserMutation, AssignProcessingJobToUserMutationVariables>) {
        return Apollo.useMutation<AssignProcessingJobToUserMutation, AssignProcessingJobToUserMutationVariables>(AssignProcessingJobToUserDocument, baseOptions);
      }
export type AssignProcessingJobToUserMutationHookResult = ReturnType<typeof useAssignProcessingJobToUserMutation>;
export type AssignProcessingJobToUserMutationResult = Apollo.MutationResult<AssignProcessingJobToUserMutation>;
export type AssignProcessingJobToUserMutationOptions = Apollo.BaseMutationOptions<AssignProcessingJobToUserMutation, AssignProcessingJobToUserMutationVariables>;
export const UpdateProcessingJobDocument = gql`
    mutation updateProcessingJob($input: UpdateProcessingJobInput!) {
  updateProcessingJob(input: $input) {
    ... on ProcessingJob {
      ...ProcessingJobOverviewFields
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingJobOverviewFieldsFragmentDoc}`;
export type UpdateProcessingJobMutationFn = Apollo.MutationFunction<UpdateProcessingJobMutation, UpdateProcessingJobMutationVariables>;

/**
 * __useUpdateProcessingJobMutation__
 *
 * To run a mutation, you first call `useUpdateProcessingJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessingJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessingJobMutation, { data, loading, error }] = useUpdateProcessingJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProcessingJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProcessingJobMutation, UpdateProcessingJobMutationVariables>) {
        return Apollo.useMutation<UpdateProcessingJobMutation, UpdateProcessingJobMutationVariables>(UpdateProcessingJobDocument, baseOptions);
      }
export type UpdateProcessingJobMutationHookResult = ReturnType<typeof useUpdateProcessingJobMutation>;
export type UpdateProcessingJobMutationResult = Apollo.MutationResult<UpdateProcessingJobMutation>;
export type UpdateProcessingJobMutationOptions = Apollo.BaseMutationOptions<UpdateProcessingJobMutation, UpdateProcessingJobMutationVariables>;
export const CreateProcessingJobLogDocument = gql`
    mutation createProcessingJobLog($input: CreateProcessingJobLogInput!) {
  createProcessingJobLog(input: $input) {
    ... on ProcessingJobLog {
      ...ProcessingJobLogFields
      job {
        ...ProcessingJobOverviewFields
      }
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingJobLogFieldsFragmentDoc}
${ProcessingJobOverviewFieldsFragmentDoc}`;
export type CreateProcessingJobLogMutationFn = Apollo.MutationFunction<CreateProcessingJobLogMutation, CreateProcessingJobLogMutationVariables>;

/**
 * __useCreateProcessingJobLogMutation__
 *
 * To run a mutation, you first call `useCreateProcessingJobLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessingJobLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessingJobLogMutation, { data, loading, error }] = useCreateProcessingJobLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProcessingJobLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateProcessingJobLogMutation, CreateProcessingJobLogMutationVariables>) {
        return Apollo.useMutation<CreateProcessingJobLogMutation, CreateProcessingJobLogMutationVariables>(CreateProcessingJobLogDocument, baseOptions);
      }
export type CreateProcessingJobLogMutationHookResult = ReturnType<typeof useCreateProcessingJobLogMutation>;
export type CreateProcessingJobLogMutationResult = Apollo.MutationResult<CreateProcessingJobLogMutation>;
export type CreateProcessingJobLogMutationOptions = Apollo.BaseMutationOptions<CreateProcessingJobLogMutation, CreateProcessingJobLogMutationVariables>;
export const UpdateProcessingJobLogDocument = gql`
    mutation updateProcessingJobLog($input: UpdateProcessingJobLogInput!) {
  updateProcessingJobLog(input: $input) {
    ... on ProcessingJobLog {
      ...ProcessingJobLogFields
      job {
        ...ProcessingJobOverviewFields
      }
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingJobLogFieldsFragmentDoc}
${ProcessingJobOverviewFieldsFragmentDoc}`;
export type UpdateProcessingJobLogMutationFn = Apollo.MutationFunction<UpdateProcessingJobLogMutation, UpdateProcessingJobLogMutationVariables>;

/**
 * __useUpdateProcessingJobLogMutation__
 *
 * To run a mutation, you first call `useUpdateProcessingJobLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessingJobLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessingJobLogMutation, { data, loading, error }] = useUpdateProcessingJobLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProcessingJobLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProcessingJobLogMutation, UpdateProcessingJobLogMutationVariables>) {
        return Apollo.useMutation<UpdateProcessingJobLogMutation, UpdateProcessingJobLogMutationVariables>(UpdateProcessingJobLogDocument, baseOptions);
      }
export type UpdateProcessingJobLogMutationHookResult = ReturnType<typeof useUpdateProcessingJobLogMutation>;
export type UpdateProcessingJobLogMutationResult = Apollo.MutationResult<UpdateProcessingJobLogMutation>;
export type UpdateProcessingJobLogMutationOptions = Apollo.BaseMutationOptions<UpdateProcessingJobLogMutation, UpdateProcessingJobLogMutationVariables>;
export const ProcessingGroupsDocument = gql`
    query processingGroups($filters: ProcessingGroupFilterSetInput) {
  processingGroups(filters: $filters) {
    ...ProcessingGroupFields
  }
}
    ${ProcessingGroupFieldsFragmentDoc}`;

/**
 * __useProcessingGroupsQuery__
 *
 * To run a query within a React component, call `useProcessingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProcessingGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupsQuery, ProcessingGroupsQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupsQuery, ProcessingGroupsQueryVariables>(ProcessingGroupsDocument, baseOptions);
      }
export function useProcessingGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupsQuery, ProcessingGroupsQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupsQuery, ProcessingGroupsQueryVariables>(ProcessingGroupsDocument, baseOptions);
        }
export type ProcessingGroupsQueryHookResult = ReturnType<typeof useProcessingGroupsQuery>;
export type ProcessingGroupsLazyQueryHookResult = ReturnType<typeof useProcessingGroupsLazyQuery>;
export type ProcessingGroupsQueryResult = Apollo.QueryResult<ProcessingGroupsQuery, ProcessingGroupsQueryVariables>;
export const CurrentAndUpcomingProcessingGroupsDocument = gql`
    query currentAndUpcomingProcessingGroups($unarchived: String) {
  processingGroups(filters: {unarchived: $unarchived}) {
    ...ProcessingGroupFields
  }
}
    ${ProcessingGroupFieldsFragmentDoc}`;

/**
 * __useCurrentAndUpcomingProcessingGroupsQuery__
 *
 * To run a query within a React component, call `useCurrentAndUpcomingProcessingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAndUpcomingProcessingGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAndUpcomingProcessingGroupsQuery({
 *   variables: {
 *      unarchived: // value for 'unarchived'
 *   },
 * });
 */
export function useCurrentAndUpcomingProcessingGroupsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAndUpcomingProcessingGroupsQuery, CurrentAndUpcomingProcessingGroupsQueryVariables>) {
        return Apollo.useQuery<CurrentAndUpcomingProcessingGroupsQuery, CurrentAndUpcomingProcessingGroupsQueryVariables>(CurrentAndUpcomingProcessingGroupsDocument, baseOptions);
      }
export function useCurrentAndUpcomingProcessingGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAndUpcomingProcessingGroupsQuery, CurrentAndUpcomingProcessingGroupsQueryVariables>) {
          return Apollo.useLazyQuery<CurrentAndUpcomingProcessingGroupsQuery, CurrentAndUpcomingProcessingGroupsQueryVariables>(CurrentAndUpcomingProcessingGroupsDocument, baseOptions);
        }
export type CurrentAndUpcomingProcessingGroupsQueryHookResult = ReturnType<typeof useCurrentAndUpcomingProcessingGroupsQuery>;
export type CurrentAndUpcomingProcessingGroupsLazyQueryHookResult = ReturnType<typeof useCurrentAndUpcomingProcessingGroupsLazyQuery>;
export type CurrentAndUpcomingProcessingGroupsQueryResult = Apollo.QueryResult<CurrentAndUpcomingProcessingGroupsQuery, CurrentAndUpcomingProcessingGroupsQueryVariables>;
export const CompletedProcessingGroupsDocument = gql`
    query completedProcessingGroups {
  processingGroups(filters: {status: "COMPLETE"}) {
    ...ProcessingGroupOverviewFields
  }
}
    ${ProcessingGroupOverviewFieldsFragmentDoc}`;

/**
 * __useCompletedProcessingGroupsQuery__
 *
 * To run a query within a React component, call `useCompletedProcessingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedProcessingGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedProcessingGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletedProcessingGroupsQuery(baseOptions?: Apollo.QueryHookOptions<CompletedProcessingGroupsQuery, CompletedProcessingGroupsQueryVariables>) {
        return Apollo.useQuery<CompletedProcessingGroupsQuery, CompletedProcessingGroupsQueryVariables>(CompletedProcessingGroupsDocument, baseOptions);
      }
export function useCompletedProcessingGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedProcessingGroupsQuery, CompletedProcessingGroupsQueryVariables>) {
          return Apollo.useLazyQuery<CompletedProcessingGroupsQuery, CompletedProcessingGroupsQueryVariables>(CompletedProcessingGroupsDocument, baseOptions);
        }
export type CompletedProcessingGroupsQueryHookResult = ReturnType<typeof useCompletedProcessingGroupsQuery>;
export type CompletedProcessingGroupsLazyQueryHookResult = ReturnType<typeof useCompletedProcessingGroupsLazyQuery>;
export type CompletedProcessingGroupsQueryResult = Apollo.QueryResult<CompletedProcessingGroupsQuery, CompletedProcessingGroupsQueryVariables>;
export const ProcessingGroupDocument = gql`
    query processingGroup($id: String!) {
  processingGroup(id: $id) {
    ...ProcessingGroupOverviewFields
  }
}
    ${ProcessingGroupOverviewFieldsFragmentDoc}`;

/**
 * __useProcessingGroupQuery__
 *
 * To run a query within a React component, call `useProcessingGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessingGroupQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupQuery, ProcessingGroupQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupQuery, ProcessingGroupQueryVariables>(ProcessingGroupDocument, baseOptions);
      }
export function useProcessingGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupQuery, ProcessingGroupQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupQuery, ProcessingGroupQueryVariables>(ProcessingGroupDocument, baseOptions);
        }
export type ProcessingGroupQueryHookResult = ReturnType<typeof useProcessingGroupQuery>;
export type ProcessingGroupLazyQueryHookResult = ReturnType<typeof useProcessingGroupLazyQuery>;
export type ProcessingGroupQueryResult = Apollo.QueryResult<ProcessingGroupQuery, ProcessingGroupQueryVariables>;
export const ProcessingGroupWithJobsDocument = gql`
    query processingGroupWithJobs($id: String!) {
  processingGroup(id: $id) {
    ...ProcessingGroupOverviewFields
    ...ProcessingGroupFieldsWithStageJobs
  }
}
    ${ProcessingGroupOverviewFieldsFragmentDoc}
${ProcessingGroupFieldsWithStageJobsFragmentDoc}`;

/**
 * __useProcessingGroupWithJobsQuery__
 *
 * To run a query within a React component, call `useProcessingGroupWithJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupWithJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupWithJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessingGroupWithJobsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupWithJobsQuery, ProcessingGroupWithJobsQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupWithJobsQuery, ProcessingGroupWithJobsQueryVariables>(ProcessingGroupWithJobsDocument, baseOptions);
      }
export function useProcessingGroupWithJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupWithJobsQuery, ProcessingGroupWithJobsQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupWithJobsQuery, ProcessingGroupWithJobsQueryVariables>(ProcessingGroupWithJobsDocument, baseOptions);
        }
export type ProcessingGroupWithJobsQueryHookResult = ReturnType<typeof useProcessingGroupWithJobsQuery>;
export type ProcessingGroupWithJobsLazyQueryHookResult = ReturnType<typeof useProcessingGroupWithJobsLazyQuery>;
export type ProcessingGroupWithJobsQueryResult = Apollo.QueryResult<ProcessingGroupWithJobsQuery, ProcessingGroupWithJobsQueryVariables>;
export const ProcessingGroupWithStrainsAndStagesDocument = gql`
    query processingGroupWithStrainsAndStages($id: String!, $strainId: String) {
  processingGroup(id: $id) {
    ...ProcessingGroupWithStrainsAndStagesFields
  }
}
    ${ProcessingGroupWithStrainsAndStagesFieldsFragmentDoc}`;

/**
 * __useProcessingGroupWithStrainsAndStagesQuery__
 *
 * To run a query within a React component, call `useProcessingGroupWithStrainsAndStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupWithStrainsAndStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupWithStrainsAndStagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      strainId: // value for 'strainId'
 *   },
 * });
 */
export function useProcessingGroupWithStrainsAndStagesQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupWithStrainsAndStagesQuery, ProcessingGroupWithStrainsAndStagesQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupWithStrainsAndStagesQuery, ProcessingGroupWithStrainsAndStagesQueryVariables>(ProcessingGroupWithStrainsAndStagesDocument, baseOptions);
      }
export function useProcessingGroupWithStrainsAndStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupWithStrainsAndStagesQuery, ProcessingGroupWithStrainsAndStagesQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupWithStrainsAndStagesQuery, ProcessingGroupWithStrainsAndStagesQueryVariables>(ProcessingGroupWithStrainsAndStagesDocument, baseOptions);
        }
export type ProcessingGroupWithStrainsAndStagesQueryHookResult = ReturnType<typeof useProcessingGroupWithStrainsAndStagesQuery>;
export type ProcessingGroupWithStrainsAndStagesLazyQueryHookResult = ReturnType<typeof useProcessingGroupWithStrainsAndStagesLazyQuery>;
export type ProcessingGroupWithStrainsAndStagesQueryResult = Apollo.QueryResult<ProcessingGroupWithStrainsAndStagesQuery, ProcessingGroupWithStrainsAndStagesQueryVariables>;
export const ProcessingGroupWithStrainsAndStagesAndJobsDocument = gql`
    query processingGroupWithStrainsAndStagesAndJobs($id: String!, $strainId: String) {
  processingGroup(id: $id) {
    ...ProcessingGroupWithStrainsAndStagesFieldsAndJobs
  }
}
    ${ProcessingGroupWithStrainsAndStagesFieldsAndJobsFragmentDoc}`;

/**
 * __useProcessingGroupWithStrainsAndStagesAndJobsQuery__
 *
 * To run a query within a React component, call `useProcessingGroupWithStrainsAndStagesAndJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupWithStrainsAndStagesAndJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupWithStrainsAndStagesAndJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      strainId: // value for 'strainId'
 *   },
 * });
 */
export function useProcessingGroupWithStrainsAndStagesAndJobsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupWithStrainsAndStagesAndJobsQuery, ProcessingGroupWithStrainsAndStagesAndJobsQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupWithStrainsAndStagesAndJobsQuery, ProcessingGroupWithStrainsAndStagesAndJobsQueryVariables>(ProcessingGroupWithStrainsAndStagesAndJobsDocument, baseOptions);
      }
export function useProcessingGroupWithStrainsAndStagesAndJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupWithStrainsAndStagesAndJobsQuery, ProcessingGroupWithStrainsAndStagesAndJobsQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupWithStrainsAndStagesAndJobsQuery, ProcessingGroupWithStrainsAndStagesAndJobsQueryVariables>(ProcessingGroupWithStrainsAndStagesAndJobsDocument, baseOptions);
        }
export type ProcessingGroupWithStrainsAndStagesAndJobsQueryHookResult = ReturnType<typeof useProcessingGroupWithStrainsAndStagesAndJobsQuery>;
export type ProcessingGroupWithStrainsAndStagesAndJobsLazyQueryHookResult = ReturnType<typeof useProcessingGroupWithStrainsAndStagesAndJobsLazyQuery>;
export type ProcessingGroupWithStrainsAndStagesAndJobsQueryResult = Apollo.QueryResult<ProcessingGroupWithStrainsAndStagesAndJobsQuery, ProcessingGroupWithStrainsAndStagesAndJobsQueryVariables>;
export const ProcessingGroupStatusDocument = gql`
    query processingGroupStatus($id: String!) {
  processingGroup(id: $id) {
    id
    name
    status
    processingGroupStrains {
      id
      status
    }
  }
}
    `;

/**
 * __useProcessingGroupStatusQuery__
 *
 * To run a query within a React component, call `useProcessingGroupStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessingGroupStatusQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupStatusQuery, ProcessingGroupStatusQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupStatusQuery, ProcessingGroupStatusQueryVariables>(ProcessingGroupStatusDocument, baseOptions);
      }
export function useProcessingGroupStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupStatusQuery, ProcessingGroupStatusQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupStatusQuery, ProcessingGroupStatusQueryVariables>(ProcessingGroupStatusDocument, baseOptions);
        }
export type ProcessingGroupStatusQueryHookResult = ReturnType<typeof useProcessingGroupStatusQuery>;
export type ProcessingGroupStatusLazyQueryHookResult = ReturnType<typeof useProcessingGroupStatusLazyQuery>;
export type ProcessingGroupStatusQueryResult = Apollo.QueryResult<ProcessingGroupStatusQuery, ProcessingGroupStatusQueryVariables>;
export const AllProcessingGroupsWithStrainsAndStagesDocument = gql`
    query allProcessingGroupsWithStrainsAndStages($strainId: String) {
  processingGroups {
    ...ProcessingGroupShortOverview
    ...ProcessingGroupWithStrainsAndStagesFields
  }
}
    ${ProcessingGroupShortOverviewFragmentDoc}
${ProcessingGroupWithStrainsAndStagesFieldsFragmentDoc}`;

/**
 * __useAllProcessingGroupsWithStrainsAndStagesQuery__
 *
 * To run a query within a React component, call `useAllProcessingGroupsWithStrainsAndStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProcessingGroupsWithStrainsAndStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProcessingGroupsWithStrainsAndStagesQuery({
 *   variables: {
 *      strainId: // value for 'strainId'
 *   },
 * });
 */
export function useAllProcessingGroupsWithStrainsAndStagesQuery(baseOptions?: Apollo.QueryHookOptions<AllProcessingGroupsWithStrainsAndStagesQuery, AllProcessingGroupsWithStrainsAndStagesQueryVariables>) {
        return Apollo.useQuery<AllProcessingGroupsWithStrainsAndStagesQuery, AllProcessingGroupsWithStrainsAndStagesQueryVariables>(AllProcessingGroupsWithStrainsAndStagesDocument, baseOptions);
      }
export function useAllProcessingGroupsWithStrainsAndStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProcessingGroupsWithStrainsAndStagesQuery, AllProcessingGroupsWithStrainsAndStagesQueryVariables>) {
          return Apollo.useLazyQuery<AllProcessingGroupsWithStrainsAndStagesQuery, AllProcessingGroupsWithStrainsAndStagesQueryVariables>(AllProcessingGroupsWithStrainsAndStagesDocument, baseOptions);
        }
export type AllProcessingGroupsWithStrainsAndStagesQueryHookResult = ReturnType<typeof useAllProcessingGroupsWithStrainsAndStagesQuery>;
export type AllProcessingGroupsWithStrainsAndStagesLazyQueryHookResult = ReturnType<typeof useAllProcessingGroupsWithStrainsAndStagesLazyQuery>;
export type AllProcessingGroupsWithStrainsAndStagesQueryResult = Apollo.QueryResult<AllProcessingGroupsWithStrainsAndStagesQuery, AllProcessingGroupsWithStrainsAndStagesQueryVariables>;
export const AllProcessingGroupsShortOverviewDocument = gql`
    query allProcessingGroupsShortOverview {
  processingGroups {
    ...ProcessingGroupShortOverview
  }
}
    ${ProcessingGroupShortOverviewFragmentDoc}`;

/**
 * __useAllProcessingGroupsShortOverviewQuery__
 *
 * To run a query within a React component, call `useAllProcessingGroupsShortOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProcessingGroupsShortOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProcessingGroupsShortOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProcessingGroupsShortOverviewQuery(baseOptions?: Apollo.QueryHookOptions<AllProcessingGroupsShortOverviewQuery, AllProcessingGroupsShortOverviewQueryVariables>) {
        return Apollo.useQuery<AllProcessingGroupsShortOverviewQuery, AllProcessingGroupsShortOverviewQueryVariables>(AllProcessingGroupsShortOverviewDocument, baseOptions);
      }
export function useAllProcessingGroupsShortOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProcessingGroupsShortOverviewQuery, AllProcessingGroupsShortOverviewQueryVariables>) {
          return Apollo.useLazyQuery<AllProcessingGroupsShortOverviewQuery, AllProcessingGroupsShortOverviewQueryVariables>(AllProcessingGroupsShortOverviewDocument, baseOptions);
        }
export type AllProcessingGroupsShortOverviewQueryHookResult = ReturnType<typeof useAllProcessingGroupsShortOverviewQuery>;
export type AllProcessingGroupsShortOverviewLazyQueryHookResult = ReturnType<typeof useAllProcessingGroupsShortOverviewLazyQuery>;
export type AllProcessingGroupsShortOverviewQueryResult = Apollo.QueryResult<AllProcessingGroupsShortOverviewQuery, AllProcessingGroupsShortOverviewQueryVariables>;
export const CreateProcessingGroupDocument = gql`
    mutation createProcessingGroup($input: CreateProcessingGroupInput!) {
  createProcessingGroup(input: $input) {
    ... on ProcessingGroup {
      ...ProcessingGroupFields
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingGroupFieldsFragmentDoc}`;
export type CreateProcessingGroupMutationFn = Apollo.MutationFunction<CreateProcessingGroupMutation, CreateProcessingGroupMutationVariables>;

/**
 * __useCreateProcessingGroupMutation__
 *
 * To run a mutation, you first call `useCreateProcessingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessingGroupMutation, { data, loading, error }] = useCreateProcessingGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProcessingGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateProcessingGroupMutation, CreateProcessingGroupMutationVariables>) {
        return Apollo.useMutation<CreateProcessingGroupMutation, CreateProcessingGroupMutationVariables>(CreateProcessingGroupDocument, baseOptions);
      }
export type CreateProcessingGroupMutationHookResult = ReturnType<typeof useCreateProcessingGroupMutation>;
export type CreateProcessingGroupMutationResult = Apollo.MutationResult<CreateProcessingGroupMutation>;
export type CreateProcessingGroupMutationOptions = Apollo.BaseMutationOptions<CreateProcessingGroupMutation, CreateProcessingGroupMutationVariables>;
export const CreateProcessingGroupStrainInventoryAdjustmentDocument = gql`
    mutation createProcessingGroupStrainInventoryAdjustment($input: CreateProcessingGroupStrainInventoryAdjustmentInput!) {
  createProcessingGroupStrainInventoryAdjustment(input: $input) {
    ... on ProcessingGroupStrain {
      ...ProcessingGroupStrainFields
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingGroupStrainFieldsFragmentDoc}`;
export type CreateProcessingGroupStrainInventoryAdjustmentMutationFn = Apollo.MutationFunction<CreateProcessingGroupStrainInventoryAdjustmentMutation, CreateProcessingGroupStrainInventoryAdjustmentMutationVariables>;

/**
 * __useCreateProcessingGroupStrainInventoryAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateProcessingGroupStrainInventoryAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessingGroupStrainInventoryAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessingGroupStrainInventoryAdjustmentMutation, { data, loading, error }] = useCreateProcessingGroupStrainInventoryAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProcessingGroupStrainInventoryAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateProcessingGroupStrainInventoryAdjustmentMutation, CreateProcessingGroupStrainInventoryAdjustmentMutationVariables>) {
        return Apollo.useMutation<CreateProcessingGroupStrainInventoryAdjustmentMutation, CreateProcessingGroupStrainInventoryAdjustmentMutationVariables>(CreateProcessingGroupStrainInventoryAdjustmentDocument, baseOptions);
      }
export type CreateProcessingGroupStrainInventoryAdjustmentMutationHookResult = ReturnType<typeof useCreateProcessingGroupStrainInventoryAdjustmentMutation>;
export type CreateProcessingGroupStrainInventoryAdjustmentMutationResult = Apollo.MutationResult<CreateProcessingGroupStrainInventoryAdjustmentMutation>;
export type CreateProcessingGroupStrainInventoryAdjustmentMutationOptions = Apollo.BaseMutationOptions<CreateProcessingGroupStrainInventoryAdjustmentMutation, CreateProcessingGroupStrainInventoryAdjustmentMutationVariables>;
export const ArchiveProcessingGroupDocument = gql`
    mutation archiveProcessingGroup($id: ID!) {
  updateProcessingGroupArchived(input: {archivedAt: "true", id: $id}) {
    ...ProcessingGroupFields
  }
}
    ${ProcessingGroupFieldsFragmentDoc}`;
export type ArchiveProcessingGroupMutationFn = Apollo.MutationFunction<ArchiveProcessingGroupMutation, ArchiveProcessingGroupMutationVariables>;

/**
 * __useArchiveProcessingGroupMutation__
 *
 * To run a mutation, you first call `useArchiveProcessingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProcessingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProcessingGroupMutation, { data, loading, error }] = useArchiveProcessingGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveProcessingGroupMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProcessingGroupMutation, ArchiveProcessingGroupMutationVariables>) {
        return Apollo.useMutation<ArchiveProcessingGroupMutation, ArchiveProcessingGroupMutationVariables>(ArchiveProcessingGroupDocument, baseOptions);
      }
export type ArchiveProcessingGroupMutationHookResult = ReturnType<typeof useArchiveProcessingGroupMutation>;
export type ArchiveProcessingGroupMutationResult = Apollo.MutationResult<ArchiveProcessingGroupMutation>;
export type ArchiveProcessingGroupMutationOptions = Apollo.BaseMutationOptions<ArchiveProcessingGroupMutation, ArchiveProcessingGroupMutationVariables>;
export const UnarchiveProcessingGroupDocument = gql`
    mutation unarchiveProcessingGroup($id: ID!) {
  updateProcessingGroupArchived(input: {archivedAt: "false", id: $id}) {
    ...ProcessingGroupFields
  }
}
    ${ProcessingGroupFieldsFragmentDoc}`;
export type UnarchiveProcessingGroupMutationFn = Apollo.MutationFunction<UnarchiveProcessingGroupMutation, UnarchiveProcessingGroupMutationVariables>;

/**
 * __useUnarchiveProcessingGroupMutation__
 *
 * To run a mutation, you first call `useUnarchiveProcessingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveProcessingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveProcessingGroupMutation, { data, loading, error }] = useUnarchiveProcessingGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveProcessingGroupMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveProcessingGroupMutation, UnarchiveProcessingGroupMutationVariables>) {
        return Apollo.useMutation<UnarchiveProcessingGroupMutation, UnarchiveProcessingGroupMutationVariables>(UnarchiveProcessingGroupDocument, baseOptions);
      }
export type UnarchiveProcessingGroupMutationHookResult = ReturnType<typeof useUnarchiveProcessingGroupMutation>;
export type UnarchiveProcessingGroupMutationResult = Apollo.MutationResult<UnarchiveProcessingGroupMutation>;
export type UnarchiveProcessingGroupMutationOptions = Apollo.BaseMutationOptions<UnarchiveProcessingGroupMutation, UnarchiveProcessingGroupMutationVariables>;
export const MoveUpProcessingGroupStrainDocument = gql`
    mutation moveUpProcessingGroupStrain($input: MoveUpProcessingGroupStrainInput!) {
  moveUpProcessingGroupStrain(input: $input) {
    ... on ProcessingGroupStrain {
      ...ProcessingGroupStrainFields
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingGroupStrainFieldsFragmentDoc}`;
export type MoveUpProcessingGroupStrainMutationFn = Apollo.MutationFunction<MoveUpProcessingGroupStrainMutation, MoveUpProcessingGroupStrainMutationVariables>;

/**
 * __useMoveUpProcessingGroupStrainMutation__
 *
 * To run a mutation, you first call `useMoveUpProcessingGroupStrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveUpProcessingGroupStrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveUpProcessingGroupStrainMutation, { data, loading, error }] = useMoveUpProcessingGroupStrainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveUpProcessingGroupStrainMutation(baseOptions?: Apollo.MutationHookOptions<MoveUpProcessingGroupStrainMutation, MoveUpProcessingGroupStrainMutationVariables>) {
        return Apollo.useMutation<MoveUpProcessingGroupStrainMutation, MoveUpProcessingGroupStrainMutationVariables>(MoveUpProcessingGroupStrainDocument, baseOptions);
      }
export type MoveUpProcessingGroupStrainMutationHookResult = ReturnType<typeof useMoveUpProcessingGroupStrainMutation>;
export type MoveUpProcessingGroupStrainMutationResult = Apollo.MutationResult<MoveUpProcessingGroupStrainMutation>;
export type MoveUpProcessingGroupStrainMutationOptions = Apollo.BaseMutationOptions<MoveUpProcessingGroupStrainMutation, MoveUpProcessingGroupStrainMutationVariables>;
export const FinishProcessingGroupStrainDocument = gql`
    mutation finishProcessingGroupStrain($input: FinishProcessingGroupStrainInput!) {
  finishProcessingGroupStrain(input: $input) {
    ... on ProcessingGroupStrain {
      ...ProcessingGroupStrainFields
    }
    ... on Error {
      message
    }
  }
}
    ${ProcessingGroupStrainFieldsFragmentDoc}`;
export type FinishProcessingGroupStrainMutationFn = Apollo.MutationFunction<FinishProcessingGroupStrainMutation, FinishProcessingGroupStrainMutationVariables>;

/**
 * __useFinishProcessingGroupStrainMutation__
 *
 * To run a mutation, you first call `useFinishProcessingGroupStrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProcessingGroupStrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProcessingGroupStrainMutation, { data, loading, error }] = useFinishProcessingGroupStrainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishProcessingGroupStrainMutation(baseOptions?: Apollo.MutationHookOptions<FinishProcessingGroupStrainMutation, FinishProcessingGroupStrainMutationVariables>) {
        return Apollo.useMutation<FinishProcessingGroupStrainMutation, FinishProcessingGroupStrainMutationVariables>(FinishProcessingGroupStrainDocument, baseOptions);
      }
export type FinishProcessingGroupStrainMutationHookResult = ReturnType<typeof useFinishProcessingGroupStrainMutation>;
export type FinishProcessingGroupStrainMutationResult = Apollo.MutationResult<FinishProcessingGroupStrainMutation>;
export type FinishProcessingGroupStrainMutationOptions = Apollo.BaseMutationOptions<FinishProcessingGroupStrainMutation, FinishProcessingGroupStrainMutationVariables>;
export const ProcessingGroupTemplatesDocument = gql`
    query processingGroupTemplates($filters: ProcessingGroupTemplateFilterSetInput) {
  processingGroupTemplates(filters: $filters) {
    ...ProcessingGroupTemplateFields
  }
}
    ${ProcessingGroupTemplateFieldsFragmentDoc}`;

/**
 * __useProcessingGroupTemplatesQuery__
 *
 * To run a query within a React component, call `useProcessingGroupTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupTemplatesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProcessingGroupTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupTemplatesQuery, ProcessingGroupTemplatesQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupTemplatesQuery, ProcessingGroupTemplatesQueryVariables>(ProcessingGroupTemplatesDocument, baseOptions);
      }
export function useProcessingGroupTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupTemplatesQuery, ProcessingGroupTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupTemplatesQuery, ProcessingGroupTemplatesQueryVariables>(ProcessingGroupTemplatesDocument, baseOptions);
        }
export type ProcessingGroupTemplatesQueryHookResult = ReturnType<typeof useProcessingGroupTemplatesQuery>;
export type ProcessingGroupTemplatesLazyQueryHookResult = ReturnType<typeof useProcessingGroupTemplatesLazyQuery>;
export type ProcessingGroupTemplatesQueryResult = Apollo.QueryResult<ProcessingGroupTemplatesQuery, ProcessingGroupTemplatesQueryVariables>;
export const ProcessingGroupTemplateDocument = gql`
    query processingGroupTemplate($id: String!) {
  processingGroupTemplate(id: $id) {
    ...ProcessingGroupTemplateFields
  }
}
    ${ProcessingGroupTemplateFieldsFragmentDoc}`;

/**
 * __useProcessingGroupTemplateQuery__
 *
 * To run a query within a React component, call `useProcessingGroupTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingGroupTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingGroupTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessingGroupTemplateQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingGroupTemplateQuery, ProcessingGroupTemplateQueryVariables>) {
        return Apollo.useQuery<ProcessingGroupTemplateQuery, ProcessingGroupTemplateQueryVariables>(ProcessingGroupTemplateDocument, baseOptions);
      }
export function useProcessingGroupTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingGroupTemplateQuery, ProcessingGroupTemplateQueryVariables>) {
          return Apollo.useLazyQuery<ProcessingGroupTemplateQuery, ProcessingGroupTemplateQueryVariables>(ProcessingGroupTemplateDocument, baseOptions);
        }
export type ProcessingGroupTemplateQueryHookResult = ReturnType<typeof useProcessingGroupTemplateQuery>;
export type ProcessingGroupTemplateLazyQueryHookResult = ReturnType<typeof useProcessingGroupTemplateLazyQuery>;
export type ProcessingGroupTemplateQueryResult = Apollo.QueryResult<ProcessingGroupTemplateQuery, ProcessingGroupTemplateQueryVariables>;
export const AddProcessingGroupTemplateDocument = gql`
    mutation addProcessingGroupTemplate($input: CreateProcessingGroupTemplateInput!) {
  createProcessingGroupTemplate(input: $input) {
    ...ProcessingGroupTemplateFields
  }
}
    ${ProcessingGroupTemplateFieldsFragmentDoc}`;
export type AddProcessingGroupTemplateMutationFn = Apollo.MutationFunction<AddProcessingGroupTemplateMutation, AddProcessingGroupTemplateMutationVariables>;

/**
 * __useAddProcessingGroupTemplateMutation__
 *
 * To run a mutation, you first call `useAddProcessingGroupTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProcessingGroupTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProcessingGroupTemplateMutation, { data, loading, error }] = useAddProcessingGroupTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProcessingGroupTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddProcessingGroupTemplateMutation, AddProcessingGroupTemplateMutationVariables>) {
        return Apollo.useMutation<AddProcessingGroupTemplateMutation, AddProcessingGroupTemplateMutationVariables>(AddProcessingGroupTemplateDocument, baseOptions);
      }
export type AddProcessingGroupTemplateMutationHookResult = ReturnType<typeof useAddProcessingGroupTemplateMutation>;
export type AddProcessingGroupTemplateMutationResult = Apollo.MutationResult<AddProcessingGroupTemplateMutation>;
export type AddProcessingGroupTemplateMutationOptions = Apollo.BaseMutationOptions<AddProcessingGroupTemplateMutation, AddProcessingGroupTemplateMutationVariables>;
export const UpdateActiveProcessingGroupTemplateVersionDocument = gql`
    mutation updateActiveProcessingGroupTemplateVersion($input: UpdateActiveProcessingGroupTemplateVersionInput!) {
  updateActiveProcessingGroupTemplateVersion(input: $input) {
    ...ProcessingGroupTemplateFields
  }
}
    ${ProcessingGroupTemplateFieldsFragmentDoc}`;
export type UpdateActiveProcessingGroupTemplateVersionMutationFn = Apollo.MutationFunction<UpdateActiveProcessingGroupTemplateVersionMutation, UpdateActiveProcessingGroupTemplateVersionMutationVariables>;

/**
 * __useUpdateActiveProcessingGroupTemplateVersionMutation__
 *
 * To run a mutation, you first call `useUpdateActiveProcessingGroupTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveProcessingGroupTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveProcessingGroupTemplateVersionMutation, { data, loading, error }] = useUpdateActiveProcessingGroupTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActiveProcessingGroupTemplateVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActiveProcessingGroupTemplateVersionMutation, UpdateActiveProcessingGroupTemplateVersionMutationVariables>) {
        return Apollo.useMutation<UpdateActiveProcessingGroupTemplateVersionMutation, UpdateActiveProcessingGroupTemplateVersionMutationVariables>(UpdateActiveProcessingGroupTemplateVersionDocument, baseOptions);
      }
export type UpdateActiveProcessingGroupTemplateVersionMutationHookResult = ReturnType<typeof useUpdateActiveProcessingGroupTemplateVersionMutation>;
export type UpdateActiveProcessingGroupTemplateVersionMutationResult = Apollo.MutationResult<UpdateActiveProcessingGroupTemplateVersionMutation>;
export type UpdateActiveProcessingGroupTemplateVersionMutationOptions = Apollo.BaseMutationOptions<UpdateActiveProcessingGroupTemplateVersionMutation, UpdateActiveProcessingGroupTemplateVersionMutationVariables>;
export const ArchiveProcessingGroupTemplateDocument = gql`
    mutation archiveProcessingGroupTemplate($id: ID!) {
  updateProcessingGroupTemplateArchived(input: {archivedAt: "true", id: $id}) {
    ...ProcessingGroupTemplateFields
  }
}
    ${ProcessingGroupTemplateFieldsFragmentDoc}`;
export type ArchiveProcessingGroupTemplateMutationFn = Apollo.MutationFunction<ArchiveProcessingGroupTemplateMutation, ArchiveProcessingGroupTemplateMutationVariables>;

/**
 * __useArchiveProcessingGroupTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveProcessingGroupTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProcessingGroupTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProcessingGroupTemplateMutation, { data, loading, error }] = useArchiveProcessingGroupTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveProcessingGroupTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProcessingGroupTemplateMutation, ArchiveProcessingGroupTemplateMutationVariables>) {
        return Apollo.useMutation<ArchiveProcessingGroupTemplateMutation, ArchiveProcessingGroupTemplateMutationVariables>(ArchiveProcessingGroupTemplateDocument, baseOptions);
      }
export type ArchiveProcessingGroupTemplateMutationHookResult = ReturnType<typeof useArchiveProcessingGroupTemplateMutation>;
export type ArchiveProcessingGroupTemplateMutationResult = Apollo.MutationResult<ArchiveProcessingGroupTemplateMutation>;
export type ArchiveProcessingGroupTemplateMutationOptions = Apollo.BaseMutationOptions<ArchiveProcessingGroupTemplateMutation, ArchiveProcessingGroupTemplateMutationVariables>;
export const UnarchiveProcessingGroupTemplateDocument = gql`
    mutation unarchiveProcessingGroupTemplate($id: ID!) {
  updateProcessingGroupTemplateArchived(input: {archivedAt: "false", id: $id}) {
    ...ProcessingGroupTemplateFields
  }
}
    ${ProcessingGroupTemplateFieldsFragmentDoc}`;
export type UnarchiveProcessingGroupTemplateMutationFn = Apollo.MutationFunction<UnarchiveProcessingGroupTemplateMutation, UnarchiveProcessingGroupTemplateMutationVariables>;

/**
 * __useUnarchiveProcessingGroupTemplateMutation__
 *
 * To run a mutation, you first call `useUnarchiveProcessingGroupTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveProcessingGroupTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveProcessingGroupTemplateMutation, { data, loading, error }] = useUnarchiveProcessingGroupTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveProcessingGroupTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveProcessingGroupTemplateMutation, UnarchiveProcessingGroupTemplateMutationVariables>) {
        return Apollo.useMutation<UnarchiveProcessingGroupTemplateMutation, UnarchiveProcessingGroupTemplateMutationVariables>(UnarchiveProcessingGroupTemplateDocument, baseOptions);
      }
export type UnarchiveProcessingGroupTemplateMutationHookResult = ReturnType<typeof useUnarchiveProcessingGroupTemplateMutation>;
export type UnarchiveProcessingGroupTemplateMutationResult = Apollo.MutationResult<UnarchiveProcessingGroupTemplateMutation>;
export type UnarchiveProcessingGroupTemplateMutationOptions = Apollo.BaseMutationOptions<UnarchiveProcessingGroupTemplateMutation, UnarchiveProcessingGroupTemplateMutationVariables>;
export const CreateToppingStyleDocument = gql`
    mutation createToppingStyle($input: CreateToppingStyleInput!) {
  createToppingStyle(input: $input) {
    ... on ToppingStyle {
      id
      name
    }
    ... on Error {
      message
    }
  }
}
    `;
export type CreateToppingStyleMutationFn = Apollo.MutationFunction<CreateToppingStyleMutation, CreateToppingStyleMutationVariables>;

/**
 * __useCreateToppingStyleMutation__
 *
 * To run a mutation, you first call `useCreateToppingStyleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateToppingStyleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createToppingStyleMutation, { data, loading, error }] = useCreateToppingStyleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateToppingStyleMutation(baseOptions?: Apollo.MutationHookOptions<CreateToppingStyleMutation, CreateToppingStyleMutationVariables>) {
        return Apollo.useMutation<CreateToppingStyleMutation, CreateToppingStyleMutationVariables>(CreateToppingStyleDocument, baseOptions);
      }
export type CreateToppingStyleMutationHookResult = ReturnType<typeof useCreateToppingStyleMutation>;
export type CreateToppingStyleMutationResult = Apollo.MutationResult<CreateToppingStyleMutation>;
export type CreateToppingStyleMutationOptions = Apollo.BaseMutationOptions<CreateToppingStyleMutation, CreateToppingStyleMutationVariables>;
export const DeleteToppingStyleDocument = gql`
    mutation deleteToppingStyle($input: DeleteToppingStyleInput!) {
  deleteToppingStyle(input: $input) {
    success
  }
}
    `;
export type DeleteToppingStyleMutationFn = Apollo.MutationFunction<DeleteToppingStyleMutation, DeleteToppingStyleMutationVariables>;

/**
 * __useDeleteToppingStyleMutation__
 *
 * To run a mutation, you first call `useDeleteToppingStyleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteToppingStyleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteToppingStyleMutation, { data, loading, error }] = useDeleteToppingStyleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteToppingStyleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteToppingStyleMutation, DeleteToppingStyleMutationVariables>) {
        return Apollo.useMutation<DeleteToppingStyleMutation, DeleteToppingStyleMutationVariables>(DeleteToppingStyleDocument, baseOptions);
      }
export type DeleteToppingStyleMutationHookResult = ReturnType<typeof useDeleteToppingStyleMutation>;
export type DeleteToppingStyleMutationResult = Apollo.MutationResult<DeleteToppingStyleMutation>;
export type DeleteToppingStyleMutationOptions = Apollo.BaseMutationOptions<DeleteToppingStyleMutation, DeleteToppingStyleMutationVariables>;
export const UpdateUserActiveDocument = gql`
    mutation updateUserActive($input: UpdateUserActiveInput!) {
  updateUserActive(input: $input) {
    ... on UserProfile {
      ...UserProfile
    }
    ... on Error {
      message
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateUserActiveMutationFn = Apollo.MutationFunction<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>;

/**
 * __useUpdateUserActiveMutation__
 *
 * To run a mutation, you first call `useUpdateUserActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserActiveMutation, { data, loading, error }] = useUpdateUserActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>) {
        return Apollo.useMutation<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>(UpdateUserActiveDocument, baseOptions);
      }
export type UpdateUserActiveMutationHookResult = ReturnType<typeof useUpdateUserActiveMutation>;
export type UpdateUserActiveMutationResult = Apollo.MutationResult<UpdateUserActiveMutation>;
export type UpdateUserActiveMutationOptions = Apollo.BaseMutationOptions<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ... on UserProfile {
      ...UserProfile
    }
    ... on Error {
      message
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    ... on UserProfile {
      ...UserProfile
    }
    ... on Error {
      message
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    ... on Role {
      ...RoleFields
    }
    ... on Error {
      message
    }
  }
}
    ${RoleFieldsFragmentDoc}`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const TokenAuthDocument = gql`
    mutation tokenAuth($username_Iexact: String!, $password: String!) {
  tokenAuth(username_Iexact: $username_Iexact, password: $password) {
    __typename
    ... on TokenAuthSuccess {
      token
      refreshToken
    }
    ... on Error {
      message
    }
  }
}
    `;
export type TokenAuthMutationFn = Apollo.MutationFunction<TokenAuthMutation, TokenAuthMutationVariables>;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      username_Iexact: // value for 'username_Iexact'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(baseOptions?: Apollo.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>) {
        return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(TokenAuthDocument, baseOptions);
      }
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<TokenAuthMutation, TokenAuthMutationVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation sendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    __typename
    ... on SendPasswordResetEmailSuccess {
      success
    }
    ... on Error {
      message
    }
  }
}
    `;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>) {
        return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, baseOptions);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;
export const SendAccountInvitationDocument = gql`
    mutation sendAccountInvitation($id: ID!) {
  sendAccountInvitation(id: $id) {
    user {
      ...UserProfile
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type SendAccountInvitationMutationFn = Apollo.MutationFunction<SendAccountInvitationMutation, SendAccountInvitationMutationVariables>;

/**
 * __useSendAccountInvitationMutation__
 *
 * To run a mutation, you first call `useSendAccountInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAccountInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAccountInvitationMutation, { data, loading, error }] = useSendAccountInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendAccountInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendAccountInvitationMutation, SendAccountInvitationMutationVariables>) {
        return Apollo.useMutation<SendAccountInvitationMutation, SendAccountInvitationMutationVariables>(SendAccountInvitationDocument, baseOptions);
      }
export type SendAccountInvitationMutationHookResult = ReturnType<typeof useSendAccountInvitationMutation>;
export type SendAccountInvitationMutationResult = Apollo.MutationResult<SendAccountInvitationMutation>;
export type SendAccountInvitationMutationOptions = Apollo.BaseMutationOptions<SendAccountInvitationMutation, SendAccountInvitationMutationVariables>;
export const PasswordResetDocument = gql`
    mutation passwordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
  passwordReset(
    token: $token
    newPassword1: $newPassword1
    newPassword2: $newPassword2
  ) {
    __typename
    ... on PasswordResetSuccess {
      success
    }
    ... on Error {
      message
    }
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword1: // value for 'newPassword1'
 *      newPassword2: // value for 'newPassword2'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, baseOptions);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    __typename
    ... on LogoutSuccess {
      success
    }
    ... on Error {
      message
    }
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: UpdateUserProfileInput) {
  updateUserProfile(input: $input) {
    __typename
    ... on UpdateUserProfileSuccess {
      user {
        ...UserProfile
      }
    }
    ... on Error {
      message
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const CreateOrganizationAndFirstUserDocument = gql`
    mutation createOrganizationAndFirstUser($input: CreateOrganizationAndFirstUserInput!) {
  createOrganizationAndFirstUser(input: $input) {
    ... on Organization {
      ...OrganizationFields
    }
    ... on Error {
      message
    }
  }
}
    ${OrganizationFieldsFragmentDoc}`;
export type CreateOrganizationAndFirstUserMutationFn = Apollo.MutationFunction<CreateOrganizationAndFirstUserMutation, CreateOrganizationAndFirstUserMutationVariables>;

/**
 * __useCreateOrganizationAndFirstUserMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationAndFirstUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationAndFirstUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationAndFirstUserMutation, { data, loading, error }] = useCreateOrganizationAndFirstUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationAndFirstUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationAndFirstUserMutation, CreateOrganizationAndFirstUserMutationVariables>) {
        return Apollo.useMutation<CreateOrganizationAndFirstUserMutation, CreateOrganizationAndFirstUserMutationVariables>(CreateOrganizationAndFirstUserDocument, baseOptions);
      }
export type CreateOrganizationAndFirstUserMutationHookResult = ReturnType<typeof useCreateOrganizationAndFirstUserMutation>;
export type CreateOrganizationAndFirstUserMutationResult = Apollo.MutationResult<CreateOrganizationAndFirstUserMutation>;
export type CreateOrganizationAndFirstUserMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationAndFirstUserMutation, CreateOrganizationAndFirstUserMutationVariables>;
export const UpdateOrganizationSettingsDocument = gql`
    mutation updateOrganizationSettings($input: UpdateOrganizationSettingsInput) {
  updateOrganizationSettings(input: $input) {
    ... on Organization {
      ...OrganizationFields
    }
    ... on Error {
      message
    }
  }
}
    ${OrganizationFieldsFragmentDoc}`;
export type UpdateOrganizationSettingsMutationFn = Apollo.MutationFunction<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>;

/**
 * __useUpdateOrganizationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingsMutation, { data, loading, error }] = useUpdateOrganizationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>) {
        return Apollo.useMutation<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>(UpdateOrganizationSettingsDocument, baseOptions);
      }
export type UpdateOrganizationSettingsMutationHookResult = ReturnType<typeof useUpdateOrganizationSettingsMutation>;
export type UpdateOrganizationSettingsMutationResult = Apollo.MutationResult<UpdateOrganizationSettingsMutation>;
export type UpdateOrganizationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>;
export const UpdateEnvironmentalTargetSetDocument = gql`
    mutation updateEnvironmentalTargetSet($input: UpdateEnvironmentalTargetSetInput!) {
  updateEnvironmentalTargetSet(input: $input) {
    ... on EnvironmentalTargetSet {
      ...EnvironmentalTargetSetFields
    }
    ... on Error {
      message
    }
  }
}
    ${EnvironmentalTargetSetFieldsFragmentDoc}`;
export type UpdateEnvironmentalTargetSetMutationFn = Apollo.MutationFunction<UpdateEnvironmentalTargetSetMutation, UpdateEnvironmentalTargetSetMutationVariables>;

/**
 * __useUpdateEnvironmentalTargetSetMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentalTargetSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentalTargetSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentalTargetSetMutation, { data, loading, error }] = useUpdateEnvironmentalTargetSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnvironmentalTargetSetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentalTargetSetMutation, UpdateEnvironmentalTargetSetMutationVariables>) {
        return Apollo.useMutation<UpdateEnvironmentalTargetSetMutation, UpdateEnvironmentalTargetSetMutationVariables>(UpdateEnvironmentalTargetSetDocument, baseOptions);
      }
export type UpdateEnvironmentalTargetSetMutationHookResult = ReturnType<typeof useUpdateEnvironmentalTargetSetMutation>;
export type UpdateEnvironmentalTargetSetMutationResult = Apollo.MutationResult<UpdateEnvironmentalTargetSetMutation>;
export type UpdateEnvironmentalTargetSetMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentalTargetSetMutation, UpdateEnvironmentalTargetSetMutationVariables>;
export const CreateEnvironmentalTargetSetDocument = gql`
    mutation createEnvironmentalTargetSet($input: CreateEnvironmentalTargetSetInput!) {
  createEnvironmentalTargetSet(input: $input) {
    ... on EnvironmentalTargetSet {
      ...EnvironmentalTargetSetFields
    }
    ... on Error {
      message
    }
  }
}
    ${EnvironmentalTargetSetFieldsFragmentDoc}`;
export type CreateEnvironmentalTargetSetMutationFn = Apollo.MutationFunction<CreateEnvironmentalTargetSetMutation, CreateEnvironmentalTargetSetMutationVariables>;

/**
 * __useCreateEnvironmentalTargetSetMutation__
 *
 * To run a mutation, you first call `useCreateEnvironmentalTargetSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnvironmentalTargetSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnvironmentalTargetSetMutation, { data, loading, error }] = useCreateEnvironmentalTargetSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnvironmentalTargetSetMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnvironmentalTargetSetMutation, CreateEnvironmentalTargetSetMutationVariables>) {
        return Apollo.useMutation<CreateEnvironmentalTargetSetMutation, CreateEnvironmentalTargetSetMutationVariables>(CreateEnvironmentalTargetSetDocument, baseOptions);
      }
export type CreateEnvironmentalTargetSetMutationHookResult = ReturnType<typeof useCreateEnvironmentalTargetSetMutation>;
export type CreateEnvironmentalTargetSetMutationResult = Apollo.MutationResult<CreateEnvironmentalTargetSetMutation>;
export type CreateEnvironmentalTargetSetMutationOptions = Apollo.BaseMutationOptions<CreateEnvironmentalTargetSetMutation, CreateEnvironmentalTargetSetMutationVariables>;
export const GetSignedUploadUrlDocument = gql`
    mutation getSignedUploadUrl($input: GetSignedUploadURLInput!) {
  getSignedUploadUrl(input: $input) {
    signedUploadUrl
    success
  }
}
    `;
export type GetSignedUploadUrlMutationFn = Apollo.MutationFunction<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>;

/**
 * __useGetSignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUploadUrlMutation, { data, loading, error }] = useGetSignedUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSignedUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>) {
        return Apollo.useMutation<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>(GetSignedUploadUrlDocument, baseOptions);
      }
export type GetSignedUploadUrlMutationHookResult = ReturnType<typeof useGetSignedUploadUrlMutation>;
export type GetSignedUploadUrlMutationResult = Apollo.MutationResult<GetSignedUploadUrlMutation>;
export type GetSignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>;
export const MyProfileDocument = gql`
    query myProfile {
  myProfile {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
        return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, baseOptions);
      }
export function useMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
          return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, baseOptions);
        }
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const ActiveUsersDocument = gql`
    query activeUsers {
  activeUsers {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useActiveUsersQuery__
 *
 * To run a query within a React component, call `useActiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveUsersQuery(baseOptions?: Apollo.QueryHookOptions<ActiveUsersQuery, ActiveUsersQueryVariables>) {
        return Apollo.useQuery<ActiveUsersQuery, ActiveUsersQueryVariables>(ActiveUsersDocument, baseOptions);
      }
export function useActiveUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveUsersQuery, ActiveUsersQueryVariables>) {
          return Apollo.useLazyQuery<ActiveUsersQuery, ActiveUsersQueryVariables>(ActiveUsersDocument, baseOptions);
        }
export type ActiveUsersQueryHookResult = ReturnType<typeof useActiveUsersQuery>;
export type ActiveUsersLazyQueryHookResult = ReturnType<typeof useActiveUsersLazyQuery>;
export type ActiveUsersQueryResult = Apollo.QueryResult<ActiveUsersQuery, ActiveUsersQueryVariables>;
export const UserManagementRowsDocument = gql`
    query userManagementRows {
  users {
    ...UserAttributes
  }
}
    ${UserAttributesFragmentDoc}`;

/**
 * __useUserManagementRowsQuery__
 *
 * To run a query within a React component, call `useUserManagementRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserManagementRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserManagementRowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserManagementRowsQuery(baseOptions?: Apollo.QueryHookOptions<UserManagementRowsQuery, UserManagementRowsQueryVariables>) {
        return Apollo.useQuery<UserManagementRowsQuery, UserManagementRowsQueryVariables>(UserManagementRowsDocument, baseOptions);
      }
export function useUserManagementRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserManagementRowsQuery, UserManagementRowsQueryVariables>) {
          return Apollo.useLazyQuery<UserManagementRowsQuery, UserManagementRowsQueryVariables>(UserManagementRowsDocument, baseOptions);
        }
export type UserManagementRowsQueryHookResult = ReturnType<typeof useUserManagementRowsQuery>;
export type UserManagementRowsLazyQueryHookResult = ReturnType<typeof useUserManagementRowsLazyQuery>;
export type UserManagementRowsQueryResult = Apollo.QueryResult<UserManagementRowsQuery, UserManagementRowsQueryVariables>;
export const RolesDocument = gql`
    query roles {
  roles {
    id
    name
    permissions
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const ToppingStylesDocument = gql`
    query toppingStyles {
  toppingStyles {
    id
    name
  }
}
    `;

/**
 * __useToppingStylesQuery__
 *
 * To run a query within a React component, call `useToppingStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `useToppingStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToppingStylesQuery({
 *   variables: {
 *   },
 * });
 */
export function useToppingStylesQuery(baseOptions?: Apollo.QueryHookOptions<ToppingStylesQuery, ToppingStylesQueryVariables>) {
        return Apollo.useQuery<ToppingStylesQuery, ToppingStylesQueryVariables>(ToppingStylesDocument, baseOptions);
      }
export function useToppingStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ToppingStylesQuery, ToppingStylesQueryVariables>) {
          return Apollo.useLazyQuery<ToppingStylesQuery, ToppingStylesQueryVariables>(ToppingStylesDocument, baseOptions);
        }
export type ToppingStylesQueryHookResult = ReturnType<typeof useToppingStylesQuery>;
export type ToppingStylesLazyQueryHookResult = ReturnType<typeof useToppingStylesLazyQuery>;
export type ToppingStylesQueryResult = Apollo.QueryResult<ToppingStylesQuery, ToppingStylesQueryVariables>;
export const StaffOrganizationsDocument = gql`
    query staffOrganizations {
  staffOrganizations {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;

/**
 * __useStaffOrganizationsQuery__
 *
 * To run a query within a React component, call `useStaffOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<StaffOrganizationsQuery, StaffOrganizationsQueryVariables>) {
        return Apollo.useQuery<StaffOrganizationsQuery, StaffOrganizationsQueryVariables>(StaffOrganizationsDocument, baseOptions);
      }
export function useStaffOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffOrganizationsQuery, StaffOrganizationsQueryVariables>) {
          return Apollo.useLazyQuery<StaffOrganizationsQuery, StaffOrganizationsQueryVariables>(StaffOrganizationsDocument, baseOptions);
        }
export type StaffOrganizationsQueryHookResult = ReturnType<typeof useStaffOrganizationsQuery>;
export type StaffOrganizationsLazyQueryHookResult = ReturnType<typeof useStaffOrganizationsLazyQuery>;
export type StaffOrganizationsQueryResult = Apollo.QueryResult<StaffOrganizationsQuery, StaffOrganizationsQueryVariables>;
export const StaffOrganizationDocument = gql`
    query staffOrganization($id: String!) {
  staffOrganization(id: $id) {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;

/**
 * __useStaffOrganizationQuery__
 *
 * To run a query within a React component, call `useStaffOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaffOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<StaffOrganizationQuery, StaffOrganizationQueryVariables>) {
        return Apollo.useQuery<StaffOrganizationQuery, StaffOrganizationQueryVariables>(StaffOrganizationDocument, baseOptions);
      }
export function useStaffOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffOrganizationQuery, StaffOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<StaffOrganizationQuery, StaffOrganizationQueryVariables>(StaffOrganizationDocument, baseOptions);
        }
export type StaffOrganizationQueryHookResult = ReturnType<typeof useStaffOrganizationQuery>;
export type StaffOrganizationLazyQueryHookResult = ReturnType<typeof useStaffOrganizationLazyQuery>;
export type StaffOrganizationQueryResult = Apollo.QueryResult<StaffOrganizationQuery, StaffOrganizationQueryVariables>;